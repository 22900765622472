import * as React from 'react';
import { ManualSignConstants } from '../../Common/Constants';
import { UploadDocuments } from './UploadDocuments';
import { ISignedDocument } from '../../../core/domain/models/manualsign/SignedDocument';
import IconInfoCircle from "src/assets/images/icon-info-circle.svg"
import { ICompany } from 'src/core/domain/models/company/Company';
import { CompanyInfo } from './CompanyInfo';

export interface UploadDocumentsProps {
    clientId: string;
    signedDocuments: ISignedDocument[];
    addSignedDocument?(clientId: string, signedDocument: ISignedDocument, successCallback?: () => void, failureCallback?: () => void): void;
    deleteSignedDocument?(clientId: string, id: number): void;
    requestSignedDocument(clientId: string): void;
    isDelegatee: boolean;
    disableNextButton?(value: boolean): void;
    companyInfo: ICompany;
}

export interface UploadDocumentsState {

}

export class UploadDocumentsArea extends React.Component<UploadDocumentsProps, UploadDocumentsState> {

    public render() {

        const { clientId, signedDocuments, addSignedDocument, deleteSignedDocument, requestSignedDocument, companyInfo } = this.props;

        return (<div className={"col-lg-12 no-padding upload-documents-area"} data-test-auto="FF0E42CF-9DD1-4370-AFB1-9C29D890F85C">

            <div className={"col-lg-12 no-padding upload-documents-title"}>
                <img className="info" src={IconInfoCircle} />
                <strong data-test-auto={"418960D4-28E5-4B54-97FA-426B49041520"}>
                    {ManualSignConstants.UploadDocumentTitle}
                </strong>
            </div>

            <div className={"upload-documents-container no-padding row"}>

                <UploadDocuments clientId={clientId}
                    addSignedDocument={addSignedDocument}
                    deleteSignedDocument={deleteSignedDocument}
                    signedDocuments={signedDocuments}
                    requestSignedDocument={requestSignedDocument}
                    isDelegatee={this.props.isDelegatee}
                    disableNextButton={this.props.disableNextButton}
                />
                <CompanyInfo address={companyInfo.address} companyDetails={companyInfo}
                    fax={companyInfo.fax} />

            </div>

        </div>);
    }
}
