import * as React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { IK1ShareHolderDetails, initialK1ShareHolderDetails } from '../../core/domain/models/IK1Distribute';
import { K1ShareHolderConstants } from '../Common/Constants';
import { match } from 'react-router';
import { MarsNotifier } from '../Common/Notification/MarsNotifier';
import { isValidEmailAddress, NullandEmptyCheck } from '../Common/Validations';

interface EditK1PartnerProps {
    showState: boolean;
    onCancel(): void;
    k1Partner: IK1ShareHolderDetails;
    match: match;
    type: string;
    updateK1ShareHolderEmail?: (clientGuid: string, k1Partner: IK1ShareHolderDetails, successCallback?: () => void) => void;
}

interface EditK1PartnerState {
    shareHolder: IK1ShareHolderDetails;
}

export class EditK1PartnerEmail extends React.Component<EditK1PartnerProps, EditK1PartnerState>{
    constructor(props: any) {
        super(props);
        this.state = {
            shareHolder: initialK1ShareHolderDetails
        }
    }

    componentWillReceiveProps(nextProps: EditK1PartnerProps) {
        if (nextProps.k1Partner) {
            this.setState({
                shareHolder: nextProps.k1Partner
            })
        }
    }

    private onMailChange = (event: any) => {
        let temp = { ...this.state.shareHolder };
        temp.email = event.target.value;
        this.setState({
            shareHolder: temp
        })
    }

    onUpdatePartner = () => {
        if (this.validateEmail()) {
            let param: any = this.props.match.params;
            if (this.props.updateK1ShareHolderEmail === undefined) {
                this.props.onCancel && this.props.onCancel()
            }
            else {
                this.props.updateK1ShareHolderEmail && this.props.updateK1ShareHolderEmail(param.clientId, this.state.shareHolder, () => { this.props.onCancel && this.props.onCancel() });
            }
        }
    }

    validateEmail() {
        if (!NullandEmptyCheck(this.state.shareHolder.email)) {
            MarsNotifier.Warning(K1ShareHolderConstants.WarningMessage.EmailAdrressWarning, null);
            return false;
        }
        else if (!isValidEmailAddress(this.state.shareHolder.email.trim())) {
            MarsNotifier.Warning(K1ShareHolderConstants.WarningMessage.InvalidEmailAddress, null);
            return false;
        }
        return true;
    }

    public render() {

        return (<Modal show={this.props.showState} onHide={this.props.onCancel} className="k1PartnerPopup">
            <Modal.Header closeButton data-test-auto="685F189F-418D-4819-8802-8F96D20A275D">
                <Modal.Title>
                    Update K1 {this.props.type} Email
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='row padT10'>
                    <div className='col-sm-4 col-md-4 col-xl-4 padT5'>
                        Email Address
                           </div>
                    <div className='col-sm-8 col-md-8 col-xl-8'>
                        <input type='text'
                            placeholder="Email Address"
                            className='form-control'
                            value={this.state.shareHolder.email}
                            onChange={(event) => this.onMailChange(event)}
                            data-test-auto="03182E2C-D126-4D82-86B8-936A4C0ABA2F">
                        </input>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={this.props.onCancel}
                    data-test-auto="0315CB8B-E54F-4FED-9744-85CB2D87E773"
                    className="btn btn-white btn-default rounded"
                    variant={"light"}>
                    <i className="fa fa-times"></i> Cancel
                    </Button>
                <Button
                    onClick={this.onUpdatePartner}
                    data-test-auto="F14431E7-1FAC-4B03-87C9-D33B64476275"
                    variant={"primary"}
                    className="btn btn-primary rounded">
                    <i className="fa fa-save"></i> Save
                    </Button>
            </Modal.Footer>
        </Modal>)
    }
}