import { ApplicationState } from "../../store/index";
import { ScreenShare } from "./ScreenShare";
import { connect } from 'react-redux';
import { actionCreators as helperAction } from '../../store/Common/HelperStore';


function mapStateToProps(state: ApplicationState) {
    return {
        iceServers: state.commonData.iceServers
    };
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        getIceServers: (clientId: string) => dispatch(helperAction.getIceServers(clientId)),

        dispatch
    }
}


const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(ScreenShare);