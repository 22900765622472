import * as React from 'react';
import * as PayWizard from '../Pay/Main/Pay';
import * as PayStore from '../../store/PayStore';
import * as TaxDocumentStore from '../../store/Common/TaxDocumentStore';
import { IPayScreenViewModel } from '../../core/domain/viewModels/IPayScreenViewModel';
import { ICommonDataViewModel } from '../../core/domain/viewModels/ICommonDataViewModel';
import { IDocumentSettings } from '../../core/domain/models/IDocumentSettings';
import * as TaxDocument from '../../core/domain/models/ITaxReturn';
import { match, RouteComponentProps } from 'react-router';
import { ICompany } from '../../core/domain/models/company/Company';
import * as CompanyStore from '../../store/Common/Company/CompanyStore';
import { History } from 'history';
import { IHeaderInfoViewModel, initialHeaderInfoViewModel } from '../../core/domain/viewModels/IHeaderInfoViewModel';
import * as PreparerMessageStore from '../../store/PreparerMessageStore';
import * as HelperStore from '../../store/Common/HelperStore';
import { PathConstants, VoucherPaymentConstants } from '../Common/Constants';
import { StepLayout } from '../Common/StepLayout/StepLayout';
import { Body } from '../Common/StepLayout/Body';
import { Footer } from '../Common/StepLayout/Footer';
import { Next } from 'awesome-multi-steps/dist/Footer/Next';
import { Previous } from 'awesome-multi-steps/dist/Footer/Previous';
import TaxpayerHelper from '../Helper/TaxpayerHelper';
import { IClientProcessViewModel } from '../../core/domain/viewModels/IClientProcessViewModel'
import { RedirectionSourcePage } from '../../core/common/Enums'
import * as SignProcessStore from '../../store/SignProcess/SignProcessStore';
import { Header } from '../Common/StepLayout/Header';
import { PaymentInstruction } from './PaymentInstruction';
import { LeftPanel } from '../Common/StepLayout/LeftPanel';
import { TaxPayment } from './TaxPayment';
import { IVoucher } from '../../core/domain/models/IGroup';
import { IDialogBox } from '../../core/utilities/ui/DialogBox';
import { container } from '../../startup/inversify.config';
import { TYPES } from '../../startup/types';
import { logger } from '../../routes';
import { LogEventConstants } from '../Helper/Constants';


const dialogBox = container.get<IDialogBox>(TYPES.IDialogBox);


type PayCompletedProps = {
    payScreenData: IPayScreenViewModel,
    commonData: ICommonDataViewModel,
    documentSettings: IDocumentSettings,
    taxReturn: TaxDocument.ITaxReturn,
    headInfoViewModel: IHeaderInfoViewModel,
    clientProcessData: IClientProcessViewModel;
    companyData: ICompany,
    match: match;
    history: History;
}
    & typeof CompanyStore.actionCreators
    & typeof HelperStore.actionCreators
    & typeof TaxDocumentStore.actionCreators
    & typeof PreparerMessageStore.actionCreators
    & typeof PayStore.actionCreators
    & typeof SignProcessStore.actionCreators;

interface PayCompletedState {
    isProcessCompleted: boolean;
    isClientView: boolean;
    isPreviewMode: boolean;
}


const pageTitle = "Pay completed Step";
export class PayCompleted extends React.Component<PayCompletedProps, PayCompletedState> {
    private _params: any;
    private _payRef: any;
    constructor(props: PayCompletedProps) {
        super(props);
        this.state = {
            isProcessCompleted: false,
            isClientView: false,
            isPreviewMode: false
        }
    }

    componentDidMount() {
        const param: any = this.props.match.params;
        this.getSignProcessStatus(param.clientId);
        this.props.requestDocumentSettings(param.clientId);
        if (this.props.commonData && this.props.commonData.taxingAuthorities.length == 0) {
            this.props.requestAllTaxingAuthorities(param.clientId);
        }
        if (!this.props.companyData || !this.props.companyData.onlinePaymentUrl) {
            // this.props.requestCompanyDetails(param.clientId);
        }
        this.props.requestTaxDocument(param.clientId);
        logger.trackEvent(
            logger.buildEvent(
                `${LogEventConstants.Common.Open}  ${pageTitle}`,
                { count: 1, page: pageTitle, clientId: param.clientId }
            )
        );
    }

    gotoCompletionWizard = () => {
        const param: any = this.props.match.params;
        this._payRef && this._payRef.updateVoucherReminderDetails(
            () => {
                this.props.history.push(`${PathConstants.CompletionWizard}${param.clientId}`);
            });
            logger.trackEvent(
                logger.buildEvent(
                    `${LogEventConstants.Common.Pay.GoToCompleteWizard}  ${pageTitle}`,
                    {
                        count: 1, page: pageTitle,
                        CustomEventType: LogEventConstants.Common.DocumentEventName,
                        DocumentGuid: this.props.taxReturn.documentGuid,
                        clientId: param.clientId
                    }
                )
            );
    }
    private getSignProcessStatus = (clientId: any) => {
        this.props.requestCurrentStepAndUserRole(clientId, this.handleRedirect);
    }
    private handleRedirect = (clientProcessState: IClientProcessViewModel) => {
        this._params = this.props.match.params;
        const url = TaxpayerHelper.getSignCompletedRedirectUrl(clientProcessState, this._params.clientId);

        if (url && url.trim()) {
            this.props.history.push(url);
            this.setState({ isClientView: TaxpayerHelper.isPreviewModeWithWritePermission(clientProcessState) });
        }
        else {
            this.setState({
                isProcessCompleted: true,
                isClientView: TaxpayerHelper.isPreviewModeWithWritePermission(clientProcessState),
                isPreviewMode: TaxpayerHelper.isPreviewMode(clientProcessState),
            });
            logger.trackEvent(
                logger.buildEvent(
                    `${LogEventConstants.Common.Pay.ProcessCompleted}  ${pageTitle}`,
                    { count: 1, page: pageTitle, clientId: this._params.cleintId }
                )
            );
        }
    }

    saveVoucherPayment = (voucher: IVoucher) => {
        if (this.state.isClientView) {
            dialogBox.alert(VoucherPaymentConstants.AddingVoucherPaymentNotAllowed);
        }
        else {
            const param: any = this.props.match.params;
            if (voucher !== null && voucher !== undefined) {
                if (!voucher.isPaid) {
                    this.props.addVoucherPayemnt(param.clientId, voucher);
                }
                else {
                    this.props.updateVoucherPayemnt(param.clientId, voucher);
                }
            }
        }
    }

    isPayPageDirectAccessFromUTE =():boolean => {
        return this.props.clientProcessData.redirectionSourcePage === RedirectionSourcePage.Pay;
    }

    handleBackButtonAction = () => {
        this.isPayPageDirectAccessFromUTE() ? this.redirectToOneHub() : this.gotoCompletionWizard();
    }

    handleNextButtonAction = () => {
        this.isPayPageDirectAccessFromUTE() ? this.redirectToOneHub() : this.gotoCompletionWizard();
    }

    redirectToOneHub = () => {
        let params: any = this.props.match.params;
        this.props.getClientHubDomainURL(params.clientId, (oneHubDomainURL: string) => {
            window.location.href = oneHubDomainURL + PathConstants.UTEPay;
        });
    }

    public render() {

        return ((this.state.isProcessCompleted) &&
            <StepLayout marginTop={20} className={"with-footer"}>

                <Body
                    lg={11} md={12} sm={12} xs={12}
                    noBackgroundColor={true}
                    bodyClassName={"no-padding no-margin pay-step row"}
                    automationTestId={"11BB28B5-42F3-4571-B567-20700B1740B6"}>

                    <Header lg={12} md={12} sm={12} xs={12} >
                        <PaymentInstruction clientInstructions={this.props.payScreenData.paymentInstructions} />
                    </Header>

                    {this.props.documentSettings.documentNotificationSetting.paymentVoucherNotificationSettingsModel && this.props.taxReturn.formGroups.length > 0 &&
                        <PayWizard.Pay
                            ref={(ref: any) => { this._payRef = ref }}
                            commonData={this.props.commonData} match={this.props.match}
                            history={this.props.history} 
                            payScreenData={this.props.payScreenData}
                            requestPayScreenDetails={this.props.requestPayScreenDetails}
                            documentSettings={this.props.documentSettings}
                            taxReturn={this.props.taxReturn}
                            downloadAllVouchers={this.props.downloadAllVouchers}
                            downloadFilingInstructions={this.props.downloadFilingInstructions}
                            updateVoucherNotificationSettings={this.props.updateVoucherNotificationSettings}
                            downloadSingleVoucher={this.props.downloadSingleVoucher}
                            saveVoucherPayment={this.saveVoucherPayment}
                            isPreviewMode={this.state.isPreviewMode}
                        />
                    }

                </Body>

                <Footer lg={11} md={12} sm={12} xs={12}>
                    <div className={"col-lg-12 steps-footer "}>
                        <Previous automationTestId={"3F43C07D-73AD-4BB5-83D1-346B1E62ABC6"} disablePreviousButton={this.props.payScreenData.taxYear === 0}
                        
                            text={"Back"} goToPreviousStep={this.handleBackButtonAction} isActive={true} />

                        <Next automationTestId={"21596F53-89B5-4830-812E-96D126E7C1AE"} disableNextButton={this.props.payScreenData.taxYear === 0}
                            text={"Done"} goToNextStep={this.handleNextButtonAction} isActive={true} />
                    </div>
                </Footer>

            </StepLayout>);

    }
}
