import * as React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { MarsNotifier } from '../Common/Notification/MarsNotifier';

interface RemarksProps {
    show: boolean;
    onSaveRemarks(): void;
    reason: string;
    onCancel: () => void;
    onRemarksChange: (event: any) => void;
    title: string;
    warningMessage: string;
}

const DelegateeCancelPopup: React.FC<RemarksProps> = (props) => {
    const onSubmit = () => {
        if (props.reason.length === 0) {
            return MarsNotifier.Warning(props.warningMessage, null);
        }

        props.onSaveRemarks();
    };

    return (<Modal show={props.show} onHide={props.onCancel} >
        <Modal.Header closeButton>
            <Modal.Title>
                <span style={{ color: '#0274bb' }} >
                    <i className="fa fa-bullhorn" style={{ marginRight: 5 }}></i>
                    {props.title}</span>
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>

            <div>
                <div style={{ padding: "10px 10px 10px 10px" }}>
                    <textarea value={props.reason} onChange={(e: any) => { props.onRemarksChange(e) }}
                        className="form-control remarksTextArea"
                    />
                </div>
            </div>
        </Modal.Body>
        <Modal.Footer>
            <Button
                onClick={props.onCancel}
                className="btn btn-white btn-default rounded"
                variant={"light"}>
                <i className="fa fa-times"></i> Cancel
               </Button>

            <Button
                onClick={onSubmit}
                className="btn btn-primary rounded"
                variant={"primary"}>
                <i className="fa fa-save"></i> Submit
                </Button>
        </Modal.Footer>
    </Modal>);

};

export { DelegateeCancelPopup };
