import * as React from 'react';

export const InvalidLink: React.FC<{}> = ({ }) => {

    return <div>
        <div className="invalid-container">
            <div className="invalid-header">
            </div>
            <div className="content-wrapper">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 error-page">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 error-page-container">
                        <div className="message">
                            This link is no longer valid. Please contact the company that prepared your tax return extension.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>;
};
