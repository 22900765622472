import { History } from 'history';
import * as React from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { HeaderStoreState } from '../../../../store/Login/Models/StoreModels';
import {
    SvgIconContactHeader
} from './../../../Common/Icons/SvgIcons';
import { Toaster } from "./../../../Common/Notification/MarsNotifier";
import Notification from "./../../../Common/Notification/NotificationContainer";

export type HeaderProps = {
    headerInfo: HeaderStoreState;
    backButton: boolean;
    navigateLink: "/CoverPage/Index" | "/GroupedReturns/CoverPage/Index" | "";
    pathname: string;
    clientId: string;
    history: History;
}

export const Header: React.FC<HeaderProps> = ({ headerInfo, backButton, navigateLink, clientId, history }) => {

    //window.Variables.companyName = headerInfo.companyName;

    let mailStr = "";

    let contactInfoPopover = (<></>);

    if (headerInfo.contactPerson !== null) {

        mailStr = "mailto:" + headerInfo.contactPerson.email;

        contactInfoPopover = (
            <Popover id="contactInfoPopover" placement="bottom">
                <h3 className="popover-header">Contact Information</h3>
                <div className="popover-body" data-test-auto="3464EE21-1DF8-4F1F-BE4B-D838ACE36298">
                    <div className='medium'>{headerInfo.contactPerson.firstName + " " + headerInfo.contactPerson.lastName} </div>
                    <div className='mail'>
                        <a href={mailStr}>{headerInfo.contactPerson.email}</a>
                    </div>
                </div>
            </Popover>
        );
    }

    let headerBGColor = "#2bcb15";
    let headerColorCode = "#e41c11";

    let companyLogo = null;

    let homeNavigate: any = "";

    if (headerInfo.brandingSettings) {
        headerBGColor = headerInfo.brandingSettings.coverPageSetting.bgColorCode;
        headerColorCode = headerInfo.brandingSettings.coverPageSetting.foreColorCode;
    }

    if (backButton) {
        homeNavigate = <span className="header-contact-info"
            style={{ color: headerColorCode }}
            onClick={() => { history.push(`${navigateLink}/${clientId}`); }}>
            <i className="fa fa-home"></i> Back To Home
        </span>;
    }

    if (headerInfo.companyWhiteLogoPath.length === 0) {
        companyLogo = <span className="company-name" style={{ color: headerColorCode }}>{headerInfo.companyName}</span>;
    } else {
        companyLogo = <img className="logo-img" src={headerInfo.companyWhiteLogoPath} />;
    }

    return (
        <>
            <Notification />
            <Toaster />
            <header className="app-header" style={{ backgroundColor: headerBGColor, color: headerColorCode, position: "absolute", width: "100%" }}>
                <div className="header-left-container" data-test-auto="951602DF-76D9-480A-BA0F-D12E216FBB2B">
                    {companyLogo}
                </div>
                <div
                    className="header-right-container"
                    data-test-auto="710F957F-072E-4A27-867F-0B40C2077D3B">

                    {homeNavigate}

                    {
                        headerInfo.contactPerson !== null ?
                            <span className="header-contact-info" style={{ color: headerColorCode }} title="Contact Person's Information">
                                <OverlayTrigger
                                    data-test-auto="CB7E32C5-518D-40B5-8E8F-E75C9A89CF58"
                                    rootClose trigger="click"
                                    onEnter={() => { document.body.click() }}
                                    overlay={contactInfoPopover}
                                    placement="bottom">
                                    <a>
                                        <SvgIconContactHeader
                                            fillColor={headerColorCode}
                                            height={20} />
                                        Contact Person
                                    </a>
                                </OverlayTrigger>
                            </span> : ""
                    }
                </div>
            </header>
        </>
    );
}
export default Header;
