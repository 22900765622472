import { History } from 'history';
import * as React from 'react';
import { match, RouteComponentProps } from 'react-router';
import { IDownloadableDocumentsViewModel } from '../../../../src/core/domain/viewModels/IDownloadableDocumentsViewModel';
import * as TaxDocument from '../../../core/domain/models/ITaxReturn';
import { ISignedDocument } from '../../../core/domain/models/manualsign/SignedDocument';
import * as EsignStore from '../../../store/Common/EsignStore';
import * as TaxDocumentStore from '../../../store/Common/TaxDocumentStore';
import * as DownloadableDocumentStore from '../../../store/DownloadableDocumentsStore';
import * as SignProcessStore from '../../../store/SignProcess/SignProcessStore';
import * as DelegateeSignerStore from '../../../store/Delegatee/DelegateeSignerStore';
import * as DelegateeSignProcessStore from '../../../store/Delegatee/DelegateeSignProcessStore';
import { CompletedSummaryPage } from './CompletedSummaryPage';
import { IDelegateeInfo } from '../../../core/domain/models/DelegateeModels';
import { Main } from '../../Summary/Main/Main';
import { logger } from '../../../routes';
import { LogEventConstants } from '../../Helper/Constants';
import { IClientProcessViewModel } from 'src/core/domain/viewModels/IClientProcessViewModel';
import * as HelperStore from '../../../store/Common/HelperStore';
import * as CompanyStore from '../../../store/Common/Company/CompanyStore';


export type CompleteWizardComponentProps = {
    history: History;
    match: match;
    delegateeSignerInfo: IDelegateeInfo;
    taxReturn: TaxDocument.ITaxReturn,
    delegateeSignedDocuments: ISignedDocument[];
    downloadableDocument: IDownloadableDocumentsViewModel;
    clientProcessData: IClientProcessViewModel;
    getClientHubDomainURL: (id: string, callback: (clientHubDomainURL: string) => void) => void;
}
    & typeof EsignStore.actionCreators
    & typeof TaxDocumentStore.actionCreators
    & typeof DownloadableDocumentStore.actionCreators
    & typeof SignProcessStore.actionCreators
    & typeof DelegateeSignerStore.actionCreators
    & typeof DelegateeSignProcessStore.actionCreators
    & typeof HelperStore.actionCreators
    & typeof CompanyStore.actionCreators
    & RouteComponentProps<{}>;

interface CompleteWizardComponentState {

}

const pageTitle = "Delegatee Completed Summary Page";
export class CompletedWizardFlow extends React.Component<CompleteWizardComponentProps, CompleteWizardComponentState> {
    constructor(props: CompleteWizardComponentProps) {
        super(props);

    }

    componentDidMount() {
        let param: any = this.props.match.params;
        this.props.requestCompanyDetails(param.clientId, true, true);
        this.props.requestDelegateeCurrentStepAndUserRole(param.clientId);
        this.props.requestDelegateeDetails(param.clientId);
        this.props.requestDownloadableDocuments(param.clientId);
        this.props.requestDelegateeSignedDocuments(param.clientId, true);
        this.props.requestTaxDocument(param.clientId);
        logger.trackEvent(
            logger.buildEvent(
                `${LogEventConstants.Common.Open}  ${pageTitle}`,
                { count: 1, page: pageTitle, clientId: param.clientId }
            )
        );
    }

    public render() {


        return (
            <div id="summary-review" className="row no-padding no-margin width-100 completed-summary-step-container margin-top-20 center-align">

                <Main>
                    <CompletedSummaryPage
                        history={this.props.history}
                        param={this.props.match.params}
                        taxReturn={this.props.taxReturn}
                        clientName={this.props.delegateeSignerInfo.firstName + " " + this.props.delegateeSignerInfo.lastName}
                        taxYear={this.props.taxReturn.taxYear}
                        downloadSignedEFileDocument={this.props.downloadDelegateeSignedEFileDocument}
                        signedDocument={this.props.delegateeSignedDocuments}
                        downloadAllAdditionEsignDocuments={this.props.delegateeDownloadAllAdditionEsignDocuments}
                        downloadableDocumentsViewModel={this.props.downloadableDocument}
                        downloadEFileDocument={this.props.delegateeDownloadEfileForms}
                        clientProcessData={this.props.clientProcessData}
                        getClientHubDomainURL = {this.props.getClientHubDomainURL}
                    />
                </Main>
            </div>
        );
    }

}
