import { ApplicationState } from "../../store/index";
import { actionCreators as accountAction } from '../../store/Common/AccountStore';
import { actionCreators as helperAction } from '../../store/Common/HelperStore';
import { actionCreators as esignAction } from '../../store/Common/EsignStore';
import { actionCreators as downloadAction } from '../../store/DownloadableDocumentsStore';
import { actionCreators as taxDocumentAction } from '../../store/Common/TaxDocumentStore';
import { DefaultLayout } from "./DefaultLayout";
import { connect } from 'react-redux';
import { createLoadingSelector } from '../../store/selectors';
import { withRouter } from "react-router";
import { ClientType } from '../../core/common/Enums';
import { IDocument } from "../../core/domain/models/esign/Document";
import { ITaxReturn } from '../../core/domain/models/ITaxReturn';
import { actionCreators as TaxDocumentStore } from '../../store/Common/TaxDocumentStore';
import { IAccessCodeValidation } from "../../core/domain/viewModels/ICommon";
const loadingSelector = createLoadingSelector(['HEADERINFO', 'TAXING_AUTHORITY']);


function mapStateToProps(state: ApplicationState) {
    return {
        headerInfo: state.headerInfo,
        loading: loadingSelector(state),
        taxReturn: state.taxReturn.taxDocument,
        signerData: state.esignData.signerDetails,
        downloadList: state.downloadableDocuments,
        iceServers: state.commonData.iceServers,
        companyData: state.companyData.data,
        clientProcessData: state.signProcessData.clientprocessmodel
    };
}
//temperroy fix for webpack failure need to invetigate further when we have time
const mapDispatchToProps = (dispatch: any) => {
    return {
        requestHeaderInfo: (id: string) =>
            dispatch(accountAction.requestHeaderInfo(id)),

        refreshToken: (clientId: string, failurecallback: () => void) =>
            dispatch(accountAction.refreshToken(clientId, failurecallback)),

        getSessionTimeOutSeconds: (clientId: string, callBack?: (sessionTimeout: number) => void) => dispatch(accountAction.getSessionTimeOutSeconds(clientId, callBack)),

        refreshCookies: (clientId: string) =>
            dispatch(accountAction.refreshCookies(clientId)),

        requestAllTaxingAuthorities: (id: string) =>
            dispatch(helperAction.requestAllTaxingAuthorities(id)),

        requestTaxDocument: (id: string) =>
            dispatch(taxDocumentAction.requestTaxDocument(id)),

        requestKBAStatus: (id: string) =>
            dispatch(taxDocumentAction.requestKBAStatus(id)),

        requestTaxDocumentclientType: (id: string) =>
            dispatch(taxDocumentAction.requestTaxDocumentclientType(id)),

        clientAuthentication: (clientGuid: string, failureCallback: () => void) =>
            dispatch(esignAction.clientAuthentication(clientGuid, failureCallback)),

        clientValidation: (clientGuid: string, data: any, successCallback: () => void, failureCallback: () => void) =>
            dispatch(esignAction.clientValidation(clientGuid, data, successCallback, failureCallback)),

        requestSigners: (clientGuid: string) =>
            dispatch(esignAction.requestSigners(clientGuid)),

        requestSignatureControls: (clientGuid: string) =>
            dispatch(esignAction.requestSignatureControls(clientGuid)),

        requestPreviewSignatureControls: (clientGuid: string) =>
            dispatch(esignAction.requestPreviewSignatureControls(clientGuid)),

        updateDOB: (clientGuid: string, id: string, dob: string, step: boolean, callback: (step: boolean) => void) =>
            dispatch(esignAction.updateDOB(clientGuid, id, dob, step, callback)),

        updateDocumentSignatureSettingModel: (clientId: string, signatureType: number, callback: any) =>
            dispatch(esignAction.updateDocumentSignatureSettingModel(clientId, signatureType, callback)),

        updateSignatureControlsData: (data: any) =>
            dispatch(esignAction.updateSignatureControlsData(data)),

        updateSpouseMail: (clientGuid: string, id: string, mail: string, type: ClientType, step?: boolean, callback?: (step: boolean) => void) =>
            dispatch(esignAction.updateSpouseMail(clientGuid, id, mail, type, step, callback)),

        updateSpouseMobileNumber: (clientGuid: string, id: string, number: string, countryCodeValue: string, ssnValue: string, clientType: ClientType, callback: () => void) =>
            dispatch(esignAction.updateSpouseMobileNumber(clientGuid, id, number, countryCodeValue, ssnValue, clientType, callback)),

        updateMobileNumber: (clientGuid: string, id: string, number: string, countryCodeValue: string,
            ssnValue: string, clientType: ClientType, callback: () => void) =>
            dispatch(accountAction.updateMobileNumber(clientGuid, id, number, countryCodeValue, ssnValue, clientType, callback)),

        sign: (clientId: string, documentData: IDocument[], callback: (status: boolean) => void) =>
            dispatch(esignAction.sign(clientId, documentData, callback)),

        updateTaxDocument: (taxDocument: ITaxReturn) =>
            dispatch(taxDocumentAction.updateTaxDocument(taxDocument)),

        requestNextSigner: (clientGuid: string) =>
            dispatch(TaxDocumentStore.requestNextSigner(clientGuid)),

        requestMyDownload: (clientId: string) =>
            dispatch(downloadAction.requestMyDownload(clientId)),

        deleteMyDownloads: (downloadId: string, clientId: string) =>
            dispatch(downloadAction.deleteMyDownloads(downloadId, clientId)),

        clearAllMyDownloads: (clientId: string) =>
            dispatch(downloadAction.clearAllMyDownloads(clientId)),

        validateTimeBasedAccessCode: (accessCode: IAccessCodeValidation, callback: () => void) =>
            dispatch(helperAction.validateTimeBasedAccessCode(accessCode, callback)),

        getIceServers: (clientId: string) =>
            dispatch(helperAction.getIceServers(clientId)),

        requestRecipientHeaderInfo: (id: string) =>
            dispatch(accountAction.requestRecipientHeaderInfo(id)),

        review: (clientGuid: string, callback?: (status: boolean) => void) =>
            dispatch(esignAction.review(clientGuid, callback)),

        getWalkMeScript: (clientId: string, callback: (script: string) => void, isAuthorized?: boolean) =>
            dispatch(helperAction.getWalkMeScript(clientId, callback, isAuthorized)),

        requestDelegateeHeaderInfo: (id: string) =>
            dispatch(accountAction.requestDelegateeHeaderInfo(id)),

        requestDelegateeKBAStatus: (clientGuid: string, successCallback?: () => void) =>
            dispatch(taxDocumentAction.requestDelegateeKBAStatus(clientGuid, successCallback)),

        logout: (clientGuid: string, callback: (id: string) => void) =>
            dispatch(accountAction.logout(clientGuid, callback)),

        relatedTaxReturnDelivered: (clientGuid: string) =>
            dispatch(taxDocumentAction.relatedTaxReturnDelivered(clientGuid)),

        togglePaymentAlert: (toggle: boolean) =>
            dispatch(taxDocumentAction.togglePaymentAlert(toggle)),

        getClientHubDomainURL: (clientGuid: string, callback: (hubDomainURL: string) => void) => 
            dispatch(helperAction.getClientHubDomainURL(clientGuid, callback)),

        checkIfUserExistsInOneHub: (clientGuid: string, callback: (isUserExists: boolean) => void) =>
            dispatch(helperAction.checkIfUserExistsInOneHub(clientGuid, callback)),
        
        getOnehubRedirectURL: (clientGuid: string, callback: (onehubRedirectURL: string) => void) =>
            dispatch(helperAction.getOnehubRedirectURL(clientGuid, callback)),

        dispatch
    }
}

const connector = connect(mapStateToProps, mapDispatchToProps);

export default withRouter(connector(DefaultLayout));
