import { Next } from 'awesome-multi-steps/dist/Footer/Next';
import { Previous } from 'awesome-multi-steps/dist/Footer/Previous';
import { History } from 'history';
import * as React from 'react';
import { match } from 'react-router';
import { ICompany } from '../../core/domain/models/company/Company';
import { ISignedDocument } from '../../core/domain/models/manualsign/SignedDocument';
import * as CompanyStore from '../../store/Common/Company/CompanyStore';
import * as ManualSignStore from '../../store/Sign/ManualSignStore';
import { ManualSignConstants, PathConstants, Common } from '../Common/Constants';
import { Body } from '../Common/StepLayout/Body';
import { Footer } from '../Common/StepLayout/Footer';
import { StepLayout } from '../Common/StepLayout/StepLayout';
import { ManualSign } from '../ManualSign/ManualSign';
import TaxpayerHelper from '../Helper/TaxpayerHelper';
import { IClientProcessViewModel } from '../../core/domain/viewModels/IClientProcessViewModel'
import { Role } from '../../core/common/Enums'
import * as SignProcessStore from '../../store/SignProcess/SignProcessStore';
import { IDialogBox } from '../../core/utilities/ui/DialogBox';
import { container } from '../../startup/inversify.config';
import { TYPES } from '../../startup/types';
import { IHeaderInfoViewModel } from '../../core/domain/viewModels/IHeaderInfoViewModel';
import { IAdditionalEsignDocument } from '../../core/domain/models/IAdditionalEsignDocument';
import { ITaxReturn } from '../../core/domain/models/ITaxReturn';
import { logger } from '../../routes';
import { LogEventConstants } from '../Helper/Constants';


type ManualSignCompletedProps = {
    manualSignData: ISignedDocument[],
    companyData: ICompany,
    headInfoViewModel: IHeaderInfoViewModel,
    match: match;
    history: History;
    additionalEsignDocuments: IAdditionalEsignDocument[];
    downloadAllAdditionEsignDocuments: (clientId: string, fileName: string) => any;
    requestAdditionalEsignDocument(clientGuid: string): void;
    taxReturn: ITaxReturn;
    clientName: string;
}
    & typeof CompanyStore.actionCreators
    & typeof ManualSignStore.actionCreators
    & typeof SignProcessStore.actionCreators;

interface ManualSignCompletedState {
    isProcessCompleted: boolean;
    isPreviewMode: boolean;
}


const dialogBox = container.get<IDialogBox>(TYPES.IDialogBox);
const pageTitle = "Manual Signcomplete Step";
export class ManualSignCompleted extends React.Component<ManualSignCompletedProps, ManualSignCompletedState> {
    private _params: any;
    private _manualSign: any;
    private _manualSignData: ISignedDocument[] = [];
    constructor(props: ManualSignCompletedProps) {
        super(props);
        this.state = {
            isProcessCompleted: false,
            isPreviewMode: false
        }
    }
    componentDidMount() {
        const param: any = this.props.match.params;
        this.getSignProcessStatus(param.clientId);
        this.props.requestCompanyDetails(param.clientId, true, true);
        logger.trackPageView(`ManualSignCompleted page`);
        logger.trackEvent(
            logger.buildEvent(
                `${LogEventConstants.Common.Open}  ${pageTitle}`,
                { count: 1, page: pageTitle, clientId: param.clientId }
            )
        );
    }
    componentWillReceiveProps() {
        if (this._manualSignData.length <= 0) {
            this._manualSignData = this.props.manualSignData;
        }

    }

    gotoCompletionWizard = () => {
        let param: any = this.props.match.params;
        this.props.history.push(`${PathConstants.CompletionWizard}${param.clientId}`);
    }

    private getSignProcessStatus = (clientId: any) => {
        this.props.requestCurrentStepAndUserRole(clientId, this.handleRedirect);
    }
    private handleRedirect = (clientProcessState: IClientProcessViewModel) => {
        this._params = this.props.match.params;
        const url = TaxpayerHelper.getSignCompletedRedirectUrl(clientProcessState, this._params.clientId);
        if (url && url.trim()) {
            this.props.history.push(url);
        }
        this.setState({ isProcessCompleted: true, isPreviewMode: TaxpayerHelper.isPreviewMode(clientProcessState) });
    }

    confirmChanges = () => {
        dialogBox.confirm(
            Common.DialogBox.ConfirmationTitle,
            ManualSignConstants.ConfirmMessage.replace("<COMPANYNAME>", this.props.headInfoViewModel.companyName),
            Common.DialogBox.Cancel,
            Common.DialogBox.Confrim,
            (result: boolean) => { result && this._manualSign && this._manualSign.completeManualSign() });
    }


    private onCompletion = () => {
        if (this._manualSignData.length === 0) {
            this.props.manualSignData.length > 0 ? this.confirmChanges() : this.gotoCompletionWizard();
        }
        else if (this.props.manualSignData.length === 0) {
            this.gotoCompletionWizard();
        }
        else {
            let done: boolean = true;
            for (let i = 0; i < this.props.manualSignData.length; i++) {
                if (this._manualSignData.some(x => x.id === this.props.manualSignData[i].id)) {
                    continue;
                }
                else {
                    done = false;
                    this.confirmChanges();
                }
            }
            done && this.gotoCompletionWizard();
        }

    }
    public render() {

        return (
            (this.state.isProcessCompleted) &&
            <StepLayout className={"with-footer"} marginTop={20}>
                <Body title={ManualSignConstants.Title}
                    height={100}
                    lg={9} md={12} sm={12} xs={12}
                    automationTestId={"11BB28B5-42F3-4571-B567-20700B1740B6"}>
                    <ManualSign match={this.props.match}
                        downloadEfileForms={this.props.downloadEfileForms}
                        addSignedDocument={this.state.isPreviewMode ? undefined : this.props.addSignedDocument}
                        deleteSignedDocument={this.state.isPreviewMode ? undefined : this.props.deleteSignedDocument}
                        requestSignedDocuments={this.props.requestSignedDocuments}
                        signedDocuments={this.props.manualSignData}
                        completeManualSign={this.props.completeManualSign}
                        onCompletion={this.gotoCompletionWizard}
                        ref={(ref: any) => { this._manualSign = ref }}
                        additionalEsignDocuments={this.props.additionalEsignDocuments}
                        downloadAllAdditionEsignDocuments={this.props.downloadAllAdditionEsignDocuments}
                        requestAdditionalEsignDocument={this.props.requestAdditionalEsignDocument}
                        clientName={this.props.headInfoViewModel.clientName}
                        taxReturn={this.props.taxReturn}
                        isPreviewMode={this.state.isPreviewMode}
                        isDelegatee={false}
                        companyInfo={this.props.companyData}
                    />
                </Body>

                <Footer lg={9} md={12} sm={12} xs={12}>
                    <div className={"col-lg-12 steps-footer "}>
                        <Previous automationTestId={"3F43C07D-73AD-4BB5-83D1-346B1E62ABC6"}
                            text={"Back"} goToPreviousStep={this.gotoCompletionWizard} isActive={true} />

                        <Next automationTestId={"21596F53-89B5-4830-812E-96D126E7C1AE"}
                            text={"Done"} goToNextStep={this.onCompletion} isActive={true} />
                    </div>
                </Footer>

            </StepLayout>)

    }
}

