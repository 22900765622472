import { IDocumentSettings, DefaultSenderInfoType, DeliveryMode, PaperReturnDeliveryType } from "./IDocumentSettings";
import { IAttachment } from "./IAttachment";
import { IDocumentAccess } from "./IDocumentAccess";
import { IDocumentTransaction } from "./IDocumentTransaction";
import { ClientTypes, ITaxpayer, ITaxClient } from "./ITaxClient";
import { ISignerModel } from "./ISignerModel";
import { IGroup } from "src/core/domain/models/IGroup";
import { IUserModel, initialUserModel } from "src/core/domain/models/IUserModel";
import { IRefundInfo } from "src/core/domain/models/IRefundInfo";
import { ClientType, DocumentStatus, EngagementTypeS, KBAMode, SignatureStatus, SignatureType } from "src/core/common/Enums";

export interface ITaxReturn {
    formGroups: IGroup[];
    documentSettings: IDocumentSettings;
    id: number;
    clientId: string;
    clientType: ClientType;
    documentStatus: DocumentStatus;
    uploadedOn: Date;
    partnerId: number;
    partner: IUserModel;
    assignToUserName: IUserModel;
    deliveredByUser: IUserModel;
    signatureStatus: SignatureStatus;
    createdBy: number;
    engagementType: EngagementTypeS;
    taxYear: number;
    assignTo: number;
    assignedUser: IUserModel;
    lockedBy: number;
    invoiceAmount: number;
    deliveredOn: Date;
    attachments: IAttachment[];
    documentGuid: string;
    isModified: boolean;
    isfullyLoaded: boolean;
    refundInfo: IRefundInfo[];
    documentAccess: IDocumentAccess;
    clientTracking: IDocumentTransaction[];
    signedDetails: ISignerModel[];
    accessCode: IAccessCode[];
    downloadableEfileForms: IDownloadableDocuments[];
    downloadableDocuments: IDownloadableDocuments[];
    downloadHistory: IDocumentTransaction[];
    taxCaddyLookupResultModel: TaxCaddyLookupResultModel;
    taxSoftware: TaxSoftwareType;
    deliveredBy?: number;
    relatedTaxReturnDelivered: boolean;
};

export interface IAccessCode {
    name: string,
    clientGuid: string,
    documentId: number,
    clientType: ClientTypes,
    createdOn: Date,
    otp: string,
    retryCount: number
}

export interface IDownloadableDocuments {
    documentId: number;
    groupId: FormType;
    fileName: string;
    shareholderId: number;
    documentGuid: string;
    taxYear: number;
    createdOn: Date;
    signedDocumentId: number;
}

export enum FormType {
    None = 0,
    Transmittals = 1,
    EFile,
    Vouchers,
    TaxReturns,
    Invoice,
    Deleted,
    K1
}

export interface TaxCaddyLookupResultModel {
    isSuccess: boolean;
    result: string;
    resultObjectModel: TaxCaddyLookupResultObjectModel;
}

export interface TaxCaddyLookupResultObjectModel {
    taxPayerUniqueId: number;
    taxpayerName: string;
    emailAddress: string;
    ssn: string;
    address: string;
    taxClientId: string;
    taxYear: number[];
}

export enum TaxSoftwareType {
    ProSystems = "ProSystems",
    UltraTax = "UltraTax",
    GoSystem = "GoSystem",
    UltraTaxM18 = "UltraTaxM18",
    Intuit = "Intuit",
    Drake = "Drake"
}

export interface IIndividualTaxReturn extends ITaxReturn {
    taxpayer: ITaxpayer;
}

export interface IMarriedJointTaxReturn extends IIndividualTaxReturn {
    spouse: ITaxpayer;
}

export interface ICorporateTaxReturn extends ITaxReturn {
    partnership: ITaxClient;
}

export function isIndividual(arg: any): arg is IIndividualTaxReturn {
    return arg.taxpayer !== undefined
        && (arg.spouse === undefined || arg.spouse === null)
        && arg.partnership === undefined;
}

export function isMutual(arg: any): arg is IMarriedJointTaxReturn {
    return arg.taxpayer !== undefined
        && arg.spouse !== undefined
        && arg.partnership === undefined;
}

export function isPartnership(arg: any): arg is ICorporateTaxReturn {
    return arg && arg.partnership !== undefined
        && arg.taxpayer === undefined
        && arg.spouse === undefined;
}

export function ToIndividual(arg: any) {
    return arg as IIndividualTaxReturn;
}

export function ToMutual(arg: any) {
    return arg as IMarriedJointTaxReturn;
}

export function ToPartnership(arg: any) {
    return arg as ICorporateTaxReturn;
}

export function dynamicCast(arg: ITaxReturn) {
    if (isIndividual(arg)) {
        return arg as IIndividualTaxReturn;
    }
    if (isPartnership(arg)) {
        return arg as ICorporateTaxReturn;
    }
    if (isMutual(arg)) {
        return arg as IMarriedJointTaxReturn;
    }
    return arg;
}

export const initialTaxReturn: Readonly<ITaxReturn> = {
    formGroups: [],
    documentSettings: {
        documentId: 0,
        deliverySettings: {
            deliverTo: ClientTypes.Undefined,
            contactPerson: 0,
            preparerMessage: {
                id: 0,
                name: "",
                body: "",
                isAllowToEdit: false
            },
            sender: {
                senderType: DefaultSenderInfoType.None,
                senderId: 0
            },
            notifyUser: 0,
            manualAddressId: 0,
            isDirectDeliveryToTaxCaddy: false,
            deliveryMode: DeliveryMode.ElectronicFiled,
            paperReturnSettings: {
                documentOrder: [],
                deliveryType: PaperReturnDeliveryType.MultipleFiles,
                isNotifyUserEnabled: false
            }
        },
        documentDisplaySetting: {
            isSendWithoutInvoice: false,
            isEnableInvoice: false,
            customColumn: {
                id: 0,
                value: "",
                customColumn: 0,
                inUseCount: 0
            }
        },
        documentSignatureSetting: {
            signatureFormSelectionType: SignatureType.None,
            knowledgeBasedAuthentication: false,
            signatureStampUser: initialUserModel,
            kbaMode: KBAMode.Test,
            enableDelegateeKbaAuthentication: false,
            enableSignerDelegation: false
        },
        documentNotificationSetting: {
            paymentVoucherNotificationSettingsModel: {
                enablePaymentVoucherReminder: false,
                noOfDaysForVoucherReminder: 0,
                disabledVoucherReminderFromTP: false
            },
            signingReminderNotificationSettingsModel: {
                enableSigningReminder: false,
                noOfDaysForSigningReminder: 0
            },
            notifyUser: initialUserModel
        },
        documentRetentionSetting: {
            retentionPeriod: 2520
        },
        isModified: false,
    },
    id: 0,
    clientId: "",
    clientType: ClientType.Undefined,
    documentStatus: DocumentStatus.None,
    uploadedOn: new Date(2020, 7, 23),
    partnerId: 0,
    partner: initialUserModel,
    assignToUserName: initialUserModel,
    deliveredByUser: initialUserModel,
    signatureStatus: SignatureStatus.None,
    createdBy: 0,
    engagementType: EngagementTypeS.None,
    taxYear: 0,
    assignTo: 0,
    assignedUser: initialUserModel,
    lockedBy: 0,
    invoiceAmount: 0,
    deliveredOn: new Date(2020, 7, 26),
    attachments: [],
    documentGuid: "",
    isModified: false,
    isfullyLoaded: false,
    refundInfo: [],
    documentAccess: {
        documentId: 0,
        userId: []
    },
    clientTracking: [],
    signedDetails: [],
    accessCode: [],
    downloadableEfileForms: [],
    downloadableDocuments: [],
    downloadHistory: [],
    taxCaddyLookupResultModel: {
        isSuccess: false,
        result: '',
        resultObjectModel: {
            taxPayerUniqueId: 0,
            taxpayerName: '',
            emailAddress: '',
            ssn: '',
            address: '',
            taxClientId: '',
            taxYear: []
        }
    },
    taxSoftware: TaxSoftwareType.ProSystems,
    relatedTaxReturnDelivered: false
}


export function getClientGuid(taxReturn: ITaxReturn) {
    if (isPartnership(taxReturn)) { return taxReturn.partnership.clientGuid }
    if (isMutual(taxReturn)) { return taxReturn.taxpayer.clientGuid }
    if (isIndividual(taxReturn)) { return taxReturn.taxpayer.clientGuid }
    return "";
}