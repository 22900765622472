import { Action, Reducer } from 'redux';
import { AppThunkAction } from '../index';
import { actionTypes } from '../ActionTypes';
import { initializeAxios } from 'src/core/services/dataAccess/DataService.Axios'
import { AxiosResponse } from 'axios';
import { StatusType, NotificationAction } from '../Common/NotificationStore';
import { ITaxReturn, initialTaxReturn } from '../../core/domain/models/ITaxReturn';
import { ClientType } from 'src/core/common/Enums';
import { ISignerModel } from 'src/core/domain/models/ISignerModel';
import { initialEsignState } from '../../store/Common/EsignStore';
import { ErrorMessages } from 'src/components/Common/Constants';
import { TelemetryLogger } from '../../components/Logger/AppInsights';

const logger = TelemetryLogger.getInstance();

interface RequestTaxDocumentDocumentAction {
    type: actionTypes.TAXDOCUMENT_REQUEST;
    data: ITaxReturn;
}

interface ResponseTaxDocumentDocumentAction {
    type: actionTypes.TAXDOCUMENT_RESPONSE;
    data: ITaxReturn;
}

interface ResponseRelatedTaxReturnDeliveredAction {
    type: actionTypes.RELATED_TAXRETURN_DELIVERED;
    returnDelivered: boolean;
}

interface FailureTaxDocumentDocumentAction {
    type: actionTypes.TAXDOCUMENT_FAILURE;
    data: ITaxReturn;
}

interface RequestSkipAuthenticationAction {
    type: actionTypes.SA_REQUEST;
    data: boolean;
}

interface ResponseSkipAuthenticationAction {
    type: actionTypes.SA_RESPONSE;
    data: boolean;
}

interface FailureSkipAuthenticationAction {
    type: actionTypes.SA_FAILURE;
    data: boolean;
}

export interface TaxDocumentState {
    taxDocument: ITaxReturn;
    isKBAPassed: boolean;
    showPaymentAlert: boolean;
}

export const initialTaxDocumentState: TaxDocumentState = {
    taxDocument: initialTaxReturn,
    isKBAPassed: false,
    showPaymentAlert: true,
}

interface RequestTaxDocumentDocumentClientTypeAction {
    type: actionTypes.TAXDOCUMENT_CLIENT_TYPE_REQUEST;
    data: ClientType;
}

interface ResponseTaxDocumentDocumentClientTypeAction {
    type: actionTypes.TAXDOCUMENT_CLIENT_TYPE_RESPONSE;
    data: ClientType;
}

interface FailureTaxDocumentDocumentClientTypeAction {
    type: actionTypes.TAXDOCUMENT_CLIENT_TYPE_FAILURE;
    data: ClientType;
}

interface RequestNextSignerDetailsAction {
    type: actionTypes.NEXT_SIGNER_REQUEST;
}

interface ResponseNextSignerDetailsAction {
    type: actionTypes.NEXT_SIGNER_RESPONSE;
    data: ISignerModel[];
}

interface FailureNextSignerDetailsAction {
    type: actionTypes.NEXT_SIGNER_FAILURE;
    data: ISignerModel[];
}

interface RequestDelegateeKBAStatusAction {
    type: actionTypes.DELEGATEE_KBA_STATUS_REQUEST;
    kbaStatus: boolean;
}

interface ResponseDelegateeKBAStatusAction {
    type: actionTypes.DELEGATEE_KBA_STATUS_RESPONSE;
    kbaStatus: boolean;
}

interface FailureDelegateeKBAStatusAction {
    type: actionTypes.DELEGATEE_KBA_STATUS_FAILURE;
}

interface PaymentAlertToggleAction {
    type: actionTypes.PAYMENT_ALERT_TOGGLE;
    toggle: boolean;
}

type KnownAction =
    DispatchAction |
    NotificationAction;

type DispatchAction = ResponseTaxDocumentDocumentAction
    | RequestTaxDocumentDocumentAction
    | FailureTaxDocumentDocumentAction
    | RequestSkipAuthenticationAction
    | ResponseSkipAuthenticationAction
    | FailureSkipAuthenticationAction
    | RequestTaxDocumentDocumentClientTypeAction
    | ResponseTaxDocumentDocumentClientTypeAction
    | FailureTaxDocumentDocumentClientTypeAction
    | RequestNextSignerDetailsAction
    | ResponseNextSignerDetailsAction
    | FailureNextSignerDetailsAction
    | RequestDelegateeKBAStatusAction
    | ResponseDelegateeKBAStatusAction
    | FailureDelegateeKBAStatusAction
    | ResponseRelatedTaxReturnDeliveredAction
    | PaymentAlertToggleAction;

export const actionCreators = {
    requestTaxDocument: (clientGuid: string, callback?: (data?: any) => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const state = getState();
        dispatch({ type: actionTypes.TAXDOCUMENT_REQUEST, data: state.taxReturn ? state.taxReturn.taxDocument : initialTaxDocumentState.taxDocument });
        return initializeAxios().get<ITaxReturn>('/api/Esign/GetTaxDocument/' + clientGuid)
            .then(function (response: AxiosResponse<ITaxReturn>) {
                dispatch({
                    type: actionTypes.TAXDOCUMENT_RESPONSE, data: response.data
                });
                if (callback) {
                    callback(response.data);
                }
            })
            .catch(function (error: any) {
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: error.response ? error.response.statusText : ErrorMessages.RequestDocumentError,
                    statusType: StatusType.Error
                });
                dispatch({ type: actionTypes.TAXDOCUMENT_FAILURE, data: state.taxReturn ? state.taxReturn.taxDocument : initialTaxDocumentState.taxDocument });
                logger.trackError(`requestTaxDocument failed with error ${error.message} for client: ${clientGuid}`);
            });
    },
    updateTaxDocument: (taxReturn: ITaxReturn, callback?: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: actionTypes.TAXDOCUMENT_RESPONSE, data: taxReturn, });
        if (callback) {
            callback();
        }
    },

    requestTaxDocumentclientType: (clientGuid: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const state = getState();
        dispatch({ type: actionTypes.TAXDOCUMENT_CLIENT_TYPE_REQUEST, data: state.taxReturn ? state.taxReturn.taxDocument.clientType : initialTaxDocumentState.taxDocument.clientType });
        return initializeAxios().get<ClientType>('/api/Esign/GetTaxDocumentClientType/' + clientGuid)
            .then(function (response: AxiosResponse<ClientType>) {
                dispatch({
                    type: actionTypes.TAXDOCUMENT_CLIENT_TYPE_RESPONSE, data: response.data
                });
            })
            .catch(function (error: any) {
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: error.response ? error.response.statusText : ErrorMessages.RequestClientType,
                    statusType: StatusType.Error
                });
                dispatch({ type: actionTypes.TAXDOCUMENT_CLIENT_TYPE_FAILURE, data: state.taxReturn ? state.taxReturn.taxDocument.clientType : initialTaxDocumentState.taxDocument.clientType });
                logger.trackError(`requestTaxDocumentclientType failed with error ${error.message} for client: ${clientGuid}`);
            });
    },

    requestKBAStatus: (clientGuid: string, successCallback?: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const state = getState();
        dispatch({ type: actionTypes.SA_REQUEST, data: state.taxReturn ? state.taxReturn.isKBAPassed : initialTaxDocumentState.isKBAPassed });
        return initializeAxios().get<boolean>('/api/Esign/GetKBAStatus/' + clientGuid)
            .then(function (response: AxiosResponse<boolean>) {
                dispatch({
                    type: actionTypes.SA_RESPONSE, data: response.data
                });
                successCallback && successCallback();
            })
            .catch(function (error: any) {
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: error.response ? error.response.statusText : ErrorMessages.RequestKBAStatus,
                    statusType: StatusType.Error
                });
                dispatch({ type: actionTypes.SA_FAILURE, data: state.taxReturn ? state.taxReturn.isKBAPassed : initialTaxDocumentState.isKBAPassed });
                logger.trackError(`requestKBAStatus failed with error ${error.message} for client: ${clientGuid}`);
            });
    },

    requestNextSigner: (clientGuid: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const state = getState();
        dispatch({ type: actionTypes.NEXT_SIGNER_REQUEST });
        return initializeAxios().get<ISignerModel[]>('/api/Esign/GetAllSigners/' + clientGuid)
            .then(function (response: AxiosResponse<ISignerModel[]>) {
                dispatch({
                    type: actionTypes.NEXT_SIGNER_RESPONSE, data: response.data
                });
            })
            .catch(function (error: any) {
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: error.response ? error.response.statusText : ErrorMessages.RequestNextSigner,
                    statusType: StatusType.Error
                });
                dispatch({ type: actionTypes.NEXT_SIGNER_FAILURE, data: state.esignData ? state.esignData.signerDetails : initialEsignState.signerDetails });
                logger.trackError(`requestNextSigner failed with error ${error.message} for client: ${clientGuid}`);
            });
    },

    requestDelegateeKBAStatus: (clientGuid: string, successCallback?: () => void): AppThunkAction<KnownAction> =>
        (dispatch, getState) => {
            const state = getState();
            dispatch({ type: actionTypes.DELEGATEE_KBA_STATUS_REQUEST, kbaStatus: state.taxReturn ? state.taxReturn.isKBAPassed : initialTaxDocumentState.isKBAPassed });
            return initializeAxios().get<boolean>('/api/DelegateeSigner/GetDelegateeKBAStatus/' + clientGuid)
                .then(function (response: AxiosResponse<boolean>) {
                    dispatch({
                        type: actionTypes.DELEGATEE_KBA_STATUS_RESPONSE, kbaStatus: response.data
                    });
                    successCallback && successCallback();
                })
                .catch(function (error: any) {
                    dispatch({
                        type: actionTypes.NOTIFICATION, statusMessage: error.response ? error.response.statusText : ErrorMessages.RequestKBAStatus,
                        statusType: StatusType.Error
                    });
                    dispatch({ type: actionTypes.DELEGATEE_KBA_STATUS_FAILURE });
                });
        },
    relatedTaxReturnDelivered: (clientGuid: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const state = getState();
        return initializeAxios().get<boolean>('/api/Esign/IsTaxReturnDeliveredForExtension/' + clientGuid)
            .then(function (response: AxiosResponse<boolean>) {
                dispatch({
                    type: actionTypes.RELATED_TAXRETURN_DELIVERED,
                    returnDelivered: response.data
                });
            })
            .catch(function (error: any) {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: error.response ? error.response.statusText : "Failed to get relatedTaxReturnDelivered",
                    statusType: StatusType.Error
                });
                dispatch({ type: actionTypes.TAXDOCUMENT_FAILURE, data: state.taxReturn ? state.taxReturn.taxDocument : initialTaxDocumentState.taxDocument });
                logger.trackError(`relatedTaxReturnDelivered failed with error ${error.message} for client: ${clientGuid}`);
            });
    },

    togglePaymentAlert: (toggle: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: actionTypes.PAYMENT_ALERT_TOGGLE, toggle })
    }
}

export const reducer: Reducer<TaxDocumentState> = (state: TaxDocumentState = initialTaxDocumentState, incomingAction: Action) => {
    const action = incomingAction as DispatchAction;
    const currentState = Object.assign({}, state);
    switch (action.type) {
        case actionTypes.TAXDOCUMENT_REQUEST:
            currentState.taxDocument = action.data;
            return currentState;
        case actionTypes.TAXDOCUMENT_RESPONSE:

            const relatedTaxReturnDelivered = currentState?.taxDocument?.relatedTaxReturnDelivered ?? false;

            if (currentState.taxDocument.clientType != ClientType.Undefined || currentState.taxDocument.signedDetails) {
                const clientType = currentState.taxDocument.clientType;
                const signedDetails = currentState.taxDocument.signedDetails;
                currentState.taxDocument = action.data;
                currentState.taxDocument.clientType = clientType;
                currentState.taxDocument.signedDetails = signedDetails;
                currentState.taxDocument.relatedTaxReturnDelivered = relatedTaxReturnDelivered;
                return currentState;
            }
            currentState.taxDocument = action.data;
            currentState.taxDocument.relatedTaxReturnDelivered = relatedTaxReturnDelivered;
            return currentState;
        case actionTypes.TAXDOCUMENT_FAILURE:
            currentState.taxDocument = action.data
            return currentState;
        case actionTypes.SA_REQUEST:
            currentState.isKBAPassed = action.data;
            return currentState;
        case actionTypes.SA_RESPONSE:
            currentState.isKBAPassed = action.data;
            return currentState;
        case actionTypes.SA_FAILURE:
            currentState.isKBAPassed = action.data
            return currentState;
        case actionTypes.TAXDOCUMENT_CLIENT_TYPE_REQUEST:
            currentState.taxDocument.clientType = action.data;
            return currentState;
        case actionTypes.TAXDOCUMENT_CLIENT_TYPE_RESPONSE:
            currentState.taxDocument.clientType = action.data;
            return currentState;
        case actionTypes.TAXDOCUMENT_CLIENT_TYPE_FAILURE:
            currentState.taxDocument.clientType = action.data;
            return currentState;
        case actionTypes.NEXT_SIGNER_REQUEST:
            return currentState;
        case actionTypes.NEXT_SIGNER_RESPONSE:
            currentState.taxDocument.signedDetails = action.data;
            return currentState;
        case actionTypes.NEXT_SIGNER_FAILURE:
            currentState.taxDocument.signedDetails = action.data;
            return currentState;
        case actionTypes.DELEGATEE_KBA_STATUS_REQUEST:
            currentState.isKBAPassed = action.kbaStatus;
            return currentState;
        case actionTypes.DELEGATEE_KBA_STATUS_RESPONSE:
            currentState.isKBAPassed = action.kbaStatus;
            return currentState;
        case actionTypes.RELATED_TAXRETURN_DELIVERED:
            currentState.taxDocument = { ...currentState.taxDocument, relatedTaxReturnDelivered: action.returnDelivered };
            return currentState;
        case actionTypes.PAYMENT_ALERT_TOGGLE:
            currentState.showPaymentAlert = action.toggle;
            return currentState;

        default:
            return currentState || initialTaxDocumentState;
    }
};