import * as React from 'react';
import Moment from 'moment/moment';
import { Modal, Col, Row } from 'react-bootstrap';

import { ITaxingAuthority } from 'src/core/domain/models/ITaxingAuthority';
import { IUtilities } from '../../core/utilities/Utilities';
import { IVoucher } from 'src/core/domain/models/IGroup';
import { container } from '../../startup/inversify.config';
import { TYPES } from '../../startup/types';
import { CustomButton, CustomButtonSize, CustomButtonPlacement } from '../Common/CustomButton/CustomButton';
import {
    SvgIconOnlinePayment,
    SvgIconPayTaxPrep,
    SvgIconDownloadAll
} from '../Common/Icons/SvgIcons';
import { Common } from '../Common/Constants';
import { AuthorityImage, StateImages } from '../Common/Icons/StateImages';
import { logger } from '../../routes';
import { LogEventConstants } from '../Helper/Constants';

const utilities = container.get<IUtilities>(TYPES.IUtilities);


export interface PaymentInformationPopupProps {
    show: boolean;
    taxingAuthority: ITaxingAuthority;
    voucher: IVoucher;
    clientId: string;
    downloadFilingInstructions: (clientId: string) => void;
    downloadSingleVoucher: (clientId: string, fileName: string, pageNo: string, bookMarks: string) => void;
    onCancel(): void;
    filingInstructionExists: boolean;
}

interface PaymentInformationPopupState {
    showPopup: boolean;
}

const pageTitle = "Payment Information Step";
export class PaymentInformationPopup extends React.Component<PaymentInformationPopupProps, PaymentInformationPopupState> {
    constructor(props: PaymentInformationPopupProps) {
        super(props);
        this.state = {
            showPopup: true,
        }
        logger.trackEvent(
            logger.buildEvent(
                `${LogEventConstants.Common.Open}  ${pageTitle}`,
                { count: 1, page: pageTitle }
            )
        );
    }

    private renderVoucherBody = () => {

        const authorityName = StateImages.find(s => s.stateUSA === this.props.taxingAuthority.AuthorityName)?.imageName;

        return <>
            {
                this.props.filingInstructionExists &&

                <div style={{ marginTop: '-10px', marginBottom: '71px' }}>
                    <CustomButton placement={CustomButtonPlacement.Right}
                        icon={Common.Images.download}
                        text="Download Filing Instructions"
                        onClick={() => this.props.downloadFilingInstructions(this.props.clientId)}
                        automationTestId="EHA1CUJW0814Z17FGPPKUXZU2H"
                    />
                </div>
            }
            <div>
                <table className="voucher">
                    <tbody>
                        <tr>
                            <td
                                rowSpan={3}
                                className="pay-icon image-col">
                                <AuthorityImage
                                    data-test-auto="XN61MH4536D4Z8GJPGX8XL1UCD"
                                    authoriyName={authorityName}
                                    className="state-shadow"
                                    height={110}
                                    width={110}
                                />
                            </td>
                            <td colSpan={3}
                                data-test-auto="TT25GUFQC8LMV4JL9UKMUL28LM"
                                style={{ paddingTop: "10px" }}
                                className="pay-fee-name large-text">
                                {this.props.taxingAuthority.AuthorityName == "Federal" ? "Internal Revenue Service" : this.props.taxingAuthority.AuthorityName}
                            </td>
                        </tr>
                        <tr>
                            <td className="larger amount indent">
                                <span>Amount:</span>
                            </td>
                            <td
                                data-test-auto="NCMDQ8TLXNNMC2JZXCH89KVJLH"
                                className="larger amount">
                                <span style={{ fontWeight: "bold" }}>
                                    ${utilities.formatCurrencyText(this.props.voucher.amount)}
                                </span>
                            </td>
                            <td />
                        </tr>
                        <tr>
                            <td
                                data-test-auto="DFADKLHTFV6M166R2LVQ1Y9U3R"
                                className="larger due lined indent">
                                <span>Due:</span>
                            </td>
                            <td
                                data-test-auto="09DM9RF2F0K4J49Y0GHUADGMCW"
                                className="larger due lined">
                                <span style={{ fontWeight: "bold" }}>
                                    {Moment.utc(this.props.voucher.dueDate).format('MM/DD/YYYY')}
                                </span>
                            </td>
                            <td />
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className={"pay-container"}   >
                {this.props.voucher.vocherMode == 1 && this.props.voucher.onlinePaymentUri &&
                    <div className="pay-box">
                        {this.getManualPayBox()}
                    </div>

                }
                {this.props.voucher.vocherMode == 1 && this.props.voucher.onlinePaymentUri === undefined &&

                    <div className="pay-box-manual">
                        {this.getManualPayBox()}
                    </div>

                }
                {this.props.voucher.onlinePaymentUri && this.props.voucher.vocherMode !== 1 &&

                    <div className="pay-box-manual">
                        {this.getPayBox()}
                    </div>

                }
                {this.props.voucher.onlinePaymentUri && this.props.voucher.vocherMode === 1 &&
                    <>
                        <p style={{ margin: "auto 5px" }}>or</p>
                        <div className="pay-box">
                            {this.getPayBox()}
                        </div>

                    </>

                }
            </div>
        </>;
    }

    private getPayBox = () => {

        return (
            <div>
                <div
                    className={this.props.voucher.vocherMode == 1 && this.props.voucher.customType !== 3 ? 'content-title' : 'pay-online-title'}>
                    {this.props.voucher.vocherMode == 1 && this.props.voucher.customType !== 3
                        ? 'Pay Online Now'
                        : this.props.taxingAuthority.AuthorityName + ' requires this payment to be made electronically'}
                </div>
                <p
                    className={this.props.voucher.vocherMode == 1 && this.props.voucher.customType !== 3 ? 'pay-info-text' : 'pay-info-online-text'}>
                    Select <span className="tColor">"Download Filing Instructions"</span> above to review your payment instructions.  Select <span className="tColor">"Pay Online"</span> below to make your payment.
                </p>
                <CustomButton
                    placement={CustomButtonPlacement.Center}
                    size={CustomButtonSize.MD}
                    icon={SvgIconOnlinePayment}
                    text="Pay Online"
                    onClick={() => { window.open(this.props.voucher.onlinePaymentUri) }}
                    automationTestId="B9554632-64F4-4089-ACBF-83319120C206"
                />
            </div>
        )
    }

    private getManualPayBox = () => {
        return (
            <div>
                <div style={{ paddingLeft: '10px' }}>
                    <div
                        data-test-auto="7C57F2F5-5907-448A-BA4D-80E797493017"
                        className="content-title">
                        Pay By Check or Money Order
                    </div>
                    <p
                        className="pay-info-text">
                        Select <span className="tColor">"Download Filing Instructions"</span> above to review your payment and mailing instructions. Select the button below to download your payment voucher.
                    </p>
                    <CustomButton
                        placement={CustomButtonPlacement.Center}
                        size={CustomButtonSize.MD}
                        icon={SvgIconDownloadAll}
                        text="Download Voucher"
                        onClick={() => { this.props.downloadSingleVoucher(this.props.clientId, 'Voucher_' + this.props.taxingAuthority.AuthorityName + '_' + Moment(this.props.voucher.dueDate).format('MM-DD-YYYY').toString(), this.props.voucher.pageNo.toString(), this.props.voucher.bookmark) }}
                        automationTestId="8B4D5347-6A26-4D66-B7D5-2ED30E40A6C7"
                    />
                </div>
            </div>
        )
    }


    public render() {
        return (<Modal
            style={{ borderRadius: '5px' }}
            className="overlayPopup"
            show={this.props.show}
            onHide={this.props.onCancel}>

            <Modal.Header closeButton>
                <Modal.Title>
                    <div className="payment-info-modal-title">
                        <span
                            data-test-auto="LWA2HTLWVK54TCLGQAA4QX1ZM8"
                            className="popup-heading">
                            Payment Information
                        </span>
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {this.renderVoucherBody()}
            </Modal.Body>
            <Modal.Footer>
                <button className=" btn btn-lg btn-iconed btn-rounded success"
                    data-test-auto="987RKAHY6534C97MA5F47AVPQH"
                    onClick={this.props.onCancel}
                    hidden={false}>
                    Done
                </button>
            </Modal.Footer>
        </Modal>);
    }
}