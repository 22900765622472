import { Action, Reducer } from 'redux';
import { AppThunkAction } from './';
import { actionTypes } from './ActionTypes';
import { StatusType, NotificationAction } from './Common/NotificationStore';
import { AxiosResponse, AxiosRequestConfig } from 'axios';
import { stat } from 'fs';
import { DisplayDownloadFile } from '../components/Common/DisplayDownloadFile';
import { handleBlobwithFileName, handleResponse } from './Library';
import { IPaymentVoucherNotificationSettings } from '../core/domain/models/IDocumentSettings';
import * as Constants from '../components/Common/Constants';
import { ILoader } from '../core/utilities/ui/Loader';
import { TYPES } from '../startup/types';
import { container } from '../startup/inversify.config';
import Moment from 'moment/moment';
import { TelemetryLogger } from '../components/Logger/AppInsights';
import { IPayScreenViewModel, initialPayScreenViewModel } from 'src/core/domain/viewModels/IPayScreenViewModel';
import { initializeAxios } from 'src/core/services/dataAccess/DataService.Axios';
import { IVoucher } from 'src/core/domain/models/IGroup';

const logger = TelemetryLogger.getInstance();

interface RequestPayScreenDetailsAction {
    type: actionTypes.PAYSCREEN_DETAILS_REQUEST;
    clientId: string
}

interface ResponsePayScreenDetailsAction {
    type: actionTypes.PAYSCREEN_DETAILS_RESPONSE;
    data: IPayScreenViewModel;
}

interface FailurePayScreenDetailsAction {
    type: actionTypes.PAYSCREEN_DETAILS_FAILURE;
    clientId: string

}

interface RequestVoucherPaymentAction {
    type: actionTypes.VOUCHER_PAYMENT_REQUEST;
    voucher: IVoucher
}

type KnownAction =
    DispatchAction |
    NotificationAction;

type DispatchAction = RequestPayScreenDetailsAction
    | ResponsePayScreenDetailsAction
    | FailurePayScreenDetailsAction
    | RequestVoucherPaymentAction;


const loader = container.get<ILoader>(TYPES.ILoader);

export const actionCreators = {

    requestPayScreenDetails: (clientId: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        let state = getState();

        dispatch({ type: actionTypes.PAYSCREEN_DETAILS_REQUEST, clientId: clientId });

        return initializeAxios().get<IPayScreenViewModel>('/api/Pay/GetPayScreenDetailsAsync/' + clientId)
            .then(function (response: AxiosResponse<IPayScreenViewModel>) {

                dispatch({ type: actionTypes.PAYSCREEN_DETAILS_RESPONSE, data: response.data });

            })
            .catch(function (error: any) {
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: error.response.statusText,
                    statusType: StatusType.Error
                });
                dispatch({ type: actionTypes.PAYSCREEN_DETAILS_FAILURE, clientId: clientId });
                logger.trackError(`requestPayScreenDetails failed with error ${error.message} for client: ${clientId}`);
            });
    },
    downloadAllVouchers: (clientId: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        loader.show();
        let config: AxiosRequestConfig = { responseType: 'arraybuffer', headers: { 'Content-Type': 'application/json;utf-8' } };
        return initializeAxios().post('/api/Download/GetAllVouchers/' + clientId, null, config)
            .then(function (response: any) {
                const contentDisposition = response.headers["content-disposition"];
                const fileNameMatch = contentDisposition ? /filename="?([^"]*)"?;/g.exec(contentDisposition) : undefined;
                let fileName = '';
                if (fileNameMatch && fileNameMatch.length > 1) {
                    fileName = fileNameMatch[1];
                }
                let displayDownloadFile = new DisplayDownloadFile();

                displayDownloadFile.showFile(response.data, fileName);
                loader.hide();
            })
            .catch(function (error: any) {
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: error.statusText,
                    statusType: StatusType.Error
                });
                logger.trackError(`downloadAllVouchers failed with error ${error.message} for client: ${clientId}`);
            });
    },
    downloadFilingInstructions: (clientId: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        loader.show();
        return initializeAxios().post('/api/Download/GetFilingInstructions/' + clientId)
            .then(function (response: any) {
                let displayDownloadFile = new DisplayDownloadFile();
                displayDownloadFile.directDownload(response.data);
                loader.hide();
            }).catch(function (error: any) {
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: error.statusText,
                    statusType: StatusType.Error
                });
                logger.trackError(`downloadFilingInstructions failed with error ${error.message} for client: ${clientId}`);
            });
    },

    updateVoucherNotificationSettings: (clientId: string, settings: IPaymentVoucherNotificationSettings, Callback: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        loader.show();
        let config: AxiosRequestConfig = { headers: { 'Content-Type': 'application/json;utf-8' } };
        return initializeAxios().put<IPaymentVoucherNotificationSettings>('/api/Pay/UpdateVoucherReminderDetails/' + clientId,
            JSON.stringify(settings), config)
            .then(function (response: any) {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: Constants.PayConstants.VoucherSettingUpdated,
                    statusType: StatusType.Success
                });
                Callback();
                loader.hide();
            })
            .catch(function (error: any) {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: error.response ? error.response.statusText : Constants.PayConstants.ErrorVoucherSettingUpdated,
                    statusType: StatusType.Error
                });
                Callback();
                logger.trackError(`updateVoucherNotificationSettings failed with error ${error.message} for client: ${clientId}`);
            });
    },

    downloadSingleVoucher: (clientId: string, fileName: string, pageNo: string, bookMarks: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        loader.show();
        //replacing / from bookMarks name which causes request to fail
        bookMarks = bookMarks.replace("/", "-");
        let config: AxiosRequestConfig = { responseType: 'arraybuffer', headers: { 'Content-Type': 'application/json;utf-8' } };
        return initializeAxios().post('/api/Download/GetSingleVoucher/' + fileName + '/' + pageNo + '/' + bookMarks + '/' + clientId, null, config)
            .then(function (response: any) {
                const contentDisposition = response.headers["content-disposition"];
                const fileNameMatch = contentDisposition ? /filename="?([^"]*)"?;/g.exec(contentDisposition) : undefined;
                let fileName = '';
                if (fileNameMatch && fileNameMatch.length > 1) {
                    fileName = fileNameMatch[1];
                }
                let displayDownloadFile = new DisplayDownloadFile();

                displayDownloadFile.showFile(response.data, fileName);
                loader.hide();
            }).catch(function (error: any) {
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: error.statusText,
                    statusType: StatusType.Error
                });
                logger.trackError(`downloadSingleVoucher failed with error ${error.message} for client: ${clientId}`);
            });
    },

    addVoucherPayemnt: (clientId: string, voucher: IVoucher): AppThunkAction<KnownAction> => (dispatch, getState) => {
        let jsonData = JSON.stringify(voucher, function (key, value) {
            if (key === "paidDate") {
                return Moment(value).format('MM/DD/YYYY');
            } else { return value; }
        });
        let config: AxiosRequestConfig = { headers: { 'Content-Type': 'application/json;utf-8', 'Accept': 'application/json' } };
        return initializeAxios().postJson(jsonData, 'api/Pay/AddVoucherPaymentAsync/' + clientId, config)
            .then(data => {
                if (data) {
                    let state = getState();
                    voucher.isPaid = true;
                    const index = state.payScreenData.vouchers.findIndex((x: any) => x.authorityID === voucher.authorityID && x.dueDate == voucher.dueDate && x.amount === voucher.amount);
                    
                    state.payScreenData.vouchers[index] = voucher;
                    dispatch({
                        type: actionTypes.PAYSCREEN_DETAILS_RESPONSE, data: state.payScreenData,
                    });

                    dispatch({
                        type: actionTypes.NOTIFICATION, statusMessage: Constants.PayConstants.SuccesVoucherPayementMessage,
                        statusType: StatusType.Success
                    });
                }
            }).catch(error => {
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: Constants.PayConstants.ErrorVoucherPaymentMessage,
                    statusType: StatusType.Error
                });
                logger.trackError(`addVoucherPayemnt failed with error ${error.message} for client: ${clientId}`);
            });
    },

    updateVoucherPayemnt: (clientId: string, voucher: IVoucher): AppThunkAction<KnownAction> => (dispatch, getState) => {
        let jsonData = JSON.stringify(voucher, function (key, value) {
            if (key === "paidDate") {
                return Moment(value).format('MM/DD/YYYY');
            } else { return value; }
        });
        let config: AxiosRequestConfig = { headers: { 'Content-Type': 'application/json;utf-8', 'Accept': 'application/json' } };
        return initializeAxios().putJson(jsonData, '/api/Pay/UpdateVoucherPaymentAsync/' + clientId, config)
            .then(data => {
                if (data) {
                    let state = getState();
                    const index = state.payScreenData.vouchers.findIndex(x => x.authorityID === voucher.authorityID && x.dueDate == voucher.dueDate && x.amount === voucher.amount);
                    state.payScreenData.vouchers[index] = voucher;
                    dispatch({
                        type: actionTypes.PAYSCREEN_DETAILS_RESPONSE, data: state.payScreenData,
                    });
                    dispatch({
                        type: actionTypes.NOTIFICATION, statusMessage: Constants.PayConstants.SuccesVoucherPayementMessage,
                        statusType: StatusType.Success
                    });
                }
            }).catch(error => {
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: Constants.PayConstants.ErrorVoucherPaymentMessage,
                    statusType: StatusType.Error
                });
                logger.trackError(`updateVoucherPayemnt failed with error ${error.message} for client: ${clientId}`);
            });
    }
}

export const reducer: Reducer<IPayScreenViewModel> = (state: IPayScreenViewModel = initialPayScreenViewModel, incomingAction: Action) => {
    const action = incomingAction as DispatchAction;
    var data = Object.assign({}, state);
    switch (action.type) {
        case actionTypes.PAYSCREEN_DETAILS_REQUEST:
            return initialPayScreenViewModel;

        case actionTypes.PAYSCREEN_DETAILS_RESPONSE:
            return action.data;
        default:
            return state || initialPayScreenViewModel;
    }
};