import { History } from 'history';
import * as React from 'react';
import { match } from 'react-router';
import { EstimatedVoucher } from '../../components/Summary/Main/EstimatedVoucher';
import { LeftBar } from '../../components/Summary/Main/LeftBar';
import { Main } from '../../components/Summary/Main/Main';
import { PaymentAndRefund } from '../../components/Summary/Main/PaymentAndRefund';
import { IClientProcessViewModel } from '../../core/domain/viewModels/IClientProcessViewModel';
import { IHeaderInfoViewModel } from '../../core/domain/viewModels/IHeaderInfoViewModel';
import TaxpayerHelper from '../Helper/TaxpayerHelper';
import { SignProcessSteps } from '../Home/TaxpayerSignFlowManager';
import { CompletedWelcomePage } from './CompletedWelcomePage';
import { IDelegateeInfo, IDelegateeSignerDetails } from '../../core/domain/models/DelegateeModels';
import { logger } from '../../routes';
import { LogEventConstants } from '../Helper/Constants';
import { Alert } from 'react-bootstrap';
import { SvgAlertIcon } from '../Common/Icons/SvgIcons';
import { ISummaryViewModel } from 'src/core/domain/viewModels/ISummaryViewModel';
import { ICommonDataViewModel } from 'src/core/domain/viewModels/ICommonDataViewModel';
import { ITaxReturn } from 'src/core/domain/models/ITaxReturn';
import { ISignedDocument } from 'src/core/domain/models/manualsign/SignedDocument';
import { IDownloadableDocumentsViewModel } from 'src/core/domain/viewModels/IDownloadableDocumentsViewModel';
import { Role } from 'src/core/common/Enums';

export interface CompletedSummaryPageProps {
    summaryData: ISummaryViewModel,
    commonData: ICommonDataViewModel,
    requestSummaryDetails: (clientId: string) => any;
    match: match;
    history: History;
    taxReturn: ITaxReturn;
    requestSignerDetails: (clientId: string) => any;
    sendSigningReminder: (clientId: string) => any;
    downloadSignedEFileDocument: (clientId: string, fileName: string, callback?: () => void) => any;
    signedDocument: ISignedDocument[];
    downloadAllAdditionEsignDocuments: (clientId: string, fileName: string) => any;
    downloadableDocumentsViewModel: IDownloadableDocumentsViewModel;
    requestDownloadableDocuments: (clientId: string) => any;
    refreshTaxDocument: (clientId: string) => any;
    requestSignedDocuments: (clientGuid: string, forceRefresh?: boolean) => any;
    downloadEFileDocument: (clientId: string, fileName: string, callback?: () => void) => any;
    requestCurrentStepAndUserRole: (
        clientId: string,
        successCallback?: (clientProcessState: IClientProcessViewModel) => void) => any;
    headerInfo: IHeaderInfoViewModel;
    signingRequiredForDocument: boolean;
    filingFormsExists: boolean;
    nextSignerExists: boolean;
    updateDelegateeDetails: (clientId: string,
        delegatee: IDelegateeSignerDetails, taxyear: number,callback: () => void) => void;
    cancelDelegation: (clientId: string, remarks: string, taxYear: number, callback: () => void) => void;
    resetLastVisitedSteps: (clientId: string, callback: () => void) => void;
    delegateeSigner: IDelegateeInfo;
    requestTaxClientDelegateeDetails: (clientId: string) => void;
    signingRequiredForSigner: boolean;
    clientProcessData: IClientProcessViewModel;
    getClientHubDomainURL: (id: string, callback: (clientHubDomainURL: string) => void) => void;
}

export interface CompletedSummaryPageState {
    role: Role;
    isPreviewMode: boolean;
    isDelegateeAssigned: boolean;
    isSignCompleted: boolean;
}

const pageTitle = "Completed Summary page";
export class CompletedSummaryPage extends React.Component<CompletedSummaryPageProps, CompletedSummaryPageState> {
    private _params: any;

    constructor(props: CompletedSummaryPageProps) {
        super(props);
        this.state = {
            role: Role.None,
            isPreviewMode: false,
            isDelegateeAssigned: false,
            isSignCompleted: false
        };
    }

    componentDidMount() {
        const param: any = this.props.match.params;

        this.getSignProcessStatus(param.clientId);

        if (param.clientId && this.props.summaryData.documentId === 0) {
            this.props.requestSummaryDetails(param.clientId);
        }
        this.props.requestSignerDetails(param.clientId);
        this.props.requestDownloadableDocuments(param.clientId);
        this.props.refreshTaxDocument(param.clientId);
        this.props.requestSignedDocuments(param.clientId, true);
        logger.trackEvent(
            logger.buildEvent(
                `${LogEventConstants.Common.Open}  ${pageTitle}`,
                { count: 1, page: pageTitle, clientId: param.clientId }
            ));
    }

    getSignProcessStatus = (clientId: any) => {
        this.props.requestCurrentStepAndUserRole(clientId, this.handleRedirect);
    }

    isLinkVisited(step: SignProcessSteps, clientProcessState: IClientProcessViewModel): boolean | undefined {
        const NO_INDEX = -1;
        let index = NO_INDEX;
        index = clientProcessState.currentstep.visitedSteps ?
            clientProcessState.currentstep.visitedSteps.indexOf(step) :
            NO_INDEX;

        return (index !== null && index !== NO_INDEX);
    }

    handleRedirect = (clientProcessState: IClientProcessViewModel) => {
        this._params = this.props.match.params;
        if (!this.isLinkVisited(SignProcessSteps.SignerProcessComplete, clientProcessState)) {
            const url = TaxpayerHelper.getSignCompletedRedirectUrl(clientProcessState, this._params.clientId);
            if (url && url.trim()) {
                this.props.history.push(url);
            }
        }

        if (clientProcessState.isDelegated) {
            this.props.requestTaxClientDelegateeDetails(this._params.clientId);
        }

        this.setState({
            role: clientProcessState.role,
            isDelegateeAssigned: clientProcessState.isDelegated,
            isSignCompleted: clientProcessState.isSigned,
            isPreviewMode: TaxpayerHelper.isPreviewMode(clientProcessState),
        });
    }

    public render() {
        const {
            signingRequiredForDocument,
            filingFormsExists,
            nextSignerExists
        } = this.props;

        return (
            <div id="summary-review" className="row no-padding no-margin width-100 completed-summary-step-container">

                <LeftBar>
                    <PaymentAndRefund
                        taxYear={this.props.summaryData.taxYear}
                        addedRefunds={this.props.summaryData.addedRefunds}
                        vouchers={this.props.summaryData.vouchers}
                        refunds={this.props.summaryData.refunds}
                        taxingAuthorities={this.props.commonData.taxingAuthorities}
                        showPaymentRefundPrint={false}
                        headerInfo={this.props.headerInfo}
                    />

                    <EstimatedVoucher
                        taxYear={this.props.summaryData.taxYear}
                        vouchers={this.props.summaryData.vouchers}
                        taxingAuthorities={this.props.commonData.taxingAuthorities} />
                </LeftBar>

                <Main>
                    <CompletedWelcomePage
                        clientName={this.props.summaryData.clientName}
                        hasShareholders={this.props.summaryData.hasShareholders}
                        taxYear={this.props.summaryData.taxYear}
                        clientType={this.props.taxReturn.clientType}
                        taxReturn={this.props.taxReturn}
                        param={this.props.match.params}
                        sendSigningReminder={this.props.sendSigningReminder}
                        history={this.props.history}
                        downloadSignedEFileDocument={this.props.downloadSignedEFileDocument}
                        sigedDocument={this.props.signedDocument}
                        downloadAllAdditionEsignDocuments={this.props.downloadAllAdditionEsignDocuments}
                        downloadableDocumentsViewModel={this.props.downloadableDocumentsViewModel}
                        downloadEFileDocument={this.props.downloadEFileDocument}
                        role={this.state.role}
                        signingRequiredForDocument={signingRequiredForDocument}
                        filingFormsExists={filingFormsExists}
                        nextSignerExists={nextSignerExists}
                        isPreviewMode={this.state.isPreviewMode}
                        updateDelegateeSignerDetails={this.props.updateDelegateeDetails}
                        isDelegateeAssigned={this.state.isDelegateeAssigned}
                        isSignCompleted={this.state.isSignCompleted}
                        cancelDelegation={this.props.cancelDelegation}
                        resetLastVisitedSteps={this.props.resetLastVisitedSteps}
                        delegateeSigner={this.props.delegateeSigner}
                        voucherExists={this.props.summaryData.vouchers.length > 0}
                        signingRequiredForSigner={this.props.signingRequiredForSigner}
                        clientProcessData={this.props.clientProcessData}
                        getClientHubDomainURL={this.props.getClientHubDomainURL}
                    />
                </Main>
            </div>);
    }
}

