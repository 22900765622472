import { ApplicationState } from "../../store/index";
import { actionCreators as SummaryStore } from '../../store/SummaryStore';
import { actionCreators as HelperStore } from '../../store/Common/HelperStore';
import { actionCreators as EsignStore } from '../../store/Common/EsignStore';
import { PreviewComponent } from "./PreviewComponent";
import { actionCreators as PreparerMessageStore } from '../../store/PreparerMessageStore';
import { connect } from 'react-redux';
import { createLoadingSelector } from '../../store/selectors';
import { actionCreators as DocumentReviewStore } from '../../store/ReviewStore';
import { actionCreators as TaxDocumentStore } from '../../store/Common/TaxDocumentStore';
import { actionCreators as ManualSignStore } from '../../store/Sign/ManualSignStore';
import { actionCreators as CompanyStore } from '../../store/Common/Company/CompanyStore';
import { EngagementTypeS } from "src/core/common/Enums";
import { actionCreators as K1DistributeStore } from '../../store/K1/K1DistributeStore';
import { IK1ShareHolderDetails } from '../../core/domain/models/IK1Distribute';
import { actionCreators as SignProcessStore } from '../../store/SignProcess/SignProcessStore';
import { actionCreators as ReviewStore } from '../../store/ReviewStore';
import { actionCreators as PayStore } from '../../store/PayStore';
import { actionCreators as DownloadableDocumentsStore } from '../../store/DownloadableDocumentsStore';
import { IPaymentVoucherNotificationSettings } from "../../core/domain/models/IDocumentSettings";
import { IClientProcessViewModel } from '../../core/domain/viewModels/IClientProcessViewModel'
import { actionCreators as TutorialStore } from '../../store/Tutorial/TutorialStore';
import { IVoucher } from '../../core/domain/models/IGroup';
import { actionCreators as SigningRequiredStore } from '../../store/SignProcess/SigningRequiredStore';
import { ClientTypesNumber, ISignerModel } from "../../core/domain/models/ISignerModel";

const loadingSelector = createLoadingSelector(['HEADERINFO', 'SUMMARY_DETAILS',
    'TAXING_AUTHORITY', 'DOCUMENT_REVIEW_MODEL', 'DOCUMENT_SETTINGS']);

function mapStateToProps(state: ApplicationState) {
    return {
        summaryData: state.summaryData,
        esignData: state.esignData.data,
        signerData: state.esignData.signerDetails,
        manualSignData: state.manualSignData.data,
        companyData: state.companyData.data,
        signatureControlsData: state.esignData.controlsData,
        payScreenData: state.payScreenData,
        loading: loadingSelector(state),
        commonData: state.commonData,
        signatureSettingModel: state.signatureSettingData,
        documentSettings: state.documentSettings,
        documentReviewModel: state.documentReviewModel,
        headInfoViewModel: state.headerInfo,
        taxReturn: state.taxReturn.taxDocument,
        k1Data: state.k1Data.data,
        previewReady: state.previewDocument.previewReady,
        signProcessInfo: state.signProcessData.clientprocessmodel.currentstep,
        loggedInUserInfo: state.headerInfo.loggedInUserInfo,
        tutorialInfo: state.tutorialInfo,
        additionalEsignDocuments: state.downloadableDocuments.additionalEsignDocuments,
        signingRequiredState: state.signingRequiredStore,
        showPaymentAlert: state.taxReturn.showPaymentAlert
    };
}

const mapDispatchToProps = (dispatch: any, state: ApplicationState) => {
    return {
        requestSummaryDetails: (clientId: string) =>
            dispatch(SummaryStore.requestSummaryDetails(clientId)),

        requestAllTaxingAuthorities: (id: string) =>
            dispatch(HelperStore.requestAllTaxingAuthorities(id)),

        clientAuthentication: (clientGuid: string, failureCallback: () => void) =>
            dispatch(EsignStore.clientAuthentication(clientGuid, failureCallback)),

        requestPreviewSignatureControls: (clientGuid: string) =>
            dispatch(EsignStore.requestPreviewSignatureControls(clientGuid)),

        updateSignatureControlsData: (data: any) =>
            dispatch(EsignStore.updateSignatureControlsData(data)),

        downloadAllAttachments: (clientId: string, attachments: any) =>
            dispatch(DocumentReviewStore.downloadAllAttachments(clientId, attachments)),

        downloadIndividualAttachment: (clientId: string, attachment: any) =>
            dispatch(DocumentReviewStore.downloadIndividualAttachment(clientId, attachment)),

        requestDocumentsForPreview: (clientId: string) =>
            dispatch(DocumentReviewStore.requestDocumentsForPreview(clientId)),

        requestDocumentSettings: (clientId: string) =>
            dispatch(PreparerMessageStore.requestDocumentSettings(clientId)),

        requestTaxDocument: (clientGuid: string, callback?: (data?: any) => void) =>
            dispatch(TaxDocumentStore.requestTaxDocument(clientGuid, callback)),

        requestSigners: (clientGuid: string, callback?: (signers: ISignerModel[]) => void) =>
            dispatch(EsignStore.requestSigners(clientGuid, callback)),

        requestSignedDocuments: (clientGuid: string, forceRefresh?: boolean) =>
            dispatch(ManualSignStore.requestSignedDocuments(clientGuid, forceRefresh)),

        requestCompanyDetails: (clientGuid: string, forceRefresh?: boolean) =>
            dispatch(CompanyStore.requestCompanyDetails(clientGuid, forceRefresh)),

        requestK1ShareHolderDetails: (clientGuid: string, pageNo: number, pageSize: number, sortBy: string, sortOrder: string, searchText: string) =>
            dispatch(K1DistributeStore.requestK1ShareHolderDetails(clientGuid, pageNo, pageSize, sortBy, sortOrder, searchText)),

        downloadK1DocumentAsync: (clientId: string, engagementTpe: EngagementTypeS) =>
            dispatch(K1DistributeStore.downloadK1DocumentForPreviewAsync(clientId, engagementTpe)),

        downloadIndividualK1DocumentAsync: (clientId: string, shareHolder: IK1ShareHolderDetails, engagementTpe: EngagementTypeS) =>
            dispatch(K1DistributeStore.downloadIndividualK1DocumentForPreviewAsync(clientId, shareHolder, engagementTpe)),

        downloadSelectedK1DocumentAsync: (clientId: string, shareHolders: IK1ShareHolderDetails[], engagementTpe: EngagementTypeS) =>
            dispatch(K1DistributeStore.downloadSelectedK1DocumentForPreviewAsync(clientId, shareHolders, engagementTpe)),

        requestDocumentPreviewStatus: (clientGuid: string, step: number, callback: (step: number) => void) =>
            dispatch(ReviewStore.requestDocumentPreviewStatus(clientGuid, step, callback)),

        downloadEfileForms: (clientGuid: string, successCallback?: () => void) =>
            dispatch(ManualSignStore.downloadEfileForms(clientGuid, successCallback)),

        downloadPreviewEfileForms: (clientGuid: string, successCallback?: () => void) =>
            dispatch(ManualSignStore.downloadPreviewEfileForms(clientGuid, successCallback)),

        requestPayScreenDetails: (clientId: string) =>
            dispatch(PayStore.requestPayScreenDetails(clientId)),

        downloadAllVouchers: (clientId: string) =>
            dispatch(PayStore.downloadAllVouchers(clientId)),

        downloadFilingInstructions: (clientId: string) =>
            dispatch(PayStore.downloadFilingInstructions(clientId)),

        updateVoucherNotificationSettings: (clientId: string, setting: IPaymentVoucherNotificationSettings, callback: () => void) =>
            dispatch(PayStore.updateVoucherNotificationSettings(clientId, setting, callback)),

        downloadSingleVoucher: (clientId: string, fileName: string, pageNo: string, bookMarks: string) =>
            dispatch(PayStore.downloadSingleVoucher(clientId, fileName, pageNo, bookMarks)),

        requestLastVisitedStep: (clientGuid: string, successCallback?: (lastVisitedStep: number) => void) =>
            dispatch(SignProcessStore.requestLastVisitedStep(clientGuid, successCallback)),

        updateK1ShareHolderEmail: (clientGuid: string, k1Partner: IK1ShareHolderDetails, successCallback?: () => void) =>
            dispatch(K1DistributeStore.updateK1ShareHolderEmail(clientGuid, k1Partner, successCallback)),

        updateK1ShareHolderDetail: (clientGuid: string, k1Partner: IK1ShareHolderDetails, isEmailChange: boolean, callBack?: () => void) =>
            dispatch(K1DistributeStore.updateK1ShareHolderDetail(clientGuid, k1Partner, isEmailChange, callBack)),

        sendMailToShareHolder: (clientId: string, partners: IK1ShareHolderDetails[], callback: () => void) =>
            dispatch(K1DistributeStore.sendMailToShareHolder(clientId, partners, callback)),

        requestCurrentStepAndUserRole: (clientGuid: string, successCallback?: (clientProcessState: IClientProcessViewModel) => void) =>
            dispatch(SignProcessStore.requestCurrentStepAndUserRole(clientGuid, successCallback)),

        requestTutorialInfoPreview: (clientId: string) =>
            dispatch(TutorialStore.requestTutorialInfoPreview(clientId)),

        hideReviewTutorialPreview: (clientId: string, doNotShowNextTime: boolean) =>
            dispatch(TutorialStore.hideReviewTutorialPreview(clientId, doNotShowNextTime)),

        hideSummaryTutorialPreview: (clientId: string, doNotShowNextTime: boolean) =>
            dispatch(TutorialStore.hideSummaryTutorialPreview(clientId, doNotShowNextTime)),

        requestAdditionalEsignDocument: (clientId: string) =>
            dispatch(DownloadableDocumentsStore.requestAdditionalEsignDocument(clientId)),

        downloadAllAdditionEsignDocuments: (clientId: string, fileName: string) =>
            dispatch(DownloadableDocumentsStore.downloadAllAdditionEsignDocuments(clientId, fileName)),

        addVoucherPayemnt: (clientId: string, voucher: IVoucher) =>
            dispatch(PayStore.addVoucherPayemnt(clientId, voucher)),

        updateVoucherPayemnt: (clientId: string, voucher: IVoucher) =>
            dispatch(PayStore.updateVoucherPayemnt(clientId, voucher)),

        checkSignatureRequiredForDocument: (clientGuid: string, callback?: (status: boolean) => void) =>
            dispatch(SigningRequiredStore.checkSignatureRequiredForDocument(clientGuid, callback)),

        checkSignatureRequiredForSigner: (clientGuid: string, callback?: (status: boolean) => void) =>
            dispatch(SigningRequiredStore.checkSignatureRequiredForSigner(clientGuid, callback)),

        checkNextSignerExists: (
            clientGuid: string,
            clientType: ClientTypesNumber,
            callback?: (status: boolean) => void) =>
            dispatch(SigningRequiredStore.checkNextSignerExists(clientGuid, clientType, callback)),

        relatedTaxReturnDelivered: (
            clientId: string) =>
            dispatch(TaxDocumentStore.relatedTaxReturnDelivered(clientId)),

        togglePaymentAlert: (
            toggle: boolean) =>
            dispatch(TaxDocumentStore.togglePaymentAlert(toggle))
    };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(PreviewComponent);
