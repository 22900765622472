import { ClientType, EngagementType, TaxpayerAuthenticationType } from "../../../core/common/Enums";
import { IHeaderInfoViewModel, initialHeaderInfoViewModel } from "../../../core/domain/viewModels/IHeaderInfoViewModel";
import { actionTypes } from "../../ActionTypes";

export interface LoginResponse {
    isSuccess: boolean;
    errorCode: string;
    errorDescription: string;
    data: any;
}

export enum LinkValidationResult {
    None,
    Success,
    LockedBySSN,
    LockedByOTP,
}

export interface OtpPageStoreState {
    loading: boolean;
    mobileNo: string;
    countryCode: string;
}

export const initialOtpPageStoreState: OtpPageStoreState = {
    loading: false,
    mobileNo: "",
    countryCode: "",
};

export interface OtpPageLoaderAction {
    type: actionTypes.OTP_PAGE_LOADER;
    loading: boolean;
}

export interface OtpPageSetDataAction {
    type: actionTypes.SET_MOBILE_OTP_PAGE_DATA;
    mobileNo: string;
    countryCode: string;
}

export interface SSNPageStoreState {
    taxpayerAuthentication: TaxpayerAuthenticationType;
    clientType: ClientType;
    clientName: any;
    linkValidationResult: LinkValidationResult;
    ssn: any;
    data: any;
    returnUrl: string;
    redirectUrl: string;
    loading: boolean;
}

export const initialSSNPageStoreState: SSNPageStoreState = {
    taxpayerAuthentication: TaxpayerAuthenticationType.None,
    clientType: ClientType.Undefined,
    clientName: "",
    linkValidationResult: LinkValidationResult.None,
    ssn: "",
    returnUrl: "",
    data: "",
    redirectUrl: "",
    loading: false,
};

export interface ReceiveSSNPageDetailsAction {
    type: actionTypes.RECEIVE_SSN_PAGE_DETAILS;
    data: SSNPageStoreState;
}

export interface SSNPageLoaderAction {
    type: actionTypes.SSN_PAGE_LOADER;
    loading: boolean;
}

export interface CoverPageStoreState {
    companyName: string;
    logoPath: string;
    taxYear: number;
    engagementType: EngagementType;
    clientName: string;
    contactAddress: ContactAddress;
    mobileVerification: TaxClientMobileVerification;
    loading: boolean;
    mfaSettings: MFASettings;
    data: string;
}
export interface MFASettings {
    isMFAEnabled: boolean;
    trustedDeviceExpiryDays: number;
    isAllowUsertoRememberTrustedDevice: boolean;
    skipMFAforIP: boolean;
    iSTPMfaEnabled: boolean;
    textBasedAuthenticationForTP: string;
}
export const initialMFASettings: MFASettings = {
    isAllowUsertoRememberTrustedDevice: false,
    isMFAEnabled: false,
    iSTPMfaEnabled: false,
    skipMFAforIP: false,
    textBasedAuthenticationForTP: "",
    trustedDeviceExpiryDays: 0,
};

export interface TaxClientMobileVerification {
    ssn: string;
    mobileNumber: string;
    countryCode: string;
    isVerified: boolean;
    verifiedOn: Date;
}
export const initialTaxClientMobileVerification: TaxClientMobileVerification = {
    countryCode: "",
    isVerified: false,
    mobileNumber: "",
    ssn: "",
    verifiedOn: new Date(),
};

export interface ContactAddress {
    name: string;
    state: string;
    city: string;
    zip: string;
    fax: string;
    streetAddress1: string;
    streetAddress2: string;
    streetAddress3: string;
}

export const initialContactAddress: ContactAddress = {
    city: "",
    fax: "",
    name: "",
    state: "",
    streetAddress1: "",
    streetAddress2: "",
    streetAddress3: "",
    zip: "",
};

export const initialCoverPageStoreState: CoverPageStoreState = {
    companyName: "",
    logoPath: "",
    taxYear: 0,
    engagementType: EngagementType.None,
    clientName: "",
    contactAddress: initialContactAddress,
    mobileVerification: initialTaxClientMobileVerification,
    loading: false,
    mfaSettings: initialMFASettings,
    data: "",
};

export interface ReceiveCoverPageDetailsAction {
    type: actionTypes.RECEIVE_COVER_PAGE_DETAILS;
    data: CoverPageStoreState;
}

export interface CoverPageLoaderAction {
    type: actionTypes.COVER_PAGE_LOADER;
    loading: boolean;
}

export enum DelegateeStatus {
    None = 0,
    Active,
    InActive,
    Canceled,
    Declined,
}

export interface ClientBaseModel {
    clientGuid: string;
    documentId: number;
    emailAddress: string;
    name: string;
    status: DelegateeStatus;
    companyName: string;
    countryCode: string;
    mobileNumber: string;
    isMobileVerified: boolean;
    taxClientGuid: string;
    engagementType: EngagementType;
    taxYear: number;
    clientType: ClientType;
    documentGuid: string;
}

export const initialClientBaseModel: ClientBaseModel = {
    clientGuid: "",
    clientType: ClientType.Undefined,
    companyName: "",
    countryCode: "",
    documentGuid: "",
    documentId: 0,
    emailAddress: "",
    engagementType: EngagementType.None,
    isMobileVerified: false,
    mobileNumber: "",
    name: "",
    status: DelegateeStatus.None,
    taxClientGuid: "",
    taxYear: 0
};

export interface DelegateeOTPPageStoreState {
    loading: boolean;
    mfaSettings: MFASettings;
    delegatee: ClientBaseModel;
    otpCreationTime: number;
}

export const initialDelegateeOTPPageStoreState: DelegateeOTPPageStoreState = {
    loading: false,
    mfaSettings: initialMFASettings,
    delegatee: initialClientBaseModel,
    otpCreationTime: 0,
};

export interface ReceiveDelegateeOtpPageDetailsAction {
    type: actionTypes.RECEIVE_DELEGATEE_OTP_PAGE_DETAILS;
    data: DelegateeOTPPageStoreState;
}

export interface DelegateeOtpPageLoaderAction {
    type: actionTypes.DELEGATEE_OTP_PAGE_LOADER;
    loading: boolean;
}

export interface HeaderStoreState extends IHeaderInfoViewModel {
    loading: boolean;
    senderName: string;
}

export const initialHeaderStoreState: HeaderStoreState = {
    ...initialHeaderInfoViewModel,
    loading: true,
    senderName: "",
};

export interface ReceiveHeaderDetailsAction {
    type: actionTypes.RECEIVE_HEADER_DETAILS;
    data: HeaderStoreState;
}

export interface ReceiveSenderNameAction {
    type: actionTypes.RECEIVE_SENDER_NAME;
    data: string;
}

export interface HeaderLoaderAction {
    type: actionTypes.HEADER_LOADER;
    loading: boolean;
}

export interface CCRecipientOTPPageStoreState {
    loading: boolean;
    mfaSettings: MFASettings;
    ccRecipientModel: ClientBaseModel;
    otpCreationTime: number;
}

export const initialCCRecipientOTPPageStoreState: CCRecipientOTPPageStoreState = {
    loading: false,
    mfaSettings: initialMFASettings,
    ccRecipientModel: initialClientBaseModel,
    otpCreationTime: 0,
};

export interface ReceiveCCRecipientOtpPageDetailsAction {
    type: actionTypes.RECEIVE_CCRECIPIENT_OTP_PAGE_DETAILS;
    data: CCRecipientOTPPageStoreState;
}

export interface CCRecipientOtpPageLoaderAction {
    type: actionTypes.CCRECIPIENT_OTP_PAGE_LOADER;
    loading: boolean;
}

export interface ErrorStoreState {
    message: string;
}

export const initialErrorStoreState: ErrorStoreState = {
    message: "",
};

export interface ErrorMessageAction {
    type: actionTypes.SET_ERROR_MESSAGE;
    message: string;
}
