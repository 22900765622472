import { AxiosResponse } from 'axios';
import { Action, Reducer } from 'redux';
import { Company, ICompany } from '../../../core/domain/models/company/Company';
import { initializeAxios } from 'src/core/services/dataAccess/DataService.Axios';
import { actionTypes } from '../../ActionTypes';
import { NotificationAction, StatusType } from '../../Common/NotificationStore';
import { AppThunkAction } from '../../index';
import { ErrorMessages } from '../../../components/Common/Constants';
import { TelemetryLogger } from '../../../components/Logger/AppInsights';

const logger = TelemetryLogger.getInstance();

interface RequestCompanyAction {
    type: actionTypes.COMPANY_INFO_REQUEST;
}

interface RequestCompanyCacheAction {
    type: actionTypes.COMPANY_INFO_CACHE;
    data: ICompany;
}

interface ResponseCompanyAction {
    type: actionTypes.COMPANY_INFO_RESPONSE;
    data: ICompany;
}

interface FailureCompanyAction {
    type: actionTypes.COMPANY_INFO_FAILURE;
    data: ICompany;
}

export interface CompanyState {
    data: ICompany;
}

export const initialCompanyState: CompanyState = {
    data: Company.createNullObject(),
}

type KnownAction =
    DispatchAction |
    NotificationAction;

type DispatchAction =
    RequestCompanyAction
    | RequestCompanyCacheAction
    | ResponseCompanyAction
    | FailureCompanyAction

export const actionCreators = {

    requestCompanyDetails: (
        clientGuid: string,
        forceRefresh?: boolean,
        isDelegatee?: boolean):
        AppThunkAction<KnownAction> => (dispatch, getState) => {
            let state = getState();
            let companyDataState = state.companyData ? state.companyData.data : initialCompanyState.data;
            if (!forceRefresh) {
                dispatch({ type: actionTypes.COMPANY_INFO_CACHE, data: companyDataState });
                return;
            }

            return initializeAxios().get<ICompany>(
                `/api/Company/GetCompanyContactDetails/${clientGuid}?isDelegatee=${isDelegatee}`)
                .then(function (response: AxiosResponse<ICompany>) {
                    dispatch({
                        type: actionTypes.COMPANY_INFO_RESPONSE, data: response.data
                    });
                })
                .catch(function (error: any) {
                    dispatch({
                        type: actionTypes.NOTIFICATION,
                        statusMessage: error.response ?
                            error.response.statusText :
                            ErrorMessages.CompanyDetails,
                        statusType: StatusType.Error,
                    });
                    dispatch({ type: actionTypes.COMPANY_INFO_FAILURE, data: companyDataState });
                    logger.trackError(`requestCompanyDetails failed with error ${error.message} for client: ${clientGuid}`);
                });
        },

};

export const reducer: Reducer<CompanyState> = (state: CompanyState = initialCompanyState, incomingAction: Action) => {
    const action = incomingAction as DispatchAction;
    const currentState = Object.assign({}, state);
    switch (action.type) {
        case actionTypes.COMPANY_INFO_REQUEST:
            currentState.data = Company.createNullObject();
            return currentState;
        case actionTypes.COMPANY_INFO_RESPONSE:
        case actionTypes.COMPANY_INFO_CACHE:
            currentState.data = action.data;
            return currentState;
        case actionTypes.COMPANY_INFO_FAILURE:
            currentState.data = action.data
            return currentState;
        default:
            return currentState || initialCompanyState;
    }
};