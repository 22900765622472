/// <reference path="../core/utilities/date/DateUtilities.ts" />
/// <reference path="../core/utilities/date/DateUtilities.ts" />
import { Container } from "inversify";
import "reflect-metadata";
import { UtilityFactory } from "src/core/utilities/factory/UtilityFactory";
import { FileUploadUtilities, IFileUploadUtilities } from '../core/utilities/FileUpload/FileUploadUtilities';
import { FileUtilities, IFileUtilities } from '../core/utilities/File/FileUtilities';
import { IHtmlUtilities } from "../core/utilities/HtmlUtilities";
import { ILocalStore } from "../core/utilities/LocalStore";
import { IRegexUtilities, RegexUtilities } from "../core/utilities/RegexUtilities";
import { ITextUtilities } from "../core/utilities/TextUtilities";
import { IDialogBox } from '../core/utilities/ui/DialogBox';
import { IUtilities, Utilities } from "../core/utilities/Utilities";
import { IWindowUtilities } from "../core/utilities/WindowUtilities";
import { IJsonUtilities, JsonUtilities } from "../core/utilities/JSON/JsonUtilities";
import { TYPES } from "./types";
import { IDataService } from "../core/services/dataAccess/abstraction/IDataService";
import { Factory } from '../startup/Factory';
import { DateUtilities, IDateUtilities } from "src/core/utilities/date/DateUtilities";
import { ILoader, Loader } from "src/core/utilities/ui/Loader";

const container = new Container();


container.bind<ILocalStore>(TYPES.ILocalStore).toConstantValue(new UtilityFactory().createLocalStore());
container.bind<IDataService>(TYPES.IDataService).toConstantValue(Factory.createDataService());
container.bind<ITextUtilities>(TYPES.ITextUtilities).toConstantValue(new UtilityFactory().createTextUtilityService());
container.bind<IWindowUtilities>(TYPES.IWindowUtilities).toConstantValue(new UtilityFactory().createWindowUtilityService());
container.bind<IHtmlUtilities>(TYPES.IHtmlUtilities).toConstantValue(new UtilityFactory().createHtmlUtilities());
container.bind<IDialogBox>(TYPES.IDialogBox).toConstantValue(new UtilityFactory().createDialogBox());
container.bind<IRegexUtilities>(TYPES.IRegexUtilities).to(RegexUtilities);
container.bind<IUtilities>(TYPES.IUtilities).to(Utilities);
container.bind<IFileUploadUtilities>(TYPES.IFileUploadUtilities).to(FileUploadUtilities);
container.bind<IFileUtilities>(TYPES.IFileUtilities).to(FileUtilities);
container.bind<IJsonUtilities>(TYPES.IJsonUtilities).to(JsonUtilities);
container.bind<IDateUtilities>(TYPES.IDateUtilities).to(DateUtilities);
container.bind<ILoader>(TYPES.ILoader).to(Loader);


export { container };
