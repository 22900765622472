import * as React from 'react';

export interface PaymentInstructionProps {
    clientInstructions:string
}

export class PaymentInstruction extends React.Component<PaymentInstructionProps, {}> {
    constructor(props: PaymentInstructionProps) {
        super(props);
    }

    public render() {
        return (<div className="payment-instruction-container">

            <div className="body" style={{ whiteSpace: "pre-line" }}> {this.props.clientInstructions} </div>
        </div>)
    }
}