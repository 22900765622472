
export interface ICompany {
    onlinePaymentUrl?: string;
    name?: string;
    address?: string;
    address1?: string;
    address2?: string;
    fax?: string;
    city?: string;
    zip?: string;
    stateName?: string;
    uteClientHubSettings: IUTESetting;
}

export class Company implements ICompany {
    onlinePaymentUrl?: string;
    name?: string;
    address?: string;
    address1?: string;
    address2?: string;
    fax?: string;
    city?: string;
    zip?: string;
    stateName?: string;
    uteClientHubSettings: IUTESetting;
    
    constructor(onlinePaymentUrl?: string,
        fax?: string,
        address?: string) {
        this.onlinePaymentUrl = onlinePaymentUrl;
        this.fax = fax;
        this.address = address;
    }

    public static createNullObject(): ICompany {
        return new Company(undefined, undefined, undefined);
    }

    public static create(fax?: string,
        address?: string, onlinePaymentUrl?: string): ICompany {
        return new Company(onlinePaymentUrl, fax, address);
    }

}

export interface IUTESetting
{
    uteEnabled: boolean;
    uteModified: Date;
}