import * as Enums from '../../common/Enums';


export interface ISignatureSettingsModel {
    signatureFormSelectionType: Enums.SignatureType,
    knowledgeBasedAuthentication: boolean,
    enableSignerDeligation: boolean,
    useSignatureStamp: boolean,
    enableEsignatureForBusinessReturns: boolean,
    manualAddressId: number
}

export const initailsignatureSettingsModel: ISignatureSettingsModel = {
    signatureFormSelectionType: Enums.SignatureType.ESignWhenAllIncludedReturnsAllowed,
    knowledgeBasedAuthentication: false,
    enableSignerDeligation: false,
    enableEsignatureForBusinessReturns: false,
    useSignatureStamp: false,
    manualAddressId: 0
}