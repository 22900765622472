import { History } from 'history';
import * as React from 'react';
import { match } from 'react-router';
import { IDownloadableDocumentsViewModel } from '../../core/domain/viewModels/IDownloadableDocumentsViewModel';
import { IDownloadableDocuments, FormType } from '../../core/domain/models/ITaxReturn';
import { IAttachment } from '../../core/domain/models/IAttachment';
import { Modal, Button } from 'react-bootstrap';
import { SvgIconDownload } from '../Common/Icons/SvgIcons';
import { MarsNotifier } from '../Common/Notification/MarsNotifier';
import { DownloadDocumentsConstants } from '../Common/Constants';

export interface DownloadAllProps {
    match: match;
    history: History;
    DownloadableDocuments: IDownloadableDocumentsViewModel;
    downloadTaxReturnDocument: (clientGuid: string, fileName: string) => any;
    downloadTransmittalDocument: (clientGuid: string, fileName: string) => any;
    downloadVoucherDocument: (clientGuid: string, fileName: string) => any;
    downloadAttachment: (clientGuid: string, fileName: string) => any;
    showModal: boolean;
    onHide: () => void;
    onDownloadAll: (clientGuid: string, fileName: string) => any;
    fileNamePrefix: string;
    isPreviewMode: boolean;
}

export const DownloadAll: React.SFC<DownloadAllProps> = (props) => {

    const downloadWarning = () => {
        MarsNotifier.Warning(DownloadDocumentsConstants.DownloadNotAllowed, null);
        return;
    };

    const param: any = props.match.params;

    const vouchers: IDownloadableDocuments[] = props.DownloadableDocuments.
        downloadableDocuments.filter(x => x.groupId === FormType.Vouchers);

    const attachments: IAttachment[] = props.DownloadableDocuments.attachments;

    const transmittals = props.DownloadableDocuments.
        downloadableDocuments.find(x => x.groupId === FormType.Transmittals);

    return (
        <div>
            <div style={{ position: 'relative' }}>
                <Modal
                    show={props.showModal}
                    onHide={props.onHide}
                    data-test-auto="3ED090CD-AE60-4267-8768-DEC161BF3E72">
                    <Modal.Header
                        closeButton
                        data-test-auto="460F501F-7086-4E49-BA9C-FDC8614D943B">
                        <Modal.Title>
                            <span
                                className='fas fa-envelope-open-text'
                                style={{ color: 'grey', marginRight: '5px' }}>
                            </span>Click a File To Download
						</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="col-lg-12 inline-flex-container download-all-body">
                            <ul>
                                {
                                    transmittals && transmittals.documentId > 0
                                    && (<li
                                        data-test-auto="856BDDCA-8535-41A4-AC03-3EBAB6132CB1"
                                        onClick={() => {
                                            props.isPreviewMode ?
                                                downloadWarning() :
                                                props.downloadTransmittalDocument(param.clientId, transmittals.fileName)
                                        }}>
                                        {"Filing Instructions.pdf"}
                                        <SvgIconDownload
                                            className="download-image"
                                        />
                                    </li>)
                                }
                                {
                                    vouchers && vouchers.length > 0
                                    && (vouchers.map((value, index) => {
                                        return (<li
                                            key={"voucher" + index}
                                            onClick={() => {
                                                props.isPreviewMode ?
                                                    downloadWarning() :
                                                    props.downloadVoucherDocument(param.clientId, value.fileName)
                                            }}
                                            data-test-auto={"9DNCEUKEW334T6VQWJHFAZFEHH" + index}
                                        >
                                            {value.fileName}
                                            <SvgIconDownload
                                                className="download-image"
                                            />
                                        </li>)
                                    })
                                    )
                                }
                                {
                                    attachments && attachments.length > 0 && (
                                        attachments.map((value, index) => {
                                            return (<li
                                                key={"attachment" + index}
                                                onClick={() => {
                                                    props.isPreviewMode ?
                                                        downloadWarning() :
                                                        props.downloadAttachment(param.clientId, value.fileName)
                                                }}
                                                data-test-auto={"KU1UP04APJ24G37XV5V6U1QVDM" + index}
                                            >
                                                Attachments -  {value.fileName}
                                                <SvgIconDownload
                                                    className="download-image"
                                                />
                                            </li>)
                                        })
                                    )
                                }

                            </ul>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            id="btnCancel"
                            variant={"light"}
                            onClick={props.onHide}
                            data-test-auto="77A3657B-E2D6-48BF-9547-A2E1791D9769"
                            className="btn btn-white btn-default rounded">
                            <i className="fa fa-times btn-icon"></i>Cancel
						</Button>
                        <Button
                            id="btnDownloadAll"
                            variant={"primary"}
                            onClick={() => {
                                props.isPreviewMode ?
                                    downloadWarning() :
                                    props.onDownloadAll(param.clientId, props.fileNamePrefix + "_Extensions.zip")
                            }}
                            data-test-auto="50AD8D23-F8B3-4251-B5E2-FDDB1399F346"
                            className="btn btn-primary rounded">
                            <i className="fa fa-download btn-icon"></i>Download All
						</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </div>);
}
