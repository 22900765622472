import { ViewerMode } from 'awesome-pdf-viewer/dist/viewer/ViewerBase';
import * as React from 'react';
import { match } from "react-router";
import { Viewer } from '../../../components/Common/PdfView/Viewer';
import { Body } from '../../../components/Common/StepLayout/Body';
import { StepLayout } from '../../../components/Common/StepLayout/StepLayout';
import { DocumentGroups } from '../../../core/domain/models/IGroup';
import { ITutorialInfo } from '../../../core/domain/models/ITutorialInfo';
import { IAttachmentDownloadViewModel } from '../../../core/domain/viewModels/IAttachmentDownloadViewModel';
import { IDocumentReviewModel } from '../../../core/domain/viewModels/IDocumentReviewModel';
import { PdfViewer } from './PdfViewer';
import { AddCCRecipientModal } from '../../CCRecipient/AddCCRecipientModal';
import { ICCRecipientModel, ICCRecipientDownloadableDocuments } from '../../../core/domain/models/ICCRecipientModel';
import { SelectiveDownload } from '../../Download/SelectiveDownload';
import { DocumentStatus, SignatureStatus, SignatureType } from '../../../core/common/Enums';
import { logger } from '../../../routes';
import { LogEventConstants } from '../../Helper/Constants';
import { History } from 'history';
import { PathConstants } from '../../Common/Constants';

interface IReviewWizardProps {
    documentReviewModel: IDocumentReviewModel[];
    downloadAttachment: (clientId: string, attachment: IAttachmentDownloadViewModel) => void;
    downloadAllAttachments: (clientId: string, attachments: IAttachmentDownloadViewModel[]) => void;
    requestPreviewDocuments?: (clientId: string) => void;
    prevStep: () => void;
    nextStep: () => void;
    onHeaderAndFooterVisibility: (visibility: boolean) => void;
    match: match;
    hideTitle: boolean;
    tutorialInfo: ITutorialInfo,
    hideReviewTutorial: (clientId: string, doNotShowNextTime: boolean) => any;
    closeCCRecipientModal?: () => void;
    showModal?: boolean;
    ccRecipientDownloadableDocuments?: ICCRecipientDownloadableDocuments[];
    taxdocumentId?: number;
    forwardDocumentsToRecipients?: (clientId: string, recipientInfo: ICCRecipientModel,
        callback: () => void) => void;
    downloadAllDocuments?: () => void;
    downloadSelectedDocuments?: (documentGroups: number[]) => void;
    showDownloadButton?: boolean;
    signingRequiredForSigner: boolean;
    signatureFormSelectionType: SignatureType;
    review: (clientId: string, callback?: ()=>void) => void;
    voucherExists?: boolean;
    goToStep: (stepNumber: number) => void;
    relatedTaxReturnDelivered: boolean;
    fromPreviewComponent: boolean;
    goToCompletionWizard: (isDocSettingsUpdated: boolean) => void;
    isClientView: boolean;
    history: History;
    spouseOperationRequired: boolean;
    signatureStatus : SignatureStatus;
    documentStatus: DocumentStatus;
    refreshTaxDocument:  (clientGuid: string, callback?: ((data?: any) => void))=> void;
    taxyear?: number;
}

export enum ReviewTabs {
    FilingInstructions = 1,
    TaxReturns,
    Attachments
}

interface IReviewDocumentKeyValue {
    tabName: string;
    tabIndex: number;
}

interface IReviewWizardStates {
    currentStep: number;
    showCloseTutorialPopover: boolean;
    activeTabs: IReviewDocumentKeyValue[];
    isDoNotShowCkbChecked: boolean;
    showDownloadModal: boolean;
}

enum ReviewTabNames {
    "Filing Instructions" = 1,
    "Tax Documents" = 2,
}

const autoIds: any = {};
autoIds["1"] = "1A7C1A5B-3330-41EB-BFD1-6888320671DC";
autoIds["2"] = "9303C576-2CA9-466A-97F1-9064CF9D86BA";
autoIds["3"] = "811EEF04-7D1E-4B00-9599-09B3AC081A5D";

const pageTitle = "Review Taxdocument Step";
export class ReviewWizardBody extends React.Component<IReviewWizardProps, IReviewWizardStates> {
    constructor(props: IReviewWizardProps) {
        super(props);
        this.state = {
            currentStep: ReviewTabs.FilingInstructions,
            showCloseTutorialPopover: true,
            activeTabs: [],
            isDoNotShowCkbChecked: false,
            showDownloadModal: false
        }
    }

    componentDidMount() {
        const param: any = this.props.match.params;
        (this.props.requestPreviewDocuments && (!this.props.documentReviewModel || this.props.documentReviewModel.length === 0)) && this.props.requestPreviewDocuments(param.clientId);
        logger.trackPageView('Review Taxdocument Page');
        logger.trackEvent(
            logger.buildEvent(
                `${LogEventConstants.Common.Open}  ${pageTitle}`,
                { count: 1, page: pageTitle, clientId: param.clientId }
            )
        );
    }

    static getDerivedStateFromProps(nextProps: IReviewWizardProps, prevState: IReviewWizardStates) {

        if (nextProps.documentReviewModel.length > 0 && prevState.activeTabs.length == 0) {
            if (nextProps.documentReviewModel.findIndex(x => x.documentGroup == DocumentGroups.Transmittals) != -1) {
                return ({ currentStep: ReviewTabs.FilingInstructions });
            }
            else if (nextProps.documentReviewModel.findIndex(x => x.documentGroup == DocumentGroups.TaxReturns) != -1) {
                return ({ currentStep: ReviewTabs.TaxReturns });
            }
        }

        return null
    }


    handleTabSelect = (index: any) => {
        this.setState({ currentStep: index });
    }

    getActiveTabs = (nextProps: IReviewWizardProps) => {
        logger.trackTrace(`Review page : getActiveTabs begins execution`);
        let activeTabs: IReviewDocumentKeyValue[] = [];
        let formIndex: number = nextProps.documentReviewModel.findIndex(x => x.documentGroup == DocumentGroups.Transmittals);
        formIndex != -1 ? activeTabs.push({ tabName: ReviewTabNames[1], tabIndex: 1 }) : "";

        if (nextProps.documentReviewModel.findIndex(x => x.documentGroup == DocumentGroups.TaxReturns) > -1 ||
            nextProps.documentReviewModel.findIndex(x => x.documentGroup == DocumentGroups.Vouchers) > -1 ||
            nextProps.documentReviewModel.findIndex(x => x.documentGroup == DocumentGroups.EFile) > -1) {
            activeTabs.push({ tabName: ReviewTabNames[2], tabIndex: 2 });
        }
        this.setState({ activeTabs: activeTabs });
        logger.trackTrace(`Review page : getActiveTabs completed execution`);
    }

    getNavBar = () => {
        logger.trackTrace(`Review page : getNavBar begins execution`);

        let currentStep: number = this.state.currentStep;

        let activeTabName = ReviewTabNames[currentStep];

        if (this.props.documentReviewModel.length > 0 && this.state.activeTabs.length == 0) {
            this.getActiveTabs(this.props);
        }

        let navBars: any = this.state.activeTabs.map((tabs, index) => {
            return <div key={tabs.tabIndex + tabs.tabName} className={activeTabName == tabs.tabName
                ? "right-pointer cursor-pointer arrow-right" : "cursor-pointer"} id="review-tab-navbar"
                data-test-auto={autoIds[tabs.tabIndex.toString()]}
                onClick={() => this.handleTabSelect(tabs.tabIndex)}>{tabs.tabName}</div>
        });

        logger.trackTrace(`Review page : getNavBar completed execution`);
        return navBars;
    }

    onPrevious = (index: number) => {
        logger.trackTrace(`Review page : onPrevious begins execution`);
        if (index > 1) {
            let tabsActive = this.state.activeTabs.length;
            if (tabsActive > 1) {
                let activeIndex: number = index - 1;
                if (this.state.activeTabs.findIndex(x => x.tabName == ReviewTabNames[activeIndex]) != -1) {
                    this.handleTabSelect(activeIndex);
                    return;
                }
                this.props.prevStep();
            }
            else {
                this.props.prevStep();
            }
        }
        else {
            this.props.prevStep();
        }
        logger.trackTrace(`Review page : onPrevious completed execution`);
    }

    onNextCompleted = () => {

        const {
            signingRequiredForSigner,
            signatureFormSelectionType,
            review,
            voucherExists,
            relatedTaxReturnDelivered,
            fromPreviewComponent,
            goToStep,
            goToCompletionWizard,
            match: { params },
            nextStep,
            isClientView,
            history,
            spouseOperationRequired,
            refreshTaxDocument,
            signatureStatus,
            documentStatus
        } = this.props;

        if (spouseOperationRequired) {
            nextStep();
            return;
        }

        if (!signingRequiredForSigner &&
            signatureFormSelectionType !== SignatureType.ManualSign) {
            if(documentStatus.toString() !== DocumentStatus[DocumentStatus.USERREVIEWED]){
                review((params as any).clientId, ()=> refreshTaxDocument((params as any).clientId));
            }

            if (!voucherExists || relatedTaxReturnDelivered) {
                if (fromPreviewComponent) {
                    if (isClientView) {
                        const param: any = this.props.match.params;
                        history && history.push(`${PathConstants.CompletionWizard}${param.clientId}`);
                    } else {
                        goToStep(0)
                    }
                } else {
                    goToCompletionWizard(true)
                }
            } else {
                nextStep();
            }

        } else {
            nextStep();
        }

    }

    onNext = (index: number) => {
        logger.trackTrace(`Review page : onNext begins execution`);
        if (index <= this.state.activeTabs.length) {
            let tabsActive = this.state.activeTabs.length;
            if (tabsActive > 1) {
                let activeIndex: number = index + 1;

                if (this.state.activeTabs.findIndex(x => x.tabName == ReviewTabNames[activeIndex]) != -1) {
                    this.handleTabSelect(activeIndex);
                    return;
                }

                this.onNextCompleted();

            }
            else {
                this.onNextCompleted();
            }
        }
        else {
            this.onNextCompleted();
        }

        logger.trackTrace(`Review page : onNext completed execution`);
    }

    handleViewModeChange = (viewerMode: ViewerMode) => {

        if (viewerMode == ViewerMode.Read) {
            this.props.onHeaderAndFooterVisibility(true);
        }
        else {
            this.props.onHeaderAndFooterVisibility(false);
        }
    }


    getActiveTabDetails = () => {
        logger.trackTrace(`Review page : getActiveTabDetails begins execution`);
        let document: IDocumentReviewModel;
        if (this.props.documentReviewModel.length > 0) {
            switch (this.state.currentStep) {
                case ReviewTabs.FilingInstructions:
                    document = this.props.documentReviewModel.filter(x => x.documentGroup == DocumentGroups.Transmittals)[0];
                    return (
                        <Viewer leftPanel={this.getNavBar()} url={document && document.fileLink} onViewModeChange={this.handleViewModeChange} />
                    )
                case ReviewTabs.TaxReturns:
                    document = this.props.documentReviewModel.filter(x => x.documentGroup == DocumentGroups.TaxReturns)[0];
                    return (
                        <Viewer leftPanel={this.getNavBar()} url={document && document.fileLink} onViewModeChange={this.handleViewModeChange} />
                    )
            }
        }
        logger.trackTrace(`Review page : getActiveTabDetails completed execution`);

    }


    getActiveTabName = () => {
        return ReviewTabNames[this.state.currentStep];
    }

    getActiveWidth = () => {
        switch (this.state.currentStep) {
            case 3:
                return 10;
            case 2:
            case 1:
                return 12;
            default:
                return 12

        }
    }

    getActiveClassName = () => {
        switch (this.state.currentStep) {
            case 3:
                return "";
            case 2:
            case 1:
                return "no-padding";
            default:
                return "no-padding"

        }
    }

    onTutorialDone = () => {
        const param: any = this.props.match.params;
        if (param.clientId) {
            this.props.hideReviewTutorial(param.clientId, this.state.isDoNotShowCkbChecked);
        }
    }

    onDoNotShowCkbChecked = (event: any) => {
        this.setState({ isDoNotShowCkbChecked: event.target.checked });
    }

    onDownloadClick = () => {
        this.setState({ showDownloadModal: true });
    }

    onHide = () => {
        this.setState({ showDownloadModal: false });
    }

    public render() {


        return (<StepLayout className={this.getActiveClassName()}
            lg={this.getActiveWidth()} md={12} sm={12} xs={12}
            title={this.props.hideTitle ? undefined : `Review Tax Documents - ${this.getActiveTabName()}`}
            showDownloadButton={this.props.showDownloadButton}
            handleDownload={this.onDownloadClick}
        >

            <Body lg={12} md={12} sm={12} xs={12}
                containerClassName={"row" + (this.state.currentStep === 3 ? " attachment-padding" : "")}
                automationTestId={"11BB28B5-42F3-4571-B567-20700B1740B6"}>
                <div className="row" style={{ height: 'inherit' }}>
                    <PdfViewer data-test-auto="4F3F7B87-7A53-4774-BDBA-1977E1BD6962">
                        {this.getActiveTabDetails()}
                    </PdfViewer>
                </div>
            </Body>

            <AddCCRecipientModal
                show={this.props.showModal}
                closeCCRecipientModal={this.props.closeCCRecipientModal}
                downloadableDocuments={this.props.ccRecipientDownloadableDocuments}
                taxdocumentId={this.props.taxdocumentId}
                forwardDocuments={this.props.forwardDocumentsToRecipients}
                match={this.props.match}
                taxyear={this.props.taxyear}
            />

            <SelectiveDownload
                showModal={this.state.showDownloadModal}
                downloadableDocuments={this.props.ccRecipientDownloadableDocuments}
                onHide={this.onHide}
                downloadAllDocuments={this.props.downloadAllDocuments}
                downloadSelectedDocuments={this.props.downloadSelectedDocuments}
            />
        </StepLayout>

        );


    }
}

