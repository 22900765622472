import { ITaxingAuthority } from "src/core/domain/models/ITaxingAuthority";

export interface ICommonDataViewModel {
    taxingAuthorities: ITaxingAuthority[];
    iceServers: RTCIceServer[];
}

export const initialCommonData: ICommonDataViewModel = {
    taxingAuthorities: [],
    iceServers: []
} 