import { connect } from 'react-redux';
import { IClientProcessViewModel } from '../../../core/domain/viewModels/IClientProcessViewModel';
import { actionCreators as TaxDocumentStore } from '../../../store/Common/TaxDocumentStore';
import { actionCreators as DownloadableDocumentsStore } from '../../../store/DownloadableDocumentsStore';
import { ApplicationState } from "../../../store/index";
import { actionCreators as SignProcessStore } from '../../../store/SignProcess/SignProcessStore';
import { actionCreators as DelegateeSignerStore } from '../../../store/Delegatee/DelegateeSignerStore';
import { CompletedWizardFlow } from './CompletedWizardFlow';
import { actionCreators as DelegateeSignProcessStore } from '../../../store/Delegatee/DelegateeSignProcessStore';
import { actionCreators as CompanyStore } from '../../../store/Common/Company/CompanyStore';
import { actionCreators as HelperStore } from '../../../store/Common/HelperStore';

function mapStateToProps(state: ApplicationState) {
    return {
        taxReturn: state.taxReturn.taxDocument,
        delegateeSignerInfo: state.delegateeSigner,
        downloadableDocument: state.downloadableDocuments,
        signProcessData: state.signProcessData.data,
        delegateeSignedDocuments: state.delegateeManualSignData,
        clientProcessData: state.signProcessData.clientprocessmodel
    };
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        requestCompanyDetails: (clientGuid: string, forceRefresh?: boolean, isDelegatee?: boolean) =>
            dispatch(CompanyStore.requestCompanyDetails(clientGuid, forceRefresh, isDelegatee)),

        requestTaxDocument: (clientGuid: string) => dispatch(TaxDocumentStore.requestTaxDocument(clientGuid)),

        downloadDelegateeSignedEFileDocument: (clientGuid: string, fileName: string) =>
            dispatch(DelegateeSignProcessStore.downloadDelegateeSignedEFileDocument(clientGuid, fileName)),

        requestDownloadableDocuments: (clientGuid: string) =>
            dispatch(DownloadableDocumentsStore.requestDownloadableDocuments(clientGuid)),

        delegateeDownloadAllAdditionEsignDocuments: (clientGuid: string, fileName: string) =>
            dispatch(DelegateeSignProcessStore.delegateeDownloadAllAdditionEsignDocuments(clientGuid, fileName)),

        delegateeDownloadEfileForms: (clientGuid: string) =>
            dispatch(DelegateeSignProcessStore.delegateeDownloadEfileForms(clientGuid)),

        requestLastVisitedStep: (clientGuid: string, successCallback?: (lastVisitedStep: number) => void) =>
            dispatch(SignProcessStore.requestLastVisitedStep(clientGuid, successCallback)),

        requestCurrentStepAndUserRole: (clientGuid: string, successCallback?: (clientProcessState: IClientProcessViewModel) => void) =>
            dispatch(SignProcessStore.requestCurrentStepAndUserRole(clientGuid, successCallback)),

        requestDelegateeSignedDocuments: (clientGuid: string, forceRefresh?: boolean) =>
            dispatch(DelegateeSignProcessStore.requestDelegateeSignedDocuments(clientGuid, forceRefresh)),

        requestDelegateeDetails: (clientId: string) => dispatch(DelegateeSignerStore.requestDelegateeDetails(clientId)),

        requestDelegateeCurrentStepAndUserRole: (clientGuid: string, successCallback?: (clientProcessState: IClientProcessViewModel) => void) =>
            dispatch(SignProcessStore.requestDelegateeCurrentStepAndUserRole(clientGuid, successCallback)),
        
        getClientHubDomainURL: (clientGuid: string, callback: (hubDomainURL: string) => void) =>
            dispatch(HelperStore.getClientHubDomainURL(clientGuid, callback))
    }
}


const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(CompletedWizardFlow);