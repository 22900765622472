import { AxiosResponse } from 'axios';
import { Action, Reducer } from 'redux';
import { ISignedDocument } from '../../core/domain/models/manualsign/SignedDocument';
import { actionTypes } from '../ActionTypes';
import { NotificationAction, StatusType } from '../Common/NotificationStore';
import { AppThunkAction } from '../index';
import { useState } from 'react';
import { ErrorMessages } from '../../components/Common/Constants';
import { DisplayDownloadFile } from 'src/components/Common/DisplayDownloadFile';
import { container } from '../../startup/inversify.config';
import { IFileUtilities } from '../../core/utilities/File/FileUtilities';
import { ILoader } from '../../core/utilities/ui/Loader';
import { TYPES } from '../../startup/types';
import { TelemetryLogger } from '../../components/Logger/AppInsights';
import { getUserTimeZone } from '../../components/Helper/HelperFunction';
import { initializeAxios } from 'src/core/services/dataAccess/DataService.Axios';

const logger = TelemetryLogger.getInstance();


interface RequestEsignDocumentAction {
    type: actionTypes.MANUAL_SIGN_REQUEST;
}

interface RequestCacheEsignDocumentAction {
    type: actionTypes.MANUAL_SIGN_CACHE;
    data: ISignedDocument[];
}

interface ResponseEsignDocumentAction {
    type: actionTypes.MANUAL_SIGN_RESPONSE;
    data: ISignedDocument[];
}

interface FailureEsignDocumentAction {
    type: actionTypes.MANUAL_SIGN_FAILURE;
    data: ISignedDocument[];
}

export interface ManualSignState {
    data: ISignedDocument[];
}

export const initialManualSignState: ManualSignState = {
    data: [] as ISignedDocument[]
}

type KnownAction =
    DispatchAction |
    NotificationAction;

type DispatchAction =
    ResponseEsignDocumentAction
    | RequestEsignDocumentAction
    | FailureEsignDocumentAction
    | RequestCacheEsignDocumentAction


const fileUtilities = container.get<IFileUtilities>(TYPES.IFileUtilities);
const loader = container.get<ILoader>(TYPES.ILoader);

export const actionCreators = {


    downloadEfileForms: (clientGuid: string, successCallback?: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        loader.show();  
        return initializeAxios().get('/api/Download/GetEfileDocumentSas/' + clientGuid)
            .then(function (response: AxiosResponse<any>) {
                fileUtilities.download(response.data);
                successCallback && successCallback();
                loader.hide();
            })
            .catch(function (error: any) {
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: error.response ? error.response.statusText : ErrorMessages.CompleteManualSign,
                    statusType: StatusType.Error
                });
                logger.trackError(`downloadEfileForms failed with error ${error.message} for client: ${clientGuid}`);
            });
    },

    downloadPreviewEfileForms: (clientGuid: string, successCallback?: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        loader.show();
        return initializeAxios().get('/api/Download/GetPreviewEfileDocumentSas/' + clientGuid)
            .then(function (response: AxiosResponse<any>) {
                fileUtilities.download(response.data);
                successCallback && successCallback();
                loader.hide();
            })
            .catch(function (error: any) {
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: error.response ? error.response.statusText : ErrorMessages.CompleteManualSign,
                    statusType: StatusType.Error
                });
                logger.trackError(`downloadPreviewEfileForms failed with error ${error.message} for client: ${clientGuid}`);
            });
    },

    requestSignedDocuments: (clientGuid: string, forceRefresh?: boolean, callback?:()=>void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const state = getState();
        let manualSignDataState = state.manualSignData != undefined ? state.manualSignData.data : initialManualSignState.data;
        if (!forceRefresh && manualSignDataState.length > 0) {
            dispatch({ type: actionTypes.MANUAL_SIGN_CACHE, data: manualSignDataState });
            return;
        }

        return initializeAxios().get<ISignedDocument[]>('/api/ManualSign/GetSignedDocuments/' + clientGuid, {}, true)
            .then(function (response: AxiosResponse<ISignedDocument[]>) {
                callback && callback()
                dispatch({
                    type: actionTypes.MANUAL_SIGN_RESPONSE, data: response.data
                });
            })
            .catch(function (error: any) {
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: error.response ? error.response.statusText : ErrorMessages.SignedDocumentError,
                    statusType: StatusType.Error
                });
                dispatch({ type: actionTypes.MANUAL_SIGN_FAILURE, data: manualSignDataState });
                logger.trackError(`requestSignedDocuments failed with error ${error.message} for client: ${clientGuid}`);
            });
    },

    addSignedDocument: (clientGuid: string, signedDocument: ISignedDocument, successCallback?: () => void, failureCallback?: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const state = getState();
        return initializeAxios().postJson(signedDocument, '/api/ManualSign/AddSignedDocument/' + clientGuid)
            .then(function (response: AxiosResponse<any>) {
                successCallback && successCallback();
            })
            .catch(function (error: any) {
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: error.response ? error.response.statusText : ErrorMessages.AddSignedDocument,
                    statusType: StatusType.Error
                });
                failureCallback && failureCallback();
                logger.trackError(`addSignedDocument failed with error ${error.message} for client: ${clientGuid}`);
            });
    },

    deleteSignedDocument: (clientGuid: string, id: number, successCallback?: () => void, failureCallback?: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const state = getState();
        loader.show();
        return initializeAxios().get(`/api/ManualSign/RemoveSignedDocument/${clientGuid}?signedDocumentId=${id}`)
            .then(function (response: AxiosResponse<any>) {
                successCallback && successCallback();
                loader.hide();
            })
            .catch(function (error: any) {
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: error.response ? error.response.statusText : ErrorMessages.DeleteSignedDocument,
                    statusType: StatusType.Error
                });
                failureCallback && failureCallback();
                logger.trackError(`deleteSignedDocument failed with error ${error.message} for client: ${clientGuid}`);
            });
    },

    completeManualSign: (clientGuid: string, successCallback?: () => void, failureCallback?: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {

        loader.show();
        const state = getState();
        return initializeAxios().post('/api/ManualSign/CompleteManualSign/timeZone/' + clientGuid + '?userTimeZone=' + getUserTimeZone())
            .then(function (response: AxiosResponse<any>) {
                successCallback && successCallback();
                loader.hide();
            })
            .catch(function (error: any) {
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: error.response ? error.response.statusText : ErrorMessages.CompleteManualSign,
                    statusType: StatusType.Error
                });
                failureCallback && failureCallback();
                logger.trackError(`completeManualSign failed with error ${error.message} for client: ${clientGuid}`);
            });
    },
}

export const reducer: Reducer<ManualSignState> = (state: ManualSignState = initialManualSignState, incomingAction: Action) => {
    const action = incomingAction as DispatchAction;
    const currentState = Object.assign({}, state);
    switch (action.type) {
        case actionTypes.MANUAL_SIGN_REQUEST:
            currentState.data = [];
            return currentState;
        case actionTypes.MANUAL_SIGN_RESPONSE:
        case actionTypes.MANUAL_SIGN_CACHE:
            currentState.data = action.data;
            return currentState;
        case actionTypes.MANUAL_SIGN_FAILURE:
            currentState.data = action.data
            return currentState;
        default:
            return currentState || initialManualSignState;
    }
}; 