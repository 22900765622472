import * as React from 'react';
import { ClientType } from '../../../core/common/Enums';

export interface NextSignerAlertProps {
    nextSignerName: string;
    clientId: string;
    icon: any;
    alertMessage: string;
    onclick: (clientId: string) => void
}

export const NextSignerAlert: React.FunctionComponent<NextSignerAlertProps> = ({
    nextSignerName, clientId, icon, alertMessage, onclick
}) => {
    const IconSvg = icon;
    return (
        <div className="pending alert alert-warning">
            <span>
                <IconSvg height={20} />
            </span>
            <span className="margin-left-1"> {alertMessage} </span>
            <span className="send-reminder"
                data-test-auto="6FD905BE-362E-41DA-880C-F40033BC409C">
                <a onClick={() => { onclick(clientId) }}>Send Reminder</a>
            </span>
        </div>);
}