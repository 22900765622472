
export enum ClientType {
    Undefined = 0,
    Taxpayer,
    Spouse,
    Partner,
    Partnership,
    Shareholder
}

export enum EngagementType {
    None,
    E1040 = 1,
    E1065,
    E1120,
    E1120S,
    E1041,
    E1040NR,
    E990,
    E990PF,
    E990EZ
}

export enum EngagementTypeS {
    None = "None",
    E1040 = "E1040",
    E1065 = "E1065",
    E1120 = "E1120",
    E1120S = "E1120S",
    E1041 ="E1041",
    E1040NR = "E1040NR",
    E990 = "E990",
    E990PF = "E990PF",
    E990EZ = "E990EZ"
}

export enum DocumentStatus {
    None = 0,
    UPLOADED,
    ERROR,
    READY,
    PROCESSING,
    REVIEW,
    USERSIGNED,
    DELIVERED,
    APPROVEDFORDELIVERY,
    PREPARINGFORDELIVERY,
    DELIVERYFAILED,
    NONSUPPORTED,
    RECALLED,
    DELETED,
    USERREVIEWED = 22,
}

export enum SignatureStatus {
    None = 0,
    ESigned,
    ManuallySigned,
    Uploaded,
    AwaitingESign,
    AwaitingUpload,
    Locked,
    MailOrFax,
    SignedAndESigned,
    AutoParsed,
    Processing,
    Delivering,
    Delivered,
    DeliveryFailed,
    Reviewed = 20,
    AwaitingReview = 21,
    PartiallySigned = 22,
    PartiallyReviewed = 23
}

export enum DefaultSenderInfoType {
    None = "None",
    Company = "Company",
    User = "User"
}

export enum SignatureType {
    None,
    ESignWhenAllIncludedReturnsAllowed,
    ManualSign,
    ReviewOnly
}

export enum KBAMode {
    Test = 0,
    Live = 1
}

export enum TransmittalDownloadOption {
    None = "None",
    IncludeTransmittalInTaxReturnPDF = "IncludeTransmittalInTaxReturnPDF",
    CreateSeparateTransmittalPDF = "CreateSeparateTransmittalPDF"
}
export enum VoucherDownloadOption {
    None = "None",
    SeparateByQuarterlyDueDates = "SeparateByQuarterlyDueDates",
    GroupInSinglePDF = "GroupInSinglePDF"
}
export enum TaxpayerAuthenticationType {
    None = "None",
    Last4ofSSN = "Last4ofSSN",
    First4ofSSN = "First4ofSSN"
}
export enum SignatureFormSelectionType {
    None = "None",
    ESign = "ESign",
    ESignWhenAllIncludedReturnsAllowed = "ESignWhenAllIncludedReturnsAllowed",
    ManualSign = "ManualSign"
}

export enum NotificationType {
    Info,
    Error,
    Success,
    Warning
}

export enum KBATransactionResultType {
    passed,
    failed,
    error,
    questions,
    accepted,
    retryExceeded
}

export enum DistributeType {
    Electronic,
    Manual
}

export enum EntityType {
    None,
    Individual,
    Partnership,
    Corporation,
    Estate,
    Trust,
    DisregardedEntity,
    ExemptOrganization,
    ForeignGovernment,
    Nominee
}

export enum RedirectionSourcePage {
    None = 0,
    TaxpayerFlow = 1,
    Signcompleted = 2,
    Pay = 3,
    Download = 4,
    DistributeK1 = 5,
    DelegateeInitialize = 6
}

export enum K1ReportStatus {
    None = 0,
    Updated,
    Confirmed,
    Pending
}
export enum Role {
    None = 0,
    Taxpayer,
    Spouse,
    Partner,
    Partnership,
    Shareholder,
    CPA,
    Delegatee
}