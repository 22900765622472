import * as React from 'react';
import { CustomButton, CustomButtonSize } from '../Common/CustomButton/CustomButton';
import { ManualSignConstants, Common, DownloadDocumentsConstants } from '../Common/Constants';
import { StepLayout } from '../Common/StepLayout/StepLayout';
import { Body } from '../Common/StepLayout/Body';
import { ITaxReturn } from '../../core/domain/models/ITaxReturn';
import { IAdditionalEsignDocument } from '../../core/domain/models/IAdditionalEsignDocument';
import { MarsNotifier } from '../Common/Notification/MarsNotifier';
import { logger } from '../../routes';
import { SignProcessSteps } from '../Home/TaxpayerSignFlowManager';
import { LogEventConstants } from '../Helper/Constants';


export interface DownloadDocumentsProps {
    clientId: string;
    goToStepById(id: any): void;
    downloadEfileForms(clientId: string, successCallback?: () => void): void;
    downloadAllAdditionEsignDocuments: (clientId: string, fileName: string) => any;
    additionalEsignDocuments: IAdditionalEsignDocument[];
    taxReturn: ITaxReturn;
    clientName: string;
    isPreviewMode: boolean;
}

const pageTitle = "manual sign download Step";
export const ManualSignDownloadFiles: React.FunctionComponent<DownloadDocumentsProps> = ({
    clientId, goToStepById,
    downloadEfileForms, downloadAllAdditionEsignDocuments,
    additionalEsignDocuments, taxReturn,
    clientName, isPreviewMode,
}) => {


    let handleEfileDownload = () => {
        if (!isPreviewMode) {
            downloadEfileForms(clientId, () => goToStepById(SignProcessSteps.ManualSign.toString()));
            logger.trackTrace('Manual Sign Download Document Page: Efile Downloaded');
            setTimeout(function () {
                additionalEsignDocuments && additionalEsignDocuments.length > 0
                    && downloadAllAdditionEsignDocuments(clientId, getOtherSignedDocumentFileName());
                logger.trackTrace('Manual Sign Download Document Page: Additional Document(s) Downloaded');
                logger.trackEvent(
                    logger.buildEvent(
                        `${LogEventConstants.Common.ManualDownload.ManualSignDownload}  ${pageTitle}`,
                        { count: 1, page: pageTitle }
                    )
                );
            }, 2000);
        } else {
            MarsNotifier.Warning(DownloadDocumentsConstants.DownloadNotAllowed, null);
            goToStepById(SignProcessSteps.ManualSign.toString());
        }
    };

    let getFileNamePrefix = () => {
        return clientName + "_" + taxReturn.taxYear + "_" + taxReturn.engagementType.toString().substr(1);
    }

    let getOtherSignedDocumentFileName = () => {
        return getFileNamePrefix() + "_Extensions_Other Signed Documents.zip";
    }

    return (<StepLayout>

        <Body title={ManualSignConstants.Title} lg={10} md={12} sm={12} xs={12}>

            <div className={"col-lg-12 no-padding download-documents-area"} data-test-auto="C2BF6BA8-E0E1-44B2-BD13-20C3B257B29B">

                <strong> {ManualSignConstants.DownloadDocumentTitle} </strong>

                <div className={"downlod-button-container"}>
                    <CustomButton size={CustomButtonSize.XL} icon={Common.Images.download} text={ManualSignConstants.DownloadDocumentButtonText} onClick={handleEfileDownload} />
                </div>

                <hr />

            </div>
        </Body>
    </StepLayout>);
};

