import { ApplicationState } from "../../../store/index";
import { connect } from 'react-redux';
import { actionCreators as K1DistributeStore } from '../../../store/K1/K1DistributeStore';
import { IK1ShareHolderDetails } from '../../../core/domain/models/IK1Distribute';
import { ManualDistribution } from "./ManualDistribution";
import { actionCreators as TaxDocumentStore } from '../../../store/Common/TaxDocumentStore';
import { EngagementTypeS } from "src/core/common/Enums";
import { actionCreators as SignProcessStore } from '../../../store/SignProcess/SignProcessStore';
import { IClientProcessViewModel } from '../../../core/domain/viewModels/IClientProcessViewModel'


function mapStateToProps(state: ApplicationState) {
    return {
        k1Data: state.k1Data.data,
        companyData: state.companyData.data,
        commonData: state.commonData,
        headInfoViewModel: state.headerInfo,
        taxReturn: state.taxReturn.taxDocument,
    };
}
//temperroy fix for webpack failure need to invetigate further when we have time
const mapDispatchToProps = (dispatch: any) => {
    return {
        requestTaxDocument: (clientGuid: string) => dispatch(TaxDocumentStore.requestTaxDocument(clientGuid)),
        requestK1ShareHolderDetails: (clientGuid: string, pageNo: number, pageSize: number, sortBy: string, sortOrder: string, searchText: string) => dispatch(K1DistributeStore.requestK1ShareHolderDetails(clientGuid, pageNo, pageSize, sortBy, sortOrder, searchText)),
        updateK1ShareHolderEmail: (clientGuid: string, k1Partner: IK1ShareHolderDetails, successCallback?: () => void) => dispatch(K1DistributeStore.updateK1ShareHolderEmail(clientGuid, k1Partner, successCallback)),
        updateK1ShareHolderDetail: (clientGuid: string, k1Partner: IK1ShareHolderDetails, isEmailChange: boolean, callBack?: () => void) => dispatch(K1DistributeStore.updateK1ShareHolderDetail(clientGuid, k1Partner, isEmailChange, callBack)),
        downloadK1DocumentAsync: (clientId: string, engagementTpe: EngagementTypeS) => dispatch(K1DistributeStore.downloadK1DocumentAsync(clientId, engagementTpe)),
        downloadIndividualK1DocumentAsync: (clientId: string, shareHolder: IK1ShareHolderDetails, engagementTpe: EngagementTypeS) => dispatch(K1DistributeStore.downloadIndividualK1DocumentAsync(clientId, shareHolder, engagementTpe)),
        sendMailToShareHolder: (clientId: string, partners: IK1ShareHolderDetails[], callback: () => void) => dispatch(K1DistributeStore.sendMailToShareHolder(clientId, partners, callback)),
        downloadSelectedK1DocumentAsync: (clientId: string, shareHolders: IK1ShareHolderDetails[], engagementTpe: EngagementTypeS) => dispatch(K1DistributeStore.downloadSelectedK1DocumentAsync(clientId, shareHolders, engagementTpe)),
        downloadSelectedK1DocumentForPreviewAsync: (clientId: string, shareHolders: IK1ShareHolderDetails[], engagementTpe: EngagementTypeS) => dispatch(K1DistributeStore.downloadSelectedK1DocumentForPreviewAsync(clientId, shareHolders, engagementTpe)),
        requestCurrentStepAndUserRole: (clientGuid: string, successCallback?: (clientProcessState: IClientProcessViewModel) => void) => dispatch(SignProcessStore.requestCurrentStepAndUserRole(clientGuid, successCallback)),
        dispatch
    }
}


const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(ManualDistribution);



