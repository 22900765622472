import * as NotificationStore from '../store/Common/NotificationStore';
import * as AccountStore from '../store/Common/AccountStore';
import * as LoaderStore from '../store/Common/LoaderStore';
import * as HelperStore from '../store/Common/HelperStore';
import * as SummaryStore from '../store/SummaryStore';
import * as EsignStore from '../store/Common/EsignStore';
import * as PayStore from '../store/PayStore';
import { ISummaryViewModel } from '../core/domain/viewModels/ISummaryViewModel';
import { ICommonDataViewModel } from '../core/domain/viewModels/ICommonDataViewModel';
import { IDocumentSettings } from '../core/domain/models/IDocumentSettings';
import * as PreparerMessageStore from '../store/PreparerMessageStore';
import * as DocumentReviewStore from '../store/ReviewStore';
import { IDocumentReviewModel } from '../core/domain/viewModels/IDocumentReviewModel';
import { ISignatureSettingsModel } from 'src/core/domain/models/ISignatureSettingsModel';
import { IKBATransactionResponse } from '../core/domain/models/IKBA';
import * as TaxDocumentStore from '../store/Common/TaxDocumentStore';
import * as ManualSignStore from '../store/Sign/ManualSignStore';
import * as CompanyStore from '../store/Common/Company/CompanyStore';
import * as K1DistributeStore from '../store/K1/K1DistributeStore';
import * as DownloadableDocumentsStore from '../store/DownloadableDocumentsStore';
import * as ReviewStore from '../store/ReviewStore';
import { IDownloadableDocumentsViewModel } from '../core/domain/viewModels/IDownloadableDocumentsViewModel';
import * as SignProcessState from './SignProcess/SignProcessStore';
import { IPayScreenViewModel } from 'src/core/domain/viewModels/IPayScreenViewModel';
import { ITutorialInfo, initialTutorialInfo } from '../core/domain/models/ITutorialInfo';
import * as TutorialStore from './Tutorial/TutorialStore';
import * as CCRecipientStore from './CCRecipientStore';
import { ICCRecipientDownloadableDocuments, ICcRecipientDownloadModel } from 'src/core/domain/models/ICCRecipientModel';
import { IHeaderInfoViewModel } from 'src/core/domain/viewModels/IHeaderInfoViewModel';

import {
    reducer as SigningRequiredStoreReducer,
    SigningRequiredStoreState,
} from './SignProcess/SigningRequiredStore';
import { IDelegateeInfo } from '../core/domain/models/DelegateeModels';
import * as DelegateeSignerStore from '../store/Delegatee/DelegateeSignerStore';
import * as DelegateeSignProcessStore from '../store/Delegatee/DelegateeSignProcessStore';
import { ISignedDocument } from '../core/domain/models/manualsign/SignedDocument';
import { reducer as coverPageStoreReducer } from "./../store/Login/TP/CoverPage/CoverPageStore";
import { reducer as ssnPageStoreReducer } from "../store/Login/TP/SSN/SSNStore";
import { reducer as otpPageStoreReducer } from "../store/Login/TP/OTP/OTPStore";
import { reducer as headerStoreReducer } from "../store/Login/Common/Header/HeaderStore";
import { reducer as errorStoreReducer } from "../store/Login/Common/Error/ErrorStore";
import { reducer as ccrecipientOtpPageStoreReducer } from "../store/Login/CCRecipient/OTP/OTPStore";
import { reducer as delegateeOtpPageStoreReducer } from "../store/Login/Delegatee/OTP/OTPStore";
import {
    CCRecipientOTPPageStoreState, CoverPageStoreState,
    DelegateeOTPPageStoreState, ErrorStoreState, HeaderStoreState,
    OtpPageStoreState, SSNPageStoreState
} from './Login/Models/StoreModels';

export interface ApplicationState {
    notification: NotificationStore.NotificationState;
    esignData: EsignStore.EsignState;
    manualSignData: ManualSignStore.ManualSignState;
    companyData: CompanyStore.CompanyState;
    kbaData: IKBATransactionResponse;
    headerInfo: IHeaderInfoViewModel;
    recipientHeaderInfo: IHeaderInfoViewModel;
    loader: LoaderStore.IRequestStatusDictionary;
    commonData: ICommonDataViewModel;
    summaryData: ISummaryViewModel;
    documentSettings: IDocumentSettings;
    documentReviewModel: IDocumentReviewModel[];
    signatureSettingData: ISignatureSettingsModel;
    taxReturn: TaxDocumentStore.TaxDocumentState;
    k1Data: K1DistributeStore.K1State;
    downloadableDocuments: IDownloadableDocumentsViewModel;
    signProcessData: SignProcessState.SignProcessState;
    payScreenData: IPayScreenViewModel;
    previewDocument: ReviewStore.IPreviewState;
    tutorialInfo: ITutorialInfo;
    ccRecipientDownloadableDocuments: ICCRecipientDownloadableDocuments[];
    ccDocumentsForDownload: ICcRecipientDownloadModel[];
    signingRequiredStore: SigningRequiredStoreState;
    delegateeHeaderInfo: IHeaderInfoViewModel;
    delegateeSigner: IDelegateeInfo;
    delegateeReviewModel: IDocumentReviewModel[];
    delegateeSignatureControlsData: EsignStore.EsignState;
    delegateeManualSignData: ISignedDocument[];
    delegateeDownloadableDocuments: ICCRecipientDownloadableDocuments[];
    delegateeKBAData: IKBATransactionResponse;
    coverPageStore: CoverPageStoreState,
    ssnPageStore: SSNPageStoreState,
    otpPageStore: OtpPageStoreState,
    headerPageStore: HeaderStoreState
    ccRecipientOTPPageStore: CCRecipientOTPPageStoreState;
    delegateeOTPPageStore: DelegateeOTPPageStoreState;
    errorStore: ErrorStoreState;
}

export const reducers = {
    notification: NotificationStore.reducer,
    esignData: EsignStore.reducer,
    companyData: CompanyStore.reducer,
    manualSignData: ManualSignStore.reducer,
    kbaData: EsignStore.kbaReducer,
    headerInfo: AccountStore.reducer,
    recipientHeaderInfo: AccountStore.recipientHeaderInfoReducer,
    loader: LoaderStore.reducer,
    commonData: HelperStore.reducer,
    summaryData: SummaryStore.reducer,
    documentSettings: PreparerMessageStore.reducer,
    documentReviewModel: DocumentReviewStore.reducer,
    taxReturn: TaxDocumentStore.reducer,
    k1Data: K1DistributeStore.reducer,
    downloadableDocuments: DownloadableDocumentsStore.reducer,
    signProcessData: SignProcessState.reducer,
    payScreenData: PayStore.reducer,
    previewDocument: ReviewStore.previewReducer,
    tutorialInfo: TutorialStore.reducer,
    ccRecipientDownloadableDocuments: CCRecipientStore.reducer,
    ccDocumentsForDownload: CCRecipientStore.downloadReducer,
    signingRequiredStore: SigningRequiredStoreReducer,
    delegateeHeaderInfo: AccountStore.delegateeHeaderInfoReducer,
    delegateeSigner: DelegateeSignerStore.delegateeInfoReducer,
    delegateeReviewModel: DelegateeSignerStore.delegateeReviewReducer,
    delegateeSignatureControlsData: DelegateeSignProcessStore.reducer,
    delegateeManualSignData: DelegateeSignProcessStore.delegateeManualSignDataReducer,
    delegateeDownloadableDocuments: DelegateeSignerStore.delegateeDownloadableDocReducer,
    delegateeKBAData: DelegateeSignProcessStore.delegateeKbaAuthReducer,
    coverPageStore: coverPageStoreReducer,
    ssnPageStore: ssnPageStoreReducer,
    otpPageStore: otpPageStoreReducer,
    headerPageStore: headerStoreReducer,
    ccRecipientOTPPageStore: ccrecipientOtpPageStoreReducer,
    delegateeOTPPageStore: delegateeOtpPageStoreReducer,
    errorStore: errorStoreReducer
};

export interface AppThunkAction<TAction> {
    (dispatch: (action: TAction | AppThunkAction<TAction>) => void, getState: () => ApplicationState): void;
}
