import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import MainComponentContainer from './components/Home/MainComponentContainer';
import PreviewComponentContainer from './components/Home/PreviewComponentContainer';
import { Layout } from './components/Layout/Layout';
import { CcRecipientLayout } from './components/Layout/RecipientLayout';
import ManualSignCompleted from './components/ManualSign/ManualSignContainer';
import PayCompleted from './components/Pay/PayContainer';
import DownloadContainer from './components/Download/DownloadContainer';
import RouteManager from './components/Home/RouteMangerContainer';
import CompletedSummaryPageContainer from './components/CompletedSummary/CompleteWizard/CompletedSummaryPageContainer';
import K1Distribution from './components/K1Distribution/Completed/K1DistributionContainer';
import ElectronicDistribution from './components/K1Distribution/Completed/ElectronicDistributionContainer';
import ManualDistribution from './components/K1Distribution/Completed/ManualDistributionContainer';
import ScreenShareContainer from './components/ScreenShare/ScreenShareContainer';
import CcRecipientDownloadContainer from './components/CCRecipient/CCRecipientDownloadContainer';
import { initializeAppInsights, TelemetryLogger } from './components/Logger/AppInsights';
import DelegateeSignerContainer from './components/Home/DelegateeSignerContainer';
import DelegateeSignCompleteContainer from './components/Delegatee/CompletedWizard/CompletedWizardContainer';
import DelegateManualSignContainer from './components/Delegatee/ManualSign/ManualSignContainer';
import DelegateeRouteManager from './components/Home/DelegateeRouteManagerContainer';
import { DelegateeLayout } from './components/Layout/Delegatee/DelegateeLayout';
import CoverPageContainer from "./components/Login/TP/CoverPage/CoverPageContainer";
import PreviewCoverPageContainer from "./components/Login/TP/PreviewCoverPage/CoverPageContainer";
import OTPContainer from "./components/Login/TP/OTP/OTPContainer";
import SSNContainer from "./components/Login/TP/SSN/SSNContainer";
import MobileOTPContainer from "./components/Login/TP/MobileOTP/MobileOTPContainer";
import DelegateeOTP from "src/components/Login/Delegatee/EmailOTP/EmailOtpContainer";
import DelegateeMobileOTP from "./components/Login/Delegatee/MobileOTP/MobileOTPContainer";
import CCRMobileOTP from "./components/Login/CCRecipient/MobileOTP/MobileOTPContainer";
import CCREmailOTP from "./components/Login/CCRecipient/EmailOTP/EmailOtpContainer";
import DelegateeRouteSelect from "./components/Login/Delegatee/RouteSelector/RouteSelectorContainer";
import CCRRouteSelect from "./components/Login/CCRecipient/RouteSelector/RouteSelectorContainer";
import { InvalidLink } from './components/Login/Common/InvalidLink/InvalidLink';
import ErrorPageContainer from './components/Login/Common/Error/ErrorPageContainer';
import MobileRestrictContainer from './components/Login/Common/MobileRestrict/MobileRestrictContainer';
import { LoginLayout } from './components/Login/Common/Layout/LoginLayout';
import { useDispatch } from 'react-redux';
import { actionCreators } from './store/Common/HelperStore'
import { initializeAxios } from './core/services/dataAccess/DataService.Axios';
import { AxiosResponse } from 'axios';

export const logger = TelemetryLogger.getInstance();

export const routes = (
    <Switch>
        <ApiRouteWrapper exact path="/WelcomeScreen/Index/:clientId" />
        <ApiRouteWrapper exact path="/Taxpayer/:clientId" />
        <ApiRouteWrapper exact path="/DownloadTaxDocuments/Index/:clientId" />
        <ApiRouteWrapper exact path="/Controller/Index/:clientId" />
        <ApiRouteWrapper exact path="/Controller/:clientId" />
        <ApiRouteWrapper exact path="/CC/DownloadTaxDocumentsReview/:clientId" />
        <ApiRouteWrapper exact path="/Delegatee/Login/:clientId" />
        <ApiRouteWrapper exact path="/WelcomeScreen/Index/" />
        {/* <ApiRouteWrapper exact path="/K1Download/Login/:clientId" />
        <ApiRouteWrapper exact path="/K1Download/index" />
        <ApiRouteWrapper exact path="/K1Partner/index" /> */}

        <RouteWrapper exact path="/MobileRestrict/:role/:clientId" component={MobileRestrictContainer} layout={LoginLayout} />

        <RouteWrapper exact path="/invalid" component={InvalidLink} layout={LoginLayout} />
        <RouteWrapper exact path="/CoverPage/Index/:clientId" component={CoverPageContainer} layout={LoginLayout} />
        <RouteWrapper exact path="/OTP/Index/:clientId" component={OTPContainer} layout={LoginLayout} />
        <RouteWrapper exact path="/Authentication/SSN/:clientId" component={SSNContainer} layout={LoginLayout} />
        <RouteWrapper exact path="/MobileOTP/Index/:clientId" component={MobileOTPContainer} layout={LoginLayout} />

        <RouteWrapper exact path="/Delegatee/OTP/Index/:clientId" component={DelegateeOTP} layout={LoginLayout} />
        <RouteWrapper exact path="/Delegatee/MobileOTP/Index/:clientId" component={DelegateeMobileOTP} layout={LoginLayout} />
        <RouteWrapper exact path="/Delegatee/:clientId" component={DelegateeRouteSelect} layout={LoginLayout} />

        <RouteWrapper exact path="/CCRecipient/OTP/Index/:clientId" component={CCREmailOTP} layout={LoginLayout} />
        <RouteWrapper exact path="/CCRecipient/MobileOTP/Index/:clientId" component={CCRMobileOTP} layout={LoginLayout} />
        <RouteWrapper exact path="/CCRecipient/:clientId" component={CCRRouteSelect} layout={LoginLayout} />

        <RouteWrapper exact path="/coverpage/taxpayerpreview/:clientId" component={PreviewCoverPageContainer} layout={LoginLayout} />

        <RouteWrapper exact path="/download/:clientId" component={DownloadContainer} layout={Layout} />
        <RouteWrapper exact path="/taxpayerflow/:clientId" component={RouteManager} layout={Layout} />
        <RouteWrapper exact path="/home/index/:clientId" component={RouteManager} layout={Layout} />
        <RouteWrapper exact path="/signflow/:clientId" component={MainComponentContainer} layout={Layout} />
        <RouteWrapper exact path="/ManualSign/:clientId" component={ManualSignCompleted} layout={Layout} />
        <RouteWrapper exact path="/signcompleted/:clientId" component={CompletedSummaryPageContainer} layout={Layout} layoutStyle={{ height: '100%' }} />
        <RouteWrapper exact path="/Pay/:clientId" component={PayCompleted} layout={Layout} />
        <RouteWrapper exact path="/taxpayerpreview/:clientId" component={RouteManager} layout={Layout} />
        <RouteWrapper exact path="/preview/index/:clientId" component={RouteManager} layout={Layout} />
        <RouteWrapper exact path="/previewflow/:clientId" component={PreviewComponentContainer} layout={Layout} />
        <RouteWrapper exact path="/DistributeK1/:clientId" component={K1Distribution} layout={Layout} />
        <RouteWrapper exact path="/DistributeK1/Electronic/:clientId" component={ElectronicDistribution} layout={Layout} />
        <RouteWrapper exact path="/DistributeK1/Manual/:clientId" component={ManualDistribution} layout={Layout} />
        <RouteWrapper exact path="/screenshare/:clientId" component={ScreenShareContainer} layout={Layout} />
        <CcProtectedRoute exact path="/Cc/DownloadTaxDocuments/:clientId" component={CcRecipientDownloadContainer} layout={CcRecipientLayout} />
        <RouteWrapper exact path="/DelegateeInitialize/:clientId" component={DelegateeRouteManager} layout={DelegateeLayout} />
        <RouteWrapper exact path="/Delegatee/SignFlow/:clientId" component={DelegateeSignerContainer} layout={DelegateeLayout} />
        <RouteWrapper exact path="/Delegatee/ManualSign/:clientId" component={DelegateManualSignContainer} layout={DelegateeLayout} />
        <RouteWrapper exact path="/Delegatee/SignCompleted/:clientId" component={DelegateeSignCompleteContainer} layout={DelegateeLayout} />
        <RouteWrapper exact path="/Error/:message?" component={ErrorPageContainer} layout={LoginLayout} />
        <RouteWrapper path="*" component={InvalidLink} />
    </Switch>);

function RouteWrapper({
    component: Component,
    layout: Layout,
    ...rest
}: any) {
    
    initializeAppInsights(rest.computedMatch.params.clientId);

    let dispatch = useDispatch();
    
    React.useEffect(() => {

        rest.computedMatch.params.clientId && dispatch(actionCreators.getWalkMeScript(rest.computedMatch.params.clientId, (script: string) => {
            if (script && script.length > 0) {
                let scriptElement = document.createElement("script");
                scriptElement.type = "text/javascript";
                scriptElement.async = true;
                scriptElement.text = script;
                document.head.appendChild(scriptElement);
                (window as any).loadWalkMe && (window as any).loadWalkMe();
            }
        }, (typeof (Layout) != typeof (LoginLayout))));


    },[])

    return (
        <Route {...rest} render={(props) =>
            Layout ?
                <Layout {...props} {...rest}>
                    <Component {...props} />
                </Layout> :
                <Component {...props} />
        } />
    );
}

function CcProtectedRoute({
    component: Component,
    layout: Layout,
    ...rest
}: any) {
    initializeAppInsights(rest.computedMatch.params.clientId);
    return (
        <Route {...rest} render={(props) =>
            Layout ?
                <CcRecipientLayout {...props}>
                    <Component {...props} />
                </CcRecipientLayout> :
                <Component {...props} />
        } />
    );
}

function ApiRouteWrapper({
    ...rest
}: any) {

    React.useEffect(() => {

        let path: string = rest.computedMatch.url;
        if (path && path[0] === "/") {
            path = path.substring(1);
        }
        initializeAxios().get<any>(path + rest.location.search)
            .then(function (response: AxiosResponse<any>) {  
                window.location.href = response.data;
            })
            .catch(function (error: any) {
                console.log(error);
            })
    }, [])

    return (
        <></>
    );
}