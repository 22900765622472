import React, { useEffect, useState } from 'react'
import { Button, Modal, Row } from 'react-bootstrap'
import { DelegateeSignerConstants, MyAccountConstants, ValidationContants, spouseEmailPopUpConstants } from '../Common/Constants';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'src/store';
import { SelectComponent } from '../Common/SelectComponent';
import { CountryCodeOptions, getDecodedClientId } from '../Helper/HelperFunction';
import PhoneNumberComponent from '../Common/PhoneNumberComponent';
import { ToMutual } from '../../core/domain/models/ITaxReturn';
import { MarsNotifier } from '../Common/Notification/MarsNotifier';
import { isValidEmailAddress, validateMobileLength } from '../Common/Validations';
import { actionCreators } from '../../store/Common/EsignStore';
import { actionCreators as taxActionCreator } from '../../store/Common/TaxDocumentStore';
import TaxpayerHelper from '../Helper/TaxpayerHelper';
import { ClientType } from '../../core/common/Enums';
import { actionCreators as accountAction } from '../../store/Common/AccountStore'




interface ISpouseDetailPopUpProps {
    show: boolean
    onHide: () => void
    goToDelegateeModal: () => void
    param?: any
    secondSignerType: ClientType
    isPreviewMode:boolean
}

interface IFormDetail {
    email: string;
    countryCode: string;
    mobileNumber: string
}

const SpouseMailPopup: React.FC<ISpouseDetailPopUpProps> = ({ show, onHide, goToDelegateeModal, param, secondSignerType,isPreviewMode }) => {


    const taxReturn = useSelector((state: ApplicationState) => state.taxReturn)

    const esignData = useSelector((state: ApplicationState) => state.esignData)

    const taxDoument = ToMutual(taxReturn.taxDocument)

    const clientGuid = getDecodedClientId(param?.clientId);

    const dispatch = useDispatch()

    const [formDetail, setFormDetail] = useState<IFormDetail>({
        email: '',
        countryCode: '',
        mobileNumber: ''
    })

    const [spouseName, setSpouseName] = useState('')

    const isEqualFormField = () =>{
        const secondSigner = secondSignerType===ClientType.Spouse ? taxDoument.spouse : taxDoument.taxpayer;

        return ((secondSigner.email??'')===formDetail.email 
               && (secondSigner.countryCode??'')===formDetail.countryCode 
               && formDetail.mobileNumber===(secondSigner.mobileNumber??''));
    }

    
    useEffect(() => {
        if (secondSignerType === ClientType.Spouse) {
 
            setSpouseName(taxDoument.spouse.name)
            setFormDetail({
                email: taxDoument.spouse.email??'',
                countryCode: taxDoument.spouse.countryCode??'',
                mobileNumber: taxDoument.spouse.mobileNumber??''
            })
        }
        else if (secondSignerType === ClientType.Taxpayer) {
            setSpouseName(taxDoument.taxpayer.name)
            setFormDetail({
                email: taxDoument.taxpayer.email??'',
                countryCode: taxDoument.taxpayer.countryCode??'',
                mobileNumber: taxDoument.taxpayer.mobileNumber??''
            })
        }
    }, [show])

    const onTaxClientMobileUpdateSuccess = (clientGuid: string) => {
        MarsNotifier.Success(MyAccountConstants.StatusMessage.UpdateInformation, null);
        dispatch(taxActionCreator.requestTaxDocument(clientGuid));
    }

    const validateDetails = () => {
        let result = true;
        if (formDetail.email?.trim() == "") {
            MarsNotifier.Warning(DelegateeSignerConstants.WarningMessage.EmptyEmailAdrress, "");
            return result = false;
        }
        else if (!isValidEmailAddress(formDetail.email?.trim())) {
            MarsNotifier.Warning(DelegateeSignerConstants.WarningMessage.InvalidEmailAddress, "");
            return result = false;
        }
        else if (formDetail.mobileNumber?.length > 0
            || formDetail.countryCode?.trim() != "") {
            if (!validateMobileLength(formDetail.mobileNumber)) {
                MarsNotifier.Warning(DelegateeSignerConstants.WarningMessage.InvalidMobileNumber, "");
                return result = false;
            }

            else if (formDetail.countryCode?.trim() == "") {
                MarsNotifier.Warning(ValidationContants.CountryCodeWarning, "");
                return result = false;
            }
        }
        return result
    }    

    const saveSpouseDetails = () => {

        if(isPreviewMode || isEqualFormField()){
            goToDelegateeModal()
        }
        else if (validateDetails()) {
            dispatch(actionCreators.updateSpouseMail(param?.clientId, TaxpayerHelper.getSecondSignerId(taxDoument
                , esignData.signerDetails, clientGuid).toString(), formDetail.email,
                TaxpayerHelper.getSecondSignerType(taxDoument, esignData.signerDetails, clientGuid)));

            dispatch(accountAction.updateMobileNumber(param?.clientId, TaxpayerHelper.getSecondSignerId(taxDoument
                , esignData.signerDetails, clientGuid).toString(), formDetail.mobileNumber,
                formDetail.countryCode, taxDoument.taxpayer.ssn, TaxpayerHelper.getSecondSignerType(taxDoument, esignData.signerDetails, clientGuid),
                () => { onTaxClientMobileUpdateSuccess( param?.clientId) }));
            goToDelegateeModal()
        }  
    }


    const onChangeEmail = (event: any) => {
        setFormDetail({
            ...formDetail,
            email: event.target.value
        });
    }

    const onCountryCodeChange = (value: string) => {
        setFormDetail({
            ...formDetail,
            countryCode: value
        })
    }
    const onPhoneNumberChange = (value:string) => {
        setFormDetail({
            ...formDetail,
            mobileNumber: value
        })
    }
    
    return (
        <div>
            <Modal show={show} onHide={onHide}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <div>
                            <span style={{ color: '#0274bb' }}>Spouse's Signature Required</span>
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ padding: '20px' }}>
                    {spouseEmailPopUpConstants.PopUpMessage}
                    <Row style={{ marginTop: 15, marginBottom: 15 }}>
                        <div className="col-sm-12 col-md-12 col-xl-12">
                            <span>Spouse's Name :  </span>
                            <b>{spouseName}</b>
                        </div>
                    </Row>

                    <Row style={{ marginBottom: 15, paddingLeft: "15px" }}>
                        <span className="spouse-detail-update-pop-up-Fields">Email Address :<span className='asterisk-error'>*</span></span>
                        <div className="col-sm-9 col-md-9 col-xl-9">
                            <div className="delegatee-input-field">
                                <input
                                    className="chip-input font-weight-bold "
                                    type="text"
                                    value={formDetail.email}
                                    onChange={(e) => { onChangeEmail(e) }}
                                    data-lpignore="true"
                                />
                            </div>
                        </div>
                    </Row>

                    <Row style={{ marginBottom: 15, paddingLeft: "15px" }}>
                        <span className="spouse-detail-update-pop-up-Fields" >Mobile Number :</span>
                        <div className="col-sm-4 col-md-4 col-xl-4 phoneNumberSelect">
                            <SelectComponent
                                id={"spouse-email-pop-up-country-code"}
                                options={CountryCodeOptions()}
                                selectedValue={formDetail.countryCode}
                                onChange={onCountryCodeChange}
                            />
                        </div>
                    
                        <div className="col-sm-5 col-md-5 col-xl-5">
                            <div className="delegatee-textbox">
                                <PhoneNumberComponent
                                    phoneNumber={formDetail.mobileNumber}
                                    handleChangePhoneNumber={onPhoneNumberChange}
                                    className="height-38 width100 delegatee-number-field"
                                />
                            </div>
                        </div>
                    </Row>

                </Modal.Body>
                <Modal.Footer>
                    <Button
                        onClick={onHide}
                        className="btn btn-white btn-default rounded"
                        variant={"light"}>
                        Cancel
                    </Button>

                    <Button
                        onClick={saveSpouseDetails}
                        variant={"primary"}
                        className="btn btn-primary spouse-detail-update-button"
                        disabled={!formDetail.email.length && !isPreviewMode}
                        >
                        Continue
                    </Button>

                </Modal.Footer>
            </Modal >
        </div>
    )
}

export default SpouseMailPopup