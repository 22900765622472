import { connect } from 'react-redux';
import SSN from "./SSN";
import * as HeaderStore from "./../../../../store/Login/Common/Header/HeaderStore";
import * as  SSNStore from "./../../../../store/Login/TP/SSN/SSNStore";
import { ApplicationState } from '../../../../store';

export default connect(
    (state: ApplicationState) => ({
        headerStoreState: state.headerPageStore,
        ssnPageStore: state.ssnPageStore,
    }),
    {
        ...HeaderStore.actionCreators,
        ...SSNStore.actionCreators,
    },
)(SSN);