import { connect } from 'react-redux';
import * as  CoverPageStore from "./../../../../store/Login/TP/CoverPage/CoverPageStore";
import * as HeaderStore from "./../../../../store/Login/Common/Header/HeaderStore";
import * as OTPPageStore from "./../../../../store/Login/TP/OTP/OTPStore";
import { ApplicationState } from '../../../../store';
import CoverPage from '../CoverPage/CoverPage';

export default connect(
    (state: ApplicationState) => ({
        coverPageStore: state.coverPageStore,
        headerStoreState: state.headerPageStore,
        isPreview: true
    }),
    {
        ...CoverPageStore.actionCreators,
        ...HeaderStore.actionCreators,
        ...OTPPageStore.actionCreators,
    },
)(CoverPage);
