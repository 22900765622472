import * as React from 'react';
import { Modal, Button, Row } from 'react-bootstrap';
import { TinyMCEComponent } from '../Common/TinyMCE';
import { SelectComponent } from '../Common/SelectComponent';
import { CountryCodeOptions } from '../Helper/HelperFunction';
import { PhoneNumberComponent } from '../Common/PhoneNumberComponent';
import { DelegateeSignerDetails, IDelegateeInfo, IDelegateeSignerDetails, initialState } from '../../core/domain/models/DelegateeModels';
import { MarsNotifier } from '../Common/Notification/MarsNotifier';
import { ITaxReturn } from '../../core/domain/models/ITaxReturn';
import {
    validateMobileLength,
    isValidEmailAddress
} from '../Common/Validations';
const htmlencode = require('htmlencode');
import { History } from 'history';
import { DelegateeSignerConstants, PathConstants, ValidationContants } from '../Common/Constants';
import { SignProcessSteps } from '../Home/TaxpayerSignFlowManager';
import { DelegateeCancelPopup } from './DelegateeCancelPopup';
import { logger } from '../../routes';
import { LogEventConstants } from '../Helper/Constants';

interface AssignToDelegateeProps {
    onReAssign?: (email: string, name: string, reason: string) => void;
    onCancel: () => void;
    show: boolean;
    param?: any;
    history?: History;
    taxReturn?: ITaxReturn;
    saveDelegateeSignerDetails?: (clientId: string,
        delegatee: IDelegateeSignerDetails, callback: () => void) => void;
    updateDelegateeSignerDetails?: (clientId: string,
            delegatee: IDelegateeSignerDetails, taxyear: number, callback: () => void) => void;
    isDelegateeAssigned?: boolean;
    successMsg: string;
    goToStep?: (stepNumber: number) => void;
    cancelDelegation?: (clientId: string, remarks: string, taxYear: number, callback: () => void) => void;
    resetLastVisitedSteps?: (clientId: string, callback: () => void) => void;
    saveDelegateeState?: () => void;
    delegateeSigner?: IDelegateeInfo;
    isPreviewMode: boolean;
    voucherExists?: boolean;
    relatedTaxReturnDelivered?: boolean;
    goToCompletionWizard?: (isDocSettingsUpdated: boolean) => void;
}

interface AssignToDelegateeState {
    delegateeDetails: IDelegateeSignerDetails;
    showCancelDelegationModal: boolean;
    reasonForCancel: string;
    isStateUpdated: boolean;
}

const pageTitle = "Assign To delegatee Modal";
export class AssignToDelegatee extends React.Component<AssignToDelegateeProps, AssignToDelegateeState> {
    constructor(props: AssignToDelegateeProps, states: AssignToDelegateeState) {
        super(props, states);
        this.state = {
            delegateeDetails: initialState,
            showCancelDelegationModal: false,
            reasonForCancel: "",
            isStateUpdated: false,
        }
    }

    static getDerivedStateFromProps(nextProps: AssignToDelegateeProps, prevState: AssignToDelegateeState) {
        if (nextProps.delegateeSigner != null && nextProps.delegateeSigner != undefined && nextProps.isDelegateeAssigned
            && prevState.delegateeDetails.email == "" && !prevState.isStateUpdated) {
            return {
                delegateeDetails: DelegateeSignerDetails.create(nextProps.delegateeSigner.firstName,
                    nextProps.delegateeSigner.lastName,
                    nextProps.delegateeSigner.email,
                    nextProps.delegateeSigner.email,
                    nextProps.delegateeSigner.countryCode,
                    nextProps.delegateeSigner.mobileNumber,
                    nextProps.delegateeSigner.description
                )
            }
        }
    }

    onChangeEmail = (event: any) => {
        let delegateeSigner = { ...this.state.delegateeDetails };
        delegateeSigner.email = event.target.value;
        this.setState({ delegateeDetails: delegateeSigner });
    }

    onChangeConfirmEmail = (event: any) => {
        let delegateeSigner = { ...this.state.delegateeDetails };
        delegateeSigner.confirmEmail = event.target.value;
        this.setState({ delegateeDetails: delegateeSigner });
    }

    onChangeFirstName = (event: any) => {
        let delegateeSigner = { ...this.state.delegateeDetails };
        delegateeSigner.firstName = event.target.value;
        this.setState({ delegateeDetails: delegateeSigner });
    }

    onChangeLastName = (event: any) => {
        let delegateeSigner = { ...this.state.delegateeDetails };
        delegateeSigner.lastName = event.target.value;
        this.setState({ delegateeDetails: delegateeSigner });
    }

    onPhoneNumberChange = (value: string) => {
        let delegateeSigner = { ...this.state.delegateeDetails };
        delegateeSigner.mobileNumber = value;
        this.setState({ delegateeDetails: delegateeSigner });
    }

    onCountryCodeChange = (value: string) => {
        let delegateeSigner = { ...this.state.delegateeDetails };
        delegateeSigner.countryCode = value;
        this.setState({ delegateeDetails: delegateeSigner });
    }

    onChangeReason = (event: any) => {
        let delegateeSigner = { ...this.state.delegateeDetails };
        delegateeSigner.description = event.target.getContent();
        this.setState({ delegateeDetails: delegateeSigner });
    }

    validateDetails = () => {
        let result = true;
        if (this.state.delegateeDetails.email.trim() == "") {
            MarsNotifier.Warning(DelegateeSignerConstants.WarningMessage.EmptyEmailAdrress, "");
            return result = false;
        }
        else if (!isValidEmailAddress(this.state.delegateeDetails.email.trim())) {
            MarsNotifier.Warning(DelegateeSignerConstants.WarningMessage.InvalidEmailAddress, "");
            return result = false;
        }
        else if (!isValidEmailAddress(this.state.delegateeDetails.confirmEmail.trim())) {
            MarsNotifier.Warning(DelegateeSignerConstants.WarningMessage.InvalidConfirmEmail, "");
            return result = false;
        }
        else if (this.state.delegateeDetails.email.trim() != this.state.delegateeDetails.confirmEmail.trim()) {
            MarsNotifier.Warning(DelegateeSignerConstants.WarningMessage.EmailDontMatch, "");
            return result = false;
        }
        else if (this.state.delegateeDetails.firstName.trim() == '') {
            MarsNotifier.Warning(DelegateeSignerConstants.WarningMessage.EmptyFirstName, "");
            return result = false;
        }
        else if (this.state.delegateeDetails.description.trim() == '') {
            MarsNotifier.Warning(DelegateeSignerConstants.WarningMessage.EmptyDescription, "");
            return result = false;
        } else if (this.state.delegateeDetails.mobileNumber.length > 0
            || this.state.delegateeDetails.countryCode.trim() != "") {
            if (!validateMobileLength(this.state.delegateeDetails.mobileNumber)) {
                MarsNotifier.Warning(DelegateeSignerConstants.WarningMessage.InvalidMobileNumber, "");
                return result = false;
            }
            else if (this.state.delegateeDetails.countryCode.trim() == "") {
                MarsNotifier.Warning(ValidationContants.CountryCodeWarning, "");
                return result = false;
            }
        }
        return result;
    }

    onDelegate = ()=> {
        this.props.saveDelegateeState && this.props.saveDelegateeState();
        (this.props.voucherExists && !this.props.relatedTaxReturnDelivered) ?
            this.props.goToStep && this.props.goToStep(SignProcessSteps.Pay) : this.props.goToCompletionWizard && this.props.goToCompletionWizard(true);
        MarsNotifier.Success(this.props.successMsg, null);
        this.onCancel();
    }

    onSubmit = () => {
        if (!this.props.isPreviewMode) {
            var isValidate = this.validateDetails();
            if (isValidate) {
                this.props.saveDelegateeSignerDetails &&
                    this.props.saveDelegateeSignerDetails(this.props.param.clientId, this.state.delegateeDetails,this.onDelegate);
                    this.props.updateDelegateeSignerDetails && 
                    this.props.updateDelegateeSignerDetails(this.props.param.clientId, this.state.delegateeDetails,this.props.taxReturn.taxYear,this.onDelegate)
                logger.trackEvent(
                    logger.buildEvent(
                        `${LogEventConstants.Common.Delegatee.Submit}  ${pageTitle}`,
                        { count: 1, page: pageTitle, clientId: this.props.param?.clientId, delegateeDetails: this.state.delegateeDetails }
                    )
                );
            }
        }
    }

    onCancelDelegation = () => {
        this.setState({ showCancelDelegationModal: true });
    }

    onCloseCancelDelegationPopup = () => {
        this.setState({
            showCancelDelegationModal: false,
            reasonForCancel: "",
            isStateUpdated: false,
        });
    }

    onReasonForCancelDelagtionChange = (event: any) => {
        this.setState({ reasonForCancel: event.target.value });
    }

    onSubmitCancelDelegation = () => {
        this.props.cancelDelegation && this.props.cancelDelegation(this.props.param.clientId,
            this.state.reasonForCancel, this.props.taxReturn!.taxYear, () => {
                this.props.resetLastVisitedSteps && this.props.resetLastVisitedSteps(this.props.param.clientId, () => {
                    this.setState({ showCancelDelegationModal: false });
                    this.onCancel();
                    MarsNotifier.Success(DelegateeSignerConstants.SuccessMessage.SavedCancelDelegationSuccess, null);
                    this.props.history?.push(`${PathConstants.SignFlow}${this.props.param.clientId}`);
                });
            })
        logger.trackEvent(
            logger.buildEvent(
                `${LogEventConstants.Common.Delegatee.SubmitCancel}  ${pageTitle}`,
                { count: 1, page: pageTitle, clientId: this.props.param?.clientId }
            )
        );
    }

    onCancel = () => {
        this.props.onCancel();
        this.setState({
            delegateeDetails: initialState,
            isStateUpdated: false
        });
    }

    public render() {
        let messageBody: string = this.state.delegateeDetails.description;
        messageBody = htmlencode.htmlDecode(messageBody);

        return (
            <div>
                <Modal show={this.props.show} onHide={this.onCancel} id="delegatee">
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <div>
                                <span style={{ color: '#0274bb' }}>Send for Signature</span>
                            </div>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ padding: '20px' }}>
                        <div>
                            <div className="delegatee-modal-title">Please enter information for the person you would like to delegate the signing process to</div>
                            <Row style={{ marginBottom: 15 }}>
                                <div className="col-sm-6 col-md-6 col-xl-6">
                                    <span className="font600">First Name<span className='asterisk-error'>*</span></span>
                                    <div className="delegatee-input-field">
                                        <input
                                            className="chip-input"
                                            type="text"
                                            value={this.state.delegateeDetails.firstName}
                                            onChange={(e) => { this.onChangeFirstName(e) }}
                                            data-lpignore="true"
                                        />

                                    </div>
                                </div>

                                <div className="col-sm-6 col-md-6 col-xl-6">
                                    <span className="font600">Last Name<span className='asterisk-error'>*</span></span>
                                    <div className="delegatee-input-field">
                                        <input
                                            className="chip-input"
                                            type="text"
                                            value={this.state.delegateeDetails.lastName}
                                            onChange={(e) => { this.onChangeLastName(e) }}
                                            data-lpignore="true"
                                        />

                                    </div>
                                </div>
                            </Row>


                            <Row style={{ marginBottom: 15 }}>
                                <div className="col-sm-6 col-md-6 col-xl-6">
                                    <span className="font600">Email Address<span className='asterisk-error'>*</span></span>
                                    <div className="delegatee-input-field">
                                        <input
                                            className="chip-input"
                                            type="text"
                                            value={this.state.delegateeDetails.email}
                                            onChange={(e) => { this.onChangeEmail(e) }}
                                            data-lpignore="true"
                                        />

                                    </div>
                                </div>

                                <div className="col-sm-6 col-md-6 col-xl-6">
                                    <span className="font600">Confirm Email Address<span className='asterisk-error'>*</span></span>
                                    <div className="delegatee-input-field">
                                        <input
                                            className="chip-input"
                                            type="text"
                                            value={this.state.delegateeDetails.confirmEmail}
                                            onChange={(e) => { this.onChangeConfirmEmail(e) }}
                                            data-lpignore="true"
                                        />

                                    </div>
                                </div>
                            </Row>

                            <Row>
                                <div className="col-sm-8 col-md-8 col-xl-8">
                                    <span className="font600">SMS/Text enabled mobile number</span>
                                </div>
                            </Row>

                            <Row style={{ marginBottom: 15 }}>
                                <div className="col-sm-4 col-md-4 col-xl-4 phoneNumberSelect">
                                    <SelectComponent
                                        id={"delegatee-signer-country-code"}
                                        options={CountryCodeOptions()}
                                        selectedValue={this.state.delegateeDetails.countryCode}
                                        onChange={this.onCountryCodeChange}
                                    />
                                </div>

                                <div className="col-sm-6 col-md-6 col-xl-6">
                                    <div className="delegatee-textbox">
                                        <PhoneNumberComponent
                                            phoneNumber={this.state.delegateeDetails.mobileNumber}
                                            handleChangePhoneNumber={this.onPhoneNumberChange}
                                            className="height-38 width100 delegatee-number-field"
                                        />
                                    </div>
                                    <span style={{ marginLeft: 25 }}>(Used for authorization)</span>
                                </div>
                            </Row>

                            <Row>
                                <div className="col-sm-12 col-md-12 col-xl-12">
                                    <span className="font600">Message to Signer<span className='asterisk-error'>*</span></span>
                                    <TinyMCEComponent
                                        messageBody={messageBody}
                                        changeStateTinymceBody={this.onChangeReason}
                                        readOnly={0}
                                    />
                                    <br />
                                </div>
                            </Row>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        {this.props.isDelegateeAssigned &&
                            <Button
                                onClick={this.onCancelDelegation}
                                className="btn btn-primary"
                                style={{ backgroundColor: '#14d13f', border: '#14d13f', marginRight: 'auto' }}>
                                Cancel Delegation
                            </Button>
                        }

                        <Button
                            onClick={this.onCancel}
                            className="btn btn-white btn-default rounded"
                            variant={"light"}>
                            Cancel
                        </Button>

                        <Button
                            disabled={this.props.isPreviewMode}
                            onClick={this.onSubmit}
                            variant={"primary"}
                            className="btn btn-primary"
                            style={{ backgroundColor: '#14d13f', border: '#14d13f', minWidth: "100px" }}>
                            Send
                        </Button>

                    </Modal.Footer>
                </Modal >

                <DelegateeCancelPopup
                    onCancel={this.onCloseCancelDelegationPopup}
                    show={this.state.showCancelDelegationModal}
                    onSaveRemarks={this.onSubmitCancelDelegation}
                    reason={this.state.reasonForCancel}
                    onRemarksChange={this.onReasonForCancelDelagtionChange}
                    title={"Reason for cancellation"}
                    warningMessage={DelegateeSignerConstants.WarningMessage.EmptyCancelDelegationRemarks}
                />
            </div>
        )
    }
}

