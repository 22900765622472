import * as React from 'react';
import { Popover, OverlayTrigger } from 'react-bootstrap';
import { match } from 'react-router';
import { History } from 'history';
import { Link } from 'react-router-dom';
import {
    IHeaderInfoViewModel,
    initialHeaderInfoViewModel
} from '../../../core/domain/viewModels/IHeaderInfoViewModel';
import { TYPES } from '../../../startup/types';
import { container } from '../../../startup/inversify.config';
import { IUtilities } from '../../../core/utilities/Utilities';
import { Shimmer } from '../../Common/Shimmer/Shimmer';
import {
    Home,
    SvgIconAccountHeader,
    SvgIconCalendarHeader, SvgIconContactHeader
} from '../../Common/Icons/SvgIcons';
import { validatePhone } from '../../Common/Validations';
import { DelegateeAccountModal } from '../../Delegatee/Account/DelegateeAccountModal';
import { ChangeNumber } from '../../../components/Layout/ChangeNumber';
import { IDelegateeInfo } from '../../../core/domain/models/DelegateeModels';
import { MarsNotifier } from '../../Common/Notification/MarsNotifier';
import { DelegateeSignerConstants, PathConstants } from '../../Common/Constants';
import { logger } from '../../../routes';
import { LogEventConstants } from '../../Helper/Constants';
import { IClientProcessViewModel } from 'src/core/domain/viewModels/IClientProcessViewModel';
import { ICompany } from 'src/core/domain/models/company/Company';
const utilities = container.get<IUtilities>(TYPES.IUtilities);


export interface DelegateeHeaderProps {
    delegateeHeaderInfo: IHeaderInfoViewModel;
    delegateeSigner: IDelegateeInfo;
    updateDelegateeMobileNumber: (clientId: string, countryCode: string, mobileNumber: string, callback?: () => void) => void;
    match: match;
    history: History;
    companyData: ICompany;
    clientProcessData: IClientProcessViewModel;
    getClientHubDomainURL: (clientGuid: string, callback: (hubDomainURL: string) => void) => void;
    checkIfUserExistsInOneHub: (clientGuid: string, callback: (isUserExists: boolean) => void) => void;
    getOnehubRedirectURL: (clientGuid: string, callback: (onehubRedirectURL: string) => void) => void;
    logout: (clientGuid: string, callback: (id: string) => void) => void;
}

export interface DelegateeHeaderState {
    headerInfo: IHeaderInfoViewModel,
    showMyAccountPopUp: boolean,
    showChangeNumberPopUp: boolean,
    isUserExistsInOneHub: boolean,
    isAccessedFromOnehub: boolean
}

const pageTitle = "DelegateeHeader";

export class DelegateeHeader extends React.Component<DelegateeHeaderProps, DelegateeHeaderState> {
    constructor(props: DelegateeHeaderProps, states: DelegateeHeaderState) {
        super(props, states);
        this.state = {
            headerInfo: initialHeaderInfoViewModel,
            showMyAccountPopUp: false,
            showChangeNumberPopUp: false,
            isUserExistsInOneHub: false,
            isAccessedFromOnehub: false
        }
    }

    static getDerivedStateFromProps(nextProps: DelegateeHeaderProps, prevState: DelegateeHeaderState) {
        if (nextProps.delegateeHeaderInfo && (nextProps.delegateeHeaderInfo.brandingSettings.coverPageSetting.bgColorCode !== prevState.headerInfo.brandingSettings.coverPageSetting.bgColorCode
            || nextProps.delegateeHeaderInfo.brandingSettings.coverPageSetting.foreColorCode !== prevState.headerInfo.brandingSettings.coverPageSetting.foreColorCode)) {
            utilities.applyBrandingSettings(nextProps.delegateeHeaderInfo.brandingSettings);
            return {
                headerInfo: nextProps.delegateeHeaderInfo,
            }
        }
        else {
            return null;
        }
    }

    componentDidMount() {
        let params: any = this.props.match.params;       
        this.props.checkIfUserExistsInOneHub(params.clientId, (isUserExists: boolean) => {
            this.setState({isUserExistsInOneHub: isUserExists});
        });
    }
    componentDidUpdate(prevProps: Readonly<DelegateeHeaderProps>, prevState: Readonly<DelegateeHeaderState>, snapshot?: any): void {
        if(prevState.isAccessedFromOnehub !== this.props.clientProcessData.isAccessedFromOneHub){
            this.setState({isAccessedFromOnehub: this.props.clientProcessData.isAccessedFromOneHub});
        }
    }

    redirectToOneHubHomePage = () => {
        let params: any = this.props.match.params;
        this.props.getClientHubDomainURL(params.clientId, (oneHubDomainURL: string) => {
            window.location.href = oneHubDomainURL;
        })
    }

    redirectToOneHubAuth = () => {       
        let params: any = this.props.match.params;
        this.props.getOnehubRedirectURL(params.clientId, (oneHubRedirectURL: string) => {
            window.location.href = oneHubRedirectURL;
        });        
    }

    getMyaccount = () => {
        this.setState({
            showMyAccountPopUp: true,
        })
    }

    onCancelMyAccountPopUp = () => {
        this.setState({
            showMyAccountPopUp: false,
        })
    }

    onChangeMobileNumberClick = () => {
        this.setState({
            showChangeNumberPopUp: true,
        })
        logger.trackEvent(
            logger.buildEvent(
                `${LogEventConstants.Common.Open} Change Number layout`,
                { count: 1, page: "Change Number layout" }
            )
        );
    }

    onHideChangeNumberPopUp = () => {
        this.setState({
            showChangeNumberPopUp: false,
        })
    }

    onSaveDelegateeNumberClick = (changedMobileNumber: string, changedCountryCode: string) => {
        if (validatePhone(changedMobileNumber)) {
            const param: any = this.props.match.params;
            this.props.updateDelegateeMobileNumber(param.clientId, changedCountryCode, changedMobileNumber, () => {
                MarsNotifier.Success(DelegateeSignerConstants.SuccessMessage.UpdateMobileNumberSuccess, null);
            });
            this.setState({ showChangeNumberPopUp: false });
            logger.trackEvent(
                logger.buildEvent(
                    `${LogEventConstants.Common.Layout.NumberChanged}  ${pageTitle}`,
                    { count: 1, page: pageTitle, countryCode: changedCountryCode, mobileNumber: changedMobileNumber, clientId: param.clientId }
                )
            );
        }
    }

    Logout = () => {
        let param: any = this.props.match.params;
        logger.trackEvent(
            logger.buildEvent(
                `${LogEventConstants.Common.UserLogout}  ${pageTitle}`,
                { count: 1, page: pageTitle, clienId: param.clientId }
            )
        );
        this.props.logout(param.clientId, this.onLogOutCompletion);
    }

    onLogOutCompletion = (id: string) => {
        const url = PathConstants.DelegateeLogin + id;
        this.props.history.push(url);
    }


    public render() {
        let param: any = this.props.match.params;
        let contactPersonFullName: string = "";
        const contactPerson = this.state.headerInfo.contactPerson;
        let companyLogo = null;
        if (this.state.headerInfo.companyWhiteLogoPath == "") {
            companyLogo = <span className="company-name" data-test-auto="16dd83b1-adf9-4211-bd92-b8976f9f30c9">{this.state.headerInfo.companyName}</span>;
        }
        else {
            companyLogo = <img className="logo-img" src={this.state.headerInfo.companyWhiteLogoPath} data-test-auto="16dd83b1-adf9-4211-bd92-b8976f9f30c9"/>;
        }
        contactPersonFullName += contactPerson.firstName;

        if (contactPerson.middleName && contactPerson.middleName.length > 0) {
            contactPersonFullName += " " + contactPerson.middleName;
        }

        if (contactPerson.lastName && contactPerson.lastName.length > 0) {
            contactPersonFullName += " " + contactPerson.lastName;
        }

        const accountPopover = (
            <Popover id="accountPopover" placement="bottom-start">
                <div className="popover-body">
                    <ul className='account-menu'>
                        <li><a onClick={() => this.getMyaccount()}><i className="fa fa-user ddl-icon"></i><span>My Account</span></a></li>
                        {
                            this.props.companyData?.uteClientHubSettings?.uteEnabled && 
                            this.state.isUserExistsInOneHub &&
                            !this.state.isAccessedFromOnehub &&                           
                            <li>
                                <a                                    
                                    onClick={() => {
                                        this.redirectToOneHubAuth();
                                    }}                                
                                    data-test-auto="D24BF8D0-A2D3-4BB9-8F91-59AF2E7FD03F"
                                    tabIndex={6}
                                >
                                    Access Client Portal
                                </a>
                            </li>                                
                        }
                        <li><a onClick={() => this.Logout()}><i className="fa fa-sign-out" style={{ marginRight: '14px' }}></i><span>Logout</span></a></li>
                    </ul>
                </div>
            </Popover>
        );


        const contactInfoPopover = (
            <Popover id="contactInfoPopover" placement="bottom">
                <h3 className="popover-header">Contact Information</h3>
                <div className="popover-body">
                    <div className='medium'>{contactPersonFullName} </div>
                    <div>
                        <strong>{utilities.formateFax(contactPerson.phoneNumber)}</strong>
                        {contactPerson && contactPerson.extension && contactPerson.extension.length > 0 && <strong>Ext: {contactPerson.extension}  </strong>}
                    </div>
                    <div className='mail'>
                        <a href={"mailto:" + contactPerson.email}>{contactPerson.email}</a>
                    </div>
                </div>
            </Popover>
        );

        return (
            this.props.delegateeHeaderInfo.brandingSettings &&
                this.props.delegateeHeaderInfo.brandingSettings.coverPageSetting.bgColorCode != "" ?
                <header className="app-header">
                    <div className="header-left-container">

                        <input type="checkbox" className="openSidebarMenu" id="openSidebarMenu" />
                        <label htmlFor="openSidebarMenu" className="sidebarIconToggle">
                            <div className="spinner diagonal part-1"> </div>
                            <div className="spinner horizontal"> </div>
                            <div className="spinner diagonal part-2"> </div>
                        </label>
                        <div id="sidebarMenu">
                            <ul className="sidebar navbar-nav">
                                <li className="nav-item layout-dropdown mobile-header-taxYear show">
                                    <span className="nav-link layout-dropdown-toggle" role="button" data-toggle="layout-dropdown" aria-haspopup="true" aria-expanded="false">
                                        <i className="fa fa-calendar margin-right-10"></i>
                                        <span>Tax Year</span></span>
                                    <div className="layout-dropdown-menu show" aria-labelledby="pageslayout-dropdown">
                                        <a className="layout-dropdown-item active" >{this.state.headerInfo.taxYear}</a>
                                    </div>
                                </li>
                                <li className="nav-item layout-dropdown show">
                                    <span className="nav-link layout-dropdown-toggle" role="button" data-toggle="layout-dropdown" aria-haspopup="true" aria-expanded="false">
                                        <i className="fa fa-cog margin-right-10"></i>
                                        <span >Account</span></span>
                                    <div className="layout-dropdown-menu show" aria-labelledby="pageslayout-dropdown">
                                        <a className="layout-dropdown-item" onClick={() => this.getMyaccount()}><i className="fa fa-user ddl-icon headerForeColor"></i>My Account</a>
                                        <a className="layout-dropdown-item" onClick={() => this.Logout()}><i className="fa fa-sign-out ddl-icon headerForeColor"></i>Logout</a>
                                    </div>
                                </li>
                            </ul>
                        </div>

                        {companyLogo}

                    </div>
                    <div className="header-right-container">

                        <span className="header-account">
                            <OverlayTrigger
                                rootClose trigger="click"
                                onEnter={() => document.body.click()}
                                overlay={accountPopover}
                                placement="bottom">
                                <a>
                                    <SvgIconAccountHeader
                                        fillColor="var(--headerForeColor)"
                                        height={20} />
                                    {`${this.props.delegateeSigner.firstName} ${this.props.delegateeSigner.lastName}`}
                                </a>
                            </OverlayTrigger>
                        </span>

                        <span className="header-contact-info" title="Contact Person's Information">
                            <OverlayTrigger
                                rootClose trigger="click"
                                onEnter={() => document.body.click()}
                                overlay={contactInfoPopover}
                                placement="bottom">
                                <a>
                                    <SvgIconContactHeader
                                        fillColor="var(--headerForeColor)"
                                        height={20} />
                                    Contact Person
                             </a>
                            </OverlayTrigger>
                        </span>
                        {   this.props.clientProcessData.isAccessedFromOneHub && 
                        <span className="header-home-info" title="Home">
                                <a
                                    tabIndex={1}
                                    onClick={() => this.redirectToOneHubHomePage()}
                                >
                                    <Home fillColor="var(--headerForeColor)" />
                                    <span>Home</span>
                                </a>                          
                        </span>
                        }
                        <span className="header-taxYear">
                            <a>
                                <SvgIconCalendarHeader
                                    fillColor="var(--headerForeColor)"
                                    height={20} />
                            Tax year: {this.state.headerInfo.taxYear}
                            </a>
                        </span>

                        <DelegateeAccountModal
                            delegateeSigner={this.props.delegateeSigner}
                            showState={this.state.showMyAccountPopUp}
                            onHide={this.onCancelMyAccountPopUp}
                            onChangeNumberClick={this.onChangeMobileNumberClick}
                        />

                        <ChangeNumber
                            onHideChangeNumberPopUp={this.onHideChangeNumberPopUp}
                            showState={this.state.showChangeNumberPopUp}
                            mobileNumber={this.props.delegateeSigner.mobileNumber}
                            countryCode={this.props.delegateeSigner.countryCode}
                            onSaveNumberClick={this.onSaveDelegateeNumberClick}
                        />

                    </div>
                </header> : <Shimmer height={55} />)
    }
}