import { ApplicationInsights, ITelemetryItem, DistributedTracingModes, SeverityLevel, IEventTelemetry, Util } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from 'history';
import { initializeAxios } from '../../core/services/dataAccess/DataService.Axios';
import { AxiosResponse } from 'axios';
import { ILogger } from './Logger';
import { AppInsightsConstants } from '../Common/Constants';

const browserHistory = createBrowserHistory({ basename: '' });
const reactPlugin = new ReactPlugin();
let profileData: any;
let inProgress: boolean;
let appInsightClientId: any;

export class TelemetryLogger implements ILogger {

    private static instance: TelemetryLogger;
    private static appInsights: ApplicationInsights;
    private static pageViewRefTime: any;

    private constructor() {
        TelemetryLogger.appInsights = appInsights;
    }

    public static getInstance(): TelemetryLogger {
        if (!TelemetryLogger.instance) {
            this.instance = new TelemetryLogger();
        }
        return TelemetryLogger.instance;
    }

    public trackPageView = (pageName: string) => {
        TelemetryLogger.appInsights.context.telemetryTrace.name = pageName;
        TelemetryLogger.appInsights.context.telemetryTrace.traceID = Util.generateW3CId();
        TelemetryLogger.appInsights.trackPageView({ name: pageName, properties: { duration: new Date().getTime() - TelemetryLogger.pageViewRefTime } });
        TelemetryLogger.pageViewRefTime = new Date().getTime();
    }

    public trackTrace = (message: string) => {
        TelemetryLogger.appInsights.trackTrace(
            {
                message: message,
                severityLevel: SeverityLevel.Information
            }
        );
    }
    public trackError = (message: string) => {
        TelemetryLogger.appInsights.trackException(
            {
                exception: new Error(message)
            }
        );
    }
    public trackEvent = (traceEvent: IEventTelemetry) => {
        TelemetryLogger.appInsights.trackEvent(traceEvent);
    }

    public buildEvent = (eventName: string, properties: any): IEventTelemetry => {
        const traceEvent: IEventTelemetry = {
            name: eventName,
            properties: properties
        };
        return traceEvent;
    }
}

const appInsights = new ApplicationInsights({
    config: {
        instrumentationKey: '',
        disableInstrumentationKeyValidation: true,
        extensions: [reactPlugin],
        disableFetchTracking: false,
        disableAjaxTracking: false,
        enableCorsCorrelation: true,
        distributedTracingMode: DistributedTracingModes.W3C,
        enableRequestHeaderTracking: true,
        enableResponseHeaderTracking: true,
        disableCorrelationHeaders: false,
        enableAutoRouteTracking: true,
        extensionConfig: {
            [reactPlugin.identifier]: { history: browserHistory }
        }
    }
});
appInsights.loadAppInsights();

appInsights.addTelemetryInitializer((envelope: ITelemetryItem) => {

    envelope.tags = envelope.tags || [];
    envelope.tags.push({ "ai.cloud.role": AppInsightsConstants.Role });

    if (profileData !== undefined) {
        envelope.tags.push({ "ai.user.accountId": profileData.companyId });
        envelope.tags.push({ "ai.user.id": profileData.userId });
    }

    var telemetryItem = envelope.baseData;
    if (telemetryItem !== undefined && profileData !== undefined) {
        telemetryItem.properties = telemetryItem.properties || {};
        telemetryItem.properties["UserId"] = profileData.userId;
        telemetryItem.properties["CompanyId"] = profileData.companyId;
        telemetryItem.properties["CompanyName"] = profileData.companyName;
        telemetryItem.properties["Product"] = AppInsightsConstants.Product;
        telemetryItem.properties["DocumentGuid"] = profileData.documentGuid;
        telemetryItem.properties["DocumentId"] = profileData.documentId;
    }
});

const initializeAppInsights = (clientId: string) => {
    if (clientId == undefined) return;
    if (!inProgress && (profileData === undefined || appInsightClientId != clientId)) {
        inProgress = true;
        initializeAxios().get<any>('/api/ApplicationInsight/BasicDetails/' + clientId)
            .then(function (response: AxiosResponse<any>) {
                profileData = response.data;
                appInsights.config.instrumentationKey = profileData.instrumentationKey;
                inProgress = false;
                if (response.data.userId) {
                    appInsightClientId = response.data.userId;
                } else {
                    appInsightClientId = clientId;
                }
            })
            .catch(function (error: any) {
                inProgress = false;
            });
    }
};

export { initializeAppInsights, reactPlugin, appInsights };