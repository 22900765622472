import { DocumentGroups } from "src/core/domain/models/IGroup";

export interface IDocumentReviewModel {
    documentId: number;
    documentGuid: string;
    fileLink: string;
    documentGroup: DocumentGroups;
    fileName: string;
    attachmentInstruction: string;
    taxYear: number;
}

export const initialDocumentReviewModel: IDocumentReviewModel[] = [];