import { IVoucher, IRefund, VoucherTypes, VoucherNo } from "../models/IGroup";
import { ITaxingAuthority } from "src/core/domain/models/ITaxingAuthority";
import { IRefundInfo } from "src/core/domain/models/IRefundInfo";

const NO_INDEX: number = -1;

export interface ISummaryViewModel {
	documentId: number,
	taxYear: number,
	clientName: string,
	hasShareholders: boolean,
	vouchers: IVoucher[],
	refunds: IRefund[],
	addedRefunds: IRefundInfo[],
}

export const initialSummaryViewModel: ISummaryViewModel = {
	documentId: 0,
	taxYear: 0,
	clientName: "",
	hasShareholders: false,
	vouchers: [],
	refunds: [],
	addedRefunds: [],
}

export interface SummaryPageGroupedItem {
	GroupName: string,
	Items: SummaryPageItem[]
	Total: number
}

export interface SummaryPageItem {
	name: string,
	value: number,
}

export function getPaymentDue(vouchers: IVoucher[], taxingAuthorities: ITaxingAuthority[]): SummaryPageGroupedItem {

	let groupedItem: SummaryPageGroupedItem = {
		GroupName: "Payments Due",
		Items: [],
		Total: 0
	};
	const paymentVouchers = vouchers.filter(x => x.paymentType === VoucherTypes.PaymentVoucher);
	let total: number = 0;
	let items: SummaryPageItem[] = [];

	if (paymentVouchers && paymentVouchers.length > 0) {
		paymentVouchers.forEach((value, index) => {
			if (value.amount > 0) {
				let authority = taxingAuthorities.find(x => x.Id === value.authorityID);
				items.push({
					name: authority ? authority.AuthorityName : "",
					value: value.amount
				});
				total += value.amount;
			}
		});
	}

	groupedItem.Items = SummaryPageGroupedItemSort(items);
	groupedItem.Total = total;

	return groupedItem;
}

export function getRefunds(refunds: IRefund[], addedRefunds: IRefundInfo[], taxingAuthorities: ITaxingAuthority[]): SummaryPageGroupedItem {

	let groupedItem: SummaryPageGroupedItem = {
		GroupName: "Refunds",
		Items: [],
		Total: 0
	};

	let total: number = 0;
	let items: SummaryPageItem[] = [];

	if (refunds && refunds.length > 0) {
		refunds.forEach((value, index) => {
			if (value.overPayment > 0) {
				let authority = taxingAuthorities.find(x => x.Id === value.authorityID);
				items.push({
					name: authority ? authority.AuthorityName : "",
					value: value.overPayment - value.overPaymentApplied
				});
				total += value.overPayment - value.overPaymentApplied;
			}
		});
	}

	if (addedRefunds && addedRefunds.length > 0) {
		addedRefunds.forEach((value, index) => {

			if (value.overPayment > 0) {
				let authority = taxingAuthorities.find(x => x.Id === value.authorityID);
				items.push({
					name: authority ? authority.AuthorityName : "",
					value: value.overPayment - value.overPaymentApplied
				});
				total += value.overPayment - value.overPaymentApplied;
			}

		});
	}

	groupedItem.Items = SummaryPageGroupedItemSort(items);
	groupedItem.Total = total;

	return groupedItem;
}

export function getOverPaymentApplied(refunds: IRefund[], addedRefunds: IRefundInfo[], taxingAuthorities: ITaxingAuthority[]): SummaryPageGroupedItem {

	let groupedItem: SummaryPageGroupedItem = {
		GroupName: "Overpayments Applied",
		Items: [],
		Total: 0
	};

	let total: number = 0;
	let items: SummaryPageItem[] = [];

	if (refunds && refunds.length > 0) {
		refunds.forEach((value, index) => {
			if (value.overPaymentApplied > 0) {
				let authority = taxingAuthorities.find(x => x.Id === value.authorityID);
				items.push({
					name: authority ? authority.AuthorityName : "",
					value: value.overPaymentApplied
				});
				total += value.overPaymentApplied;
			}
		});
	}

	if (addedRefunds && addedRefunds.length > 0) {
		addedRefunds.forEach((value, index) => {
			if (value.overPaymentApplied > 0) {
				let authority = taxingAuthorities.find(x => x.Id === value.authorityID);
				items.push({
					name: authority ? authority.AuthorityName : "",
					value: value.overPaymentApplied
				});
				total += value.overPaymentApplied;
			}
		});
	}

	groupedItem.Items = SummaryPageGroupedItemSort(items);
	groupedItem.Total = total;

	return groupedItem;
}

export function getEstimatedVouchers(vouchers: IVoucher[], taxingAuthorities: ITaxingAuthority[]): SummaryPageGroupedItem[] {

	let groupedItemList: SummaryPageGroupedItem[] = [];

	const voucherNoList = vouchers.filter(x => x.paymentType === VoucherTypes.EstimatedVoucher).map(x => x.voucherNo);
	const distVoucherNoList = voucherNoList.filter(distinct);

	if (distVoucherNoList) {

		distVoucherNoList.forEach((voucherNo, index) => {

			const estimatedVouchers = vouchers.filter(x => x.voucherNo === voucherNo);

			let groupedItem: SummaryPageGroupedItem = {
				GroupName: VoucherNo[voucherNo].toString(),
				Items: [],
				Total: 0
			};

			let total: number = 0;
			let items: SummaryPageItem[] = [];

			if (estimatedVouchers && estimatedVouchers.length > 0) {
				estimatedVouchers.forEach((value, index) => {

					if (value.amount > 0) {

						let authority = taxingAuthorities.find(x => x.Id === value.authorityID);
						items.push({
							name: authority ? authority.AuthorityName : "",
							value: value.amount
						});
						total += value.amount;
					}
				});
			}

			groupedItem.Items = SummaryPageGroupedItemSort(items);
			groupedItem.Total = total;

			groupedItemList.push(groupedItem);
		});
	}

	return SummaryPageGroupSort(groupedItemList);
}

export function SummaryPageGroupSort(items: SummaryPageGroupedItem[]): SummaryPageGroupedItem[] {

	items = items.sort(function (a, b) {
		var nameA = a.GroupName.toLowerCase(), nameB = b.GroupName.toLowerCase()
		if (nameA < nameB) //sort string ascending
			return -1
		if (nameA > nameB)
			return 1
		return 0
	});

	return items;
}



export function SummaryPageGroupedItemSort(items: SummaryPageItem[]): SummaryPageItem[] {

	items = items.sort(function (a, b) {
		var nameA = a.name.toLowerCase(), nameB = b.name.toLowerCase()
		if (nameA < nameB) //sort string ascending
			return -1
		if (nameA > nameB)
			return 1
		return 0
	});

	const indexes: number[] = [];
	let i = -1;
	const names: string[] = items.map(function (item) { return item.name });
	while ((i = names.indexOf("Federal", i + 1)) != NO_INDEX) {
		indexes.push(i);
	}

	indexes.map(function (i) {
		let federalItem = items.splice(i, 1);
		items.unshift(federalItem[0]);
	});

	return items;
}



function distinct(value: any, index: number, self: any) {
	return self.indexOf(value) === index;
}