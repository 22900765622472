import * as React from 'react';
import Skeleton from "react-loading-skeleton";
import { Toaster } from './../../../Common/Notification/MarsNotifier';
import Notification from "./../../../Common/Notification/NotificationContainer";
import { History } from 'history';
import { RouteComponentProps } from 'react-router';
import { MatchProps } from './../OTP/EmailOTP';

interface RouteSelectorProps extends RouteComponentProps<MatchProps> {
    history: History;
    pageValidity: (clientId: string, selectPath: (path: string) => void, handleError: (message: string) => void) => void;
    mobileOtpPath: string;
    emailOtpPath: string;
}

const RouteSelector: React.FC<RouteSelectorProps> =
    ({ history, pageValidity, match, mobileOtpPath, emailOtpPath }) => {

        React.useEffect(() => {
            pageValidity(match.params.clientId, selectPath, handleError);
        }, []);

        const handleError = (message: string) => {
            history.push(`/Error/${message}`);
        };

        const selectPath = (path: string) => {
            const clientId: string = match.params.clientId;
            if (path === "TextOTP") {
                history.push(`${mobileOtpPath}${clientId}`);
            } else {
                history.push(`${emailOtpPath}${clientId}`);
            }
        };

        return (<>
            <Notification />
            <Toaster />
            <Skeleton circle={false} height={"100vh"} width={"100vw"} />

        </>
        );

    };
export default RouteSelector;
