import * as React from 'react';
import { TaxpayerAuthenticationType } from './../../../../core/common/Enums';
import Skeleton from "react-loading-skeleton";
import Header from "./../../Common/Header/Header";
import { MatchProps } from '../../Common/OTP/EmailOTP';
import { RouteComponentProps } from 'react-router';
import { HeaderStoreState, SSNPageStoreState } from '../../../../store/Login/Models/StoreModels';

interface SSNProps extends RouteComponentProps<MatchProps> {
    ssnPageStore: SSNPageStoreState;
    requestHeaderDetails: (clientId: string) => void;
    requestSSNData: (clientId: string, errorCallBack: (message: string) => void) => void;
    headerStoreState: HeaderStoreState;
    verifySSN: (SSN: string, clientId: string, navigatePath: (target: string) => void, errorCallBack?: (message: string) => void) => void;
}

interface SSNState {
    ssn: string;
    continueState: boolean;
}

export class SSN extends React.Component<SSNProps, SSNState> {

    continueRef: any = null;

    constructor(props: any) {
        super(props);
        this.state = {
            ssn: "",
            continueState: false
        };
    }

    componentDidMount() {
        this.props.requestHeaderDetails(this.props.match.params.clientId);
        this.props.requestSSNData(this.props.match.params.clientId, this.errorCallBack);
    }

    errorCallBack = (message: string) => {
        this.props.history.push("/Error/" + message);
    }

    navigateToOTP = (target: string) => {
        let clientId: string = this.props.match.params.clientId;
        if (target === "OTP") {
            this.props.history.push(`/OTP/Index/${clientId}`);
        } else {
            this.props.history.push(`/MobileOTP/Index/${clientId}`);
        }

    }

    handleSSNChange = (e: any) => {
        if (e.target.value.length <= 4 && (!isNaN(e.target.value) || e.target.value.length === 0)) {
            if (e.target.value.length === 4) {
                this.setState({ ssn: e.target.value, continueState: true });
            } else {
                this.setState({ ssn: e.target.value, continueState: false });
            }

        }
    }

    verifySSN = () => {
        let clientId: string = this.props.match.params.clientId;

        if (this.state.continueState === true) {
            this.props.verifySSN(this.state.ssn, clientId, this.navigateToOTP, this.errorCallBack);
        }
    }

    render() {
        let message: string = "";
        let hint: string = "";

        const { ssnPageStore: { taxpayerAuthentication, loading, clientName }, headerStoreState } = this.props;

        if (taxpayerAuthentication !== TaxpayerAuthenticationType.None) {
            if (taxpayerAuthentication === TaxpayerAuthenticationType.First4ofSSN) {
                message = "Please enter the first four digits of your Social Security Number:";
                hint = "(We use the first four digits to verify your identity)";
            } else {
                message = "Please enter the last four digits of your Social Security Number:";
                hint = "(We use the last four digits to verify your identity)";
            }
        }

        let verifyBtnClass = "btn btn-primary continue disabled float-right";
        if (this.state.continueState) {
            verifyBtnClass = "btn btn-primary continue float-right";
            this.continueRef.focus();
        }

        return (<>
            <Header headerInfo={headerStoreState} pathname={this.props.match.path} clientId={this.props.match.params.clientId} history={this.props.history} backButton={true} navigateLink="/CoverPage/Index" />
            {
                loading || headerStoreState.loading ?
                    <Skeleton circle={false} height={"100vh"} width={"100vw"} /> :
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 ssn-page">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 ssn-page-container" style={{ marginTop: "8vh", height: "88%" }}>

                            <h5 data-test-auto="7DF189E3-2E6F-47C8-B843-BEF8CE21D6A5">Authentication</h5>
                            <br />
                            <strong data-test-auto="QRE5GQ404HLMZFQLVZ1R4JVTQR">{clientName}</strong>
                            <div>
                                <div className="ssn-page-container-label-div">
                                    <span data-test-auto="8546U06DYUNML97VJTE8PD8240"> {message} </span>
                                    <br />
                                    <span data-test-auto="T7U3LD6H19N4921E2ZWL5JDE3M" className="font-style-italic"> {hint} </span>
                                </div>

                                <div className="ssn-page-container-input-div">
                                    <input id="SSNNumber" type="text" value={this.state.ssn} onChange={this.handleSSNChange} style={{ marginLeft: "15px" }} autoComplete="off" />
                                </div>
                                <div className="margin-top-50">
                                    <button ref={(ref) => { this.continueRef = ref }} data-test-auto="U3FJ9K54A32MN9LEDNDGRRULZH" type="submit" onClick={this.verifySSN} className={verifyBtnClass} id="btnAuthenticate">Continue</button>
                                </div>

                            </div>

                        </div>
                    </div>

            }
        </>);
    }
}

export default SSN;