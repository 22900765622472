import * as React from 'react';
import { Modal, Alert, Button } from 'react-bootstrap';
import { SiginingConstants } from '../Common/Constants';
import { isValidEmailAddress } from '../Common/Validations';
import { MarsNotifier } from '../Common/Notification/MarsNotifier';
import { logger } from '../../routes';
import { LogEventConstants } from '../Helper/Constants';

export interface EditSpouseEmailProps {
    showAlert: boolean;
    showState: boolean;
    onSaveButtonClick: (email: string) => void;
    onHideEditSpouseEmail: () => void;
    spouseEmail: string;
}

interface EditSpouseEmailState {
    emailAddress: string;
}

const pageTitle = "Edit Spouse Mail page";

export class EditSpouseMail extends React.Component<EditSpouseEmailProps, EditSpouseEmailState>
{
    constructor(props: any) {
        super(props);
        this.state = {
            emailAddress: props.spouseEmail
        }
    }

    private onSpouseMailChange = (e: any) => {
        this.setState({ emailAddress: e.target.value });
        logger.trackEvent(
            logger.buildEvent(
                `${LogEventConstants.Common.Esign.SpouseMailUpdated} ${pageTitle}`,
                { count: 1, page: pageTitle, email: this.state.emailAddress }
            )
        );
    }
    componentDidMount() {
        this.setState({
            emailAddress: this.props.spouseEmail
        });
        logger.trackEvent(
            logger.buildEvent(
                `${LogEventConstants.Common.Open} ${pageTitle}`,
                { count: 1, page: pageTitle }
            )
        );
    }

    onHide = () => {
        this.setState({ emailAddress: this.props.spouseEmail }, () => this.props.onHideEditSpouseEmail());
    }

    public render() {
        return (
            <div>
                <div style={{ position: 'relative' }}>
                    <Modal show={this.props.showState} onHide={this.onHide}>
                        <Modal.Header closeButton data-test-auto="6E0248EE-A2AB-4F54-90F3-6AE4953B1BAC">
                            <Modal.Title>
                                <span
                                    className='fas fa-envelope-open-text'
                                    style={{ color: 'grey', marginRight: '5px' }}>
                                </span>{SiginingConstants.StatusMessage.EditSpouseEmail}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="row marL10 marB10">
                                <div className="col-lg-12" style={{ display: 'inline' }} data-test-auto="D6E17265-FCBC-4D8A-91AB-56FD94F48770">
                                    <label>Enter spouse Email Address:</label>
                                    <div>
                                        <input
                                            className="form-control input-sm"
                                            type="text"
                                            value={this.state.emailAddress}
                                            onChange={(e) => this.onSpouseMailChange(e)}
                                            data-test-auto="BACE91D0-C00E-458D-9992-3CEB62ABB5E7"
                                        />
                                    </div>
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button
                                className="btn btn-white btn-default rounded"
                                onClick={this.onHide}
                                data-test-auto="C20DF8CC-F4AA-4E8D-B710-75DB7EEC6146"
                                variant={"light"}>
                                <i className="fa fa-times"></i> Cancel
                    </Button>
                            <Button
                                className="btn btn-primary rounded"
                                onClick={() => { this.props.onSaveButtonClick(this.state.emailAddress) }}
                                data-test-auto="3BAB1674-CFFD-466E-B34E-18BF07B654C0"
                                variant={"primary"}>
                                <i className="fa fa-save"></i> Save
                    </Button>
                        </Modal.Footer>
                    </Modal>
                </div>
            </div>
        );
    }

}

export default EditSpouseMail;