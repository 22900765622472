import * as React from 'react';
import { Modal, Row, Col, Button } from 'react-bootstrap';
import { Countdown } from '../../Helper/CountDown';
import { match } from 'react-router';
import { DelegateePathConstants } from '../../Common/Constants'
import { logger } from '../../../routes';
import { LogEventConstants } from '../../Helper/Constants';
import SessionTimeoutIcon from "src/assets/images/SessionTimeout.svg";

declare global {
    interface Window {
        Variables: any;
    }
}
// const SessionIdleMinutes = window.Variables.sessionTimeout / 60;
const WarningVisibleMinutes = 1;

interface ISessionTimeoutProps {
    match: match;
    idleSessionTimeoutInSeconds: number;
    refreshToken(clientId: string, callback: () => void): void;
    logOutDelegatee:(clientGuid:string,callback: (url: string) => void) => void;
}

interface ISessionTimeoutState {
    showTimeout: boolean
}

const pageTitle = "Delegatee Session timeout Modal";
export class DelegateeSessionTimeout extends React.Component<ISessionTimeoutProps, ISessionTimeoutState> {
    private countDown?: Countdown;
    constructor(props: ISessionTimeoutProps) {
        super(props);
        this.countDown = undefined;
        this.state = {
            showTimeout: false
        };
    }

    componentDidMount() {
        this.setExpiryTime();
        let param: any = this.props.match.params;
        logger.trackEvent(logger.buildEvent(
            `${LogEventConstants.Common.Open}  ${pageTitle}`,
            { count: 1, page: pageTitle, clientId: param.clientId }
        ));
    }

    public setExpiryTime = () => {
        window.setTimeout(() => { this.setState({ showTimeout: true }) }, 1000 * 60 * ((this.props.idleSessionTimeoutInSeconds / 60 ) - WarningVisibleMinutes));
    }
    
    logOutSuccess=(url:string)=>{
        let param: any = this.props.match.params;
        window.location.href=url

        logger.trackEvent(
            logger.buildEvent(
                `${LogEventConstants.Common.Account.Logoff} ${pageTitle}`,
                { count: 1, page: pageTitle, clientId: param.clientId }
            )
            );
    }

    public render() {
        return <Modal
            className="session-timeout-modal"
            show={this.state.showTimeout}
            onHide={this.onLogout}>
            <Modal.Header closeButton data-test-auto="60334535-F01C-41D3-AC34-CA9BC7B804DD">
                <Modal.Title>
                    <span className='modalIcon text-secondary fa fa-clock'>
                    </span>Warning
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row data-test-auto="F12E9E3A-5B0D-4463-9329-750FAA9B7D12">
                    <Col lg={3}>
                        <img width="100px" height="100px"
                            src={SessionTimeoutIcon}
                            className="svg-shadow" />
                    </Col>
                    <Col lg={9}>
                        <h2 style={{ color: '#337ab7' }}>Session Expiration</h2>
                        <div>Your session will expire in <Countdown
                            date={this.getCountDown()}
                            onComplete={this.onLogout}
                            ref={(instance: any) => { this.countDown = instance }} /> Do you want to continue working?
                        </div>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    className="btn-white"
                    data-test-auto="C0280D93-94DD-438B-9AB9-4F5BC452FE66"
                    onClick={this.onLogout}
                ><i className='fa fa-sign-out-alt'></i>Logoff</Button>
                <Button
                    variant="info"
                    data-test-auto="CD93CDEF-A642-4D28-9C11-E6913BC2C2C0"
                    onClick={this.onStayAlive}
                ><i className='fa fa-check-double'></i>Continue Working</Button>
            </Modal.Footer>
        </Modal>
    }

    private getCountDown = (): Date => {
        const now = new Date();
        let ret = now;
        ret.setMinutes(now.getMinutes() + WarningVisibleMinutes);
        return ret;
    }

    private onStayAlive = (e: React.SyntheticEvent<EventTarget>) => {
        e.preventDefault();
        let param: any = this.props.match.params;
        if (this.countDown) {
            clearInterval(this.countDown.interval);
        }
        this.props.refreshToken(param.clientId, this.setExpiryTime);
        this.setState({ showTimeout: false });
        logger.trackEvent(
            logger.buildEvent(
                `${LogEventConstants.Common.Account.StayAlive}  ${pageTitle}`,
                { count: 1, page: pageTitle, clientId: param.clientId }
            )
        );
    }

    private onLogout = () => {
        
        let param: any = this.props.match.params;
        this.props.logOutDelegatee(param.controllerId ? param.controllerId : param.clientId,this.logOutSuccess)
    }
}