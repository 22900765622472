import * as React from 'react';

export interface ICurrencyTextBoxProps {
    value?: number;
    placeholder?: string;
    minimumValue?: number;
    decimalPlaces?: number;
    allowNegative?: boolean;
    disabled?: boolean;
    onChange?: (value: number) => void;
    onBlur?: (value: number) => void;
}

interface ICurrencyTextBoxState {
    value: string | null;
}

const DEFAULT_PLACEHOLDER_TEXT = "Amount";
const DEFAULT_MINIMUM_VALUE = 0;
const DEFAULT_MINIMUM_NEGATIVE_VALUE = Number.NEGATIVE_INFINITY;
const DEFAULT_DECIMAL_PLACES = 2;

export class CurrencyTextBox extends React.Component<ICurrencyTextBoxProps, ICurrencyTextBoxState> {
    constructor(props: any) {
        super(props);

        this.state = {
            value: null
        };
        this.updateValue = this.updateValue.bind(this);
    }

    componentDidMount() {
        this.updateValue(this.props);
    }

    componentWillReceiveProps(nextProps: ICurrencyTextBoxProps) {
        this.updateValue(nextProps);
    }

    private updateValue(props: ICurrencyTextBoxProps) {
        const decimalPlaces = props.decimalPlaces ? props.decimalPlaces : DEFAULT_DECIMAL_PLACES;
        const minimumValue = props.minimumValue ? props.minimumValue : props.allowNegative ? DEFAULT_MINIMUM_NEGATIVE_VALUE : DEFAULT_MINIMUM_VALUE;

        const oldValue = this.state.value && this.state.value.trim() != "" ? parseFloat(this.state.value) : 0;
        let newValue = props.value ? props.value : 0;
        if (this.state.value == null || newValue != oldValue) {
            if (newValue < minimumValue) {
                newValue = minimumValue;
            }

            this.setState({
                value: newValue.toFixed(decimalPlaces)
            });
        }
    }

    private handleChange = (event: any) => {
        let _self = this;

        let value = event.target.value;
        let selectionStart = event.target.selectionStart;
        const target = event.target;

        var RE = this.props.allowNegative ? /^-?\d*(\.?\d*)?$/ : /^\d*(\.?\d*)?$/;
        if (RE.test(value)) {

            const decimalPointIndex = value.indexOf(".");
            if (decimalPointIndex > -1) {

                const decimalPlaces = this.props.decimalPlaces ? this.props.decimalPlaces : DEFAULT_DECIMAL_PLACES;
                const maximumLength = decimalPointIndex + decimalPlaces + 1;
                if (value.length > maximumLength) {
                    value = value.substring(0, maximumLength);
                }
            }
        }
        else {
            value = this.state.value;
            selectionStart -= 1;
        }

        _self.setState({ value: value }, () => {
            target.setSelectionRange(selectionStart, selectionStart);

            if (_self.props.onChange) {
                const amount = value != "" ? parseFloat(value) : 0;
                _self.props.onChange(amount);
            }
        });
    }

    private handleBlur = (event: any) => {
        let _self = this;

        let newAmount = event.target.value;
        if (newAmount == "") {
            if (_self.props.onBlur) {
                _self.props.onBlur(0);
            }
        }
        else if (newAmount) {
            let decAmount = new Number(newAmount);
            const decimalPlaces = this.props.decimalPlaces ? this.props.decimalPlaces : DEFAULT_DECIMAL_PLACES;
            newAmount = decAmount.toFixed(decimalPlaces);

            _self.setState({ value: newAmount }, () => {
                if (_self.props.onBlur) {
                    _self.props.onBlur(parseFloat(newAmount));
                }
            });
        }
    }

    private onFocus = (event: any) => {
        if (this.state.value) {
            const amount = parseFloat(this.state.value);
            if (amount == 0) {
                this.setState({ value: '' });
            }
            else {
                this.setState({ value: amount.toString() });
            }
        }
    }

    public render() {
        return (
            <div className="input-group">
                <span className="input-group-addon"><i className="fa fa-usd"></i></span>
                <input type="text"
                    value={this.state.value ? this.state.value : ""}
                    placeholder={this.props.placeholder ? this.props.placeholder : DEFAULT_PLACEHOLDER_TEXT}
                    disabled={this.props.disabled ? this.props.disabled : false}
                    className="form-control inputTextAllignRight"
                    onChange={this.handleChange}
                    onBlur={this.handleBlur}
                    onFocus={this.onFocus}
                    data-lpignore="true"
                />
            </div>
        );
    }
}

export default CurrencyTextBox