import * as React from 'react';
import { match } from 'react-router';
import { EngagementTypeS } from '../../core/common/Enums';
import { ITaxReturn } from '../../core/domain/models/ITaxReturn';
import { CustomButton, CustomButtonSize, CustomButtonPlacement } from '../Common/CustomButton/CustomButton';
import {
    SvgIconDownloadAll
} from '../Common/Icons/SvgIcons';

interface DownloadAllK1Props {
    downloadK1DocumentAsync: (clientId: string, engagementTpe: EngagementTypeS) => void;
    match: match;
    taxReturn: ITaxReturn; 
}

export class DownloadAllK1 extends React.Component<DownloadAllK1Props, {}>{
    constructor(props: any) {
        super(props);
    }

    downloadK1Document = () => {
        let param: any = this.props.match.params;
        this.props.downloadK1DocumentAsync(param.clientId, this.props.taxReturn.engagementType);
    }

    public render() {
        return (<CustomButton
            placement={CustomButtonPlacement.Right}
            size={CustomButtonSize.LG}
            icon={SvgIconDownloadAll}
            text="Download All K-1s"
            onClick={this.downloadK1Document}
            automationTestId="C0606EEB-4C1A-43FF-AAA9-A5EA5D575268"
        />);
    }
}