import { AxiosResponse } from 'axios';
import { Action, Reducer } from 'redux';
import { initializeAxios } from './../../../../core/services/dataAccess/DataService.Axios';
import { actionTypes } from './../../../ActionTypes';
import { NotificationAction, StatusType } from './../../../Common/NotificationStore';
import { AppThunkAction } from './../../../index';
import { HeaderConstants } from './../../../../components/Common/Constants';
import {
    HeaderLoaderAction, HeaderStoreState,
    initialHeaderStoreState, LoginResponse,
    ReceiveHeaderDetailsAction, ReceiveSenderNameAction,
} from '../../Models/StoreModels';
import { IUserModel } from '../../../../core/domain/models/IUserModel';

type KnownAction =
    DispatchAction |
    NotificationAction;

type DispatchAction =
    ReceiveHeaderDetailsAction
    | ReceiveSenderNameAction
    | HeaderLoaderAction;

export const actionCreators = {
    requestHeaderDetails: (clientId: string): AppThunkAction<KnownAction> => (dispatch, getState) => {

        dispatch({ type: actionTypes.HEADER_LOADER, loading: true });

        return initializeAxios().get<LoginResponse>("api/Coverpage/HeaderInfo/" + clientId)
            .then((response: AxiosResponse<LoginResponse>) => {
                const result = response.data;
                if (result.isSuccess) {
                    dispatch({
                        type: actionTypes.RECEIVE_HEADER_DETAILS,
                        data: { ...result.data, loading: false }
                    });
                } else {
                    const errorDesc = result.errorDescription;
                    dispatch({
                        type: actionTypes.NOTIFICATION,
                        statusMessage: errorDesc,
                        statusType: StatusType.Error
                    });
                }
            })
            .catch((e: any) => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: HeaderConstants.ErrorMessage.RetrieveFailed,
                    statusType: StatusType.Error,
                });
            });
    },

    requestDelegateeHeaderDetails: (clientId: string): AppThunkAction<KnownAction> => (dispatch, getState) => {

        dispatch({ type: actionTypes.HEADER_LOADER, loading: true });

        return initializeAxios().get<LoginResponse>("api/Delegatee/HeaderInfo/" + clientId)
            .then((response: AxiosResponse<LoginResponse>) => {
                const result = response.data;
                if (result.isSuccess) {
                    dispatch({
                        type: actionTypes.RECEIVE_HEADER_DETAILS,
                        data: { ...result.data, loading: false },
                    });
                } else {
                    const errorDesc = result.errorDescription;
                    dispatch({
                        type: actionTypes.NOTIFICATION,
                        statusMessage: errorDesc,
                        statusType: StatusType.Error,
                    });
                }

            })
            .catch((e: any) => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: HeaderConstants.ErrorMessage.RetrieveFailed,
                    statusType: StatusType.Error,
                });
            });

    },
    requestCcRecipientHeaderDetails: (clientId: string): AppThunkAction<KnownAction> => (dispatch, getState) => {

        dispatch({ type: actionTypes.HEADER_LOADER, loading: true });

        return initializeAxios().get<LoginResponse>("api/CcRecipient/HeaderInfo/" + clientId)
            .then((response: AxiosResponse<LoginResponse>) => {
                const result = response.data;
                if (result.isSuccess) {
                    dispatch({
                        type: actionTypes.RECEIVE_HEADER_DETAILS,
                        data: { ...result.data, loading: false }
                    });
                } else {
                    const errorDesc = result.errorDescription;
                    dispatch({
                        type: actionTypes.NOTIFICATION,
                        statusMessage: errorDesc,
                        statusType: StatusType.Error
                    });
                }
            })
            .catch((e: any) => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: HeaderConstants.ErrorMessage.RetrieveFailed,
                    statusType: StatusType.Error
                });
            });
    },
    requestSenderName: (role: string, clientId: string): AppThunkAction<KnownAction> => (dispatch, getState) => {

        dispatch({ type: actionTypes.HEADER_LOADER, loading: true });

        return initializeAxios().get<LoginResponse>("api/Coverpage/GetSenderName/" + role + "/" + clientId)
            .then((response: AxiosResponse<string>) => {
                const result = response.data;
                dispatch({
                    type: actionTypes.RECEIVE_SENDER_NAME,
                    data: result
                });
                dispatch({ type: actionTypes.HEADER_LOADER, loading: false });
            })
            .catch((e: any) => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: HeaderConstants.ErrorMessage.RetrieveFailed,
                    statusType: StatusType.Error
                });
            });
    },
};

export const reducer: Reducer<HeaderStoreState> = (state: HeaderStoreState = initialHeaderStoreState, incomingAction: Action) => {
    const action = incomingAction as DispatchAction;
    const currentState = Object.assign({}, state);
    switch (action.type) {
        case actionTypes.RECEIVE_HEADER_DETAILS:
            if (!action.data.contactPerson) {
                action.data.contactPerson = { firstName: '', lastName: '', middleName: '', phoneNumber: '', extension: '', email: '', } as IUserModel;
            }
            return ({ ...currentState, ...action.data });

        case actionTypes.HEADER_LOADER:
            return ({ ...currentState, loading: action.loading })

        case actionTypes.RECEIVE_SENDER_NAME:
            return ({ ...currentState, senderName: action.data })

        default:
            return currentState || initialHeaderStoreState;
    }
};
