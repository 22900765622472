import { connect } from 'react-redux';
import { actionCreators as HeaderActions }  from "./../../../../store/Login/Common/Header/HeaderStore";
import { actionCreators as DelegateeActions }  from "./../../../../store/Login/Delegatee/OTP/OTPStore";
import { ApplicationState } from '../../../../store';
import EmailOtp from '../../Common/OTP/EmailOTP';

export default connect(
    (state: ApplicationState) => ({
        headerStoreState: state.headerPageStore,
        backToHomePath: "",
        successRedirectPath: "/DelegateeInitialize/",
        backToHome: false,
    }),
    {
        requestHeaderDetails: (clientId: string) =>
            HeaderActions.requestDelegateeHeaderDetails(clientId),

        requestClientInfo: (clientId: string) =>
            DelegateeActions.getClientInfo(clientId),

        generateOtp: (clientId: string) =>
            DelegateeActions.generateOTP(clientId),

        verifyOtp: (otp: string, clientId: string, handelOtpVerification: (guid: string) => void, handleError: () => void) =>
            DelegateeActions.verifyOTP(otp, clientId, handelOtpVerification, handleError)
    },
)(EmailOtp);