import { AxiosResponse } from 'axios';
import { Action, Reducer } from 'redux';
import { initializeAxios } from './../../../../core/services/dataAccess/DataService.Axios';
import { actionTypes } from './../../../ActionTypes';
import { NotificationAction, StatusType } from './../../../Common/NotificationStore';
import { AppThunkAction } from './../../../index';
import { DisplayError, OTPPageConstants } from './../../../../components/Common/Constants';
import { OTPMobilePageConstants } from './../../../../components/Common/Constants';
import {
    DelegateeOtpPageLoaderAction, DelegateeOTPPageStoreState,
    initialDelegateeOTPPageStoreState, LoginResponse,
    ReceiveDelegateeOtpPageDetailsAction,
    ErrorMessageAction
} from '../../Models/StoreModels';

type KnownAction =
    DispatchAction |
    NotificationAction |
    ErrorMessageAction;

type DispatchAction =
    ReceiveDelegateeOtpPageDetailsAction
    | DelegateeOtpPageLoaderAction;

export const actionCreators = {
    pageValidity: (clientId: string, navigate: (path: string) => void, handleError: (message: string) => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: actionTypes.DELEGATEE_OTP_PAGE_LOADER, loading: true });
        return initializeAxios().get<LoginResponse>("/api/Delegatee/" + clientId)
            .then((response: AxiosResponse<LoginResponse>) => {

                const result = response.data;

                if (result.isSuccess) {
                    dispatch({ type: actionTypes.DELEGATEE_OTP_PAGE_LOADER, loading: false });
                    navigate(result.data);
                } else {
                    const errorDesc = result.errorDescription;
                    const errorCode = result.errorCode;

                    if (errorCode && DisplayError.includes(errorCode)) {
                        dispatch({
                            type: actionTypes.SET_ERROR_MESSAGE,
                            message: errorDesc,
                        });
                        handleError(errorDesc);
                    } else {
                        dispatch({
                            type: actionTypes.NOTIFICATION,
                            statusMessage: errorDesc,
                            statusType: StatusType.Error,
                        });
                    }

                }
            })
            .catch((e: any) => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: OTPPageConstants.ErrorMessage.serverError,
                    statusType: StatusType.Error
                })

            }
            );

    },

    getClientInfo: (clientId: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: actionTypes.DELEGATEE_OTP_PAGE_LOADER, loading: true });
        return initializeAxios().get<DelegateeOTPPageStoreState>("/api/Delegatee/GetClientInfo/" + clientId)
            .then((response: AxiosResponse<DelegateeOTPPageStoreState>) => {
                const result = response.data;
                dispatch({ type: actionTypes.RECEIVE_DELEGATEE_OTP_PAGE_DETAILS, data: { ...result, loading: false } });
            })
            .catch((e: any) => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: OTPPageConstants.ErrorMessage.serverError,
                    statusType: StatusType.Error,
                });
            });

    },

    generateMobileOTP: (clientId: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        return initializeAxios().postJson<boolean>(null, '/api/Delegatee/Mobile/Generate/' + clientId)
            .then((response: AxiosResponse<boolean>) => {
                const result = response.data;
                if (result) {
                    dispatch({
                        type: actionTypes.NOTIFICATION,
                        statusMessage: OTPMobilePageConstants.SuccessMessage.OTPTextGenerateSuccess,
                        statusType: StatusType.Success
                    });
                } else {
                    dispatch({
                        type: actionTypes.NOTIFICATION,
                        statusMessage: OTPPageConstants.ErrorMessage.OTPGenerateFailed,
                        statusType: StatusType.Error
                    });
                }
            })
            .catch((error: any) => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: OTPMobilePageConstants.ErrorMessage.serverError,
                    statusType: StatusType.Error
                });

            });

    },
    verifyMobileOTP: (OTP: string, clientId: string, handleVerify: (guid: string) => void, handleError: (message: string) => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        return initializeAxios().postJson<LoginResponse>('"' + OTP + '"', '/api/Delegatee/Text/Validate/' + clientId)
            .then((response: AxiosResponse<LoginResponse>) => {
                const result = response.data;
                if (result.isSuccess) {
                    handleVerify(result.data);
                } else {
                    const errDesc = result.errorDescription;
                    const errorCode = result.errorCode;

                    if (errorCode && DisplayError.includes(errorCode)) {
                        dispatch({
                            type: actionTypes.SET_ERROR_MESSAGE,
                            message: errDesc,
                        });
                        handleError(errDesc);

                    } else {
                        dispatch({
                            type: actionTypes.NOTIFICATION,
                            statusMessage: errDesc,
                            statusType: StatusType.Error
                        });
                    }

                }
            })
            .catch((error: any) => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: OTPMobilePageConstants.ErrorMessage.serverError,
                    statusType: StatusType.Error
                });
            });
    },
    generateOTP: (clientId: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        return initializeAxios().postJson<boolean>(null, '/api/Delegatee/Generate/' + clientId)
            .then((response: AxiosResponse<boolean>) => {
                const result = response.data;
                if (result) {
                    dispatch({
                        type: actionTypes.NOTIFICATION,
                        statusMessage: OTPPageConstants.SuccessMessage.OTPEmailGenerateSuccess,
                        statusType: StatusType.Success
                    });
                } else {
                    dispatch({
                        type: actionTypes.NOTIFICATION,
                        statusMessage: OTPPageConstants.ErrorMessage.OTPGenerateFailed,
                        statusType: StatusType.Error
                    });
                }

            })
            .catch((error: any) => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: OTPMobilePageConstants.ErrorMessage.serverError,
                    statusType: StatusType.Error
                });
            });

    },
    verifyOTP: (OTP: string, clientId: string, handleVerify: (guid: string) => void, handleError: (message: string) => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        return initializeAxios().postJson<LoginResponse>('"' + OTP + '"', `/api/Delegatee/Validate/${clientId}`)
            .then((response: AxiosResponse<LoginResponse>) => {
                const result = response.data;

                if (result.isSuccess) {
                    handleVerify(result.data);
                } else {
                    const errorDesc = result.errorDescription;
                    const errorCode = result.errorCode;

                    if (errorCode && DisplayError.includes(errorCode)) {
                        dispatch({
                            type: actionTypes.SET_ERROR_MESSAGE,
                            message: errorDesc,
                        });
                        handleError(errorDesc);

                    } else {
                        dispatch({
                            type: actionTypes.NOTIFICATION,
                            statusMessage: errorDesc,
                            statusType: StatusType.Error,
                        });
                    }
                }
            })
            .catch((error: any) => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: OTPPageConstants.ErrorMessage.serverError,
                    statusType: StatusType.Error
                });

            });
    },
};

export const reducer: Reducer<DelegateeOTPPageStoreState> = (state: DelegateeOTPPageStoreState = initialDelegateeOTPPageStoreState, incomingAction: Action) => {
    const action = incomingAction as DispatchAction;
    const currentState = Object.assign({}, state);
    switch (action.type) {

        case actionTypes.DELEGATEE_OTP_PAGE_LOADER:
            return { ...currentState, loading: action.loading };

        case actionTypes.RECEIVE_DELEGATEE_OTP_PAGE_DETAILS:
            return { ...currentState, ...action.data };

        default:
            return currentState || initialDelegateeOTPPageStoreState;
    }
};