import { IDownloadedZipFilesModel } from 'src/components/Layout/MyDownload';
import { IAdditionalEsignDocument } from 'src/core/domain/models/IAdditionalEsignDocument';
import { IAttachment } from 'src/core/domain/models/IAttachment';
import { IDownloadableDocuments } from 'src/core/domain/models/ITaxReturn';

export interface IDownloadableDocumentsViewModel {
    downloadableDocuments: IDownloadableDocuments[],
    attachments: IAttachment[],
    additionalEsignDocuments: IAdditionalEsignDocument[],
    myDownloadList: IDownloadedZipFilesModel[]
}

export const initialDownloadableDocumentsViewModel: IDownloadableDocumentsViewModel = {
    downloadableDocuments: [],
    additionalEsignDocuments: [],
    attachments: [],
    myDownloadList: []
}