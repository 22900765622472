import { ILoader } from '../../core/utilities/ui/Loader';
import { TYPES } from '../../startup/types';
import { container } from '../../startup/inversify.config';
import { Action, Reducer } from 'redux';
import { AppThunkAction } from './../index';
import { actionTypes } from './../ActionTypes';
import {
    ITaxDocumentModel,
    initialTaxDocumentModel
} from '../../core/domain/models/ITaxDocumentModel';
import { DocumentSignatureDataViewModel } from '../../core/domain/viewModels/IDocumentSignatureDataViewModel';
import { AxiosResponse, AxiosRequestConfig } from 'axios';
import { StatusType, NotificationAction } from './../Common/NotificationStore';
import { IDocument } from '../../core/domain/models/esign/Document';
import { IDocumentAdapter, DocumentAdapter } from '../../core/services/adapters/ControlDataAdapter'
import * as Constants from '../../components/Common/Constants';
import { EsignState, initialEsignState } from './../Common/EsignStore';
import { ISignerControlDataModal } from '../../core/domain/viewModels/ISignerControlDataModal';
import { DisplayDownloadFile } from '../../components/Common/DisplayDownloadFile';
import { ErrorMessages } from '../../components/Common/Constants';
import { IFileUtilities } from '../../core/utilities/File/FileUtilities';
import { ISignedDocument } from '../../core/domain/models/manualsign/SignedDocument';
import { IAttachmentDownloadViewModel } from '../../core/domain/viewModels/IAttachmentDownloadViewModel';
import { IKBATransactionResponse, IBaseServiceResponse, initialKBAResponse, IKBAAnswers } from './../../core/domain/models/IKBA';
import { KBATransactionResultType } from 'src/core/common/Enums';
import { getUserTimeZone } from '../../components/Helper/HelperFunction';
import { initializeAxios } from 'src/core/services/dataAccess/DataService.Axios';

interface RequestDelegateeSignatureControlsDataAction {
    type: actionTypes.DELEGATEE_SIGNATURE_CONTROLS_DATA_REQUEST;
}

interface ResponseDelegateeSignatureControlsDataAction {
    type: actionTypes.DELEGATEE_SIGNATURE_CONTROLS_DATA_RESPONSE;
    data: IDocument[];
}

interface FailureDelegateeSignatureControlsDataAction {
    type: actionTypes.DELEGATEE_SIGNATURE_CONTROLS_DATA_FAILURE;
}

interface RequestDelegateeManulUploadDocumentAction {
    type: actionTypes.DELEGATEE_MANUAL_SIGN_REQUEST;
    data: ISignedDocument[];
}

interface ResponseDelegateeManulUploadDocumentAction {
    type: actionTypes.DELEGATEE_MANUAL_SIGN_RESPONSE;
    data: ISignedDocument[];
}

interface FailureDelegateeManulUploadDocumentAction {
    type: actionTypes.DELEGATEE_MANUAL_SIGN_FAILURE;
    data: ISignedDocument[];
}

interface RequestDelegateeKbaAuthAction {
    type: actionTypes.DELEGATEE_KBA_AUTH_REQUEST;
}

interface ResponseDelegateeKbaAuthAction {
    type: actionTypes.DELEGATEE_KBA_AUTH_RESPONSE;
    data: IKBATransactionResponse;
}

interface FailureDelegateeKbaAuthAction {
    type: actionTypes.DELEGATEE_KBA_AUTH_FAILURE;
    data: IKBATransactionResponse;
}

type KnownAction =
    DispatchAction |
    NotificationAction;

type DispatchAction = RequestDelegateeSignatureControlsDataAction
    | ResponseDelegateeSignatureControlsDataAction
    | FailureDelegateeSignatureControlsDataAction
    | RequestDelegateeManulUploadDocumentAction
    | ResponseDelegateeManulUploadDocumentAction
    | FailureDelegateeManulUploadDocumentAction
    | RequestDelegateeKbaAuthAction
    | ResponseDelegateeKbaAuthAction
    | FailureDelegateeKbaAuthAction;

const fileUtilities = container.get<IFileUtilities>(TYPES.IFileUtilities);
const loader = container.get<ILoader>(TYPES.ILoader);

export const actionCreators = {

    requestDelegateeSignatureControls: (clientGuid: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const state = getState();
        dispatch({ type: actionTypes.DELEGATEE_SIGNATURE_CONTROLS_DATA_REQUEST });
        return initializeAxios().get<ITaxDocumentModel>('/api/DelegateeSigner/GetDocumentSignatureData/' + clientGuid)
            .then(function (response: AxiosResponse<DocumentSignatureDataViewModel[]>) {
                const documentAdapter: IDocumentAdapter = DocumentAdapter.create();

                dispatch({
                    type: actionTypes.DELEGATEE_SIGNATURE_CONTROLS_DATA_RESPONSE,
                    data: documentAdapter.convertToClientModel(response.data)
                });
            })
            .catch(function (error: any) {
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: error.response ? error.response.statusText :
                        Constants.ErrorMessages.SignedDocumentError,
                    statusType: StatusType.Error
                });
                dispatch({ type: actionTypes.DELEGATEE_SIGNATURE_CONTROLS_DATA_FAILURE });
            });
    },

    delegateeSignComplete: (clientId: string, documentData: IDocument[], callback: (status: boolean) => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        loader.show();
        let signatureData: ISignerControlDataModal = DocumentAdapter.create().convertToServerModel(documentData);
        signatureData.clientGuid = clientId;
        initializeAxios().postJson<boolean>(signatureData, `api/DelegateeSigner/DelegateeSignCompletionAsync/${clientId}?userTimeZone=${getUserTimeZone()}`)
            .then(function (response: AxiosResponse<boolean>) {
                const { data } = response;
                callback(data);
                loader.hide();
            })
            .catch(function (error: any) {
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: error,
                    statusType: StatusType.Error
                });
            });
    },

    delegateeDownloadEfileForms: (clientGuid: string, successCallback?: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        loader.show();
        return initializeAxios().get('/api/DelegateeDownload/GetEfileDocumentSasForDelegateeAsync/' + clientGuid)
            .then(function (response: AxiosResponse<any>) {
                fileUtilities.download(response.data);
                successCallback && successCallback();
                loader.hide();
            })
            .catch(function (error: any) {
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: error.response ? error.response.statusText :
                        ErrorMessages.CompleteManualSign,
                    statusType: StatusType.Error
                });
            });
    },

    delegateeDownloadAllAdditionEsignDocuments: (clientId: string, fileName: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        loader.show();
        return initializeAxios().get('/api/DelegateeDownload/GetOtherEsignDocumentsSasForDelegateeAsync/' + clientId)
            .then(function (response: any) {
                let displayDownloadFile = new DisplayDownloadFile();
                if (response.data && response.data.length > 0) {
                    displayDownloadFile.directDownload(response.data);
                    loader.hide();
                }
                else {
                    let config: AxiosRequestConfig = { responseType: 'arraybuffer', headers: { 'Content-Type': 'application/json;utf-8' } };
                    return initializeAxios().get('/api/DelegateeDownload/GetDelegateeAllOtherEsignDocumentsZipStreamAsync/' + clientId, config)
                        .then(function (response: any) {
                            displayDownloadFile.showFile(response.data, fileName);
                            loader.hide();
                        }).catch(function (error: any) {
                            dispatch({
                                type: actionTypes.NOTIFICATION, statusMessage: error.response ? error.response.statusText :
                                    "Failed to download additional esign document(s)",
                                statusType: StatusType.Error
                            });
                        });
                }
            }).catch(function (error: any) {
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: error.response ? error.response.statusText : "Failed to download Other Signed Documents",
                    statusType: StatusType.Error
                });
            });
    },

    downloadDelegateeSelectedDocuments: (clientId: string, documentGroups: number[]): AppThunkAction<KnownAction> => (dispatch, getState) => {
        let config: AxiosRequestConfig = { responseType: 'arraybuffer', headers: { 'Content-Type': 'application/json;utf-8' } };
        loader.show();
        let fileName: string;
        return initializeAxios().post<IAttachmentDownloadViewModel[]>('/api/DelegateeDownload/GetDelegateeSelectedDocuments/' + clientId,
            JSON.stringify(documentGroups), config)
            .then(function (response: any) {
                let displayDownloadFile = new DisplayDownloadFile();
                const contentDisposition = response.headers["content-disposition"];
                const fileNameMatch = contentDisposition ? /filename="?([^"]*)"?;/g.exec(contentDisposition) : undefined;
                if (fileNameMatch && fileNameMatch.length > 1) {
                    fileName = fileNameMatch[1];
                }
                displayDownloadFile.showFile(response.data, fileName);
                loader.hide();
            }).catch(function (error: any) {
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: error.response.statusText,
                    statusType: StatusType.Error
                });
            });
    },

    downloadDelegateeSignedEFileDocument: (clientId: string, fileName: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        loader.show();
        let config: AxiosRequestConfig = { responseType: 'arraybuffer', headers: { 'Content-Type': 'application/json;utf-8' } };
        return initializeAxios().get('/api/DelegateeDownload/GetDelegateeSignedEfileDocumentStreamAsync/' + clientId, config)
            .then(function (response: any) {
                let displayDownloadFile = new DisplayDownloadFile();
                displayDownloadFile.showFile(response.data, fileName);
                loader.hide();
            }).catch(function (error: any) {
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: error.response ? error.response.statusText :
                        "Failed to download E-File",
                    statusType: StatusType.Error
                });
            });
    },

    requestDelegateeSignedDocuments: (clientGuid: string, forceRefresh?: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const state = getState();
        let manualSignDataState = state.manualSignData != undefined ? state.manualSignData.data : initialManualSignState;
        if (!forceRefresh && manualSignDataState.length > 0) {
            dispatch({ type: actionTypes.DELEGATEE_MANUAL_SIGN_REQUEST, data: manualSignDataState });
            return;
        }

        return initializeAxios().get<ISignedDocument[]>('/api/DelegateeSigner/GetDelegateeSignedDocuments/' + clientGuid, {}, true)
            .then(function (response: AxiosResponse<ISignedDocument[]>) {
                dispatch({
                    type: actionTypes.DELEGATEE_MANUAL_SIGN_RESPONSE, data: response.data
                });
            })
            .catch(function (error: any) {
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: error.response ? error.response.statusText :
                        ErrorMessages.SignedDocumentError,
                    statusType: StatusType.Error
                });
                dispatch({ type: actionTypes.DELEGATEE_MANUAL_SIGN_FAILURE, data: manualSignDataState });
            });
    },

    addDelegateeSignedDocument: (clientGuid: string, signedDocument: ISignedDocument, successCallback?: () => void, failureCallback?: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const state = getState();
        return initializeAxios().postJson(signedDocument, '/api/DelegateeSigner/AddDelegateeSignedDocument/' + clientGuid)
            .then(function (response: AxiosResponse<any>) {
                successCallback && successCallback();
            })
            .catch(function (error: any) {
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: error.response ? error.response.statusText :
                        ErrorMessages.AddSignedDocument,
                    statusType: StatusType.Error
                });
                failureCallback && failureCallback();
            });
    },

    deleteDelegateeSignedDocument: (clientGuid: string, id: number, successCallback?: () => void, failureCallback?: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const state = getState();
        loader.show();
        return initializeAxios().get(`/api/DelegateeSigner/RemoveDelegateeSignedDocument/${clientGuid}?signedDocumentId=${id}`)
            .then(function (response: AxiosResponse<any>) {
                successCallback && successCallback();
                loader.hide();
            })
            .catch(function (error: any) {
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: error.response ? error.response.statusText :
                        ErrorMessages.DeleteSignedDocument,
                    statusType: StatusType.Error
                });
                failureCallback && failureCallback();
            });
    },

    completeDelegateeManualSign: (clientGuid: string, successCallback?: () => void, failureCallback?: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {

        loader.show();
        const state = getState();
        return initializeAxios().post(`/api/DelegateeSigner/CompleteDelegateeManualSign/${clientGuid}?userTimeZone=${getUserTimeZone()}`)
            .then(function (response: AxiosResponse<any>) {
                successCallback && successCallback();
                loader.hide();
            })
            .catch(function (error: any) {
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: error.response ? error.response.statusText :
                        ErrorMessages.CompleteManualSign,
                    statusType: StatusType.Error
                });
                failureCallback && failureCallback();
            });
    },

    kbaAuthentication: (clientGuid: string, failureCallback: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const state = getState();
        dispatch({ type: actionTypes.DELEGATEE_KBA_AUTH_REQUEST });
        return initializeAxios().get<IBaseServiceResponse>('/api/DelegateeSigner/ClientAuthentication/' + clientGuid)
            .then(function (response: AxiosResponse<IBaseServiceResponse>) {
                const kbaResult = response.data as IKBATransactionResponse;
                if (kbaResult.transactionStatus.toString() === KBATransactionResultType[KBATransactionResultType.error]
                    || kbaResult.transactionStatus.toString() === KBATransactionResultType[KBATransactionResultType.failed]) {
                    failureCallback();
                }
                else {
                    dispatch({
                        type: actionTypes.DELEGATEE_KBA_AUTH_RESPONSE, data: kbaResult
                    });
                }
            })
            .catch(function (error: any) {
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: error.response ? error.response.statusText : Constants.ErrorMessages.ClientAuthentication,
                    statusType: StatusType.Error
                });
                dispatch({ type: actionTypes.DELEGATEE_KBA_AUTH_FAILURE, data: state.kbaData ? state.kbaData : initialKBAResponse });
            });
    },

    kbaValidation: (clientGuid: string, data: IKBAAnswers, successCallback: (isNextQuestionSet?: boolean) => void, failureCallback: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const state = getState();
        dispatch({ type: actionTypes.DELEGATEE_KBA_AUTH_REQUEST });
        const options = {
            headers: {
                'Accept': 'application/json, text/plain, *',
                'Content-Type': 'application/json; charset=utf-8'
            }
        };
        return initializeAxios().post<IBaseServiceResponse>('/api/DelegateeSigner/ClientValidation/' + clientGuid, data, options)
            .then(function (response: AxiosResponse<IBaseServiceResponse>) {
                const kbaResult = response.data as IKBATransactionResponse;
                if (kbaResult.transactionStatus.toString() == KBATransactionResultType[KBATransactionResultType.failed].toString() ||
                    kbaResult.transactionStatus.toString() == KBATransactionResultType[KBATransactionResultType.error].toString() ||
                    kbaResult.transactionStatus.toString() == KBATransactionResultType[KBATransactionResultType.retryExceeded].toString()) {
                    failureCallback();
                }
                else if (kbaResult.transactionStatus.toString() == KBATransactionResultType[KBATransactionResultType.questions].toString()) {
                    successCallback(true);
                    dispatch({
                        type: actionTypes.DELEGATEE_KBA_AUTH_RESPONSE, data: kbaResult
                    });
                }
                else {
                    successCallback();
                }
            })
            .catch(function (error: any) {
                failureCallback();
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: error.response ? error.response.statusText : Constants.ErrorMessages.ClientValidation,
                    statusType: StatusType.Error
                });
                dispatch({ type: actionTypes.DELEGATEE_KBA_AUTH_FAILURE, data: state.kbaData ? state.kbaData : initialKBAResponse });
            });
    },
}

export const reducer: Reducer<EsignState> = (state: EsignState = initialEsignState, incomingAction: Action) => {
    const action = incomingAction as DispatchAction;
    const currentState = Object.assign({}, state);
    switch (action.type) {
        case actionTypes.DELEGATEE_SIGNATURE_CONTROLS_DATA_REQUEST:
            currentState.controlsData = [];
            return currentState;
        case actionTypes.DELEGATEE_SIGNATURE_CONTROLS_DATA_RESPONSE:
            currentState.controlsData = action.data;
            return currentState;
        default:
            return currentState || initialEsignState;
    }
};

export const initialManualSignState: ISignedDocument[] = [];

export const delegateeManualSignDataReducer: Reducer<ISignedDocument[]> = (state: ISignedDocument[] = initialManualSignState,
    incomingAction: Action) => {
    const action = incomingAction as DispatchAction;
    switch (action.type) {
        case actionTypes.DELEGATEE_MANUAL_SIGN_REQUEST:
            return initialManualSignState;
        case actionTypes.DELEGATEE_MANUAL_SIGN_RESPONSE:
            let receivedDelegateeModel: ISignedDocument[] = action.data;
            return receivedDelegateeModel;
        default:
            return state || initialManualSignState;
    }
};


export const delegateeKbaAuthReducer: Reducer<IKBATransactionResponse> = (state: IKBATransactionResponse = initialKBAResponse,
    incomingAction: Action) => {
    const action = incomingAction as DispatchAction;
    const currentState = Object.assign({}, state);
    switch (action.type) {
        case actionTypes.DELEGATEE_KBA_AUTH_REQUEST:
            return initialKBAResponse;
        case actionTypes.DELEGATEE_KBA_AUTH_RESPONSE:
            return action.data;
        case actionTypes.DELEGATEE_KBA_AUTH_FAILURE:
            return currentState;
        default:
            return currentState || initialKBAResponse;
    }
};

