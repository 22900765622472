import {
    initialSignProcessInfoViewModel,
    ISignProcessInfoViewModel,
} from "../viewModels/ISignProcessInfoViewModel";

import { RedirectionSourcePage, Role } from "../../common/Enums";

export interface IClientProcessViewModel {
    role: Role;
    currentstep: ISignProcessInfoViewModel;
    permission: string;
    isSigned: boolean;
    isDelegated: boolean;
    isAccessedFromOneHub: boolean;
    redirectionSourcePage: RedirectionSourcePage;
}

export const initialClientProcessModel: IClientProcessViewModel = {
    role: Role.None,
    currentstep: initialSignProcessInfoViewModel,
    permission: "",
    isSigned: false,
    isDelegated: false,
    isAccessedFromOneHub: false,
    redirectionSourcePage : RedirectionSourcePage.None
};
