import * as React from 'react';

import { IPayScreenViewModel } from 'src/core/domain/viewModels/IPayScreenViewModel';
import { IDocumentDisplaySetting } from 'src/core/domain/models/IDocumentSettings';
import { VoucherTypes, DocumentGroups } from '../../core/domain/models/IGroup';
import { IUtilities } from '../../core/utilities/Utilities';
import { container } from '../../startup/inversify.config';
import { TYPES } from '../../startup/types';
import { Shimmer } from '../Common/Shimmer/Shimmer';
import { ITaxReturn } from 'src/core/domain/models/ITaxReturn';
import { getDueDateBasedVouchers } from './PayHelper';

const utilities = container.get<IUtilities>(TYPES.IUtilities);

export interface TaxPaymentProps {
    payScreenData: IPayScreenViewModel,
    documentDisplaySetting: IDocumentDisplaySetting,
    handleDueDateSelection: (selectedDate: string, selectedPaymentType: string) => void;
    taxReturn: ITaxReturn
}

interface TaxPaymentStates {
    selectedTab: string;
    selectedPaymentType: string;
}


export class TaxPayment extends React.Component<TaxPaymentProps, TaxPaymentStates> {
    constructor(props: TaxPaymentProps) {
        super(props);
        this.state = {
            selectedTab: "",
            selectedPaymentType: ""
        }
    }

    handleTabSelect = (selectedTab: string, selectedPaymentType: string) => {
        this.setState({ selectedTab: selectedTab, selectedPaymentType: selectedPaymentType });
        this.props.handleDueDateSelection(selectedTab, selectedPaymentType);
    }

    public render() {
		let groupedVouchers = getDueDateBasedVouchers(this.props.payScreenData.vouchers);

        const isLoaded = this.props.payScreenData.documentId === 0 ? false : true

        return (
            <div className="tax-payment-container">

                {this.props.payScreenData.documentId !== 0 ?

                    <div style={{ height: "75px", padding: "30px 0px 0px 20px" }}>
                        <div className="title" style={{ color: "#0973ba" }}>
                          Extension Tax Payments
                    </div>
                        <span>Select to see details</span>
                        <hr />
                    </div>
                    : <Shimmer width={250} />
                }

                <div className="pay-left-panel-list">
                    {isLoaded &&
                        this.props.taxReturn.formGroups.find(m => m.group == DocumentGroups.Vouchers) ?.forms ?.length === 0 &&
                            this.props.taxReturn.invoiceAmount === 0 &&
                            <React.Fragment>
                                <div> <h6 className="section-heading"> No Payment Vouchers</h6></div>
                            </React.Fragment>
                    }

                    {/* Payment Vouchers*/}
                    {isLoaded ?
                        this.props.payScreenData.vouchers.find(m => m.paymentType === VoucherTypes.PaymentVoucher) &&
                        <section>
                            <div className="section-title" data-test-auto="5C0512A6-6E63-458E-8A3D-2B0B7FC56C88">
                                <b>{this.props.payScreenData.taxYear} Extension Tax Payments</b>
                            </div>

                            {
                                groupedVouchers.map((item, _index) => {
                                    if (item.vouchers.find(m => m.paymentType === VoucherTypes.PaymentVoucher)) {
                                        let paymentVoucherTotal = item.vouchers.map(v => v.paymentType == VoucherTypes.PaymentVoucher ? v.amount : 0)
                                            .reduce((sum, current) => sum + current);



                                        let className = item.groupName === this.state.selectedTab && this.state.selectedPaymentType === VoucherTypes[VoucherTypes.PaymentVoucher] ?
                                            "pay-right-pointer pay-arrow-right cursor-pointer padding-left-6 pay-screen-voucher" :
                                            " cursor-pointer  pay-screen-voucher";

                                        return (
                                            <li id="pay-tab-navbar" className={className}
                                                data-test-auto="A09FAA24-083C-4D52-A608-DC81E0E46941"
												onClick={() => this.handleTabSelect(item.groupName, VoucherTypes[VoucherTypes.PaymentVoucher])}>
												<span> Due {item.groupName} </span>
                                                <span className="amount flrt  pay-currencytext"
                                                    data-test-auto="B02D53DB-2AC8-4100-A81E-B97D5F4F7A00">
                                                    ${utilities.formatCurrencyText(paymentVoucherTotal)}
                                                </span>
                                            </li>)
                                    }

                                })
                            }

                        </section> :
                        <Shimmer lineCount={8} />
                    }

                    {/* Estimated Vouchers*/}
                    {isLoaded ?
                        this.props.payScreenData.vouchers.find(m => m.paymentType === VoucherTypes.EstimatedVoucher) &&
                        <section>
                            <div className="section-title" data-test-auto="F1B09F46-E41C-4892-BAC9-8F9CA5E8DBB1">
                                <b>{this.props.payScreenData.taxYear + 1} Extension Estimated Payments </b>
                            </div>

                            {
                                groupedVouchers.map((item, _index) => {

                                    if (item.vouchers.find(m => m.paymentType === VoucherTypes.EstimatedVoucher)) {
                                        let estimatedVoucherTotal = item.vouchers.map(v => v.paymentType == VoucherTypes.EstimatedVoucher ? v.amount : 0)
                                            .reduce((sum, current) => sum + current);


										let className = item.groupName === this.state.selectedTab && this.state.selectedPaymentType === VoucherTypes[VoucherTypes.EstimatedVoucher] ?
                                            "pay-right-pointer pay-arrow-right cursor-pointer padding-left-6 pay-screen-voucher" :
                                            " cursor-pointer  pay-screen-voucher";

                                        return (
                                            <li id="pay-tab-navbar" className={className}
                                                data-test-auto="A22C6443-A3D2-4604-BA95-4B04EF0CE0CA"
												onClick={() => this.handleTabSelect(item.groupName, VoucherTypes[VoucherTypes.EstimatedVoucher])}>
												<span> Due {item.groupName} </span>
                                                <span className="amount flrt pay-currencytext"
                                                    data-test-auto="B790E0FF-3DF1-49AE-8378-BF487780437E">
                                                    ${utilities.formatCurrencyText(estimatedVoucherTotal as number)}
                                                </span>
                                            </li>)
                                    }

                                })
                            }
                        </section> :
                        <Shimmer lineCount={8} />
                    }
                </div>
            </div>);
    }
}