import * as React from 'react';
import { Col, Row, Modal, Button } from 'react-bootstrap';
import { MyDownloadsConstants } from '../Common/Constants';
import { MarsNotifier } from '../Common/Notification/MarsNotifier';
import {
    ICCRecipientDownloadableDocuments,
    CCRecipientDocumentGroups
} from '../../core/domain/models/ICCRecipientModel';

export interface SelectiveDownloadProps {
    showModal?: boolean;
    downloadableDocuments?: ICCRecipientDownloadableDocuments[];
    onHide: () => void;
    downloadAllDocuments?: () => void;
    downloadSelectedDocuments?: (documentGroups: number[]) => void;
}

interface SelectiveDownloadState {
    documents: number[];
}

export class SelectiveDownload extends React.Component<SelectiveDownloadProps, SelectiveDownloadState>{

    constructor(props: any) {
        super(props);
        this.state = {
            documents: [],
        }
    }

    selectDocuments = (event: any, documentGroup: CCRecipientDocumentGroups) => {
        if (event.target.checked) {
            let documentGroups = [...this.state.documents, documentGroup]
            this.setState({ documents: documentGroups });
        }
        else {
            this.setState({ documents: this.state.documents.filter((groupId, index) => groupId !== documentGroup) });
        }
    }

    downloadSelectedDocuments = () => {
        if (this.state.documents.length == 0) {
            MarsNotifier.Warning(MyDownloadsConstants.DocumentsNotSelectedWarning, null);
            return;
        }
        this.props.downloadSelectedDocuments && this.props.downloadSelectedDocuments(this.state.documents);

    }

    public render() {

        return (
            <Modal show={this.props.showModal} id="ccRecipient-modal">
                <Modal.Header data-test-auto="e109565d-0c31-4e16-aae8-210b5d7805d3">
                    <Modal.Title style={{ color: "#fff" }}>
                        <div>{'Download Tax Documents'}</div>

                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <Row className="form-group" style={{ marginTop: 10 }}>
                            <Col xs={6}>
                                <div data-test-auto="fd6c0bc7-814b-4bca-b95d-9ca0ed7a5708" className="cc-choose-docs">{MyDownloadsConstants.DownloadFiles}</div>
                            </Col>
                            <Col xs={2} />
                            <Col xs={10} style={{ marginTop: 5, marginLeft: 40 }}>
                                <div className="col-sm-10" data-test-auto="d1a12932-2f2d-4b30-a614-141b1a804e58">
                                    {
                                        this.props.downloadableDocuments && this.props.downloadableDocuments.map((document, i) => {
                                            return (
                                                <div key={document.fileName + i}>
                                                    <Row>
                                                        <Col xs={1}>
                                                            <input type="checkbox" className="input-document-list"
                                                                data-test-auto={"18EF199A-D9BE-42A6-ABAB-533174ABD07" + i}
                                                                key={document.fileName + document.documentGroup}
                                                                checked={
                                                                    this.state.documents.findIndex(groupId => groupId == document.documentGroup) > -1
                                                                }
                                                                onChange={(e: any) => this.selectDocuments(e, document.documentGroup)} />
                                                        </Col>
                                                        <Col xs={8} data-test-auto="85abbf19-5670-489a-aed8-8444b2a99a1b">
                                                            <label>{document.fileName}</label>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Row style={{ width: "100%" }}>
                        <div className="col-lg-6" data-test-auto="810e61cc-c198-4d4c-aedb-86af2ecea901">
                            <div className="buttonDownload" onClick={this.props.downloadAllDocuments}>Download All as a Zip File
                                <i className="fa fa-download" style={{ float: 'right', fontSize: 'medium', paddingLeft: 10 }}></i>
                            </div>
                        </div>
                        <div className="col-lg-6" style={{ textAlign: "right" }}>
                            <Button
                                onClick={this.props.onHide}
                                data-test-auto="14f8eeff-4e49-4d6a-917e-16c8ef427eaa"
                                variant={"primary"}
                                className="btn btn-primary rounded margin-right-10"> Cancel
                            </Button>
                            <Button
                                onClick={this.downloadSelectedDocuments}
                                data-test-auto="106c8fe1-4c06-4b68-9f34-1a06f8ff7828"
                                variant={"primary"}
                                className="btn btn-primary rounded"> OK
                            </Button>
                        </div>
                    </Row>
                </Modal.Footer>
            </Modal >

        )
    }
}