import * as React from 'react';
import { History } from 'history';
import { RouteComponentProps } from 'react-router';
import Header from "./../Header/Header";
import { HeaderStoreState } from '../../../../store/Login/Models/StoreModels';
import Skeleton from 'react-loading-skeleton';

export interface MatchProps {
    clientId: string;
}

export interface OtpProps extends RouteComponentProps<MatchProps> {
    requestHeaderDetails: (clientId: string) => void;
    pageValidity?: (clientId: string, errorCallback: (message: string) => void) => void;
    requestClientInfo?: (clientId: string) => void;
    generateOtp: (clientId: string) => void;
    verifyOtp: (otp: string, clientId: string, handelOtpVerification: (guid: string) => void, handleError: (message: string) => void) => void;
    successRedirectPath: string;
    history: History;
    headerStoreState: HeaderStoreState;
    backToHomePath: "/CoverPage/Index" | "/GroupedReturns/CoverPage/Index" | "";
    backToHome: boolean;
}

export interface OtpState {
    otpVal: string;
    continueState: boolean;
}

export default class EmailOtp extends React.Component<OtpProps, OtpState> {

    continueRef: any = null;

    constructor(props: any) {
        super(props);
        this.state = {
            otpVal: "",
            continueState: false
        };
    }

    componentDidMount() {
        this.props.requestHeaderDetails(this.props.match.params.clientId);
        this.props.requestClientInfo && this.props.requestClientInfo(this.props.match.params.clientId);
        this.props.pageValidity && this.props.pageValidity(this.props.match.params.clientId, this.handleError);
    }

    handleError = (message: string) => {
        this.props.history.push(`/Error/${message}`);
    }

    handleOTPChange = (e: any) => {
        const otpValue = e.target.value.trim().substring(0, 8);
        if (otpValue.length <= 8 && (!isNaN(otpValue) || otpValue.length === 0)) {
            if (otpValue.length === 8) {
                this.setState({ otpVal: otpValue, continueState: true });
            } else {
                this.setState({ otpVal: otpValue, continueState: false });
            }
        }
    }

    requestCode = () => {
        this.props.generateOtp(this.props.match.params.clientId);
    }

    handleOTPVerification = (guid: string) => {
        this.props.history.push(`${this.props.successRedirectPath}${guid}`);
    }

    verifyOTP = () => {
        if (this.state.continueState) {
            this.props.verifyOtp(this.state.otpVal, this.props.match.params.clientId, this.handleOTPVerification, this.handleError);
        }
    }
    render() {

        const { backToHomePath, headerStoreState, match, history, backToHome } = this.props;

        let continueClass = "btn btn-primary continue disabled float-right";
        if (this.state.continueState) {
            continueClass = "btn btn-primary continue float-right";
            this.continueRef.focus();
        }
        return (<>
            <Header headerInfo={headerStoreState} pathname={match.path} clientId={match.params.clientId} history={history} backButton={backToHome} navigateLink={backToHomePath} />
            {
                headerStoreState.loading ?
                    <Skeleton circle={false} height={"100vh"} width={"100vw"} /> :
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 otp-page">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 otp-page-container">
                            <h5 data-test-auto="BAC57E4B-7806-429B-9E44-0163145A9ACC">Authentication</h5>
                            <br />
                            <strong data-test-auto="6B37FC81-36C9-4362-9E27-516FF7AC423F">Access Code Required</strong>
                            <div>
                                <div data-test-auto="8A141EFB-B353-4AD4-BB55-C3B78E9F4677" className="margin-top-10">
                                    <p> Please select “Request Access Code” and we will send you a one-time expiring access code to your email.</p>
                                </div>
                                <div className="margin-top-50">
                                    <button data-test-auto="F0D86424-5958-4AD3-8973-CF1B2BC4B61E" type="button" className="btn btn-primary continue" id="btnK1RequestAccessCode" onClick={this.requestCode}>Request Access Code</button>
                                </div>
                                <div className="otp-page-container-input-div margin-top-50">
                                    <span data-test-auto="7584177D-06BE-437D-961D-4F0E7907804F">Enter access code here:</span> &nbsp;
                                    <input data-test-auto="WL2PVLYGV7Y4V6M2KCECL0WT9D" id="otp" name="otp" type="text" value={this.state.otpVal} onChange={this.handleOTPChange} style={{ margin: "0 36px 0 4px" }} autoComplete="false" />
                                    <span className="access-code-expiry-info" data-test-auto="AEFBB9D6-3E8D-4854-A55A-5B4A70E870E3">(This code will expire in 20 minutes)</span>
                                </div>
                                <div className="margin-top-50">
                                    <button ref={(ref) => { this.continueRef = ref }} data-test-auto="21DD3A5C-6CA3-4137-95E6-536C84067160" type="submit" id="btnCcContinue" className={continueClass} onClick={this.verifyOTP}>Continue</button>
                                </div>
                            </div>
                        </div>
                    </div>
            }
        </>
        );
    }
}