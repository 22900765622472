import { connect } from 'react-redux';
import { actionCreators as accountAction } from '../../../store/Common/AccountStore';
import { IClientProcessViewModel } from '../../../core/domain/viewModels/IClientProcessViewModel';
import { actionCreators as CompanyStore } from '../../../store/Common/Company/CompanyStore';
import { actionCreators as EsignStore } from '../../../store/Common/EsignStore';
import { actionCreators as HelperStore } from '../../../store/Common/HelperStore';
import { actionCreators as TaxDocumentStore } from '../../../store/Common/TaxDocumentStore';
import { actionCreators as DownloadableDocumentsStore } from '../../../store/DownloadableDocumentsStore';
import { ApplicationState } from "../../../store/index";
import { createLoadingSelector } from '../../../store/selectors';
import { actionCreators as ManualSignStore } from '../../../store/Sign/ManualSignStore';
import { actionCreators as SignProcessStore } from '../../../store/SignProcess/SignProcessStore';
import { actionCreators as SummaryStore } from '../../../store/SummaryStore';
import { CompletedWizardFlow } from './CompletedWizardFlow';
import { actionCreators as SigningRequiredStore } from '../../../store/SignProcess/SigningRequiredStore';
import { ClientTypesNumber } from '../../../core/domain/models/ISignerModel';
import { actionCreators as DelegateeSignerStore } from '../../../store/Delegatee/DelegateeSignerStore';
import { IDelegateeSignerDetails } from '../../../core/domain/models/DelegateeModels';

const loadingSelector = createLoadingSelector(['HEADERINFO', 'SUMMARY_DETAILS',
    'TAXING_AUTHORITY', 'DOCUMENT_REVIEW_MODEL', 'DOCUMENT_SETTINGS']);

function mapStateToProps(state: ApplicationState) {
    return {

        summaryData: state.summaryData,
        commonData: state.commonData,
        taxReturn: state.taxReturn.taxDocument,
        signedDocument: state.manualSignData.data,
        headInfoViewModel: state.headerInfo,
        downloadableDocument: state.downloadableDocuments,
        signProcessData: state.signProcessData.data,
        signingRequiredState: state.signingRequiredStore,
        delegateeSigner: state.delegateeSigner,
        showPaymentAlert: state.taxReturn.showPaymentAlert,
        clientProcessData: state.signProcessData.clientprocessmodel
    };
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        requestSummaryDetails: (clientId: string) =>
            dispatch(SummaryStore.requestSummaryDetails(clientId)),

        requestAllTaxingAuthorities: (id: string) =>
            dispatch(HelperStore.requestAllTaxingAuthorities(id)),

        requestNextSigner: (clientGuid: string) =>
            dispatch(TaxDocumentStore.requestNextSigner(clientGuid)),

        sendSigningReminder: (clientId: string) =>
            dispatch(SummaryStore.sendSigningReminder(clientId)),

        requestCompanyDetails: (clientGuid: string, forceRefresh?: boolean) =>
            dispatch(CompanyStore.requestCompanyDetails(clientGuid, forceRefresh)),

        requestTaxDocument: (clientGuid: string, callback?: (data?: any) => void) =>
            dispatch(TaxDocumentStore.requestTaxDocument(clientGuid, callback)),

        requestSigners: (clientGuid: string) =>
            dispatch(EsignStore.requestSigners(clientGuid)),

        downloadSignedEFileDocument: (clientGuid: string, fileName: string, callback?: () => void) =>
            dispatch(DownloadableDocumentsStore.downloadSignedEFileDocument(clientGuid, fileName, callback)),

        requestDownloadableDocuments: (clientGuid: string) =>
            dispatch(DownloadableDocumentsStore.requestDownloadableDocuments(clientGuid)),

        downloadAllAdditionEsignDocuments: (clientGuid: string, fileName: string) =>
            dispatch(DownloadableDocumentsStore.downloadAllAdditionEsignDocuments(clientGuid, fileName)),

        requestSignedDocuments: (clientGuid: string, forceRefresh?: boolean) =>
            dispatch(ManualSignStore.requestSignedDocuments(clientGuid, forceRefresh)),

        downloadEFileDocument: (clientGuid: string, fileName: string, callback?: () => void) =>
            dispatch(DownloadableDocumentsStore.downloadEFileDocument(clientGuid, fileName, callback)),

        requestLastVisitedStep: (clientGuid: string, successCallback?: (lastVisitedStep: number) => void) =>
            dispatch(SignProcessStore.requestLastVisitedStep(clientGuid, successCallback)),

        requestCurrentStepAndUserRole: (
            clientGuid: string,
            successCallback?: (clientProcessState: IClientProcessViewModel) => void) =>
            dispatch(SignProcessStore.requestCurrentStepAndUserRole(clientGuid, successCallback)),

        requestMyDownload: (clientId: string) =>
            dispatch(DownloadableDocumentsStore.requestMyDownload(clientId)),

        deleteMyDownloads: (downloadId: string, clientId: string) =>
            dispatch(DownloadableDocumentsStore.deleteMyDownloads(downloadId, clientId)),

        clearAllMyDownloads: (clientId: string) =>
            dispatch(DownloadableDocumentsStore.clearAllMyDownloads(clientId)),

        requestHeaderInfo: (id: string) =>
            dispatch(accountAction.requestHeaderInfo(id)),

        checkSignatureRequiredForDocument: (clientGuid: string, callback?: (status: boolean) => void) =>
            dispatch(SigningRequiredStore.checkSignatureRequiredForDocument(clientGuid, callback)),

        checkSignatureRequiredForSigner: (clientGuid: string, callback?: (status: boolean) => void) =>
            dispatch(SigningRequiredStore.checkSignatureRequiredForSigner(clientGuid, callback)),

        checkFilingFormsExists: (clientGuid: string, callback?: (status: boolean) => void) =>
            dispatch(SigningRequiredStore.checkFilingFormsExists(clientGuid, callback)),

        checkNextSignerExists: (
            clientGuid: string,
            clientType: ClientTypesNumber,
            callback?: (status: boolean) => void) =>
            dispatch(SigningRequiredStore.checkNextSignerExists(clientGuid, clientType, callback)),

        updateDelegatee: (clientId: string, delegatee: IDelegateeSignerDetails, taxyear: number,callback: () => void) =>
            dispatch(DelegateeSignerStore.updateDelegatee(clientId, delegatee,  taxyear, callback)),

        cancelDelegation: (clientId: string, remarks: string, taxYear: number, callback: () => void) =>
            dispatch(DelegateeSignerStore.cancelDelegation(clientId, remarks, taxYear, callback)),

        resetLastVisitedSteps: (clientId: string, callback: () => void) =>
            dispatch(SignProcessStore.resetLastVisitedSteps(clientId, callback)),

        requestTaxClientDelegateeDetails: (clientId: string) =>
            dispatch(DelegateeSignerStore.requestTaxClientDelegateeDetails(clientId)),

        logout: (clientGuid: string, callback: (id: any) => void) =>
            dispatch(accountAction.logout(clientGuid, callback)),

        relatedTaxReturnDelivered: (
            clientId: string) =>
            dispatch(TaxDocumentStore.relatedTaxReturnDelivered(clientId)),

        togglePaymentAlert: (
            toggle: boolean) =>
            dispatch(TaxDocumentStore.togglePaymentAlert(toggle)),

        getClientHubDomainURL: (clientGuid: string, callback: (hubDomainURL: string) => void) =>
            dispatch(HelperStore.getClientHubDomainURL(clientGuid, callback))
    
    };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(CompletedWizardFlow);
