import * as React from 'react';
import Header from "./../Header/Header";
import Skeleton from "react-loading-skeleton";
import { OtpState, OtpProps } from './EmailOTP';
import { OtpPageStoreState } from '../../../../store/Login/Models/StoreModels';

interface MobileOtpProps extends OtpProps {
    mobileOtpPageStoreState: OtpPageStoreState;
}

export class MobileOTP extends React.Component<MobileOtpProps, OtpState> {
    continueRef: any = null;
    constructor(props: any) {
        super(props);
        this.state = {
            otpVal: "",
            continueState: false
        };
    }

    componentDidMount() {
        this.props.requestHeaderDetails(this.props.match.params.clientId);
        this.props.requestClientInfo && this.props.requestClientInfo(this.props.match.params.clientId);
        this.props.pageValidity && this.props.pageValidity(this.props.match.params.clientId, this.handleError);
    }

    handleError = (message: string) => {
        this.props.history.push(`/Error/${message}`);
    }

    requestCode = () => {
        this.props.generateOtp(this.props.match.params.clientId);
    }

    verifyOTP = () => {
        if (this.state.continueState) {
            this.props.verifyOtp(this.state.otpVal, this.props.match.params.clientId, this.handleOTPVerification, this.handleError);
        }
    }

    handleOTPChange = (e: any) => {
        let otpValue = e.target.value.trim().substring(0, 6);
        if (otpValue.length <= 6 && (!isNaN(otpValue) || otpValue.length === 0)) {
            if (otpValue.length === 6) {
                this.setState({ otpVal: otpValue, continueState: true });
            }
            else {
                this.setState({ otpVal: otpValue, continueState: false });
            }
        }
    }

    handleOTPVerification = (guid: string) => {
        this.props.history.push(`${this.props.successRedirectPath}${guid}`);
    }

    render() {

        const {
            headerStoreState, match, history,
            backToHomePath,
            mobileOtpPageStoreState: { loading, mobileNo }, backToHome
        } = this.props;

        let continueClass = "btn btn-primary continue disabled float-right";
        if (this.state.continueState) {
            continueClass = "btn btn-primary continue float-right";
            this.continueRef.focus();
        }

        let lastNum: string = "";

        if (mobileNo && mobileNo.length !== 0) {
            lastNum = "{xxx}xxx-" + mobileNo.substr(6);
        }

        return (<>

            <Header headerInfo={headerStoreState} pathname={match.path} clientId={match.params.clientId} history={history} backButton={backToHome} navigateLink={backToHomePath} />
            {
                loading || headerStoreState.loading ?
                    <Skeleton circle={false} height={"100vh"} width={"100vw"} /> :
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 otp-page">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 otp-page-container">
                            <h5 data-test-auto="BAC57E4B-7806-429B-9E44-0163145A9ACC">Authentication</h5>
                            <br />
                            <strong data-test-auto="6B37FC81-36C9-4362-9E27-516FF7AC423F">Access Code Required</strong>
                            <div>
                                <div className="margin-top-10" data-test-auto="C55FD8AE-04E4-40F4-A091-9A901898F5B7">
                                    <p> Please select “Request Access Code” and we will send you a one-time expiring access code to your mobile device number:{lastNum} </p>
                                </div>


                                <div className="margin-top-10" data-test-auto="2DDA88CF-DDF5-44E1-90E9-7ABF94182032">
                                    <p> if the mobile device number is incorrect please contact your Tax Professional.</p>
                                </div>
                                <div className="margin-top-50">
                                    <button data-test-auto="724ECE16-9490-4BA3-BAC1-3B6E1631983D" type="button" className="btn btn-primary continue" id="btnRequestDelegateeAccessCode" onClick={this.requestCode}>Request Access Code</button>
                                </div>

                                <div className="otp-page-container-input-div margin-top-50" data-test-auto="211DC38A-0C44-4829-8742-09FC6C26BC10">
                                    <span>Enter access code here:</span> &nbsp;
                                    <input data-test-auto="WL2PVLYGV7Y4V6M2KCECL0WT9D" id="otp" name="otp" type="text" value={this.state.otpVal} onChange={this.handleOTPChange} style={{ margin: "0 36px 0 4px" }} autoComplete="false" />
                                    <span className="access-code-expiry-info">(This code will expire in 20 minutes)</span>
                                </div>

                                <div className="margin-top-50">
                                    <button ref={(ref) => { this.continueRef = ref }} type="submit" data-test-auto="44B7FAC9-D67F-4D5C-AAA0-5FD8212C5377" id="btnDelegateeSubmit" className={continueClass} onClick={this.verifyOTP} >Continue</button>
                                </div>
                            </div>
                        </div>
                    </div>
            }

        </>
        );
    }

}
export default MobileOTP;
