import { Action, Reducer } from 'redux';
import { AppThunkAction } from './';
import { actionTypes } from './ActionTypes';
import { StatusType, NotificationAction } from './Common/NotificationStore';
import { AxiosResponse } from 'axios';
import { ErrorMessages } from '../components/Common/Constants';
import { TelemetryLogger } from '../components/Logger/AppInsights';
import { IDocumentSettings } from 'src/core/domain/models/IDocumentSettings';
import { initializeAxios } from 'src/core/services/dataAccess/DataService.Axios';

const logger = TelemetryLogger.getInstance();

interface RequestPreparerMessageAction {
    type: actionTypes.DOCUMENT_SETTINGS_REQUEST;
}

interface ResponsePreparerMessageAction {
    type: actionTypes.DOCUMENT_SETTINGS_RESPONSE;
    data: IDocumentSettings;
}
interface FailurePreparerMessageAction {
    type: actionTypes.DOCUMENT_SETTINGS_FAILURE;
}

type KnownAction =
    DispatchAction |
    NotificationAction;

type DispatchAction = ResponsePreparerMessageAction
    | RequestPreparerMessageAction
    | FailurePreparerMessageAction;

export const actionCreators = {

    requestDocumentSettings: (clientId: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: actionTypes.DOCUMENT_SETTINGS_REQUEST });
        return initializeAxios().get<IDocumentSettings>('/api/PreparerMessage/GetDocumentSettings/' + clientId)
            .then(function (response: AxiosResponse<IDocumentSettings>) {

                dispatch({
                    type: actionTypes.DOCUMENT_SETTINGS_RESPONSE, data: response.data
                });

            })
            .catch(function (error: any) {
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: error.response ? error.response.statusText : ErrorMessages.PreparerDocumentSettingError,
                    statusType: StatusType.Error
                });
                dispatch({ type: actionTypes.DOCUMENT_SETTINGS_FAILURE });
                logger.trackError(`requestDocumentSettings failed with error ${error.message} for client: ${clientId}`);
            });
    }
}

const initialDocumentSettings = {
    documentId: 0,
    deliverySettings: {
        preparerMessage: {
            id: 0,
            name: "",
            body: "",
            isAllowToEdit: false
        }
    },
    documentDisplaySetting: {},
    documentSignatureSetting: {},
    documentNotificationSetting: {},
    documentRetentionSetting: {}
} as IDocumentSettings

export const reducer: Reducer<IDocumentSettings> = (state: IDocumentSettings = initialDocumentSettings, incomingAction: Action) => {
    const action = incomingAction as DispatchAction;
    var data = Object.assign({}, state);
    switch (action.type) {
        case actionTypes.DOCUMENT_SETTINGS_REQUEST:
            return initialDocumentSettings;
        case actionTypes.DOCUMENT_SETTINGS_RESPONSE:
            let receivedDocumentSettings: IDocumentSettings = { ...initialDocumentSettings }
            receivedDocumentSettings.documentId = action.data.documentId;
            receivedDocumentSettings.deliverySettings = action.data.deliverySettings;
            receivedDocumentSettings.documentDisplaySetting = action.data.documentDisplaySetting;
            receivedDocumentSettings.documentSignatureSetting = action.data.documentSignatureSetting;
            receivedDocumentSettings.documentNotificationSetting = action.data.documentNotificationSetting;
            receivedDocumentSettings.documentRetentionSetting = action.data.documentRetentionSetting;
            return receivedDocumentSettings;
        default:
            return state || initialDocumentSettings;
    }
};