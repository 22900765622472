import { IDocumentSettings } from 'src/core/domain/models/IDocumentSettings';
import { ITaxReturn } from 'src/core/domain/models/ITaxReturn';
import { ICommonDataViewModel } from 'src/core/domain/viewModels/ICommonDataViewModel';
import { IPayScreenViewModel } from 'src/core/domain/viewModels/IPayScreenViewModel';
import { History } from 'history';
import * as React from 'react';
import { match } from 'react-router';
import { IPaymentVoucherNotificationSettings } from '../../../core/domain/models/IDocumentSettings';
import { IVoucher } from '../../../core/domain/models/IGroup';
import { ILoader } from '../../../core/utilities/ui/Loader';
import { container } from '../../../startup/inversify.config';
import { TYPES } from '../../../startup/types';
import { LeftPanel } from '../../Common/StepLayout/LeftPanel';
import { TaxPayment } from '../TaxPayment';
import { VoucherDetails } from '../VoucherDetails';


export interface PayProps {
    commonData: ICommonDataViewModel,
    match: match,
    history: History,
    payScreenData: IPayScreenViewModel,
    documentSettings: IDocumentSettings,
    requestPayScreenDetails: (clientId: string) => any;
    downloadAllVouchers: (clientId: string) => void;
    downloadFilingInstructions: (clientId: string) => void;
    downloadSingleVoucher: (clientId: string, fileName: string, pageNo: string, bookMarks: string) => void;
    prevStep?: () => void;
    nextStep?: (updated: boolean) => void;
    taxReturn: ITaxReturn,
    updateVoucherNotificationSettings?: (clientId: string, setting: IPaymentVoucherNotificationSettings, callback: () => void) => any;
    saveVoucherPayment: (voucher: IVoucher) => void;
    isPreviewMode: boolean;
}

interface PayState {
    groupedVouchers: { duedate: string, vouchers: IVoucher[] }[],
    selectedTab: string;
    selectedPaymentType: string;
    initialPaymentVoucherModel: IPaymentVoucherNotificationSettings;
}

const loader = container.get<ILoader>(TYPES.ILoader);

export class Pay extends React.Component<PayProps, PayState> {
    private clientId: string = "";
    private VoucherReminderDays: number = 0;
    private IsVoucherRemainderEnable: boolean = false;
    constructor(props: PayProps) {
        super(props);
        this.state = {
            groupedVouchers: [],
            selectedTab: "",
            selectedPaymentType: "",
            initialPaymentVoucherModel: { enablePaymentVoucherReminder: false, noOfDaysForVoucherReminder: 0, disabledVoucherReminderFromTP: false },
        }
    }

    componentDidMount() {
        let param: any = this.props.match.params;
        if (param.clientId && this.props.payScreenData.documentId === 0) {
            this.props.requestPayScreenDetails(param.clientId);
        }
        this.clientId = param.clientId;
        const { enablePaymentVoucherReminder, noOfDaysForVoucherReminder } = this.props.documentSettings?.documentNotificationSetting?.paymentVoucherNotificationSettingsModel;
        this.VoucherReminderDays = noOfDaysForVoucherReminder;
        this.IsVoucherRemainderEnable = enablePaymentVoucherReminder;
        this.setState({
            initialPaymentVoucherModel: this.props.documentSettings?.documentNotificationSetting?.paymentVoucherNotificationSettingsModel
        });
    }

    componentWillReceiveProps(nextProps: PayProps) {
        if (nextProps && nextProps.documentSettings) {
            let reminderSettings = nextProps.documentSettings.documentNotificationSetting.paymentVoucherNotificationSettingsModel;
            if (reminderSettings && (reminderSettings.enablePaymentVoucherReminder !== this.state.initialPaymentVoucherModel.enablePaymentVoucherReminder ||
                reminderSettings.noOfDaysForVoucherReminder !== this.state.initialPaymentVoucherModel.noOfDaysForVoucherReminder)) {
                this.setState({
                    initialPaymentVoucherModel: nextProps.documentSettings.documentNotificationSetting.paymentVoucherNotificationSettingsModel
                })
            }
        }
    }

    handleDueDateSelection = (selectedDate: string, selectedPaymentType: string) => {

        this.setState({ selectedTab: selectedDate, selectedPaymentType: selectedPaymentType });
    }

    updateVoucherReminderDetails = (callback?: () => void) => {
        let updated = false;
        const initialValue = this.state.initialPaymentVoucherModel
        let paymentVoucherDetails: IPaymentVoucherNotificationSettings = { ...initialValue };
        if (initialValue && initialValue.enablePaymentVoucherReminder !== this.IsVoucherRemainderEnable) {
            updated = true;
            paymentVoucherDetails.enablePaymentVoucherReminder = this.IsVoucherRemainderEnable;
            if (initialValue.enablePaymentVoucherReminder && !this.IsVoucherRemainderEnable) {
                paymentVoucherDetails.disabledVoucherReminderFromTP = true;
            }
        }
        if (this.IsVoucherRemainderEnable === true && this.state.initialPaymentVoucherModel.noOfDaysForVoucherReminder !== this.VoucherReminderDays) {
            updated = true;
            paymentVoucherDetails.noOfDaysForVoucherReminder = this.VoucherReminderDays;
        }
        if (updated) {
            loader.show();
            this.props.updateVoucherNotificationSettings && this.props.updateVoucherNotificationSettings(this.clientId, paymentVoucherDetails, () => {
                callback ? this.completePayStep(updated, callback) : this.completePayStep(updated);
                loader.hide();
            });
        }
        else {
            callback ? this.completePayStep(updated, callback) : this.completePayStep(updated);
        }

    }

    completePayStep = (updated: boolean, callback?: () => void) => {
        if (callback) {
            callback();
        }
        else {
            this.props.nextStep && this.props.nextStep(updated);
        }
    }

    HandleVoucherReminderEnable = (value: boolean) => {
        this.IsVoucherRemainderEnable = value;
    }
    HandleVoucherReminderDays = (value: number) => {
        this.VoucherReminderDays = value;
    }

    public render() {
        const params: any = this.props.match.params;

        return (<React.Fragment>
            <LeftPanel lg={3} md={5} sm={5} xs={5}>
                <TaxPayment
                    payScreenData={this.props.payScreenData}
                    documentDisplaySetting={this.props.documentSettings.documentDisplaySetting}
                    handleDueDateSelection={this.handleDueDateSelection}
                    taxReturn={this.props.taxReturn} />
            </LeftPanel>

            <div className="col-lg-9 col-md-7 col-sm-7 col-xs-7 pay-content-right">
                <VoucherDetails
                    payScreenData={this.props.payScreenData}
                    documentNotificationSetting={this.props.documentSettings.documentNotificationSetting}
                    taxingAuthorities={this.props.commonData.taxingAuthorities}
                    selectedDate={this.state.selectedTab}
                    selectedPaymentType={this.state.selectedPaymentType}
                    taxReturn={this.props.taxReturn}
                    clientId={params.clientId}
                    downloadAllVouchers={this.props.downloadAllVouchers}
                    downloadFilingInstructions={this.props.downloadFilingInstructions}
                    downloadSingleVoucher={this.props.downloadSingleVoucher}
                    HandleVoucherReminderEnable={this.HandleVoucherReminderEnable}
                    HandleVoucherReminderDays={this.HandleVoucherReminderDays}
                    saveVoucherPayment={this.props.saveVoucherPayment}
                    isPreviewMode={this.props.isPreviewMode}
                />
            </div>
        </React.Fragment>);

    }
}