import { AxiosResponse } from 'axios';
import { Action, Reducer } from 'redux';
import { actionTypes } from '../ActionTypes';
import { NotificationAction, StatusType } from '../Common/NotificationStore';
import { AppThunkAction } from '../index';
import { SignProcessSteps } from '../../components/Home/TaxpayerSignFlowManager';
import { IClientProcessViewModel, initialClientProcessModel } from '../../core/domain/viewModels/IClientProcessViewModel'
import { ErrorMessages } from '../../components/Common/Constants';
import { TelemetryLogger } from '../../components/Logger/AppInsights';
import { initializeAxios } from 'src/core/services/dataAccess/DataService.Axios';
import { Role } from 'src/core/common/Enums';

const logger = TelemetryLogger.getInstance();

interface RequestSignProcessAction {
    type: actionTypes.SIGN_PROCESS_REQUEST;
}
interface RequestCurrentStepAction {
    type: actionTypes.SIGN_PROCESS_STEP;
    data: IClientProcessViewModel;
}

interface ResponseSignProcessAction {
    type: actionTypes.SIGN_PROCESS_RESPONSE;
    data: number;
}

interface FailureSignProcessAction {
    type: actionTypes.SIGN_PROCESS_FAILURE;
    data: number;
}

interface UpdateLastVisitedStep {
    type: actionTypes.UPDATE_LAST_VISITED_PROCESS_STEP;
    data: SignProcessSteps;
}

export interface SignProcessState {
    data: number;
    clientprocessmodel: IClientProcessViewModel;
}

export const initialSignProcessState: SignProcessState = {
    data: 0,
    clientprocessmodel: initialClientProcessModel,
};

type KnownAction =
    DispatchAction |
    NotificationAction;

type DispatchAction =
    ResponseSignProcessAction
    | RequestSignProcessAction
    | FailureSignProcessAction
    | RequestCurrentStepAction
    | UpdateLastVisitedStep;

export const actionCreators = {
    requestCurrentStepAndUserRole: (
        clientGuid: string,
        successCallback?: (clientProcessState: IClientProcessViewModel) => void):
        AppThunkAction<KnownAction> => (dispatch, getState) => {
            const state = getState();

            return initializeAxios().get<IClientProcessViewModel>(`/api/SignProcess/GetCurrentStepAndRole/${clientGuid}`)
                .then((response: AxiosResponse<IClientProcessViewModel>) => {
                    dispatch({
                        type: actionTypes.SIGN_PROCESS_STEP, data: response.data,
                    });
                    dispatch({ type: actionTypes.SIGN_PROCESS_STEP, data: response.data });
                    if(response.data.currentstep.visitedSteps === null && (response.data.role.toString() != Role[Role.CPA]))
                        dispatch(actionCreators.updateLastVisitedStep(clientGuid, null));
                    if (successCallback) {
                        successCallback(response.data);
                    }
                })
                .catch((error: any) => {
                    dispatch({
                        type: actionTypes.NOTIFICATION,
                        statusMessage: error.response ?
                            error.response.statusText :
                            ErrorMessages.GetCurrentStepAndRoleError,
                        statusType: StatusType.Error,
                    });
                    dispatch({
                        type: actionTypes.SIGN_PROCESS_FAILURE,
                        data: state.signProcessData.data,
                    });
                    logger.trackError(`requestCurrentStepAndUserRole failed with error ${error.message} for client: ${clientGuid}`);
                });
        },
    requestLastVisitedStep: (
        clientGuid: string,
        successCallback?: (lastVisitedStep: SignProcessSteps) => void):
        AppThunkAction<KnownAction> => (dispatch, getState) => {
            const state = getState();

            return initializeAxios().get<number>(`/api/SignProcess/GetLastVisitedStep/${clientGuid}`)
                .then((response: AxiosResponse<number>) => {
                    dispatch({
                        type: actionTypes.SIGN_PROCESS_RESPONSE, data: response.data
                    });
                    dispatch({ type: actionTypes.SIGN_PROCESS_RESPONSE, data: response.data });
                    if (successCallback) {
                        successCallback(response.data);
                    }
                })
                .catch((error: any) => {
                    dispatch({
                        type: actionTypes.NOTIFICATION,
                        statusMessage: error.response ?
                            error.response.statusText :
                            ErrorMessages.GetLastVisitedStepError,
                        statusType: StatusType.Error,
                    });
                    dispatch({ type: actionTypes.SIGN_PROCESS_FAILURE, data: state.signProcessData.data });
                    logger.trackError(`requestLastVisitedStep failed with error ${error.message} for client: ${clientGuid}`);
                });
        },

    updateLastVisitedStep: (
        clientGuid: string,
        step: SignProcessSteps,
        successCallback?: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {

            const state = getState();

            if (state.signProcessData.data == step) {
                if (successCallback) {
                    successCallback();
                }
                return;
            }

            const url = `/api/SignProcess/AddOrUpdateProcessInfoAsync/${clientGuid}?step=${step}`;

            state.signProcessData.data = step;

            return initializeAxios().post(url)
                .then(() => {
                    dispatch({ type: actionTypes.UPDATE_LAST_VISITED_PROCESS_STEP, data: step });
                    if (successCallback) {
                        successCallback();
                    }
                })
                .catch((error: any) => {
                    if (error.response && error.response.statusText) {
                        dispatch({
                            statusMessage: error.response.statusText,
                            statusType: StatusType.Error,
                            type: actionTypes.NOTIFICATION,
                        });
                        logger.trackError(`updateLastVisitedStep failed with error ${error.message} for client: ${clientGuid}`);
                    }
                });
        },

    requestDelegateeCurrentStepAndUserRole: (
        clientGuid: string,
        successCallback?: (clientProcessState: IClientProcessViewModel) => void):
        AppThunkAction<KnownAction> => (dispatch, getState) => {

            const state = getState();

            return initializeAxios().get<IClientProcessViewModel>('/api/SignProcess/GetDelegateeCurrentStepAndRole/' + clientGuid)
                .then(function (response: AxiosResponse<IClientProcessViewModel>) {
                    dispatch({
                        type: actionTypes.SIGN_PROCESS_STEP, data: response.data
                    });
                    dispatch({ type: actionTypes.SIGN_PROCESS_STEP, data: response.data });
                    if(response.data.currentstep.visitedSteps === null && (response.data.role.toString() != Role[Role.CPA]))
                        dispatch(actionCreators.updateLastVisitedStep(clientGuid, null));
                    successCallback && successCallback(response.data);
                })
                .catch(function (error: any) {
                    dispatch({
                        type: actionTypes.NOTIFICATION,
                        statusMessage: error.response ?
                            error.response.statusText :
                            ErrorMessages.GetCurrentStepAndRoleError,
                        statusType: StatusType.Error,
                    });
                    dispatch({ type: actionTypes.SIGN_PROCESS_FAILURE, data: state.signProcessData.data });
                });
        },

    resetLastVisitedSteps: (clientId: string, callback: () => void):
        AppThunkAction<KnownAction> => (dispatch, getState) => {

            const state = getState();
            return initializeAxios().get<IClientProcessViewModel>('/api/SignProcess/DeleteLastVisitedSteps/' + clientId)
                .then(function (response: AxiosResponse<any>) {
                    callback();
                })
                .catch(function (error: any) {
                    dispatch({
                        type: actionTypes.NOTIFICATION,
                        statusMessage: error.response ?
                            error.response.statusText :
                            ErrorMessages.GetCurrentStepAndRoleError,
                        statusType: StatusType.Error
                    });
                    dispatch({ type: actionTypes.SIGN_PROCESS_FAILURE, data: state.signProcessData.data });
                });
        },

    resetTaxClientLastVisitedSteps: (
        clientId: string,
        callback: () => void):
        AppThunkAction<KnownAction> => (dispatch, getState) => {

            const state = getState();
            return initializeAxios().get<IClientProcessViewModel>('/api/SignProcess/DeleteDelegatedSignerLastVisitedSteps/' + clientId)
                .then(function (response: AxiosResponse<any>) {
                    callback();
                })
                .catch(function (error: any) {
                    dispatch({
                        type: actionTypes.NOTIFICATION,
                        statusMessage: error.response ?
                            error.response.statusText :
                            ErrorMessages.GetCurrentStepAndRoleError,
                        statusType: StatusType.Error,
                    });
                    dispatch({ type: actionTypes.SIGN_PROCESS_FAILURE, data: state.signProcessData.data });
                });
        },

};

export const reducer: Reducer<SignProcessState> =
    (state: SignProcessState = initialSignProcessState, incomingAction: Action) => {
        const action = incomingAction as DispatchAction;
        const currentState = Object.assign({}, state);
        switch (action.type) {
            case actionTypes.SIGN_PROCESS_REQUEST:
                currentState.data = 0;
                return currentState;
            case actionTypes.SIGN_PROCESS_RESPONSE:
                currentState.data = action.data;
                return currentState;
            case actionTypes.SIGN_PROCESS_FAILURE:
                currentState.data = action.data;
                return currentState;
            case actionTypes.SIGN_PROCESS_STEP:
                currentState.clientprocessmodel = action.data;
                return currentState;
            case actionTypes.UPDATE_LAST_VISITED_PROCESS_STEP:

                if (currentState.clientprocessmodel.currentstep.visitedSteps === null) {
                    currentState.clientprocessmodel.currentstep.visitedSteps = [];
                }

                currentState.clientprocessmodel.currentstep.visitedSteps.push(action.data);
                return currentState;
            default:
                return currentState || initialSignProcessState;
        }
    };
