import * as React from 'react';
import { MarsNotifier } from '../Common/Notification/MarsNotifier';
import { match, RouteComponentProps } from 'react-router';
import { History } from 'history';
import { ICcRecipientDownloadModel, CCRecipientDocumentGroups } from '../../core/domain/models/ICCRecipientModel';
import * as CcRecipientStore from '../../store/CCRecipientStore';
import { Shimmer } from '../Common/Shimmer/Shimmer';
import { logger } from '../../routes';
import { LogEventConstants } from '../Helper/Constants';

export type CcRecipientDownloadTaxdocumentProps = {
    match: match;
    history: History;
    ccDocumentsForDownload: ICcRecipientDownloadModel[];
}
    & typeof CcRecipientStore.actionCreators
    & RouteComponentProps<{}>;


const pageTitle = "Cc Recipient Download Taxdocument";   
export class CcRecipientDownloadTaxdocument extends React.Component<CcRecipientDownloadTaxdocumentProps, {}>{
    private _params: any;
    constructor(props: any) {
        super(props);
    }

    componentDidMount() {
        let param: any = this.props.match.params;
        this._params = this.props.match.params;
        this.props.requestCCRecipientDownloadableDocumentsForDownload(param.clientId);
        let screenData: any = {};
        history.pushState(screenData, window.document.title, window.location.href);
        window.onpopstate = function (event:any) {
            history.go(1);
        };
        logger.trackEvent(
            logger.buildEvent(
                `${LogEventConstants.Common.Open} ${pageTitle}`,
                { count: 1, page: pageTitle, clientId: param.clientId }
            )
        );

    }

    downloadSelectedDocument = (clientId: string, documentId: string, taxYear: number,
        fileName: string, groupId: CCRecipientDocumentGroups) => {
        
        logger.trackEvent(
            logger.buildEvent(
                `${LogEventConstants.Common.CcRecipients.SelectiveDownload} ${pageTitle}`,
                { count: 1, page: pageTitle, clientId: clientId,  documentId: documentId, groupId: CCRecipientDocumentGroups }
            )
        );
        if (groupId == CCRecipientDocumentGroups.AdditionalEsignDocument) {
            this.props.downloadAllAdditionEsignDocuments(clientId, documentId, taxYear, groupId);
            return;
        }
        this.props.downloadTaxDocument(clientId, documentId, taxYear, fileName, groupId);
        
    }

    downloadAllDocuments = () => {
        logger.trackEvent(
            logger.buildEvent(
                `${LogEventConstants.Common.CcRecipients.DownloadAll} ${pageTitle}`,
                { count: 1, page: pageTitle, clientId: this._params.clientId }
            )
        );
        this.props.downloadAll(this._params.clientId, this.props.ccDocumentsForDownload);
    }

    public render() {
        let fileName: string = "";
        return (
            this.props.ccDocumentsForDownload.length > 0 ?
                <div id="ccdownload_main" className="col-sm-12 col-md-8 col-md-offset-2 col-lg-6 col-lg-offset-3" data-test-auto="0F9270EF-837B-429C-BBB5-0C4689BD46D2">
                    <div className="ccdownload-modal">
                        <div className="ccdownload-content">
                            <ul id="UlDownloadList"></ul>
                            {this.props.ccDocumentsForDownload.map((taxDocument, index) => {
                                fileName = (taxDocument.documentGroup == CCRecipientDocumentGroups.SignedEFile
                                    || taxDocument.documentGroup == CCRecipientDocumentGroups.AdditionalEsignDocument
                                    || taxDocument.documentGroup == CCRecipientDocumentGroups.Transmittals) ?
                                    taxDocument.fileName.replace(".pdf", "") : taxDocument.fileName;
                                return <li key={taxDocument.documentGuid + index} data-test-auto={"75378BA6-7D53-4757-85DB-54EF9B2A18D" + index}
                                    className="ccdownload-list" onClick={() => {
                                        this.downloadSelectedDocument(this._params.clientId,
                                            taxDocument.documentGuid, taxDocument.taxYear, taxDocument.fileName, taxDocument.documentGroup)
                                    }}>
                                    {
                                        taxDocument.documentGroup == CCRecipientDocumentGroups.SignedEFile ?
                                            fileName.startsWith("Signed") ? taxDocument.fileName : "Filing Forms"
                                            : taxDocument.documentGroup == CCRecipientDocumentGroups.Transmittals &&
                                                fileName.startsWith("Extension Transmittals") ? "Filing Instructions" : fileName}
                                    </li>
                            })
                            }
                        </div>
                        <div className="download-foo-btn">
                            <button type="submit" className="btn btn-primary" onClick={this.downloadAllDocuments}
                                data-test-auto="2AF9D2D4-F3A3-431B-A80B-A90B604B0D9C">Download All Files</button>
                        </div>
                    </div>
                </div> : <Shimmer lineCount={30} />
        )
    }

}