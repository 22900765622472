import * as React from 'react';
import { match } from 'react-router';
import { DocumentSignatureDataViewModel } from '../../../core/domain/viewModels/IDocumentSignatureDataViewModel';
import { PdfView } from './PdfView';
import { IDocument } from '../../../core/domain/models/esign/Document';
import { EsignManager, IEsignValidationModel } from './EsignBase';
import { MarsNotifier } from '../../Common/Notification/MarsNotifier';
import { Main } from '../Main/Main';
import { History } from 'history';
import { ViewerMode } from 'awesome-pdf-viewer/dist/viewer/ViewerBase';
import { IHeaderInfoViewModel } from '../../../core/domain/viewModels/IHeaderInfoViewModel';
import { EngagementTypeS } from '../../../core/common/Enums';
import { isIndividualEngagementType } from '../../Helper/HelperFunction';
import { logger } from '../../../routes';
import { LogEventConstants } from '../../Helper/Constants';

export const NO_INDEX = -1;

interface EsignState {
    documentControlsAndData: DocumentSignatureDataViewModel[];
    showTimeout: boolean;
    finishEnabled: boolean;
    showSubmitLoader: boolean;
}

export interface ESignProps {
    requestSignatureControls: (clientGuid: string) => void;
    match: match;
    history: History;
    signatureControlsData: IDocument[];
    onSigningComplete: (signData: IDocument[]) => any;
    disableNextButton: (value: boolean) => void;
    completeSigningStep: () => void;
    isSpouseSignRequired: boolean;
    sign?: (clientId: string, documentData: IDocument[], callback: (status: boolean) => void) => void;
    onHeaderAndFooterVisibility: (visibility: boolean) => void;
    isPreviewMode: boolean;
    headerInfo: IHeaderInfoViewModel;
    engagementType: EngagementTypeS;
}

const pageTitle = "Esign PDF view";

export class Esign extends React.Component<ESignProps, EsignState> {

    private _pdfView: any;

    constructor(props: any, states: EsignState) {
        super(props, states);
        this.state = {
            documentControlsAndData: [],
            showTimeout: false,
            finishEnabled: false,
            showSubmitLoader: false
        }
        this.props.disableNextButton(!this.props.isPreviewMode);
    }

    componentDidMount() {
        let param: any = this.props.match.params;
        if (this.props.signatureControlsData.length == 0) {
            this.props.requestSignatureControls(param.clientId);
        }
        logger.trackEvent(
            logger.buildEvent(
                `${LogEventConstants.Common.Open}  ${pageTitle}`,
                { count: 1, page: pageTitle, clientId: param.clientId }
            )
        );
    }

    static getDerivedStateFromProps(nextProps: ESignProps, nextState: EsignState) {
        if (nextProps.signatureControlsData &&
            nextProps.signatureControlsData.length !== nextState.documentControlsAndData.length) {
            return { documentControlsAndData: nextProps.signatureControlsData }
        }
        else {
            return { documentControlsAndData: nextState.documentControlsAndData };
        }
    }

    handleDocumentSignFinish = (documentControlData: DocumentSignatureDataViewModel) => {
        let documentDataList: DocumentSignatureDataViewModel[] = [...this.state.documentControlsAndData];

        const index = documentDataList.findIndex(x => x.id === documentControlData.id);
        if (index == NO_INDEX) {
            documentDataList.push(documentControlData);
        }
        else {
            documentDataList[index] = documentControlData;
        }
        this.setState({ documentControlsAndData: documentDataList });
        let param: any = this.props.match.params;
        logger.trackEvent(
            logger.buildEvent(
                `${LogEventConstants.Common.Esign.DocumentSignFinished}  ${pageTitle}`,
                { count: 1, page: pageTitle, clientId: param.clientId }
            )
        );
    }

    handleSigningCompleted = () => {

        const documentData: IDocument[] = this._pdfView.getDocumentsData();
        const validation: IEsignValidationModel = EsignManager.validateEsignData(documentData);

        if (validation.status) {
            this.props.onSigningComplete(documentData);
            this.props.disableNextButton(false);

        }
        else {
            this._pdfView.showValidationMessage(validation.document, validation.page, validation.control);
        }
    }

    onNext() {
        logger.trackTrace(`isSpouseSignRequired : ${this.props.isSpouseSignRequired} `);
        if (this.props.isSpouseSignRequired) {
            this.props.completeSigningStep();
        }
        else {
            this.setState({ showSubmitLoader: true }, () => {
                this.props.disableNextButton(true);
                let param: any = this.props.match.params;
                logger.trackTrace(`IsPreviewMode : ${this.props.isPreviewMode}`);
                if (this.props.isPreviewMode) {
                    this.props.completeSigningStep();
                }
                else {
                    this.props.sign && this.props.sign(param.clientId, this.props.signatureControlsData, (status: boolean) => {
                        if (status) {
                            logger.trackTrace(`gotoNextStep : Signing Completed since API response : ${status}`);
                            this.props.completeSigningStep();
                        }
                        else {
                            this.setState({ showSubmitLoader: false }, () => { MarsNotifier.Error("Signing process failed!", ""); });
                        }
                    });
                }
            });
        }
    }

    handleViewModeChange = (viewerMode: ViewerMode) => {

        if (viewerMode == ViewerMode.Read) {
            this.props.onHeaderAndFooterVisibility(true);
        }
        else {
            this.props.onHeaderAndFooterVisibility(false);
        }

        let param: any = this.props.match.params;
        logger.trackEvent(
            logger.buildEvent(
                `${LogEventConstants.Common.Esign.ViewModeChanged} ${viewerMode}  ${pageTitle}`,
                { count: 1, page: pageTitle, mode: viewerMode, clientId: param.clientId }
            )
        );
    }

    public render() {
        return (
            <Main>
                <PdfView
                    onViewModeChange={this.handleViewModeChange}
                    documents={this.props.signatureControlsData}
                    onSigningCompleted={this.handleSigningCompleted}
                    headerInfo={this.props.headerInfo}
                    ref={(ref: PdfView) => this._pdfView = ref}
                    doNotAllowToEditSignatureName={isIndividualEngagementType(this.props.engagementType)} />
            </Main>
        );
    }
}
