import { AxiosResponse } from 'axios';
import { Action, Reducer } from 'redux';
import { actionTypes } from '../ActionTypes';
import { NotificationAction, StatusType } from '../Common/NotificationStore';
import { AppThunkAction } from '../index';
import { ErrorMessages } from "../../components/Common/Constants";
import { ClientTypesNumber } from '../../core/domain/models/ISignerModel';
import { TelemetryLogger } from '../../components/Logger/AppInsights';
import { initializeAxios } from 'src/core/services/dataAccess/DataService.Axios';

const logger = TelemetryLogger.getInstance();

interface SigningRequiredForSignerAction {
    type: actionTypes.SIGNING_REQUIRED_FOR_SIGNER;
    data: boolean;
}

interface SigningRequiredForDocumentAction {
    type: actionTypes.SIGNING_REQUIRED_FOR_DOCUMENT;
    data: boolean;
}

interface FilingFormsExistsAction {
    type: actionTypes.FILING_FORMS_EXISTS;
    data: boolean;
}

interface NextSignerExistsAction {
    type: actionTypes.NEXT_SIGNER_EXISTS;
    data: boolean;
}

export interface SigningRequiredStoreState {
    signingRequiredForSigner: boolean;
    signingRequiredForDocument: boolean;
    filingFormsExists: boolean;
    nextSignerExists: boolean;
}

export const intialSigningRequiredStoreState: SigningRequiredStoreState = {
    filingFormsExists: false,
    nextSignerExists: false,
    signingRequiredForDocument: false,
    signingRequiredForSigner: false,
};

type KnownAction =
    DispatchAction |
    NotificationAction;

type DispatchAction =
    SigningRequiredForDocumentAction
    | SigningRequiredForSignerAction
    | FilingFormsExistsAction
    | NextSignerExistsAction;

export const actionCreators = {

    checkSignatureRequiredForSigner: (
        clientGuid: string,
        successCallback?: (isSignatureRequired: boolean) => void): AppThunkAction<KnownAction> => (dispatch) => {

            return initializeAxios().get<boolean>(`/api/SignProcess/CheckSignatureRequiredForSigner/${clientGuid}`)
                .then((response: AxiosResponse<boolean>) => {

                    dispatch({
                        data: response.data,
                        type: actionTypes.SIGNING_REQUIRED_FOR_SIGNER,
                    });

                    if (successCallback) {
                        successCallback(response.data);
                    }
                })
                .catch((error: any) => {
                    dispatch({
                        statusMessage: error.response ?
                            error.response.statusText :
                            ErrorMessages.GetIsSignatureRequiredForSigner,
                        statusType: StatusType.Error,
                        type: actionTypes.NOTIFICATION,
                    });
                    logger.trackError(`checkSignatureRequiredForSigner failed with error ${error.message} for client: ${clientGuid}`);
                });
        },

    checkSignatureRequiredForDocument: (
        clientGuid: string,
        successCallback?: (isSignatureRequired: boolean) => void): AppThunkAction<KnownAction> => (dispatch) => {

            return initializeAxios().get<boolean>(`/api/SignProcess/CheckSignatureRequiredForDocument/${clientGuid}`)
                .then((response: AxiosResponse<boolean>) => {

                    dispatch({
                        data: response.data,
                        type: actionTypes.SIGNING_REQUIRED_FOR_DOCUMENT,
                    });

                    if (successCallback) {
                        successCallback(response.data);
                    }
                })
                .catch((error: any) => {
                    dispatch({
                        statusMessage: error.response ?
                            error.response.statusText :
                            ErrorMessages.GetIsSignatureRequiredForDocument,
                        statusType: StatusType.Error,
                        type: actionTypes.NOTIFICATION,
                    });
                    logger.trackError(`checkSignatureRequiredForDocument failed with error ${error.message} for client: ${clientGuid}`);
                });
        },

    checkFilingFormsExists: (
        clientGuid: string,
        successCallback?: (isSignatureRequired: boolean) => void): AppThunkAction<KnownAction> => (dispatch) => {

            return initializeAxios().get<boolean>(`/api/SignProcess/CheckFilingFormsExists/${clientGuid}`)
                .then((response: AxiosResponse<boolean>) => {

                    dispatch({
                        data: response.data,
                        type: actionTypes.FILING_FORMS_EXISTS,
                    });

                    if (successCallback) {
                        successCallback(response.data);
                    }
                })
                .catch((error: any) => {
                    dispatch({
                        statusMessage: error.response ?
                            error.response.statusText :
                            ErrorMessages.GetIsSignatureRequiredForDocument,
                        statusType: StatusType.Error,
                        type: actionTypes.NOTIFICATION,
                    });
                    logger.trackError(`checkFilingFormsExists failed with error ${error.message} for client: ${clientGuid}`);
                });
        },

    checkNextSignerExists: (
        clientGuid: string,
        clientType: ClientTypesNumber,
        successCallback?: (isSignatureRequired: boolean) => void): AppThunkAction<KnownAction> => (dispatch) => {

            return initializeAxios().post<boolean>(`/api/SignProcess/CheckSignatureRequiredForNextSigner/${clientGuid}?clientType=${clientType}`)
            .then((response: AxiosResponse<boolean>) => {

                dispatch({
                    data: response.data,
                    type: actionTypes.NEXT_SIGNER_EXISTS,
                });

                if (successCallback) {
                    successCallback(response.data);
                }
            })
            .catch((error: any) => {
                dispatch({
                    statusMessage: error.response ?
                        error.response.statusText :
                        ErrorMessages.GetIsSignatureRequiredForSigner,
                    statusType: StatusType.Error,
                    type: actionTypes.NOTIFICATION,
                });
                logger.trackError(`checkNextSignerExists failed with error ${error.message} for client: ${clientGuid}`);
            });
    },
};

export const reducer: Reducer<SigningRequiredStoreState> =
    (state: SigningRequiredStoreState = intialSigningRequiredStoreState, incomingAction: Action) => {
        const action = incomingAction as DispatchAction;
        const currentState = Object.assign({}, state);
        switch (action.type) {
            case actionTypes.SIGNING_REQUIRED_FOR_DOCUMENT:
                currentState.signingRequiredForDocument = action.data;
                return currentState;
            case actionTypes.SIGNING_REQUIRED_FOR_SIGNER:
                currentState.signingRequiredForSigner = action.data;
                return currentState;
            case actionTypes.FILING_FORMS_EXISTS:
                currentState.filingFormsExists = action.data;
                return currentState;
            case actionTypes.NEXT_SIGNER_EXISTS:
                currentState.nextSignerExists = action.data;
                return currentState;
            default:
                return currentState || intialSigningRequiredStoreState;
        }
    };
