import { injectable } from "inversify";

export interface IFileUtilities {
    getExtension(fileName: string): string;
    isValidateSize(file: any, maxSize: number): boolean;
    isValidateExtension(file: any, expectedExtensions: string[]): boolean;
    download(url: string, fileName?: string): void;
    getSafeFilename(fileName: string): string;
}

@injectable()
export class FileUtilities implements IFileUtilities {
    
    getExtension(fileName: string): string {
        return fileName.slice((fileName.lastIndexOf(".") - 1 >>> 0) + 2);
    }

    isValidateSize(file: any, maxSize: number): boolean {
        return file.size <= maxSize;
    }

    isValidateExtension(file: any, expectedExtensions: string[]): boolean {        
        const fileExtension: string = this.getExtension(file);
        return expectedExtensions.indexOf(fileExtension.toLowerCase())!==-1;
    }
    getSafeFilename(fileName: string): string {
        var pattern = new RegExp(/[^a-z A-Z 0-9. _]+/g);
        return fileName.replace(pattern, '_');
    }

    download(url: string, fileName?: string): void {
        let link: HTMLAnchorElement = document.createElement('a');
        link.href = url;
        link.target = "_blank";
        if (fileName) {
            link.download = fileName;
        }
        document.body.appendChild(link);
        link.click();
        setTimeout(function () {
            document.body.removeChild(link);
        }, 100);
    }
}