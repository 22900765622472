import * as React from 'react';
import { IVoucher, VoucherNo } from '../../../core/domain/models/IGroup';
import { ITaxingAuthority } from '../../../core/domain/models/ITaxingAuthority';
import { getEstimatedVouchers } from '../../../core/domain/viewModels/ISummaryViewModel';
import { TYPES } from '../../../startup/types';
import { container } from '../../../startup/inversify.config';
import { IUtilities } from '../../../core/utilities/Utilities';
import { Shimmer } from '../../../components/Common/Shimmer/Shimmer';
import { logger } from '../../../routes';


const utilities = container.get<IUtilities>(TYPES.IUtilities);

export interface EstimatedVoucherProps {
	taxYear: number,
	vouchers: IVoucher[],
	taxingAuthorities: ITaxingAuthority[],
}

export class EstimatedVoucher extends React.Component<EstimatedVoucherProps, {}> {

	constructor(props: EstimatedVoucherProps) {
		super(props);
		logger.trackPageView(`EstimatedVoucher page`);
	}

	public render() {
		const autoIds: any = {};
		autoIds[VoucherNo[VoucherNo.Q1].toString()] = "4F660DE8-BE88-48AA-AF2C-51646238FE67";
		autoIds[VoucherNo[VoucherNo.Q2].toString()] = "5BDD3FEC-D42C-463F-83E0-FD2801B27286";
		autoIds[VoucherNo[VoucherNo.Q3].toString()] = "CD5C7E01-F18C-45D8-9690-8565E5F4CB66";
		autoIds[VoucherNo[VoucherNo.Q4].toString()] = "6366E3A8-1895-4017-91FF-97662F494CF8";

		const estimatedVouchers = getEstimatedVouchers(this.props.vouchers, this.props.taxingAuthorities);

		const displayStyle = estimatedVouchers && estimatedVouchers.length > 0 ? { display: "block" } : { display: "none" };

		return (<div className="estimated-voucher-container" style={displayStyle} data-test-auto="53ACA0BD-6956-4E2C-A6E4-886A3D8015CE">
			<div className="title">{this.props.taxYear ? (this.props.taxYear + 1 + " Estimated Payments ") : <Shimmer lineCount={12} />} </div>
			<div className="body">

				{
					estimatedVouchers.map((value, index) => {
						if (value.Items.length > 0) {
							return (
								<section className="preSelect" key={value.GroupName + index} data-test-auto={autoIds[value.GroupName]}>
									<div className="section-title">{value.GroupName}</div>
									{
										value.Items.length > 0 ? (
											value.Items.map((item, i) => {
												return (<div key={value.GroupName + item.name + i} className="sub-section">
													<div className="authority fllft ellipsis">{item.name}</div>
													<div className="amount flrt red currencytext">${utilities.formatCurrencyText(item.value)}</div>
												</div>)
											})
										)
											: ""
									}
									{
										value.Items.length > 0 ? (

											<div className="sub-section">
												<div className="red fllft ellipsis"><span className="flrt">Total</span></div>
												<div className="flrt currencytext red total-amount-red">${utilities.formatCurrencyText(value.Total)}</div>
											</div>

										) : ""
									}
								</section>
							);
						}
					})
				}

			</div>
		</div>);
	}
}
