import axios, { AxiosRequestConfig } from 'axios';
import { injectable } from "inversify";
import { IDataService } from "src/core/services/dataAccess/abstraction/IDataService";
import { history } from 'src/core/services/dataAccess/History';
import { API_BASE_URL } from 'src/utils/contants';

// const localStore = container && container.get<ILocalStore>(TYPES.ILocalStore);
export const inMemoryToken: Map<string, string> = new Map();

@injectable()
export class AxiosDataService implements IDataService {
    private _baseUri: string = API_BASE_URL;
    // private _pageSize: number=10;
    constructor(clientId?: string, initializeResponseInterceptor?: boolean) {
        //if (clientId && localStore.get(clientId)) {
        //    axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStore.get(clientId);
        //}

        if (clientId && inMemoryToken.has(clientId)) {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + inMemoryToken.get(clientId);
        }
        axios.defaults.withCredentials = true;
        axios.interceptors.request.use(function (config: any) {

            config.withCredentials = true;
            return config;
        }, function (error: any) {
            return Promise.reject(error);
        });
        if (initializeResponseInterceptor) {

            axios.interceptors.response.use(function (response: any) {
                if (response.status === 401) {
                    //redirect to login url
                    // localStore && localStore.remove('loggedIn');
                    history.push('/invalid');
                }
                return response;
            }, function (error: any) {
                if (error.response.status === 302) {
                    if (error.response?.data === 'invalidlink') {
                         history.push('/invalidlink');
                         return Promise.reject('Invalid page redirection occurred.');
                     }
                 }
                if (error.config?.__isRetryRequest !== undefined && !error.config?.__isRetryRequest) {
                    
                    if (error.response.status == "403" || error.response.status == "500") {
                        // localStore && localStore.remove('loggedIn');
                        history.push(
                            '/invalid',
                            {
                                statusCode: error.response.status,
                                statusText: error.response.statusText,
                                message: error.response.data.error
                            }
                        );
                    }
                    else {
                        if (error.response.data && error.response.data.length > 0) {
                            error.response.data.map((message: any) => {
                                console.error(message.ErrorMessage);
                            })
                        }
                    }
                    error.config.__isRetryRequest = true;
                }
                return Promise.reject(error);
            });
        }
    }

    set(baseUri: string, pageSize: number) {
        this._baseUri = baseUri;
        //this._pageSize = pageSize;
    }

    getPaged<T>(page: number, uriPart?: string | undefined): any {
        return axios.get<T>(this.getFormattedUri(uriPart));
    }

    get<T>(uriPart?: string | undefined, data?: any, disableCache?: boolean): any {

        if (disableCache) {
            axios.defaults.headers['Pragma'] = 'no-cache';
        }
        return axios.get<T>(this.getFormattedUri(uriPart), data);
    }

    post<T>(uriPart: string | undefined, data?: any, config?: AxiosRequestConfig, mapResult?: boolean): any {
        return axios.post<T>(this.getFormattedUri(uriPart), data, config);
    }

    postJson<T>(data: any, uriPart?: string | undefined, config?: any, mapResult?: boolean | undefined): any {
        return axios.post<T>(this.getFormattedUri(uriPart), data, { headers: { 'Content-Type': 'application/json;utf-8' } });
    }

    put<T>(uriPart: string | undefined, data?: any, config?: AxiosRequestConfig, mapResult?: boolean): any {
        return axios.put<T>(this.getFormattedUri(uriPart), data, config);
    }
    putJson<T>(data: any, uriPart?: string | undefined, config?: any, mapResult?: boolean | undefined): any {
        return axios.put<T>(this.getFormattedUri(uriPart), data, { headers: { 'Content-Type': 'application/json;utf-8' } });
    }

    delete(id: number, uriPart?: string | undefined): any {
        return axios.delete(this.getFormattedUri(uriPart));

    }

    deleteExtended(data: any, uriPart?: any): any {
        throw new Error("Method not implemented.");
    }

    getFormattedUri(uriPart?: string | undefined): any {
        if(this._baseUri.charAt(this._baseUri.length-1) === "/" && uriPart?.charAt(0) === "/"){
            return this._baseUri + uriPart.substring(1);
        }
        return this._baseUri + uriPart;   
    }
}
export function initializeAxios(clientId?: string, initializeResponseInterceptor?: boolean): AxiosDataService { return new AxiosDataService(clientId); };

export function storeTokenInMemory(clientId: string, token: string) { inMemoryToken.set(clientId, token); };
