import * as React from 'react';
import { Step } from 'awesome-multi-steps/dist/Body/Step';
import { Wizard } from 'awesome-multi-steps/dist/Body/Wizard';
import { History } from 'history';
import Modal from 'react-bootstrap/Modal';
import { match, RouteComponentProps } from 'react-router';

import {
    ClientType,
    DistributeType,
    DocumentStatus,
    EngagementTypeS,
    SignatureType
} from '../../core/common/Enums';
import { ISignerModel, SignerStatus } from '../../core/domain/models/ISignerModel';
import { ICompany } from '../../core/domain/models/company/Company';
import { IDocument } from '../../core/domain/models/esign/Document';
import { IDocumentSettings } from '../../core/domain/models/IDocumentSettings';
import { DocumentGroups, IVoucher } from '../../core/domain/models/IGroup';
import { IK1ShareHolderDetails } from '../../core/domain/models/IK1Distribute';
import { initailsignatureSettingsModel, ISignatureSettingsModel } from '../../core/domain/models/ISignatureSettingsModel';
import { ITaxDocumentModel } from '../../core/domain/models/ITaxDocumentModel';
import { ISignedDocument } from '../../core/domain/models/manualsign/SignedDocument';
import { IClientProcessViewModel } from '../../core/domain/viewModels/IClientProcessViewModel';
import { ICommonDataViewModel } from '../../core/domain/viewModels/ICommonDataViewModel';
import { IDocumentReviewModel } from '../../core/domain/viewModels/IDocumentReviewModel';
import { IHeaderInfoViewModel, initialHeaderInfoViewModel } from '../../core/domain/viewModels/IHeaderInfoViewModel';
import { ILoggedInUserInfo } from '../../core/domain/viewModels/ILoggedInUserInfo';
import { IPayScreenViewModel } from '../../core/domain/viewModels/IPayScreenViewModel';
import { ISignProcessInfoViewModel } from '../../core/domain/viewModels/ISignProcessInfoViewModel';
import { ISummaryViewModel } from '../../core/domain/viewModels/ISummaryViewModel';
import { ITutorialInfo } from '../../core/domain/models/ITutorialInfo';
import { IDialogBox } from '../../core/utilities/ui/DialogBox';
import { container } from '../../startup/inversify.config';
import { TYPES } from '../../startup/types';
import * as TaxDocument from '../../core/domain/models/ITaxReturn';
import * as AccountStore from '../../store/Common/AccountStore';
import * as CompanyStore from '../../store/Common/Company/CompanyStore';
import * as EsignStore from '../../store/Common/EsignStore';
import * as HelperStore from '../../store/Common/HelperStore';
import * as TaxDocumentStore from '../../store/Common/TaxDocumentStore';
import * as K1DistributeStore from '../../store/K1/K1DistributeStore';
import * as PayStore from '../../store/PayStore';
import * as PreparerMessageStore from '../../store/PreparerMessageStore';
import * as DocumentReviewStore from '../../store/ReviewStore';
import * as ReviewStore from '../../store/ReviewStore';
import * as ManualSignStore from '../../store/Sign/ManualSignStore';
import * as SignProcessStore from '../../store/SignProcess/SignProcessStore';
import * as TutorialStore from '../../store/Tutorial/TutorialStore';
import * as SummaryStore from '../../store/SummaryStore';
import * as PreparerMessageWizard from '../PreparerMessage/MessageWizard';
import * as ReviewWizard from '../Review/Main/Review';
import * as SummaryWizard from '../Summary/Main/WizardBody';
import * as PayWizard from '../Pay/Main/Pay';

import {
    Common,
    ManualSignConstants,
    PathConstants,
    VoucherPaymentConstants
} from '../Common/Constants';
import {
    SvgIconMakePaymentsWizardActive,
    SvgIconDownloadWizardActive, SvgIconReviewWizardActive,
    SvgIconSignWizardActive,
    SvgIconSummaryWizardActive,
    SvgAlertIcon
} from '../Common/Icons/SvgIcons';
import { MarsNotifier } from '../Common/Notification/MarsNotifier';
import { Body } from '../Common/StepLayout/Body';
import { Header } from '../Common/StepLayout/Header';
import { StepLayout } from '../Common/StepLayout/StepLayout';
import { EsignConsentEmail } from '../Esign/EsignConsentEmail';
import { Esign } from '../Esign/Main/Esign';
import SelectSignMethod from '../Esign/SelectSignMethod';
import SpouseSignRequired from '../Esign/SpouseSignRequired';
import TaxpayerHelper from '../Helper/TaxpayerHelper';
import { ManualSign } from '../ManualSign/ManualSign';
import { ManualSignDownloadFiles } from '../ManualSign/ManualSignDownloadFiles';
import { PaymentInstruction } from '../Pay/PaymentInstruction';
import { SignProcessSteps, TaxpayerSignFlowManager } from './TaxpayerSignFlowManager';
import { getCookie, hideReviewStep, isIndividualEngagementType } from '../Helper/HelperFunction';
import { IAdditionalEsignDocument } from '../../core/domain/models/IAdditionalEsignDocument';
import SignatureCompletedPage from '../Esign/SignatureCompletedPage';
import {
    actionCreators as SigningRequiredStoreActionCreators,
    SigningRequiredStoreState,
} from "../../store/SignProcess/SigningRequiredStore";
import { logger } from '../../routes';
import { Alert } from 'react-bootstrap';

const NO_INDEX = -1;

export type PreviewComponentProps =
    {
        summaryData: ISummaryViewModel,
        esignData: ITaxDocumentModel,
        signerData: ISignerModel[],
        manualSignData: ISignedDocument[],
        companyData: ICompany,
        payScreenData: IPayScreenViewModel,
        signatureControlsData: IDocument[],
        commonData: ICommonDataViewModel,
        signatureSettingModel: ISignatureSettingsModel,
        documentSettings: IDocumentSettings,
        documentReviewModel: IDocumentReviewModel[],
        headInfoViewModel: IHeaderInfoViewModel,
        taxReturn: TaxDocument.ITaxReturn,
        match: match;
        history: History;
        loading: boolean;
        k1Data: IK1ShareHolderDetails[];
        signProcessInfo: ISignProcessInfoViewModel;
        previewReady: boolean;
        loggedInUserInfo: ILoggedInUserInfo,
        tutorialInfo: ITutorialInfo,
        additionalEsignDocuments: IAdditionalEsignDocument[];
        downloadAllAdditionEsignDocuments: (clientId: string, fileName: string) => any;
        requestAdditionalEsignDocument(clientGuid: string): void;
        signingRequiredState: SigningRequiredStoreState;
        showPaymentAlert: boolean;
    }
    & typeof SummaryStore.actionCreators
    & typeof HelperStore.actionCreators
    & typeof EsignStore.actionCreators
    & typeof AccountStore.actionCreators
    & typeof ManualSignStore.actionCreators
    & typeof CompanyStore.actionCreators
    & typeof PreparerMessageStore.actionCreators
    & typeof DocumentReviewStore.actionCreators
    & typeof TaxDocumentStore.actionCreators
    & typeof K1DistributeStore.actionCreators
    & typeof SignProcessStore.actionCreators
    & typeof ReviewStore.actionCreators
    & typeof PayStore.actionCreators
    & typeof TutorialStore.actionCreators
    & typeof SigningRequiredStoreActionCreators
    & RouteComponentProps<{}>;

interface PreviewComponentState {
    signatureSettings: ISignatureSettingsModel;
    headInfoViewModel: IHeaderInfoViewModel;
    hideNextButton: boolean;
    hidePreviousButton: boolean;
    isNextButtonDisable: boolean;
    isConsentAgreed: boolean;
    showModal: boolean;
    isDocumentError: boolean;
    isClientView: boolean;
    hideNavigationBar: boolean;
    hideFooter: boolean;
    isPreviewMode: boolean;
}

const dialogBox = container.get<IDialogBox>(TYPES.IDialogBox);
//let timeoutFunc: number;

export class PreviewComponent extends React.Component<PreviewComponentProps, PreviewComponentState> {
    private _wizardRef: any;
    private _reviewRef: any;
    private _consentRef: any;
    private _esignRef: any;
    private _spouseSignRequiredRef: any;
    private _manualSign: any;
    private _params: any;
    private _taxpayerSignFlowManager: TaxpayerSignFlowManager = TaxpayerSignFlowManager.createNullObject();
    private _distributeManualRef: any = React.createRef();
    private _distributeElectronicRef: any = React.createRef();
    private _payRef: any;

    constructor(props: PreviewComponentProps) {
        super(props);
        this.state = {
            signatureSettings: initailsignatureSettingsModel,
            headInfoViewModel: initialHeaderInfoViewModel,
            hideNextButton: false,
            hidePreviousButton: false,
            isNextButtonDisable: false,
            isConsentAgreed: false,
            showModal: false,
            isDocumentError: false,
            isClientView: false,
            hideNavigationBar: false,
            hideFooter: false,
            isPreviewMode: true,
        };
    }

    componentDidMount() {
        let param: any = this.props.match.params;
        this._params = this.props.match.params;
        this.getSignProcessStatus(param.clientId);
        this.props.checkSignatureRequiredForDocument(param.clientId);
        this.props.checkSignatureRequiredForSigner(param.clientId);
        this.props.requestTaxDocument(param.clientId, (taxReturn: TaxDocument.ITaxReturn) => {
            this.props.relatedTaxReturnDelivered(param.clientId);
        });
        this.props.requestDocumentSettings(param.clientId);
        this.props.requestTutorialInfoPreview(param.clientId);
        logger.trackPageView(`PreviewComponent Page`);

        // add this once preview has clientid
        this.props.requestSigners(param.clientId,
           (signers: ISignerModel[]) => {
               const clientGuid = getCookie("clientId");

               const nextSinger = signers.filter((signer) => signer.clientGuid !== clientGuid);

               if (nextSinger.length === 1 &&
                   nextSinger[0].signerStatus === SignerStatus.NotSigned) {

                   this.props.checkNextSignerExists(param.clientId, nextSinger[0].signerType);

               }

           });

        if (this.props.commonData && this.props.commonData.taxingAuthorities.length == 0) {
            this.props.requestAllTaxingAuthorities(param.clientId);
        }
        this.props.requestCompanyDetails(param.clientId, true, true);
        this.props.requestLastVisitedStep(
            this._params.clientId,
            (lastVisitedStep: SignProcessSteps) => {
                this.signProcessRecoverConfirmation(lastVisitedStep)
            });
        !this.state.isClientView && this.props.requestDocumentsForPreview(param.clientId);
        this.props.requestAdditionalEsignDocument(this._params.clientId);
    }

    private getSignProcessStatus = (clientId: any) => {
        this.props.requestCurrentStepAndUserRole(clientId, this.setclientState);
    }


    private setclientState = (clientProcessState: IClientProcessViewModel) => {
        this.setState({ isClientView: TaxpayerHelper.isPreviewModeWithWritePermission(clientProcessState), isPreviewMode: TaxpayerHelper.isPreviewMode(clientProcessState) });
    }

    /* TODO Take from props(redux), move the method to SelectSignMethod component */

    handleSetSignMethod = (signatureType: SignatureType) => {

        const param: any = this.props.match.params;

        let tempSignatureSetting: ISignatureSettingsModel = this.state.signatureSettings as ISignatureSettingsModel;
        tempSignatureSetting.signatureFormSelectionType = signatureType;
        this.props.taxReturn.documentSettings.documentSignatureSetting.signatureFormSelectionType = signatureType;
        this.setState({ signatureSettings: tempSignatureSetting }, () => {
            this.updateSettingsAndRefreshTaxDocument(param.clientId);
        });
    }

    updateSettingsAndRefreshTaxDocument = (clientId: string) => {
        if (this.state.signatureSettings.signatureFormSelectionType === SignatureType.ESignWhenAllIncludedReturnsAllowed) {
            this.goToNextStep();
        } else {
            this.goToStepById(SignProcessSteps.ManualSignDownload.toString());
        }
    }

    private isSpouseOperationRequired = (): boolean => {
        let param: any = this.props.match.params;
        return (this.props.taxReturn.documentSettings.documentSignatureSetting.signatureFormSelectionType !== SignatureType.ManualSign
            && TaxDocument.isMutual(this.props.taxReturn)
            && this.props.taxReturn.engagementType == EngagementTypeS.E1040
            && (TaxpayerHelper.isMarried(this.props.taxReturn)
            && TaxpayerHelper.isFirstSignerLoggedIn(this.props.taxReturn, this.props.signerData, param.clientId)
            && !TaxpayerHelper.isNextSignerDeceased(this.props.taxReturn)));
    }

    private isSecondSignerExists = (): boolean => {
        let param: any = this.props.match.params;
        return (this.props.taxReturn.documentSettings.documentSignatureSetting.signatureFormSelectionType == SignatureType.ESignWhenAllIncludedReturnsAllowed
            && TaxDocument.isMutual(this.props.taxReturn)
            && TaxpayerHelper.isMarried(this.props.taxReturn)
            && (ClientType.Undefined != TaxpayerHelper.getSecondSignerType(this.props.taxReturn, this.props.signerData, param.clientId))
            && !TaxpayerHelper.isNextSignerDeceased(this.props.taxReturn));
    }

    private hideSignComplete = (): boolean => {
        if (this.props.taxReturn.documentSettings.documentSignatureSetting.signatureFormSelectionType != SignatureType.ESignWhenAllIncludedReturnsAllowed) {
            return true;
        }
        else {
            if (this.isSecondSignerExists()) {
                return true;
            }
            else {
                return false;
            }
        }
    }


    private clearState = () => {
        this.setState({
            hideNextButton: false,
            hidePreviousButton: false,
            isNextButtonDisable: false
        })
    }

    goToStepById = (id: any) => {
        this._wizardRef && this._wizardRef.goToStepById(id)
    }

    goToStep = (stepNumber: number) => {
        this._wizardRef && this._wizardRef.goToStep(stepNumber);
    }

    goToNextStep = () => {
        this._wizardRef && this._wizardRef.goToNextStep();
    };

    goToPreviousStep = () => {
        this._wizardRef && this._wizardRef.goToPreviousStep();
    };

    goToCompletionWizard = (isDocSettingsUpdated: boolean = false) => {
        const param: any = this.props.match.params;
        this.props.history.push(`${PathConstants.CompletionWizard}${param.clientId}`);
    };

    handleHeaderAndFooterVisibility = (visibility: boolean) => {
        this.setState({ hideNavigationBar: visibility, hideFooter: visibility });
    }

    disableNextButton = (value: boolean) => {
        this.setState({ isNextButtonDisable: value });
    }

    onReviewWizardPreviousBtnClick = () => {
        if (this.props.documentSettings.deliverySettings.preparerMessage.body) {
            this._reviewRef.onPrevious(this._reviewRef.state.currentStep);
        }
        else {
            this.goToStepById(SignProcessSteps.Summary.toString())
        }
    }

    onReviewWizardNextBtnClick = () => {
        this._reviewRef.onNext(this._reviewRef.state.currentStep);
    }

    onConsentWizardNextBtnClick = (currStep: number) => {
        this._consentRef.onNext(currStep);
    }

    onConsentWizardPreviousBtnClick = (currStep: number) => {
        this._consentRef.onPrevious(currStep);
    }

    onEsignWizardNextBtnClick = (currStep: number) => {
        this._esignRef.onNext(currStep);
    }

    onSpouseSignRequiredNextBtnClick = (currStep: number) => {
        this._spouseSignRequiredRef.onNext(currStep);
    }

    onConsentAgreed = (value: boolean) => {
        this.disableNextButton(!value);
        this.setState({ isConsentAgreed: value });
    }

    onEsignComplete = () => {
        this.goToNextStep();
    }

    onK1DistributionNextBtnClick = (currStep: number) => {
        this.goToStepById(SignProcessSteps.Pay.toString())
    }
    onK1DistributionPreviousBtnClick = () => {
        this.goToStepById(SignProcessSteps.DistributeSelection.toString())
    }

    handleSetDistributeMethod = (type: DistributeType) => {
        DistributeType.Manual === type ? this.goToStepById(SignProcessSteps.DistributeManual.toString()) : this.goToStepById(SignProcessSteps.DistributeElectronic.toString());
    }

    handleStepChange = (step: number, stepId: string) => {

    }

    handleSubmit = () => {
        if (this.state.isClientView) {
            const param: any = this.props.match.params;
            this.props.history.push(`${PathConstants.CompletionWizard}${param.clientId}`);
        }
        else {
            this.goToStep(0);
        }
    }

    initiateDocumentStatusPolling = (retrycount: number, step: number, callback: (step: number) => void) => {
        this.setState({ showModal: true, isDocumentError: false });
        const param: any = this.props.match.params;
        let timeout: number = 10000;
        if (retrycount > 10) {
            timeout = 60000;
        }
        if (retrycount == 16) {
            this.setState({ showModal: false, isDocumentError: true });
            return false;
        }
        if (!this.props.previewReady) {
            this.props.requestDocumentPreviewStatus && this.props.requestDocumentPreviewStatus(param.clientId, SignProcessSteps.PreparerMessage, callback);
            setTimeout(this.initiateDocumentStatusPolling, timeout, retrycount + 1, SignProcessSteps.PreparerMessage, callback);
        }
    }

    signProcessRecoverConfirmation = (lastVisitedStep: SignProcessSteps) => {
        if (Number(lastVisitedStep) > 0) {
            this.goToStepById(lastVisitedStep);
        }
    }

    disableNavigationLink = (step: SignProcessSteps) => {
        let index = NO_INDEX;
        index = this.props.signProcessInfo && this.props.signProcessInfo.visitedSteps && this.props.signProcessInfo.visitedSteps.indexOf(step);
        if (index == null || (index != null && index === NO_INDEX)) {
            return true;
        }
        else {
            return false;
        }
    }

    confirmRedirectToPay = () => {
        dialogBox.confirm(
            Common.DialogBox.ConfirmationTitle,
            ManualSignConstants.ConfirmMessage,
            Common.DialogBox.Cancel,
            Common.DialogBox.Confrim,
            (result: boolean) => { result && this.goToNextStep() });
    }

    private isSigningProcessCompleted = (): boolean | undefined => {

        const index = this.props && this.props.signProcessInfo.visitedSteps ?
            this.props.signProcessInfo.visitedSteps.filter((x) => x === SignProcessSteps.Pay
                || x === SignProcessSteps.DistributeSelection
                || x === SignProcessSteps.SignComplete) : null;

        return (index !== null && index.length > 0) ||
            (
                this.props.taxReturn.documentStatus == DocumentStatus.USERSIGNED ||
                this.props.taxReturn.documentStatus == DocumentStatus.USERREVIEWED
            );
    }

    private isLinkVisited = (step: SignProcessSteps): boolean | undefined => {
        let index = NO_INDEX;
        index = this.props && this.props.signProcessInfo.visitedSteps ?
            this.props.signProcessInfo.visitedSteps.indexOf(step) : NO_INDEX;

        return (index !== null && index !== NO_INDEX);
    }

    saveVoucherPayment = (voucher: IVoucher) => {
        dialogBox.alert(VoucherPaymentConstants.AddingVoucherPaymentNotAllowed);
    }

    openInvoiceUrl = () => {
        window.open(this.props.companyData.onlinePaymentUrl, '_blank');
    }

    showCustomBtnInReviewStep = () => {
        return false;
    }

    toolTipForPay = (relatedTaxReturnDelivered: boolean, voucherExists: boolean) => {
        if (!voucherExists) {
            return "No Payment Voucher Available";
        }
        else if (relatedTaxReturnDelivered) {
            return "Your payment step has been disabled per the delivery of your tax return.";
        }
        else {
            return "Pay"
        }
    }

    public render() {
        const { hideNavigationBar, hideFooter } = this.state;
        const {
            downloadEfileForms,
            downloadPreviewEfileForms,
            downloadFilingInstructions,
            downloadAllVouchers,
            signingRequiredState: {
                signingRequiredForSigner,
                nextSignerExists
            },
            taxReturn: {
                documentSettings: {
                    documentSignatureSetting: {
                        signatureFormSelectionType
                    }
                },
                relatedTaxReturnDelivered,
                taxYear
            },
            showPaymentAlert
        } = this.props;

        const taxDocument = this.props.taxReturn;
        const ssnValue: string = taxDocument && (taxDocument.clientType == ClientType.Spouse && TaxDocument.isMutual(taxDocument))
            ? taxDocument.taxpayer.ssn
            : taxDocument && (taxDocument.clientType == ClientType.Taxpayer && TaxDocument.isMutual(taxDocument))
                ? taxDocument.spouse.ssn : "";
        const param: any = this.props.match.params;
        const showLoader: boolean = this.state.showModal && !this.state.isDocumentError && !this.props.previewReady && !this.state.isClientView;
        const SignStepIcon = Common.Images.SignStepIcon;

        const invoicePresent = false;

        const voucherExists = this.props.summaryData.vouchers.length > 0;

        const paymentSectionDisabled = !voucherExists || relatedTaxReturnDelivered;

        const paymentAlert = relatedTaxReturnDelivered ?
            <Alert
                dismissible={true}
                show={showPaymentAlert}
                className="payment-alert"
                onClose={() => {
                    this.props.togglePaymentAlert(false);
                }}>
                <span className="payment-alert-icon">
                    <SvgAlertIcon />
                </span>
                <span className="payment-alert-text">
                    A link to your completed {taxYear} tax return has been sent to you via email.
                    Payments can be made by accessing the link contained in the email you received.</span>
            </Alert> :
            null;

        const spouseOperationRequired = this.isSpouseOperationRequired();

        return (<Wizard
            hideTopNavigation={hideNavigationBar}
            hideFooter={hideFooter}
            nextButtonAutomationTestId={"FA6F3407-F80B-4FCD-87A5-C094ECF286EC"}
            previousButtonAutomationTestId={"D945DDEF-3701-4155-9529-1C7BDECA4A29"}
            ref={(ref: any) => {
                this._wizardRef = ref;
                this._taxpayerSignFlowManager = TaxpayerSignFlowManager.create(ref);
            }}
            submitLabel={"Done"}
            onStepChange={this.handleStepChange}
            onSubmit={this.handleSubmit}
        >

            <Step id={SignProcessSteps.Summary.toString()}
                tooltip={"Summary"}
                name={"Summary"}
                template={<SvgIconSummaryWizardActive className={"step-icon"} />}
                hideNextButton={true} hidePreviousButton={true}
                previousLabel={Common.Wizard.PreviousButtonLabel}
                nextLabel={Common.Wizard.NextButtonLabel}
            >
                {this.state.isDocumentError &&
                    MarsNotifier.Error("Unable to generate documents. Please contact support.", "")}
                <div>
                    <Modal dialogClassName="information-popup" show={showLoader} data-test-auto="3ED090CD-AE60-4267-8768-DEC161BF3E72">

                        <Modal.Body>
                            <div className="col-lg-12 inline-flex-container download-all-body">
                                Generating your documents Please wait...
                            </div>
                        </Modal.Body>

                    </Modal>
                </div>
                {paymentAlert}
                <SummaryWizard.WizardBody
                    commonData={this.props.commonData}
                    match={this.props.match}
                    history={this.props.history}
                    summaryData={this.props.summaryData}
                    requestSummaryDetails={this.props.requestSummaryDetails}
                    goToStep={this.goToStep}
                    showPaymentRefundPrint={false}
                    isPreviewReady={this.props.previewReady}
                    initiateDocumentStatusPolling={this.state.isClientView ? undefined : this.initiateDocumentStatusPolling}
                    signProcessInfo={this.props.signProcessInfo}
                    goToStepById={this.goToStepById}
                    hideSummaryTutorial={this.props.hideSummaryTutorialPreview}
                    tutorialInfo={this.props.tutorialInfo}
                    headerInfo={this.props.headInfoViewModel}
                    signingRequiredForSigner={signingRequiredForSigner}
                    signatureFormSelectionType={signatureFormSelectionType}
                    showPreparerMessage={this.props.documentSettings.deliverySettings.preparerMessage.body != ""}
                    relatedTaxReturnDelivered={relatedTaxReturnDelivered}
                />
            </Step>
            {this.props.documentSettings.deliverySettings.preparerMessage.body != "" &&
                <Step id={SignProcessSteps.PreparerMessage.toString()}
                    disableNavigationClick={true}
                    previousLabel={Common.Wizard.PreviousButtonLabel}
                    nextLabel={Common.Wizard.NextButtonLabel}
                >
                    {paymentAlert}
                    <PreparerMessageWizard.PreparerMessageWizardBody
                        preparerMessage={this.props.documentSettings.deliverySettings.preparerMessage.body}
                        headerInfo={this.props.headInfoViewModel}
                        isDelegatee={false}
                    />
                </Step>
            }

            <Step id={SignProcessSteps.Invoice.toString()}
                name={"Review"}
                tooltip={"Review"}
                template={<SvgIconReviewWizardActive className={"step-icon"} />}
                preventNextButtonDefaultBehaviour={!invoicePresent}
                preventPreviousButtonDefaultBehaviour={!invoicePresent}
                onNext={this.onReviewWizardNextBtnClick}
                onPrevious={this.onReviewWizardPreviousBtnClick}
                hidden={hideReviewStep(this.props.documentReviewModel)}
                disableNavigationClick={this.disableNavigationLink(SignProcessSteps.Invoice)}
                previousLabel={Common.Wizard.PreviousButtonLabel}
                nextLabel={(
                    paymentSectionDisabled &&
                    !signingRequiredForSigner &&
                    signatureFormSelectionType != SignatureType.ManualSign
                ) ?
                    Common.Wizard.Submit :
                    Common.Wizard.NextButtonLabel
                }
                showCustomButton={this.showCustomBtnInReviewStep()}
                customButtonLabel={invoicePresent
                    ? (!this.props.companyData.onlinePaymentUrl ? undefined : Common.Wizard.PayNow)
                    : undefined}
                onCustomButtonClick={invoicePresent
                    ? (!this.props.companyData.onlinePaymentUrl ? undefined : this.openInvoiceUrl)
                    : undefined}
            >
                {paymentAlert}
                <ReviewWizard.ReviewWizardBody
                    ref={(ref: any) => { this._reviewRef = ref }}
                    onHeaderAndFooterVisibility={this.handleHeaderAndFooterVisibility}
                    documentReviewModel={this.props.documentReviewModel}
                    downloadAttachment={this.props.downloadIndividualAttachment}
                    downloadAllAttachments={this.props.downloadAllAttachments}
                    requestPreviewDocuments={this.props.requestDocumentsForPreview}
                    match={this.props.match}
                    nextStep={this.goToNextStep}
                    prevStep={this.goToPreviousStep}
                    hideTitle={hideNavigationBar && hideFooter}
                    tutorialInfo={this.props.tutorialInfo}
                    hideReviewTutorial={this.props.hideReviewTutorialPreview}
                    showDownloadButton={false}
                    signingRequiredForSigner={signingRequiredForSigner}
                    signatureFormSelectionType={signatureFormSelectionType}
                    review={() => { }}
                    voucherExists={voucherExists}
                    relatedTaxReturnDelivered={relatedTaxReturnDelivered}
                    goToStep={this.goToStep}
                    fromPreviewComponent={true}
                    goToCompletionWizard={this.goToCompletionWizard}
                    isClientView={this.state.isClientView}
                    history={this.props.history}
                    spouseOperationRequired={spouseOperationRequired}
                    signatureStatus={this.props.taxReturn.signatureStatus}
                    documentStatus={this.props.taxReturn.documentStatus}
                    refreshTaxDocument = {this.props.requestTaxDocument}
                />
            </Step>

            <Step id={SignProcessSteps.SignMethod.toString()}
                name={"Sign"}
                tooltip={"Sign"}
                template={<SvgIconSignWizardActive className={"step-icon"} />}
                hidden={!(signingRequiredForSigner && signatureFormSelectionType == SignatureType.ESignWhenAllIncludedReturnsAllowed
                    && !this.isSigningProcessCompleted())}
                hideNextButton={true}
                hidePreviousButton={false}
                onPrevious={this.onConsentWizardPreviousBtnClick}
                disableNavigationClick={this.disableNavigationLink(SignProcessSteps.SignMethod)}
                previousLabel={Common.Wizard.PreviousButtonLabel}
                nextLabel={Common.Wizard.NextButtonLabel}
            >
                {paymentAlert}
                
                <SelectSignMethod
                    onSetSignMethod={this.handleSetSignMethod}
                    clearParentState={this.clearState}
                    isPreviewMode={this.state.isPreviewMode}
                    taxReturn={this.props.taxReturn}
                    goToCompletionWizard={this.goToCompletionWizard}
                    signerData={this.props.signerData}
                    match={this.props.match}
                />
            </Step>

            <Step id={SignProcessSteps.EsignConsentEmail.toString()}
                hidden={!(signingRequiredForSigner && signatureFormSelectionType == SignatureType.ESignWhenAllIncludedReturnsAllowed
                    && isIndividualEngagementType(this.props.taxReturn.engagementType)
                    && !this.isSigningProcessCompleted())}
                disableNextButton={this.state.isNextButtonDisable}
                onNext={this.onConsentWizardNextBtnClick}
                onPrevious={this.onConsentWizardPreviousBtnClick}
                preventNextButtonDefaultBehaviour={true}
                preventPreviousButtonDefaultBehaviour={true}
                disableNavigationClick={true}
                previousLabel={Common.Wizard.PreviousButtonLabel}
                nextLabel={Common.Wizard.NextButtonLabel}
            >
                {paymentAlert}
                <EsignConsentEmail
                    ref={(ref: any) => { this._consentRef = ref }}
                    history={this.props.history}
                    match={this.props.match}
                    isPreviewMode={this.state.isPreviewMode}
                    updateSpouseMailChange={this.props.updateSpouseMail}
                    updateSpouseMobileChange={this.props.updateMobileNumber}
                    ssnValue={ssnValue}
                    updateDOBChange={this.props.updateDOB}
                    individual={this.props.taxReturn as TaxDocument.IIndividualTaxReturn}
                    mutual={this.props.taxReturn as TaxDocument.IMarriedJointTaxReturn}
                    consent={this.state.isConsentAgreed}
                    onConsentAgreed={this.onConsentAgreed}
                    nextStep={this.goToNextStep}
                    prevStep={this.goToPreviousStep}
                    refreshTaxDocument={this.props.requestTaxDocument}
                    signerData={this.props.signerData}
                    disableNextButton={this.disableNextButton}
                    clearParentState={this.clearState}
                    nextSignerExists={nextSignerExists}
                />
            </Step>

            <Step id={SignProcessSteps.Esign.toString()}
                hidden={!(signingRequiredForSigner && signatureFormSelectionType == SignatureType.ESignWhenAllIncludedReturnsAllowed
                    && !this.isSigningProcessCompleted())}
                disableNextButton={false}
                disableNavigationClick={true}
                preventNextButtonDefaultBehaviour={true}
                onNext={this.onEsignWizardNextBtnClick}
                previousLabel={Common.Wizard.PreviousButtonLabel}
                nextLabel={
                    spouseOperationRequired ?
                        Common.Wizard.NextButtonLabel :
                        !paymentSectionDisabled ? Common.Wizard.NextButtonLabel : Common.Wizard.Submit}>
                {paymentAlert}
                <Esign
                    ref={(ref: any) => { this._esignRef = ref }}
                    match={this.props.match}
                    history={this.props.history}
                    isPreviewMode={this.state.isPreviewMode}
                    onHeaderAndFooterVisibility={this.handleHeaderAndFooterVisibility}
                    requestSignatureControls={this.props.requestPreviewSignatureControls}
                    signatureControlsData={this.props.signatureControlsData}
                    onSigningComplete={this.props.updateSignatureControlsData}
                    disableNextButton={this.disableNextButton}
                    completeSigningStep={
                        spouseOperationRequired || !paymentSectionDisabled ?
                            this.onEsignComplete :
                            this.handleSubmit
                    }
                    isSpouseSignRequired={spouseOperationRequired}
                    headerInfo={this.props.headInfoViewModel}
                    engagementType={this.props.taxReturn.engagementType}
                />
            </Step>

            <Step id={SignProcessSteps.SpouseSignature.toString()}
                name={"Sign"} tooltip={"Sign"}
                template={this.isSigningProcessCompleted() && spouseOperationRequired && <SvgIconSignWizardActive className={"step-icon"} />}
                hidden={!spouseOperationRequired}
                disableNavigationClick={true}
                preventNextButtonDefaultBehaviour={true}
                onNext={this.onSpouseSignRequiredNextBtnClick}
                previousLabel={Common.Wizard.PreviousButtonLabel}
                nextLabel={
                    !paymentSectionDisabled ?
                        Common.Wizard.NextButtonLabel :
                        Common.Wizard.Submit
                }
            >
                {paymentAlert}
                <SpouseSignRequired
                    ref={(ref: any) => { this._spouseSignRequiredRef = ref }}
                    companyName={this.props.headInfoViewModel.companyName}
                    refreshTaxDocument={this.props.requestTaxDocument}
                    mutual={this.props.taxReturn as TaxDocument.IMarriedJointTaxReturn}
                    match={this.props.match}
                    isPreviewMode={this.state.isPreviewMode}
                    signatureControlsData={this.props.signatureControlsData}
                    gotoNextStep={!paymentSectionDisabled ? this.goToNextStep : this.handleSubmit}
                    signerData={this.props.signerData}
                    signingCompleted={() => this.isSigningProcessCompleted()}
                    nextSignerExists={nextSignerExists}
                    signingRequiredForSigner={signingRequiredForSigner}
                    signatureFormSelectionType={signatureFormSelectionType}
                    review={() => { }}
                    goToStep={this.goToStep}
                    relatedTaxReturnDelivered={relatedTaxReturnDelivered}
                    voucherExists={voucherExists}
                    goToCompletionWizard={this.goToCompletionWizard}
                    history={this.props.history}
                    isClientView={this.state.isClientView}
                    signatureStatus={this.props.taxReturn.signatureStatus}
                />
            </Step>

            {this.isSigningProcessCompleted() &&
                <Step id={SignProcessSteps.SignComplete.toString()}
                    name={"Sign"} tooltip={"Sign"}
                    template={this.isSigningProcessCompleted() &&
                        <SignStepIcon className="step-icon" />}
                    hidden={!(signingRequiredForSigner && this.hideSignComplete())}
                    disableNavigationClick={true}
                    previousLabel={Common.Wizard.PreviousButtonLabel}
                    nextLabel={!paymentSectionDisabled ? Common.Wizard.NextButtonLabel : Common.Wizard.Submit}
                    preventNextButtonDefaultBehaviour={true}
                    onNext={!paymentSectionDisabled ? this.confirmRedirectToPay : this.handleSubmit}
                >
                    {paymentAlert}

                    <SignatureCompletedPage companyName={this.props.headInfoViewModel.companyName} />

                </Step>
            }


            <Step id={SignProcessSteps.ManualSignDownload.toString()}
                name={"Sign"}
                tooltip={"Sign"}
                template={<SvgIconSignWizardActive className={"step-icon"} />}
                disableNextButton={true}
                previousLabel={Common.Wizard.PreviousButtonLabel}
                nextLabel={Common.Wizard.NextButtonLabel}
                hidden={
                    !(signatureFormSelectionType == SignatureType.ManualSign)
                    || this.isLinkVisited(SignProcessSteps.ManualSign)}
            >
                {paymentAlert}

                <ManualSignDownloadFiles
                    downloadEfileForms={this.state.isClientView
                        ? downloadEfileForms
                        : downloadPreviewEfileForms}
                    clientId={param.clientId}
                    goToStepById={this.goToStepById}
                    additionalEsignDocuments={this.props.additionalEsignDocuments}
                    downloadAllAdditionEsignDocuments={() => { }}
                    clientName={this.props.headInfoViewModel.clientName}
                    taxReturn={this.props.taxReturn}
                    isPreviewMode={this.state.isPreviewMode}
                />

            </Step>


            <Step id={SignProcessSteps.ManualSign.toString()}
                name={"Sign"} tooltip={"Sign"}
                template={this.isLinkVisited(SignProcessSteps.ManualSign) && <SvgIconDownloadWizardActive className={"step-icon"} />}
                hidden={!(signatureFormSelectionType == SignatureType.ManualSign)}
                preventNextButtonDefaultBehaviour={true}
                previousLabel={Common.Wizard.PreviousButtonLabel}
                nextLabel={!paymentSectionDisabled ? Common.Wizard.NextButtonLabel : Common.Wizard.Submit}
                onNext={!paymentSectionDisabled ? this.goToNextStep : this.handleSubmit}
                disableNavigationClick={true}
            >
                {paymentAlert}

                <StepLayout>

                    <Body title={ManualSignConstants.Title}
                        lg={10} md={12} sm={12} xs={12}
                        automationTestId={"11BB28B5-42F3-4571-B567-20700B1740B6"}
                    >
                        <ManualSign match={this.props.match}
                            requestSignedDocuments={this.props.requestSignedDocuments}
                            signedDocuments={this.props.manualSignData}
                            downloadEfileForms={this.state.isClientView ?
                            downloadEfileForms : downloadPreviewEfileForms}
                            onCompletion={!paymentSectionDisabled ? this.confirmRedirectToPay : this.handleSubmit}
                            ref={(ref: any) => { this._manualSign = ref }}
                            additionalEsignDocuments={this.props.additionalEsignDocuments}
                            downloadAllAdditionEsignDocuments={() => { } }
                            requestAdditionalEsignDocument={this.props.requestAdditionalEsignDocument}
                            clientName={this.props.headInfoViewModel.clientName}
                            taxReturn={this.props.taxReturn}
                            isPreviewMode={this.state.isPreviewMode}
                            isDelegatee={false}
                            companyInfo={this.props.companyData}
                        />
                    </Body>
                </StepLayout>

            </Step>

            <Step id={SignProcessSteps.Pay.toString()}
                tooltip={this.toolTipForPay(relatedTaxReturnDelivered, voucherExists)}
                name={"Pay"}
                template={
                    !paymentSectionDisabled ?
                        <SvgIconMakePaymentsWizardActive className={"step-icon"} /> :
                        <SvgIconMakePaymentsWizardActive className={"step-icon cursor-disable"} fillColor={"#D3D3D3"} outerSecondaryColor={"#999999"} secondaryColor={"#0973BA"} primaryColor={"#90EE90"} />
                }
                disableNavigationClick={!paymentSectionDisabled ? this.disableNavigationLink(SignProcessSteps.Pay) : true}
                previousLabel={Common.Wizard.PreviousButtonLabel}
                nextLabel={Common.Wizard.NextButtonLabel}
            >

                <StepLayout>
                    <Body lg={11} md={12} sm={12} xs={12}
                        noBackgroundColor={true}
                        bodyClassName={"no-padding no-margin pay-step row"}
                        automationTestId={"11BB28B5-42F3-4571-B567-20700B1740B6"}
                    >
                        <Header lg={12} md={12} sm={12} xs={12}>
                            <PaymentInstruction clientInstructions={this.props.payScreenData.paymentInstructions} />
                        </Header>

                        <PayWizard.Pay
                            ref={(ref: any) => { this._payRef = ref }}
                            commonData={this.props.commonData} match={this.props.match}
                            history={this.props.history} payScreenData={this.props.payScreenData}
                            requestPayScreenDetails={this.props.requestPayScreenDetails}
                            documentSettings={this.props.documentSettings}
                            nextStep={this.goToCompletionWizard}
                            prevStep={this.goToPreviousStep}
                            taxReturn={this.props.taxReturn}
                            downloadAllVouchers={(clientId: string) => { }}
                            downloadFilingInstructions={(clientId: string) => { }}
                            downloadSingleVoucher={(clientId: string) => { }}
                            saveVoucherPayment={this.saveVoucherPayment}
                            isPreviewMode={this.state.isPreviewMode}
                        />
                    </Body>
                </StepLayout>

            </Step>
        </Wizard>);
    }
}