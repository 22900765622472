import * as React from 'react';

interface LeftPanelProps {
    title?: string;
    className?: string;
    width?: number;
    lg?: number;
    md?: number;
    sm?: number;
    xs?: number;
}

const DEFAULT_WIDTH_LG: number = 9;
const DEFAULT_WIDTH_MD: number = 11;
const DEFAULT_WIDTH_SM: number = 12;
const DEFAULT_WIDTH_XS: number = 12;

export const LeftPanel: React.FunctionComponent<LeftPanelProps> = ({
    title,
    className,
    width,
    lg,
    md,
    sm,
    xs, children
}) => {
       

    let getBodyWidthClass = () => {

        let bodyWidthClass: string = "";

        if (lg) {
            bodyWidthClass = bodyWidthClass.concat(' col-lg-', lg.toString());
        }
        else {
            bodyWidthClass = bodyWidthClass.concat(' col-lg-', DEFAULT_WIDTH_LG.toString());
        }

        if (md) {
            bodyWidthClass = bodyWidthClass.concat(' col-md-', md.toString());
        }
        else {
            bodyWidthClass = bodyWidthClass.concat(' col-md-', DEFAULT_WIDTH_MD.toString());
        }

        if (sm) {
            bodyWidthClass = bodyWidthClass.concat(' col-sm-', sm.toString());
        }
        else {
            bodyWidthClass = bodyWidthClass.concat(' col-sm-', DEFAULT_WIDTH_SM.toString());
        }

        if (xs) {
            bodyWidthClass = bodyWidthClass.concat(' col-xs-', xs.toString());
        }
        else {
            bodyWidthClass = bodyWidthClass.concat(' col-xs-', DEFAULT_WIDTH_XS.toString());
        }

        return bodyWidthClass;
    }


    return (<div className={"step-layout-left-panel no-padding " + getBodyWidthClass() + " " + className} 
        data-test-auto="971D0BBE-EEEE-44DB-88FB-92417E8AD826">
        {title && <div className={"step-layout-left-panel-header"}>{title}</div>}
        <div className={"step-layout-left-panel-body"}>
            {children}
        </div>
    </div>);
};
