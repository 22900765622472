import { AxiosResponse } from 'axios';
import { Action, Reducer } from 'redux';
import { actionTypes } from './../../../ActionTypes';
import { NotificationAction, StatusType } from './../../../Common/NotificationStore';
import { AppThunkAction } from './../../../index';
import { DisplayError, OTPPageConstants } from './../../../../components/Common/Constants';
import { OTPMobilePageConstants } from './../../../../components/Common/Constants';
import {
    initialOtpPageStoreState, OtpPageStoreState,
    OtpPageLoaderAction, OtpPageSetDataAction, LoginResponse,
    ErrorMessageAction
} from '../../Models/StoreModels';
import { initializeAxios } from 'src/core/services/dataAccess/DataService.Axios';

type KnownAction =
    DispatchAction |
    NotificationAction |
    ErrorMessageAction;

type DispatchAction = OtpPageLoaderAction | OtpPageSetDataAction;

export const actionCreators = {
    pageValidity: (clientId: string, errorCallback: (message: string) => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: actionTypes.OTP_PAGE_LOADER, loading: true });
        return initializeAxios().get<any>("api/OTP/" + clientId)
            .then((response: AxiosResponse<LoginResponse>) => {

                const result = response.data;
                dispatch({ type: actionTypes.OTP_PAGE_LOADER, loading: false });
                if (!result.isSuccess) {
                    const errorDesc = result.errorDescription;
                    const errorCode = result.errorCode;

                    if (errorCode && DisplayError.includes(errorCode)) {
                        dispatch({
                            type: actionTypes.SET_ERROR_MESSAGE,
                            message: errorDesc,
                        });
                        errorCallback(errorDesc);
                    } else {
                        dispatch({
                            type: actionTypes.NOTIFICATION,
                            statusMessage: errorDesc,
                            statusType: StatusType.Error,
                        });
                    }
                }
            })
            .catch((e: any) => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: OTPPageConstants.ErrorMessage.serverError,
                    statusType: StatusType.Error,
                });
            });

    },

    generateMobileOTP: (clientId: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        return initializeAxios().postJson<any>(null, '/api/OTP/Mobile/Generate/' + clientId)
            .then((response: AxiosResponse<LoginResponse>) => {
                const result = response.data;
                if (result.isSuccess) {
                    dispatch({
                        type: actionTypes.NOTIFICATION,
                        statusMessage: OTPMobilePageConstants.SuccessMessage.OTPTextGenerateSuccess,
                        statusType: StatusType.Success
                    });
                } else {
                    let errorDesc = result.errorDescription;
                    dispatch({
                        type: actionTypes.NOTIFICATION,
                        statusMessage: errorDesc,
                        statusType: StatusType.Error,
                    });
                }
            })
            .catch((error: any) => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: OTPMobilePageConstants.ErrorMessage.serverError,
                    statusType: StatusType.Error
                });
            });
    },

    generateOTP: (clientId: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        return initializeAxios().postJson<any>(null, '/api/OTP/Generate/' + clientId)
            .then((response: AxiosResponse<LoginResponse>) => {
                const result = response.data;
                if (result) {
                    dispatch({
                        type: actionTypes.NOTIFICATION,
                        statusMessage: OTPPageConstants.SuccessMessage.OTPEmailGenerateSuccess,
                        statusType: StatusType.Success,
                    });
                } else {

                    dispatch({
                        type: actionTypes.NOTIFICATION,
                        statusMessage: OTPPageConstants.ErrorMessage.OTPError,
                        statusType: StatusType.Error
                    });

                }

            })
            .catch((error: any) => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: OTPPageConstants.ErrorMessage.serverError,
                    statusType: StatusType.Error
                });

            });
    },
    verifyOTP: (OTP: string, clientId: string, handleVerify: (guid: string) => void, errorCallback: (message: string) => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        return initializeAxios().postJson<any>('"' + OTP + '"', `/api/OTP/Validate/${clientId}`)
            .then((response: AxiosResponse<LoginResponse>) => {
                const result = response.data;

                if (result.isSuccess) {
                    handleVerify(result.data);
                } else {
                    const errorDesc = result.errorDescription;
                    const errorCode = result.errorCode;

                    if (errorCode && DisplayError.includes(errorCode)) {
                        dispatch({
                            type: actionTypes.SET_ERROR_MESSAGE,
                            message: errorDesc,
                        });
                        errorCallback(errorDesc);

                    } else {
                        dispatch({
                            type: actionTypes.NOTIFICATION,
                            statusMessage: errorDesc,
                            statusType: StatusType.Error,
                        });
                    }
                }

            })
            .catch((error: any) => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: OTPPageConstants.ErrorMessage.serverError,
                    statusType: StatusType.Error,
                });
            });

    },
    setMobileOtpPageData: (mobileNo: string, countryCode: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        return dispatch({ type: actionTypes.SET_MOBILE_OTP_PAGE_DATA, mobileNo, countryCode });
    },
};

export const reducer: Reducer<OtpPageStoreState> = (state: OtpPageStoreState = initialOtpPageStoreState, incomingAction: Action) => {
    const action = incomingAction as DispatchAction;
    const currentState = Object.assign({}, state);
    switch (action.type) {

        case actionTypes.OTP_PAGE_LOADER:
            return { ...currentState, loading: action.loading };

        case actionTypes.SET_MOBILE_OTP_PAGE_DATA:
            return { ...currentState, countryCode: action.countryCode, mobileNo: action.mobileNo };

        default:
            return currentState || initialOtpPageStoreState;
    }
};
