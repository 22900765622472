import * as React from 'react';
import { DownloadDocuments } from './Parts/DownloadDocuments';
import { UploadDocumentsArea } from './Parts/UploadDocumentsArea';
import { DownloadDocumentsConstants, ManualSignConstants } from '../Common/Constants';
import { ISignedDocument } from '../../core/domain/models/manualsign/SignedDocument';
import { ITaxReturn } from '../../core/domain/models/ITaxReturn';
import { IAdditionalEsignDocument } from '../../core/domain/models/IAdditionalEsignDocument';
import { MarsNotifier } from '../Common/Notification/MarsNotifier';
import { logger } from '../../routes';
import { LogEventConstants } from '../Helper/Constants';
import { ICompany } from 'src/core/domain/models/company/Company';


export interface ManualSignProps {
    match: any;
    hideFileUploadArea?: boolean;
    signedDocuments: ISignedDocument[];
    downloadEfileForms(clientId: string): void;
    requestSignedDocuments(clientId: string, forceRefresh?: boolean): void;
    addSignedDocument?(clientId: string, signedDocument: ISignedDocument, successCallback?: () => void, failureCallback?: () => void): void;
    deleteSignedDocument?(clientId: string, id: number, successCallback?: () => void): void;
    completeManualSign?(clientId: string, successCallback?: () => void): void;
    onCompletion?(): void;
    additionalEsignDocuments: IAdditionalEsignDocument[];
    downloadAllAdditionEsignDocuments: (clientId: string, fileName: string) => any;
    requestAdditionalEsignDocument(clientGuid: string): void;
    taxReturn: ITaxReturn;
    clientName: string;
    isPreviewMode: boolean;
    isDelegatee: boolean;
    disableNextButton?(value: boolean) : void;
    companyInfo: ICompany;
}

export interface ManualSignState {

}

const pageTitle = "Manual Sign complete Step";
export class ManualSign extends React.Component<ManualSignProps, ManualSignState> {

    private _params: any;

    componentDidMount() {
        this._params = this.props.match.params;
        this.props.requestSignedDocuments(this._params.clientId, true);
        this.props.disableNextButton && this.props.disableNextButton(false);
        logger.trackEvent(
            logger.buildEvent(
                `${LogEventConstants.Common.Open}  ${pageTitle}`,
                { count: 1, page: pageTitle, clientId: this._params.clientId }
            )
        );
    }

    handleRequestSignedDocument = (clientId: string) => {
        this.props.requestSignedDocuments(clientId, true);
    }

    downloadESignDocuments = (clientId: string) => {
        if (!this.props.isPreviewMode) {
            const _self = this;
            _self.props.downloadEfileForms(clientId);
            logger.trackTrace('Manual Sign Page: Efile Downloaded');
            setTimeout(function () {
                _self.props.additionalEsignDocuments && _self.props.additionalEsignDocuments.length > 0
                    && _self.props.downloadAllAdditionEsignDocuments(clientId, _self.getOtherUnSignedDocumentFileName(_self.props.additionalEsignDocuments.length));
                logger.trackTrace('Manual Sign Page: Additional Document(s) Downloaded');
                logger.trackEvent(
                    logger.buildEvent(
                        `${LogEventConstants.Common.ManualDownload.AdditionalFileDownload}  ${pageTitle}`,
                        { count: 1, page: pageTitle, clientId: clientId }
                    )
                );
            }, 2000);
        }
        else {
            MarsNotifier.Warning(DownloadDocumentsConstants.DownloadNotAllowed, null);
        }
    }

    handleDeleteSignedDocument = (clientId: string, id: number) => {
        this.props.deleteSignedDocument && this.props.deleteSignedDocument(clientId, id, () => { this.props.requestSignedDocuments(clientId, true) });
        logger.trackTrace(`Manual Sign Page: Uploaded Document Deleted from client :${clientId}`);
        logger.trackEvent(
            logger.buildEvent(
                `${LogEventConstants.Common.ManualDownload.DocumentDeleted}  ${pageTitle}`,
                { count: 1, page: pageTitle, clientId: clientId }
            )
        );
    }

    completeManualSign = () => {
        this.props.completeManualSign && this.props.completeManualSign(this._params.clientId, () => {
            logger.trackTrace(`CompleteManualSign for : ${this._params?.clientId}`);
            this.props.onCompletion && this.props.onCompletion()
        });
    }

    getFileNamePrefix = () => {
        return this.props.clientName + "_" + this.props.taxReturn.taxYear + "_" + this.props.taxReturn.engagementType.toString().substr(1);
    }

    getOtherSignedDocumentFileName = () => {
        return this.getFileNamePrefix() + ManualSignConstants.DownloadZipSignedFileName;
    }

    getOtherUnSignedDocumentFileName = (noOfFiles: number) => {
        return this.getFileNamePrefix() + (noOfFiles > 1 ? ManualSignConstants.DownloadZipMultipleUnSignedFileName : ManualSignConstants.DownloadZipUnSignedFileName);
    }

    public render() {

        const params: any = this.props.match.params;
        const { hideFileUploadArea, signedDocuments, addSignedDocument, companyInfo } = this.props;

        return (<React.Fragment>
            <DownloadDocuments clientId={params.clientId} downloadEfileForms={this.downloadESignDocuments} />
            {
                !hideFileUploadArea &&
                <UploadDocumentsArea
                    clientId={params.clientId}
                    signedDocuments={signedDocuments}
                    deleteSignedDocument={this.handleDeleteSignedDocument}
                    addSignedDocument={addSignedDocument}
                    requestSignedDocument={this.handleRequestSignedDocument}
                    isDelegatee={this.props.isDelegatee}
                    disableNextButton={this.props.disableNextButton}
                    companyInfo={companyInfo}
                />
            }
        </React.Fragment>);
    }
}
