import { Action, Reducer } from 'redux';
import { AppThunkAction } from '../';
import { actionTypes } from '../ActionTypes';
import { StatusType, NotificationAction } from '../Common/NotificationStore';
import { IHeaderInfoViewModel, initialHeaderInfoViewModel } from 'src/core/domain/viewModels/IHeaderInfoViewModel';
import { initializeAxios } from 'src/core/services/dataAccess/DataService.Axios'
import { AxiosResponse } from 'axios';
import { ClientType } from 'src/core/common/Enums';
import { ITaxDocumentModel, initialTaxDocumentModel } from 'src/core/domain/models/ITaxDocumentModel';
import { ErrorMessages } from '../../components/Common/Constants';
import { ILoader } from '../../core/utilities/ui/Loader';
import { TYPES } from '../../startup/types';
import { container } from '../../startup/inversify.config';
import { TelemetryLogger } from '../../components/Logger/AppInsights';

const logger = TelemetryLogger.getInstance();

interface RequestHeaderInfoAction {
    type: actionTypes.HEADERINFO_REQUEST;
    id: string;
}

interface ResponseHeaderInfoAction {
    type: actionTypes.HEADERINFO_RESPONSE;
    data: IHeaderInfoViewModel;
}
interface FailureHeaderInfoAction {
    type: actionTypes.HEADERINFO_FAILURE;
    id: string;
}

interface RequestUpdatedTaxclientMobileNumberAction {
    type: actionTypes.UPDATE_TAXCLIENT_MOBILE_NUMBER_REQUEST;
}

interface ResponseUpdatedTaxclientMobileNumberAction {
    type: actionTypes.UPDATE_TAXCLIENT_MOBILE_NUMBER_RESPONSE;
    mobileNumber: string;
    countryCode: string;
    clientType: ClientType;
}

interface RequestRecipientHeaderInfoAction {
    type: actionTypes.RECIPIENT_HEADERINFO_REQUEST;
    id: string;
}

interface ResponseRecipientHeaderInfoAction {
    type: actionTypes.RECIPIENT_HEADERINFO_RESPONSE;
    headerInfo: IHeaderInfoViewModel;
}
interface FailureRecipientHeaderInfoAction {
    type: actionTypes.RECIPIENT_HEADERINFO_FAILURE;
    id: string;
}

interface RequestDelegateeHeaderInfoAction {
    type: actionTypes.DELEGATEE_HEADERINFO_REQUEST;
    id: string;
}

interface ResponseDelegateeHeaderInfoAction {
    type: actionTypes.DELEGATEE_HEADERINFO_RESPONSE;
    delegateeHeaderInfo: IHeaderInfoViewModel;
}
interface FailureDelegateeHeaderInfoAction {
    type: actionTypes.DELEGATEE_HEADERINFO_FAILURE;
    id: string;
}

type KnownAction =
    DispatchAction |
    NotificationAction;

type DispatchAction = ResponseHeaderInfoAction
    | RequestHeaderInfoAction
    | FailureHeaderInfoAction
    | RequestUpdatedTaxclientMobileNumberAction
    | ResponseUpdatedTaxclientMobileNumberAction
    | RequestRecipientHeaderInfoAction
    | ResponseRecipientHeaderInfoAction
    | FailureRecipientHeaderInfoAction
    | RequestDelegateeHeaderInfoAction
    | ResponseDelegateeHeaderInfoAction
    | FailureDelegateeHeaderInfoAction;

const loader = container.get<ILoader>(TYPES.ILoader);
export const actionCreators = {

    requestHeaderInfo: (id: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        let state = getState();
        dispatch({ type: actionTypes.HEADERINFO_REQUEST, id: id });
        return initializeAxios().get<IHeaderInfoViewModel>('/api/Helper/GetHeaderInfoAsync/' + id)
            .then(function (response: AxiosResponse<IHeaderInfoViewModel>) {

                dispatch({
                    type: actionTypes.HEADERINFO_RESPONSE, data: response.data
                });

            })
            .catch(function (error: any) {
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: error.response ? error.response.statusText : ErrorMessages.HeaderInfoError,
                    statusType: StatusType.Error
                });
                dispatch({ type: actionTypes.HEADERINFO_FAILURE, id: id });
                logger.trackError(`requestHeaderInfo failed with error ${error.message} for client: ${id}`);
            });
    },

    refreshToken: (clientId: string, failurecallback: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        // let token = (document.getElementById('RequestVerificationToken') as HTMLInputElement).value;
        // let data = { 'RequestVerificationToken': token };
        // const options = {
        //     headers: {
        //         'RequestVerificationToken': token
        //     }
        // };
        return initializeAxios().post('api/OTP/refresh/' + clientId)
            .then(function () {

            })
            .catch(function (error: any) {
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: error.response ? error.response.statusText : "Failed to refresh token",
                    statusType: StatusType.Error
                });
                failurecallback();
                logger.trackError(`refreshToken failed with error ${error.message} for client: ${clientId}`);
            });
    },

    logout: (clientId: string, successCallback: (id: string) => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        return initializeAxios().post(`/api/Authentication/Logout/${clientId}`)
            .then(function (response: AxiosResponse<string>) {
                successCallback(response.data);
            })
    },
    logOutCC:(clientId:string,successCallback:(url:string)=>void):AppThunkAction<KnownAction>=>(dispatch,getState)=>{
        return initializeAxios().post(`/api/Authentication/RecipientLogout/${clientId}`)
        .then(function(response:AxiosResponse<string>){
            successCallback(response.data);            
        })
    },
    logOutDelegatee:(clientId:string,successCallback:(id:string)=>void):AppThunkAction<KnownAction>=>(dispatch,getState)=>{
        return initializeAxios().post(`api/Authentication/DelegateeLogout/${clientId}`)
        .then(function(response:AxiosResponse<string>){
            successCallback(response.data);
        })
    },
    refreshCookies: (clientId: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        return initializeAxios().get('api/OTP/RefreshCookies/' + clientId)
            .then(function (response: any) {

            })
            .catch(function (error: any) {
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: error.response ? error.response.statusText : "Failed to refresh cookie",
                    statusType: StatusType.Error
                });
                logger.trackError(`refreshCookies failed with error ${error.message} for client: ${clientId}`);
            });
    },

    updateMobileNumber: (clientGuid: string, id: string, number: string, countryCodeValue: string, ssnValue: string, clientType: ClientType, callback: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const state = getState();
        dispatch({ type: actionTypes.UPDATE_TAXCLIENT_MOBILE_NUMBER_REQUEST });
        loader.show();

        const data = { clientId: id, mobileNumber: number, countryCode: countryCodeValue, ssn: ssnValue };
        return initializeAxios().putJson(data, '/api/Esign/UpdateMobileNumber/' + clientGuid)
            .then(function (response: AxiosResponse<ITaxDocumentModel>) {
                loader.hide();
                dispatch({
                    type: actionTypes.UPDATE_TAXCLIENT_MOBILE_NUMBER_RESPONSE, mobileNumber: data.mobileNumber, countryCode: data.countryCode, clientType: clientType
                });
                callback();
            })
            .catch(function (error: any) {
                loader.hide();
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: error.response ? error.response.statusText : ErrorMessages.ChangeMobileNumberError,
                    statusType: StatusType.Error
                });
                logger.trackError(`updateMobileNumber failed with error ${error.message} for client: ${clientGuid}`);
            });
    },

    requestRecipientHeaderInfo: (id: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        let state = getState();
        dispatch({ type: actionTypes.RECIPIENT_HEADERINFO_REQUEST, id: id });
        return initializeAxios().get<IHeaderInfoViewModel>('/api/Helper/GetRecipientHeaderInfoAsync/' + id)
            .then(function (response: AxiosResponse<IHeaderInfoViewModel>) {

                dispatch({
                    type: actionTypes.RECIPIENT_HEADERINFO_RESPONSE, headerInfo: response.data
                });

            })
            .catch(function (error: any) {
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: error.response ? error.response.statusText : ErrorMessages.HeaderInfoError,
                    statusType: StatusType.Error
                });
                dispatch({ type: actionTypes.RECIPIENT_HEADERINFO_FAILURE, id: id });
                logger.trackError(`requestRecipientHeaderInfo failed with error ${error.message} for client: ${id}`);
            });
    },

    requestDelegateeHeaderInfo: (id: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: actionTypes.DELEGATEE_HEADERINFO_REQUEST, id: id });
        return initializeAxios().get<IHeaderInfoViewModel>('/api/Helper/GetDelegateeHeaderInfoAsync/' + id)
            .then(function (response: AxiosResponse<IHeaderInfoViewModel>) {

                dispatch({
                    type: actionTypes.DELEGATEE_HEADERINFO_RESPONSE, delegateeHeaderInfo: response.data
                });

            })
            .catch(function (error: any) {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: error.response ?
                        error.response.statusText :
                        ErrorMessages.HeaderInfoError,
                    statusType: StatusType.Error,
                });
                dispatch({ type: actionTypes.DELEGATEE_HEADERINFO_FAILURE, id: id });
            });
    },
    getSessionTimeOutSeconds: (clientId: string, callBack?: (sessionTimeout: number) => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
		return initializeAxios().get<number>('api/Helper/GetSessionTimeOutSeconds/' + clientId)
			.then(function (response: AxiosResponse<number>) {					
				callBack && callBack(response.data);
			})
			.catch(function (error: any) {	
				dispatch({
					type: actionTypes.NOTIFICATION, statusMessage: error.response ? error.response.statusText : ErrorMessages.GetSessionTimeOutSecondsError,
					statusType: StatusType.Error
				});			
			});
	}
};

export const reducer: Reducer<IHeaderInfoViewModel> = (state: IHeaderInfoViewModel = initialHeaderInfoViewModel, incomingAction: Action) => {
    const action = incomingAction as DispatchAction;
    var currentState = Object.assign({}, state);
    switch (action.type) {
        case actionTypes.HEADERINFO_REQUEST:
            return initialHeaderInfoViewModel;
        case actionTypes.HEADERINFO_RESPONSE:
            return action.data;
        case actionTypes.UPDATE_TAXCLIENT_MOBILE_NUMBER_REQUEST:
            currentState.data = initialTaxDocumentModel;
            return currentState;
        case actionTypes.UPDATE_TAXCLIENT_MOBILE_NUMBER_RESPONSE:
            switch (action.clientType) {
                case ClientType.Taxpayer:
                    currentState.data.taxpayer.mobileNumber = action.mobileNumber;
                    currentState.data.taxpayer.countryCode = action.countryCode;
                    break;
                case ClientType.Spouse:
                    currentState.data.spouse.mobileNumber = action.mobileNumber;
                    currentState.data.spouse.countryCode = action.countryCode;
                    break;
                case ClientType.Partnership:
                    currentState.data.partnership.mobileNumber = action.mobileNumber;
                    currentState.data.partnership.countryCode = action.countryCode;
                    break;
            }
            return currentState;
        default:
            return state || initialHeaderInfoViewModel;
    }
};

export const recipientHeaderInfoReducer: Reducer<IHeaderInfoViewModel> = (state: IHeaderInfoViewModel = initialHeaderInfoViewModel, incomingAction: Action) => {
    const action = incomingAction as DispatchAction;
    var currentState = Object.assign({}, state);
    switch (action.type) {
        case actionTypes.RECIPIENT_HEADERINFO_REQUEST:
            return initialHeaderInfoViewModel;
        case actionTypes.RECIPIENT_HEADERINFO_RESPONSE:
            return action.headerInfo;
        default:
            return state || initialHeaderInfoViewModel;
    }
};

export const delegateeHeaderInfoReducer: Reducer<IHeaderInfoViewModel> = (
    state: IHeaderInfoViewModel = initialHeaderInfoViewModel,
    incomingAction: Action) => {
    const action = incomingAction as DispatchAction;
    switch (action.type) {
        case actionTypes.DELEGATEE_HEADERINFO_REQUEST:
            return initialHeaderInfoViewModel;
        case actionTypes.DELEGATEE_HEADERINFO_RESPONSE:
            return action.delegateeHeaderInfo;
        default:
            return state || initialHeaderInfoViewModel;
    }
};