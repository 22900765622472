import * as React from 'react';
var htmlencode = require('htmlencode');
import { IHeaderInfoViewModel } from '../../core/domain/viewModels/IHeaderInfoViewModel';
import { SvgIconPrint } from '../Common/Icons/SvgIcons';
import { IDelegateeInfo } from '../../core/domain/models/DelegateeModels';

interface PrepareMessageProps {
    message: string;
    headerInfo: IHeaderInfoViewModel;
    delegateeInfo?: IDelegateeInfo;
    isDelegatee?: boolean;
}
export class PreparerMessage extends React.Component<PrepareMessageProps, {}> {

    printPreparerMessage = () => {
        const companyLogo: any = '<div><img src="' + this.props.headerInfo.companyLogoPath + '" width="150" height="50"></div><hr>';
        const printContent: any = document.getElementById("preparer_message");
        const WindowPrt: any = window.open('', '_blank', 'left=0,top=0,width=900,height=900,toolbar=0,scrollbars=0,status=0');
        WindowPrt.document.write('<html><head>');
        WindowPrt.document.write("<style>.preparerMessage { margin-top: 10px;border: 2px solid " + this.props.headerInfo.brandingSettings.coverPageSetting.bgColorCode +
            ";min-height:250px;padding: 20px;position:relative;font-weight: 500;} .preparerMessagePrint{display:none}" +
            ".preparerMessageContainer { margin-left: 20px; margin-right: 20px; padding-left: 20px; padding-right: 20px; }");
        WindowPrt.document.write('</style></head><body>');
        WindowPrt.document.write(companyLogo);
        WindowPrt.document.write(printContent.innerHTML);
        WindowPrt.document.write('</body></html>');
        WindowPrt.document.close();
        WindowPrt.focus();
        WindowPrt.print();
    }

    public render() {
        return (<div id="preparer_message" data-test-auto="5EDC5E02-3A04-4588-9B64-4B51DA8AE12D">
            <div className="preparerMessageContainer">
                <div className="row" data-test-auto="2EEFD3EF-647C-4BF7-9DF4-7F91D13F3CD5">
                    <div style={{ width: "calc(100% - 26px)", fontSize: '20px', fontWeight: 'bolder', marginTop: '-13px' }}
                        data-test-auto="16F217FB-E625-4417-A837-E9EE13BC27CF">
                        A message from {this.props.isDelegatee ? this.props.delegateeInfo?.taxClientName :
                            this.props.headerInfo.companyName}
                    </div>
                    <img src={this.props.headerInfo.companyLogoPath} hidden={true} />
                    <SvgIconPrint
                        height={20}
                        className="preparerMessagePrint"
                        data-test-auto="4BB44852-6962-4D6F-B507-3633DF0F2ECF"
                        onClick={this.printPreparerMessage.bind(this)} />
                </div>
                <div className="row preparerMessage" data-test-auto="14A7D44C-1F5D-4110-95C4-ED64C6FA13E6">
                    <div dangerouslySetInnerHTML={{ __html: htmlencode.htmlDecode(this.props.message) }}></div>
                </div>
            </div>
        </div>);
    }
}
