import * as React from 'react';
import { WebrtcSignalR } from './WebrtcSignalR';
import * as HelperStore from '../../store/Common/HelperStore';
import { match, RouteComponentProps } from 'react-router';
import { SvgIconStopScreenShare } from '../Common/Icons/SvgIcons';
import { logger } from '../../routes';
import { LogEventConstants } from '../Helper/Constants';


type ScreenShareProps =
    {
        match: match;
        iceServers: RTCIceServer[];
        getIceServers: (clientId: string) => void;
    }
    & typeof HelperStore.actionCreators
    & RouteComponentProps<{}>;

export interface ScreenShareState {
    cssClass: string;
};

const pageTitle = "Screen Share page";
export class ScreenShare extends React.Component<ScreenShareProps, ScreenShareState> {
    videoRef: React.RefObject<HTMLVideoElement>;
    webrtcRef: any;
    remoteStream: MediaStream;

    constructor(props: ScreenShareProps) {
        super(props);

        this.state = {
            cssClass: 'disabled'
        }

        this.remoteStream = new MediaStream();
        this.videoRef = React.createRef();
        this.webrtcRef = React.createRef();
        logger.trackPageView(`ScreenShare page`);
        const param: any = this.props.match.params;
        logger.trackEvent(
            logger.buildEvent(
                `${LogEventConstants.Common.Open} ${pageTitle}`,
                { count: 1, page: pageTitle, clientId: param.clientId }
            )
        );
    }

    setStreamOnVideoElement = () => {
        if (this.videoRef.current !== null && this.videoRef.current !== undefined) {
            this.videoRef.current.srcObject = this.remoteStream;
        }
        const param: any = this.props.match.params;
        logger.trackEvent(
            logger.buildEvent(
                `${LogEventConstants.Common.ScreenShare.SetStream} ${pageTitle}`,
                { count: 1, page: pageTitle, clientId: param.clientId }
            )
        );
    }

    attachMediaStream = (evt: RTCTrackEvent) => {
        logger.trackTrace(`ScreenShare page : attachMediaStream begins execution`);
        if (evt.track.kind === "audio") {
            this.remoteStream.addTrack(evt.track);
        }
        if (evt.track.kind === "video") {
            this.remoteStream.addTrack(evt.track);
        }
        if (this.videoRef.current !== null && this.videoRef.current !== undefined) {
            this.videoRef.current.srcObject = this.remoteStream;
        }
        this.setState({ cssClass: 'enabled' });
        logger.trackTrace(`ScreenShare page : attachMediaStream completed execution`);
        const param: any = this.props.match.params;
        logger.trackEvent(
            logger.buildEvent(
                `${LogEventConstants.Common.ScreenShare.AttachMediaStream} ${pageTitle}`,
                { count: 1, page: pageTitle, clientId: param.clientId }
            )
        );
    };

    removeMediaStream = () => {
        logger.trackTrace(`ScreenShare page : removeMediaStream begins execution`);
        if (this.videoRef.current) {
            this.videoRef.current.srcObject = null;
            logger.trackTrace(`ScreenShare page : Removed remote screen sharing stream`);
        }

        this.remoteStream &&
            this.remoteStream.getTracks().forEach((track) => {
                track.stop();
            });

        this.setState({ cssClass: 'disabled' });
        logger.trackTrace(`ScreenShare page : removeMediaStream completed execution`);
        const param: any = this.props.match.params;
        logger.trackEvent(
            logger.buildEvent(
                `${LogEventConstants.Common.ScreenShare.DetachMediaStream} ${pageTitle}`,
                { count: 1, page: pageTitle, clientId: param.clientId }
            )
        );
    };

    handleHangup = () => {
        const param: any = this.props.match.params;
        logger.trackEvent(
            logger.buildEvent(
                `${LogEventConstants.Common.ScreenShare.HangUp} ${pageTitle}`,
                { count: 1, page: pageTitle, clientId: param.clientId }
            )
        );
        this.webrtcRef.onHangup();
        
    }

    public render() {
        return (<div>
            <video id="video" ref={this.videoRef} className="screen partner" autoPlay></video>
            <div id="controls" className="vertical-center">
                <SvgIconStopScreenShare className={'stop ' + this.state.cssClass} onClick={this.state.cssClass === 'enabled' ? this.handleHangup : undefined} />
            </div>
            <WebrtcSignalR
                ref={ref => this.webrtcRef = ref}
                isDebugEnabled={false}
                match={this.props.match}
                isCPAMode={true}
                handleStream={this.setStreamOnVideoElement}
                handleRemoveStream={this.removeMediaStream}
                handleTrack={this.attachMediaStream}
                getIceServers={this.props.getIceServers}
                iceServers={this.props.iceServers }
            />
        </div>);
    }
}