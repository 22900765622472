import * as React from 'react';
import * as TaxDocumentStore from '../../../store/Common/TaxDocumentStore';
import * as TaxDocument from '../../../core/domain/models/ITaxReturn';
import { match, RouteComponentProps } from 'react-router';
import { ICompany } from '../../../core/domain/models/company/Company';
import * as CompanyStore from '../../../store/Common/Company/CompanyStore';
import { History } from 'history';
import { IHeaderInfoViewModel, initialHeaderInfoViewModel } from '../../../core/domain/viewModels/IHeaderInfoViewModel'
import { DistributeType } from '../../../core/common/Enums';
import { K1DistributionMethod } from '../../K1Distribution/K1DistributionMethod';
import { PathConstants } from '../../Common/Constants';
import { Body } from '../../Common/StepLayout/Body';
import { StepLayout } from '../../Common/StepLayout/StepLayout';
import { Next } from 'awesome-multi-steps/dist/Footer/Next';
import { Previous } from 'awesome-multi-steps/dist/Footer/Previous';
import { Footer } from '../../Common/StepLayout/Footer';
import TaxpayerHelper from '../../Helper/TaxpayerHelper';
import { IClientProcessViewModel } from '../../../core/domain/viewModels/IClientProcessViewModel'
import { Role } from '../../../core/common/Enums'
import * as SignProcessStore from '../../../store/SignProcess/SignProcessStore';
import { GetK1EngagementType } from '../../../core/domain/models/IK1Distribute';

type K1DistributionCompletedProps = {
    taxReturn: TaxDocument.ITaxReturn,
    headInfoViewModel: IHeaderInfoViewModel,
    companyData: ICompany,
    match: match;
    history: History;
}
    & typeof CompanyStore.actionCreators
    & typeof TaxDocumentStore.actionCreators
    & typeof SignProcessStore.actionCreators;


interface K1DistributionCompletedState {
    isProcessCompleted: boolean;
}

export class K1DistributionCompleted extends React.Component<K1DistributionCompletedProps, K1DistributionCompletedState> {
    private _params: any;
    constructor(props: K1DistributionCompletedProps) {
        super(props);
        this.state = {
            isProcessCompleted: false,
        }
    }

    componentDidMount() {
        const param: any = this.props.match.params;
        this.getSignProcessStatus(param.clientId);
        this.props.requestTaxDocument(param.clientId);
    }
    handleSetDistributeMethod = (type: DistributeType) => {
        const param: any = this.props.match.params;
        DistributeType.Manual === type ? this.props.history.push(`${PathConstants.DistributeK1Manually}${param.clientId}`) : this.props.history.push(`${PathConstants.DistributeK1Electronically}${param.clientId}`);
    } 
    //private clearState = () => {
    //    this.setState({
    //        hideNextButton: false,
    //        hidePreviousButton: false,
    //        isNextButtonDisable: false
    //    })
    //}
    gotoCompletionWizard = () => {
        const param: any = this.props.match.params;
        this.props.history.push(`${PathConstants.CompletionWizard}${param.clientId}`);
    }
    private getSignProcessStatus = (clientId: any) => {
        this.props.requestCurrentStepAndUserRole(clientId, this.handleRedirect);
    }
    private handleRedirect = (clientProcessState: IClientProcessViewModel) => {
        this._params = this.props.match.params;
        const url = TaxpayerHelper.getSignCompletedRedirectUrl(clientProcessState, this._params.clientId);
        if (url && url.trim()) {
            this.props.history.push(url);
        }
        else {
            this.setState({ isProcessCompleted: true });
        }
    }
    public render() {

        const type = this.props.taxReturn && GetK1EngagementType(this.props.taxReturn.engagementType);

        return ((this.state.isProcessCompleted) &&

            <StepLayout className={"with-footer"} marginTop={20}>
                <Body lg={10} md={12} sm={12} xs={12}
                    title={`Distribute K-1 Documents to ${type}`}
                    subTitle={`Select the method you would like to distribute your K-1's`}
                    automationTestId={"11BB28B5-42F3-4571-B567-20700B1740B6"}>
                {this.props.taxReturn.formGroups.length > 0 &&
                    <K1DistributionMethod
                        taxReturn={this.props.taxReturn}
                        onSetDistributeMethod={this.handleSetDistributeMethod}
                    />
                }
            </Body>

                <Footer lg={10} md={12} sm={12} xs={12}>
                <div className={"col-lg-12 steps-footer "}>
                    <Previous automationTestId={"3F43C07D-73AD-4BB5-83D1-346B1E62ABC6"}
                        text={"Back"} goToPreviousStep={this.gotoCompletionWizard} isActive={true} />

                    <Next automationTestId={"21596F53-89B5-4830-812E-96D126E7C1AE"}
                        text={"Done"} goToNextStep={this.gotoCompletionWizard} isActive={true} />
                </div>
            </Footer>

                </StepLayout>); 

    }
}
