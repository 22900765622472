import * as React from 'react';
import { SignatureType } from '../../../core/common/Enums';
import { IDialogBox } from '../../../core/utilities/ui/DialogBox';
import { container } from '../../../startup/inversify.config';
import { TYPES } from '../../../startup/types';
import { SiginingConstants, Common } from '../../Common/Constants';
import { CustomLargeButton } from '../../Common/CustomButton/CustomLargeButton';
import { StepLayout } from '../../Common/StepLayout/StepLayout';
import { Body } from '../../Common/StepLayout/Body';
import { logger } from '../../../routes';
import { LogEventConstants } from '../../Helper/Constants';


interface SelectSignMethodProps {
    onSetSignMethod: (signatureType: SignatureType) => void;
    clearParentState: () => void;
}


const dialogBox = container.get<IDialogBox>(TYPES.IDialogBox);

const pageTitle = "Delegatee Sign Method Selection Step";
export class SelectSignMethod extends React.Component<SelectSignMethodProps, {}>
{
    constructor(props: any) {
        super(props);
        this.props.clearParentState();
        logger.trackEvent(
            logger.buildEvent(
                `${LogEventConstants.Common.Open}  ${pageTitle}`,
                { count: 1, page: pageTitle }
            )
        );
    }

    private selectSignMethodConfirm = (signatureType: SignatureType) => {
        dialogBox.confirm(
            Common.DialogBox.ConfirmationTitle,
            SiginingConstants.StatusMessage.ManualSignSelectConfirmationMessage,
            Common.DialogBox.No,
            Common.DialogBox.Yes,
            (result: boolean) => { result && this.setSignMethod(signatureType) });
    }

    private setSignMethod = (signatureType: SignatureType) => {

        const { onSetSignMethod } = this.props;
        onSetSignMethod(signatureType);
        logger.trackEvent(
            logger.buildEvent(
                `${LogEventConstants.Common.Delegatee.SelectSignMethod}  ${pageTitle}`,
                { count: 1, page: pageTitle, type: signatureType }
            )
        );
    }

    render() {

        return (<StepLayout>
            <Body title={SiginingConstants.SelectSignTitle}
                subTitle={SiginingConstants.SelectSignMessage}
                lg={10} md={12} sm={12} xs={12}
            >

                <div className={"row col-lg-12"} style={{ height: "100%" }}>

                    <div style={{ margin: "14% auto auto auto", textAlign: "center" }}>
                        <CustomLargeButton text={SiginingConstants.ESignImageCaption}
                            className={"margin-bottom-10"}
                            icon={Common.Images.ElectronicalSignIcon}
                            onClick={() => { this.setSignMethod(SignatureType.ESignWhenAllIncludedReturnsAllowed) }} />
                        <span className="large-button-footer-text">{SiginingConstants.EsignCaption}</span>
                    </div>

                    <div style={{ margin: "14% auto auto auto", textAlign: "center" }}>
                        <CustomLargeButton text={SiginingConstants.ManuallySignImageCaption}
                            className={"margin-bottom-10"}
                            icon={Common.Images.ManuallySignIcon}
                            onClick={() => { this.selectSignMethodConfirm(SignatureType.ManualSign) }} />
                        <span className="large-button-footer-text">{SiginingConstants.ManuallySignCaption}</span>
                    </div>

                </div>



            </Body>
        </StepLayout>);

    }
}

export default SelectSignMethod;