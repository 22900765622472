import * as React from 'react';
import {
    Col, Row,
    Modal, Button
} from 'react-bootstrap';
import {
    CCRecipientConstants, Common,
    SiginingConstants, K1ShareHolderConstants
} from '../Common/Constants';
import { isValidEmailAddress, NullandEmptyCheck, isValidEmailAddresses } from '../Common/Validations';
import { MarsNotifier } from '../Common/Notification/MarsNotifier';
var htmlencode = require('htmlencode');
import {
    ICCRecipientDownloadableDocuments, ICCRecipientModel,
    CCRecipientDocumentGroups
} from '../../core/domain/models/ICCRecipientModel';
import { Popover, OverlayTrigger, Alert, Overlay } from 'react-bootstrap';
import { match } from "react-router";
import { container } from '../../startup/inversify.config';
import { TYPES } from '../../startup/types';
import { IDialogBox } from '../../core/utilities/ui/DialogBox';
import { SelectComponent } from '../Common/SelectComponent';
import { CountryCodeOptions } from '../Helper/HelperFunction';
import { PhoneNumberComponent } from '../Common/PhoneNumberComponent';
import { DocumentGroups } from '../../core/domain/models/IGroup';
import { logger } from '../../routes';
import { LogEventConstants } from '../Helper/Constants';
import { Guid } from 'src/core/utilities/Guid';

export interface CCRecipientProps {
    show?: boolean;
    closeCCRecipientModal?: () => void;
    downloadableDocuments?: ICCRecipientDownloadableDocuments[];
    taxdocumentId?: number;
    forwardDocuments?: (clientId: string, recipients: ICCRecipientModel, callback: () => void) => void;
    match: match;
    taxyear?:number;
}

interface CCRecipientState {
    ccRecipients: IRecipient[];
    documents: number[];
    showAlert: boolean;
    showPopOver: boolean;
    popoverTarget: any;
}

export interface IRecipient {
    clientGuid: Guid;
    email: string;
    mobileNumber: string;
    countryCode: string;
}

const dialogBox = container.get<IDialogBox>(TYPES.IDialogBox);


const pageTitle = "Add CC Recipient Modal";

export class AddCCRecipientModal extends React.Component<CCRecipientProps, CCRecipientState>{
    private attachAddPopoverRef = (target: any) => { this.setState({ popoverTarget: target }) };

    constructor(props: any) {
        super(props);
        this.state = {
            ccRecipients: [{ clientGuid: Guid.newGuid(), email: "", mobileNumber: "", countryCode: "" }],
            documents: [],
            showAlert: false,
            showPopOver: false,
            popoverTarget: {},
        }
    }

    onEmailChange = (e: any, index: number) => {
        const recipientEmail = e.target.value;
        let ccRecipient = this.state.ccRecipients;
        ccRecipient[index].email = recipientEmail;
        this.setState({
            ccRecipients: ccRecipient
        });
    }

    onNumberChange = (value: string, index: number) => {
        const recipientNumber = value;
        let ccRecipient = this.state.ccRecipients;
        ccRecipient[index].mobileNumber = recipientNumber;
        this.setState({
            ccRecipients: ccRecipient
        });
    }

    onChangeCountryCode = (value: string, index: number) => {
        const recipientCountryCode = value;
        let ccRecipient = this.state.ccRecipients;
        ccRecipient[index].countryCode = recipientCountryCode;
        this.setState({
            ccRecipients: ccRecipient
        });
    }

    onEmailInputKeyDown = (e: any, idx: number) => {
        if (e.keyCode === 13 || e.keyCode === 9) {
            e.preventDefault();
            if (NullandEmptyCheck(this.state.ccRecipients[idx].email)) {
                if (!isValidEmailAddress(this.state.ccRecipients[idx].email)) {
                    MarsNotifier.Warning(SiginingConstants.WarningMessage.InvalidEmailAddress, null);
                    return;
                }
                this.getCountryCodeInputFocus(idx);
            }
            else {
                MarsNotifier.Warning(K1ShareHolderConstants.WarningMessage.EmailAdrressEmpty, null);
                return;
            }
        }
    }

    onNumberInputKeyDown = (e: any, idx: number) => {
        if (e.keyCode === 13 || e.keyCode === 9) {
            e.preventDefault();
            if (idx < this.state.ccRecipients.length - 1) {
                this.getEmailInputFocus(idx + 1);
            }
            else {
                const focusOutInput = document.getElementById("ccMobile" + idx);
                focusOutInput && focusOutInput.blur();
            }
        }
    }

    onCountryCodeInputKeyDown = (e: any, idx: number) => {
        if (e.keyCode === 13 || e.keyCode === 9) {
            this.getNumberInputFocus(idx);
        }
    }

    handleDelete = (inputToDelete: IRecipient, idx: number) => {
        if (idx > 0) {
            this.setState({
                ccRecipients: this.state.ccRecipients.filter((input, index) => idx !== index)
            });
        }
        else {
            let recipients = this.state.ccRecipients;
            recipients[idx].mobileNumber = "";
            recipients[idx].email = "";
            recipients[idx].countryCode = "";
            this.setState({
                ccRecipients: recipients
            });
        }

        const param: any = this.props.match.params;
        logger.trackEvent(
            logger.buildEvent(
                `${LogEventConstants.Common.CcRecipients.DeleteRow} ${idx} ${pageTitle}`,
                { count: 1, page: pageTitle, clientId: param.clientId }
            )
        );
    }

    handleClose = () => {
        if (this.state.ccRecipients.some(x => x.email != "") || this.state.ccRecipients.some(x => x.mobileNumber != "") ||
            this.state.ccRecipients.some(x => x.countryCode != "")) {
            this.setState({ showPopOver: true });
            return;
        }
        this.clearSections();
        this.props.closeCCRecipientModal && this.props.closeCCRecipientModal();
        const param: any = this.props.match.params;
        logger.trackEvent(
            logger.buildEvent(
                `${LogEventConstants.Common.Close} ${pageTitle}`,
                { count: 1, page: pageTitle, clientId: param.clientId }
            )
        );
    }

    saveDocumentsToShow = (event: any, documentGroup: CCRecipientDocumentGroups) => {
        if (event.target.checked) {
            if (documentGroup == CCRecipientDocumentGroups.FilingForms) {

                let filingFormsDownload: ICCRecipientDownloadableDocuments[] = [];
                this.props.downloadableDocuments?.filter(x => x.documentGroup == CCRecipientDocumentGroups.SignedEFile ||
                    x.documentGroup == CCRecipientDocumentGroups.TaxReturns ||
                    x.documentGroup == CCRecipientDocumentGroups.Vouchers).forEach(x => filingFormsDownload.push(x));

                var documentGroupdata = filingFormsDownload.map((value, index) => { return value.documentGroup as number; })
                let documentGroups = [...this.state.documents, ...documentGroupdata]
                this.setState({ documents: documentGroups });
            }
            else {
                let documentGroups = [...this.state.documents, documentGroup]
                this.setState({ documents: documentGroups });
            }
        }
        else {
            if (documentGroup == CCRecipientDocumentGroups.FilingForms) {

                this.setState({
                    documents: this.state.documents.filter(groupId => groupId !== CCRecipientDocumentGroups.SignedEFile &&
                        groupId !== CCRecipientDocumentGroups.Vouchers && groupId !== CCRecipientDocumentGroups.TaxReturns)
                });

            } else {
                this.setState({ documents: this.state.documents.filter((groupId, index) => groupId !== documentGroup) });
            }
        }
        const param: any = this.props.match.params;
        logger.trackEvent(
            logger.buildEvent(
                `${LogEventConstants.Common.CcRecipients.DocumentsToShow} ${pageTitle}`,
                { count: 1, page: pageTitle, clientId: param.clientId, doucumentIds: this.state.documents.join(), documentGroup: CCRecipientDocumentGroups }
            )
        );
    }

    mailDocumentsToRecipients = () => {

        if (!this.state.ccRecipients.every(x => x.email != "")) {
            MarsNotifier.Warning(CCRecipientConstants.CcEmptyWarning, null);
            return;
        }
        else if (this.state.documents.length == 0) {
            MarsNotifier.Warning(CCRecipientConstants.DocumentsNotSelectedWarning, null);
            return;
        }

        if (!isValidEmailAddresses(this.state.ccRecipients.map((recipient, index) => { return recipient.email }))) {
            MarsNotifier.Warning(CCRecipientConstants.InvalidEmail, null);
            return;
        }

        let recipientInfo: ICCRecipientModel = { documentId: 0, downloadableDocuments: [], recipients: [], taxyear: 0 };
        recipientInfo.documentId = this.props.taxdocumentId;
        recipientInfo.recipients = this.state.ccRecipients;
        recipientInfo.downloadableDocuments = this.state.documents;
        recipientInfo.taxyear = this.props.taxyear;
        const param: any = this.props.match.params;
        this.props.forwardDocuments && this.props.forwardDocuments(param.clientId, recipientInfo, () => { this.onSuccess() });
        logger.trackEvent(
            logger.buildEvent(
                `${LogEventConstants.Common.CcRecipients.SendMail} ${pageTitle}`,
                { count: 1, page: pageTitle, clientId: param.clientId, doucumentIds: this.state.documents.join(), recipients: this.state.ccRecipients.map(x => x.email) }
            )
        );
    }

    onSuccess = () => {
        dialogBox.alert(CCRecipientConstants.SuccessfulyMailedDocuments, () => { this.confirmPopoverClose() });
    }

    getNumberInputFocus = (index: number) => {
        const inputNumberRef = document.getElementById("ccMobile" + index);
        inputNumberRef && inputNumberRef.focus();
    }

    getEmailInputFocus = (index: number) => {
        const inputEmailRef = document.getElementById("ccEmail" + index);
        inputEmailRef && inputEmailRef.focus();
    }

    getCountryCodeInputFocus = (index: number) => {
        const inputCountryCodeRef: any = document.getElementById('ccRecipientCountryCode' + index);
        const innerHtml: any = inputCountryCodeRef && inputCountryCodeRef.children[0].children[0].children[1].children[0].innerHTML;
        const selectorId: string = innerHtml && innerHtml.match(/react-select-\d+-input/)[0];
        const countrySelector = document.getElementById(selectorId);
        countrySelector && countrySelector.focus();
    }

    clearSections = () => {
        this.setState({
            ccRecipients: [{ clientGuid: Guid.newGuid(), email: "", mobileNumber: "", countryCode: "" }],
            documents: [],
        });
        const param: any = this.props.match.params;
        logger.trackEvent(
            logger.buildEvent(
                `${LogEventConstants.Common.CcRecipients.Clear} ${pageTitle}`,
                { count: 1, page: pageTitle, clientId: param.clientId }
            )
        );
    }

    addNewRecipientInputRow = () => {
        let recipients = [...this.state.ccRecipients, { clientGuid: Guid.newGuid(), email: "", mobileNumber: "", countryCode: "" }];

        this.setState({ ccRecipients: recipients });
        const param: any = this.props.match.params;
        logger.trackEvent(
            logger.buildEvent(
                `${LogEventConstants.Common.CcRecipients.AddNewRow} ${pageTitle}`,
                { count: 1, page: pageTitle, clientId: param.clientId }
            )
        );
    }

    confirmPopoverClose = () => {
        this.setState({ showPopOver: false });
        this.clearSections();
        this.props.closeCCRecipientModal && this.props.closeCCRecipientModal();
        const param: any = this.props.match.params;
        logger.trackEvent(
            logger.buildEvent(
                `${LogEventConstants.Common.Close} ${pageTitle}`,
                { count: 1, page: pageTitle, clientId: param.clientId }
            )
        );
    }

    hidePopover = () => {
        this.setState({ showPopOver: false });
    }

    deleteConfirmation = () => {
        return (
            <Popover id="closeCCRecipientPopover" placement="left"
                data-test-auto="C4ED4674-B4EA-4649-BD90-47F944980BB6">
                <h3 className="popover-header">Confirm Discard</h3>
                <div className="popover-body">
                    <div>Your changes will be discarded, Are you sure you want to discard? </div>
                    <Row>
                        <Col sm={4}>
                            <button
                                className="btn ccRecipientPopover-confirm-btn"
                                onClick={this.confirmPopoverClose}
                                data-test-auto="1E01E792-06DB-4361-A944-9A010FAB9EB6"
                                style={{ marginLeft: "30px" }}>
                                Yes
                            </button>
                        </Col>
                        <Col sm={4}>
                            <button
                                data-test-auto="313B8870-6D87-40F8-B2C0-FEE5C2423279"
                                className="btn ccRecipientPopover-confirm-btn"
                                style={{ marginLeft: "35px" }}
                                onClick={this.hidePopover}>
                                No
                            </button>
                        </Col>
                    </Row>
                </div>
            </Popover>
        );
    }

    showSuccessAlert = () => {
        return (
            <Alert show={this.state.showAlert} variant="success" data-test-auto="664C5275-3E9C-4F7B-B04F-A03CC43D63A3">
                <p data-test-auto="83D62FD4-D2DB-480E-99A2-13A3F490E78D">
                    Email sent Successfully
                </p>
                <hr />
                <div className="d-flex justify-content-end" >
                    <Button variant="outline-success" data-test-auto="5CA98E7B-BAF7-4DBC-A76B-AFA1F2F5F8E7">
                        Ok
                    </Button>
                </div>
            </Alert>

        );
    }

    public render() {
        var downloadableFiles = [];
        this.props.downloadableDocuments?.filter(x => x.documentGroup != CCRecipientDocumentGroups.SignedEFile &&
            x.documentGroup != CCRecipientDocumentGroups.TaxReturns &&
            x.documentGroup != CCRecipientDocumentGroups.Vouchers).forEach(x => downloadableFiles.push(x));

        this.props.downloadableDocuments?.some(x => x.documentGroup == CCRecipientDocumentGroups.SignedEFile ||
            x.documentGroup == CCRecipientDocumentGroups.TaxReturns ||
            x.documentGroup == CCRecipientDocumentGroups.Vouchers) ? downloadableFiles.push({ fileName: "Filing Forms", documentGroup: CCRecipientDocumentGroups.FilingForms }) : "";

        return (
            <Modal show={this.props.show && this.props.show} id="ccRecipient-modal">
                <Modal.Header data-test-auto="01897DA0-02F5-45C6-9D29-E0C2F0C4E7C9">
                    <Modal.Title style={{ color: "#fff" }}>
                        <div>{Common.Wizard.ForwardDocuments}</div>
                        <button type="button" className="close" data-dismiss="modal" data-test-auto="75613D09-8326-4FE6-B256-F30085B56534"
                            onClick={this.handleClose} ref={this.attachAddPopoverRef}>&times;</button>
                        <Overlay placement="left"
                            data-test-auto="AE380218-167B-4BD2-A179-75BC737BCEF7"
                            target={this.state.popoverTarget}
                            show={this.state.showPopOver}
                            rootClose={true}>
                            {this.deleteConfirmation}
                        </Overlay>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <div data-test-auto="BAD25332-6C0A-46C0-88DA-6FD34DDD0F69">
                            <div dangerouslySetInnerHTML={{ __html: htmlencode.htmlDecode(CCRecipientConstants.ModalDescription) }}></div>
                        </div>
                        <br />
                        <div data-test-auto="5F5FF704-5BF5-4A7B-9B77-CC8B82EEC7CC">
                            <span style={{ color: "red" }}>{CCRecipientConstants.Note}</span>
                        </div>
                        <Row className='w-100 m-0'>
                            <Col sm={1}></Col>
                            <Col sm={10}>
                                <Row className="form-group">
                                    <Col xs={1}><label className="cc-title" data-test-auto="4E8B058F-1E3F-4096-A0BC-FBC981026776">CC:</label></Col>
                                    <Col xs={10}>
                                        <div className="ccRecipient-input-titles">
                                            <Col xs={5} >
                                                <div data-test-auto="2BE442A6-D078-499C-96BB-B6DB6107FFE6" className="ccRecipient-title-highlight">Email Address</div>
                                            </Col>
                                            <Col xs={2} />
                                            <Col xs={8} >
                                                <div data-test-auto="84A46BF2-11EA-4A26-8BCC-66BD6AE22D35" className="ccRecipient-title-highlight">Phone Number</div>
                                            </Col>
                                        </div>
                                    </Col>
                                </Row>

                                {
                                    this.state.ccRecipients.length > 0 &&

                                    this.state.ccRecipients.map((recipient, index) => {
                                        return (

                                            <Row className="form-group" key={recipient.clientGuid.toString()} style={{ marginTop: '-8px' }}>
                                                <Col xs={1}>
                                                    <div onClick={() => { this.handleDelete(recipient, index) }} data-test-auto="6944FE2C-8C70-4334-8049-454A2D7809F8">
                                                        <i className="fa fa-times cc-remove-recipient"></i>
                                                    </div>
                                                </Col>
                                                <Col xs={5}>

                                                    <div className="div-chip-list">
                                                        <input
                                                            id={"ccEmail" + index}
                                                            className="chip-input"
                                                            type="text"
                                                            onChange={(event: any) => { this.onEmailChange(event, index) }}
                                                            onKeyDown={(e: any) => { this.onEmailInputKeyDown(e, index) }}
                                                            value={this.state.ccRecipients[index].email}
                                                            data-lpignore="true"
                                                            data-test-auto="FE0309F3-01BC-4517-A8FD-0F12B2A4BF9F"
                                                        />

                                                    </div>
                                                </Col>

                                                <div className="col-sm-2 col-md-2 col-xl-2 phoneNumberSelect">
                                                    <SelectComponent
                                                        id={"ccRecipientCountryCode" + index}
                                                        options={CountryCodeOptions()}
                                                        onChange={(event: any) => { this.onChangeCountryCode(event, index) }}
                                                        selectedValue={this.state.ccRecipients[index].countryCode}
                                                        trackKeyEvent={(e: any) => { this.onCountryCodeInputKeyDown(e, index) }}
                                                        isCcCountrySelector={true}
                                                        data-test-auto="F4C7445C-E947-423D-BE32-1D3817519C5C"
                                                    />
                                                </div>
                                                <Col xs={4} className="ccRecipient-input-number">
                                                    <PhoneNumberComponent
                                                        id={"ccMobile" + index}
                                                        isCcPhoneInput={true}
                                                        phoneNumber={this.state.ccRecipients[index].mobileNumber}
                                                        handleChangePhoneNumber={(value: string) => this.onNumberChange(value, index)}
                                                        data-test-auto="8243250E-2983-4267-B864-8EFA449BDAAB"
                                                        className="height-38"
                                                        trackKeyEvent={(e: any) => { this.onNumberInputKeyDown(e, index) }}
                                                    />
                                                </Col>
                                            </Row>)
                                    })
                                }
                                <Row>
                                    <Col xs={4}>
                                        <div onClick={this.addNewRecipientInputRow} data-test-auto="F8A14D37-AAB9-44FE-99F8-43D568E14F83">
                                            <i className="fa fa-plus cc-add-recipient"></i>
                                            Add New Contact</div>
                                    </Col>
                                </Row>
                                <Row className="cc-details-container"></Row>
                                <Row className="form-group" style={{ marginTop: 10 }}>
                                    <Col xs={6}>
                                        <div data-test-auto="F366A8F2-401C-4188-9DE3-3CF62F568763" className="cc-choose-docs">{CCRecipientConstants.DocumentForwardText}</div>
                                    </Col>
                                    <Col xs={2} />
                                    <Col xs={10} style={{ marginTop: 5, marginLeft: 40 }}>
                                        <div className="col-sm-10" data-test-auto="70DE7921-95CD-4E39-B77C-EC7DC99BF346">
                                            {downloadableFiles && downloadableFiles.map((document, i) => {
                                                let isFilingForm
                                                if (document.documentGroup == CCRecipientDocumentGroups.FilingForms) {
                                                    isFilingForm = this.state.documents.findIndex(groupId => groupId == DocumentGroups.EFile || groupId == DocumentGroups.Vouchers || groupId == DocumentGroups.TaxReturns);
                                                }
                                                else {
                                                    isFilingForm = this.state.documents.findIndex(groupId => groupId == document.documentGroup);
                                                }

                                                return (
                                                    <div key={document.fileName + i}>
                                                        <Row>
                                                            <Col xs={1}>
                                                                <input type="checkbox" className="input-document-list" data-test-auto={"18EF199A-D9BE-42A6-ABAB-533174ABD07" + i}
                                                                    key={document.fileName + document.documentGroup}
                                                                    checked={isFilingForm > -1 ? true : false}
                                                                    onChange={(e: any) => this.saveDocumentsToShow(e, document.documentGroup)} />
                                                            </Col>
                                                            <Col xs={8} data-test-auto="5D2CC712-DA7E-451B-96E6-4EA34176B0C0">
                                                                <label>{document.documentGroup == CCRecipientDocumentGroups.FilingForms ?
                                                                    "Tax Documents" : document.documentGroup == CCRecipientDocumentGroups.Transmittals ?
                                                                        "Filing Instructions" : document.fileName}</label>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                )
                                            })
                                            }
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        className="btn-white email-document-btn"
                        data-test-auto="E927BDC4-A00E-4A67-9D5D-F5CA7CC46BBB"
                        onClick={this.mailDocumentsToRecipients}>
                        Email Tax Documents
                    </Button>
                </Modal.Footer>
            </Modal >

        )
    }
}