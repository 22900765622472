import { AxiosResponse } from 'axios';
import { Action, Reducer } from 'redux';
import { actionTypes } from './../../../ActionTypes';
import { NotificationAction, StatusType } from './../../../Common/NotificationStore';
import { AppThunkAction } from './../../../index';
import { CoverPageConstants, DisplayError } from './../../../../components/Common/Constants';
import {
    CoverPageLoaderAction, CoverPageStoreState,
    initialCoverPageStoreState,
    LoginResponse,
    ReceiveCoverPageDetailsAction,
    ErrorMessageAction
} from '../../Models/StoreModels';
import { initializeAxios } from 'src/core/services/dataAccess/DataService.Axios';

type KnownAction =
    DispatchAction |
    NotificationAction |
    ErrorMessageAction;

type DispatchAction =
    ReceiveCoverPageDetailsAction
    | CoverPageLoaderAction;

export const actionCreators = {
    requestCoverClientData: (clientId: string, errorCallBack: (message: string) => void): AppThunkAction<KnownAction> => (dispatch, getState) => {

        dispatch({
            type: actionTypes.COVER_PAGE_LOADER, loading: true,
        });

        return initializeAxios().get<any>("api/Coverpage/GetClientInfo/" + clientId)
            .then((response: AxiosResponse<LoginResponse>) => {
                const result = response.data;
                if (result.isSuccess) {
                    dispatch({
                        type: actionTypes.RECEIVE_COVER_PAGE_DETAILS,
                        data: { ...result.data, loading: false }
                    });

                } else {
                    const errorDesc = result.errorDescription;
                    const errorCode = result.errorCode;

                    if (errorCode && DisplayError.includes(errorCode)) {
                        dispatch({
                            type: actionTypes.SET_ERROR_MESSAGE,
                            message: errorDesc,
                        });
                        errorCallBack(errorDesc);
                    } else {
                        dispatch({
                            type: actionTypes.NOTIFICATION,
                            statusMessage: errorDesc,
                            statusType: StatusType.Error,
                        });
                    }

                }
            })
            .catch((e: any) => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: CoverPageConstants.ErrorMessage.clientFetchFailed,
                    statusType: StatusType.Error
                });
            });
    },
    getGuid: (clientId: string, navigate: (guid: string) => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        return initializeAxios(clientId).get<any>("api/Coverpage/GetGUID/" + clientId)
            .then((response: AxiosResponse<string>) => {
                const result = response.data;
                navigate(result);
            })
            .catch((e: any) => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: CoverPageConstants.ErrorMessage.serverError,
                    statusType: StatusType.Error
                });
            });

    },
};

export const reducer: Reducer<CoverPageStoreState> = (state: CoverPageStoreState = initialCoverPageStoreState, incomingAction: Action) => {
    const action = incomingAction as DispatchAction;
    const currentState = Object.assign({}, state);
    switch (action.type) {
        case actionTypes.COVER_PAGE_LOADER:
            return ({ ...currentState, loaderState: action.loading });

        case actionTypes.RECEIVE_COVER_PAGE_DETAILS:
            return { ...currentState, ...action.data };

        default:
            return currentState || initialCoverPageStoreState;
    }
};
