import * as React from 'react';
import Header from "./../../Common/Header/Header";
import Skeleton from "react-loading-skeleton";
import { EngagementType } from './../../../../core/common/Enums';
import { RouteComponentProps } from 'react-router';
import { MatchProps } from '../../Common/OTP/EmailOTP';
import { CoverPageStoreState, HeaderStoreState } from '../../../../store/Login/Models/StoreModels';

interface CoverPageProps extends RouteComponentProps<MatchProps> {
    coverPageStore: CoverPageStoreState;
    requestHeaderDetails: (clientId: string) => void;
    headerStoreState: HeaderStoreState;
    requestCoverClientData: (clientId: string, errorCallBack: () => void) => void;
    setMobileOtpPageData: (mobileNo: string, countryCode: string) => void;
    getGuid: (clientId: string, navigate: (guid: string) => void) => void;
    isPreview: boolean;
}

const CoverPage: React.FC<CoverPageProps> = ({
    requestCoverClientData,
    requestHeaderDetails,
    match: { params: { clientId } },
    history,
    coverPageStore,
    setMobileOtpPageData,
    headerStoreState,
    match,
    isPreview,
    getGuid
}) => {

    React.useEffect(() => {
        requestCoverClientData(clientId, goToErrorPage);
        requestHeaderDetails(clientId);
    }, []);

    const goToErrorPage = () => {
        history.push("/Error");
    };

    const navigate = () => {
        if (!isPreview) {

            if (coverPageStore.engagementType === EngagementType.E1040) {
                history.push(`/Authentication/SSN/${clientId}`);

                if (coverPageStore.mobileVerification) {
                    setMobileOtpPageData(coverPageStore.mobileVerification.mobileNumber,
                        coverPageStore.mobileVerification.countryCode);
                }
            } else {
                const mfaSettings = coverPageStore.mfaSettings;
                if (mfaSettings.iSTPMfaEnabled === true
                    && coverPageStore.mobileVerification ? coverPageStore.mobileVerification.mobileNumber : false
                        && coverPageStore.mobileVerification ? coverPageStore.mobileVerification.countryCode : false) {

                    setMobileOtpPageData(coverPageStore.mobileVerification.mobileNumber,
                        coverPageStore.mobileVerification.countryCode);
                    history.push(`/MobileOTP/Index/${clientId}`);
                } else {
                    history.push(`/OTP/Index/${clientId}`);
                }
            }

        } else {
            getGuid(clientId, goToPreview);
        }

    };

    const goToPreview = (guid: string) => {
        history.push("/taxpayerpreview/" + guid);
    };

    const coverBorderColor = headerStoreState?.brandingSettings?.coverPageSetting?.bgColorCode ?? "";
    const textColorCode = headerStoreState?.brandingSettings?.coverPageSetting?.foreColorCode ?? "";

    return (<>

        <>
            <Header headerInfo={headerStoreState} pathname={match.path} clientId={clientId} history={history} backButton={false} navigateLink="" />
            {
                coverPageStore.loading || headerStoreState.loading ?
                    <Skeleton circle={false} height={"100vh"} width={"100vw"} /> :

                    <div className="cover-page">
                        <div data-test-auto="NYG6C9FLML943538RXC2D9XDW8" style={{ borderColor: coverBorderColor }} className="col-lg-12 col-md-12 col-sm-12 col-xs-12 cover-page-container" onClick={navigate}>
                            <div className="welcome-page-navigator" style={{ color: textColorCode, backgroundColor: coverBorderColor }}>
                                <a data-test-auto="28KGQXY3F9PM926T2586PDPQJW" >Click Here to Get Started</a>
                            </div>
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 welcome-page-content-box">
                                <span data-test-auto="JY3NUDQEG0D471NH0XPU52468D" className="tax-year-header">{coverPageStore.taxYear} Tax Return Extension</span>
                                <h5 data-test-auto="W4GRMP71REY4Z1DV1YG7L6XDLH" className="margin-top-10">{coverPageStore.clientName}</h5>
                            </div>
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 welcome-page-content-box prepare-by margin-top-50">
                                <div>
                                    <span data-test-auto="DKL160X9V0A45ATKDN4REGDFE0" className="prepared-by-title">Prepared by</span>
                                </div>
                                <div>
                                    {
                                        coverPageStore.logoPath.length !== 0 ?

                                            <img data-test-auto="VM1Y507X3KAMT985AC3UCPACMW" className="prepared-by-img" src={coverPageStore.logoPath} />
                                            :
                                            <span data-test-auto="R3YCTEA0QLM4T6Z3495P4NJ1DR" className="prepared-by-company-name">{coverPageStore.companyName}</span>

                                    }


                                </div>

                                <div className="margin-top-10">
                                    {coverPageStore.logoPath.length !== 0 &&
                                        <span data-test-auto="VM1Y507X3KAMT985AC3UCPACMW">{headerStoreState.companyName}</span>
                                    }
                                    <br />
                                </div>
                            </div>
                        </div>
                    </div>
            }
        </>

    </>);

};

export default CoverPage;
